import { createSlice } from '@reduxjs/toolkit';

import { getClientOrderFilters } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	client_order_filters: {},
	loading_client_order_filters: {},
	error: null,
};

const clientOrderFiltersSlice = createSlice({
	name: 'client_order_filters',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getClientOrderFilters.pending, state => {
			state.loading_client_order_filters = true;
		});

		builder.addCase(getClientOrderFilters.fulfilled, (state, action) => {
			state.client_order_filters = action.payload?.data || {};
			state.loading_client_order_filters = false;
		});

		builder.addCase(getClientOrderFilters.rejected, (state, action) => {
			state.error = action?.error || null;
			state.loading_client_order_filters = false;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default clientOrderFiltersSlice.reducer;
