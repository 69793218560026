import React, { useEffect } from 'react';
import { Card } from 'reactstrap';

import { useNavigate, useLocation } from 'react-router-dom';

const OrderSuccessfullySent = () => {
	document.title = 'Pedido enviado';

	const navigate = useNavigate();
	const location = useLocation();

	const { order_id } = location.state || {};

	useEffect(() => {
		if (!order_id) {
			navigate('/');
		}
	}, [order_id]);

	return (
		<div className="page-content d-flex justify-content-center align-items-center">
			<Card className="align-items-center bg-success-subtle text-center p-4" style={{ maxWidth: 450 }}>
				<div
					className="bg-light rounded-circle d-flex justify-content-center align-items-center"
					style={{ height: 90, width: 90 }}>
					<i className="ri-check-double-fill text-success" style={{ fontSize: 42 }}></i>
				</div>

				<h4 className="mb-3 mt-4">Pedido #{order_id}</h4>

				<p className="text-muted fs-15 mb-4">
					Su pedido ha sido recibido con éxito y está en proceso de verificación.
				</p>

				<div className="hstack gap-3 justify-content-center">
					<button className="btn btn-success px-5" onClick={() => navigate('/')}>
						Volver al inicio
					</button>
				</div>
			</Card>
		</div>
	);
};

export { OrderSuccessfullySent };
