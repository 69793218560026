import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	getMergedConfigurations as getMergedConfigurationsAPI,
	updateMergedConfigurations as updateMergedConfigurationsAPI,
	replaceMergedConfigurations as replaceMergedConfigurationsAPI,
} from '../../helpers/backend_helper';

export const getMergedConfigurations = createAsyncThunk('user/getMergedConfigurations', async () => {
	return getMergedConfigurationsAPI();
});

export const updateMergedConfigurations = createAsyncThunk('user/updateMergedConfigurations', async data => {
	try {
		const response = await updateMergedConfigurationsAPI(data);

		if (!response.success) {
			throw new Error(response);
		}

		toast.success('Edición de configuración realizada con éxito', { autoClose: 3000 });

		return data;
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error';

		toast.error(message, { autoClose: 3000 });
		throw error;
	}
});

export const replaceMergedConfigurations = createAsyncThunk('user/replaceMergedConfigurations', async data => {
	try {
		const response = await replaceMergedConfigurationsAPI(data);

		if (!response.success) {
			throw new Error(response);
		}

		toast.success('Edición de configuración realizada con éxito', { autoClose: 3000 });

		return data;
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error';

		toast.error(message, { autoClose: 3000 });
		throw error;
	}
});
