import React from 'react';

import OtherEventItem from './OtherEventItem';
import OperationEventItem from './OperationEventItem';

const others_events = ['stationary_period', 'polyline_group'];

const EventItem = ({ event, ...props }) => {
	if (others_events.includes(event.type)) {
		return <OtherEventItem event={event} {...props} />;
	}

	return <OperationEventItem event={event} isInsideTrackingSession={true} {...props} />;
};

export default EventItem;
