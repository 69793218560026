import { createSlice } from '@reduxjs/toolkit';

import { resetState } from '../../actions/resetState';

const saved_cart_items = JSON.parse(localStorage.getItem('cart_items'));

export const initialState = {
	cart_items: saved_cart_items || [],
	deadline: null,
	observations: null,
};

const LayoutSlice = createSlice({
	name: 'new_order',
	initialState,

	reducers: {
		addCartItem(state, action) {
			const { quantity, product_data } = action.payload;
			const { product_id } = product_data;

			// eslint-disable-next-line eqeqeq
			const item_already_on_cart = state.cart_items.find(item => item.product_data.product_id == product_id);

			if (item_already_on_cart) {
				// If item is already on cart add new quantity
				state.cart_items = state.cart_items.map(item => {
					// eslint-disable-next-line eqeqeq
					if (item.product_data.product_id == product_id) {
						item.quantity += quantity;
					}

					return item;
				});

				return;
			}

			state.cart_items = [...state.cart_items, action.payload];
		},

		removeCartItem(state, action) {
			const product_id = action.payload;

			// eslint-disable-next-line eqeqeq
			state.cart_items = state.cart_items.filter(item => item.product_data.product_id != product_id);
		},

		addProductItem(state, action) {
			const product_id = action.payload;

			state.cart_items = state.cart_items.map(item => {
				// eslint-disable-next-line eqeqeq
				if (item.product_data.product_id == product_id) {
					item.quantity += 1;
				}

				return item;
			});
		},

		removeProductItem(state, action) {
			const product_id = action.payload;

			state.cart_items = state.cart_items.map(item => {
				// eslint-disable-next-line eqeqeq
				if (item.product_data.product_id == product_id) {
					const current_quantity = item.quantity;

					if (current_quantity > 1) {
						item.quantity -= 1;
					}
				}

				return item;
			});
		},

		addDeadline(state, action) {
			state.deadline = action.payload;
		},

		addObservations(state, action) {
			state.observations = action.payload;
		},

		cleanNewOrder(state) {
			state.cart_items = [];
			state.deadline = null;
			state.observations = null;
		},
	},

	extraReducers: builder => {
		// * Reset state
		builder.addCase(resetState, () => initialState);

		// * Save cart items copy on every state update
		builder.addMatcher(
			action => action.type.startsWith('new_order'),
			(state, action) => {
				if (
					action.type === 'new_order/addCartItem' ||
					action.type === 'new_order/removeCartItem' ||
					action.type === 'new_order/addProductItem' ||
					action.type === 'new_order/removeProductItem'
				) {
					localStorage.setItem('cart_items', JSON.stringify(state.cart_items));
				}
			}
		);
	},
});

export const {
	addCartItem,
	removeCartItem,
	addProductItem,
	removeProductItem,
	addDeadline,
	addObservations,
	cleanNewOrder,
} = LayoutSlice.actions;

export default LayoutSlice.reducer;
