import React from 'react';
import { Col, Row, Button } from 'reactstrap';

import classnames from 'classnames';

import { humanReadableTimeElapsedForLastPosition } from '../../helpers/dates_helper';

const UserLastPositionItem = ({
	userLastPosition,
	canBeSelected = true,
	setCenterCoordinates = () => {},
	goToTrackingSession = () => {},
}) => {
	const { data } = userLastPosition;
	const { first_name, last_name, last_coordinates } = data || {};
	const { latitude, longitude, recorded_at } = last_coordinates || {};

	const time_elapsed_from_recorded_at = humanReadableTimeElapsedForLastPosition(recorded_at);

	const seeOnMap = () => {
		setCenterCoordinates({ lat: latitude, lng: longitude });
	};

	return (
		<div className="mb-2">
			<Row
				className={classnames(
					'd-flex align-items-center border border-1 rounded shadow mx-2 px-2 bg-body-secondary map-last-position-event-item'
				)}
				style={{ height: 58 }}>
				<Row className="justify-content-center mx-0 px-0 ps-1">
					<Col className="col mx-0 px-0">
						<h5
							className="col my-0 mx-0 fs-14"
							style={{ paddingBottom: 2 }}>{`${first_name} ${last_name}`}</h5>

						<p
							className="d-flex align-items-center mb-0 fs-12 text-muted"
							style={{ paddingTop: 2, lineHeight: '14px' }}>
							<i className="ri-time-line fs-13 align-bottom label-icon me-1"></i>
							{time_elapsed_from_recorded_at}
						</p>
					</Col>

					{canBeSelected ? (
						<Col className="col-auto mx-0 px-0">
							<Button
								onClick={seeOnMap}
								color="dark"
								className="btn btn-sm border-dark border-opacity-25"
								outline>
								Ver en mapa
							</Button>
						</Col>
					) : null}
				</Row>
			</Row>
		</div>
	);
};

export default UserLastPositionItem;
