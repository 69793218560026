import { createSlice } from '@reduxjs/toolkit';

import { getUserTracking } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	user_tracking: null,
	loading_user_tracking: false,
	error: null,
};

const userTrackingSlice = createSlice({
	name: 'tracking',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getUserTracking.pending, state => {
			state.loading_user_tracking = true;
		});

		builder.addCase(getUserTracking.fulfilled, (state, action) => {
			state.user_tracking = action.payload?.data || null;
			state.loading_user_tracking = false;
		});

		builder.addCase(getUserTracking.rejected, (state, action) => {
			state.error = action?.error || null;
			state.loading_user_tracking = false;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default userTrackingSlice.reducer;
