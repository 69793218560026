/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Col, Label, Row, Spinner, Button } from 'reactstrap';

import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import moment from 'moment';
import classnames from 'classnames';

import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es';

import EventItem from '../../components/maps/EventItem';
import MapPolyline from '../../components/maps/MapPolyline';
import MapCurrentLocationMarker from '../../components/maps/MapCurrentLocationMarker';
import UserLastPositionItem from '../../components/maps/UserLastPositionItem';
import StartEventItem from '../../components/maps/StartEventItem';
import LastEventItem from '../../components/maps/LastEventItem';
import OperationEventItem from '../../components/maps/OperationEventItem';

import {
	getHumanTimeDifference,
	humanReadableTimeElapsedForLastPosition,
	getHumanTimeRemaining,
} from '../../helpers/dates_helper';
import { processLastPositions, processTrackingData } from '../../helpers/tracking_helper';

import {
	getUserTracking as onGetUserTracking,
	getUsersLastPosition as onGetUsersLastPosition,
} from '../../slices/thunks';
import AdvancedMapMarker from '../../components/maps/AdvancedMapMarker';
import OperationMapMarker from '../../components/maps/OperationMapMarker';
import MapPolylinePathDivider from '../../components/maps/MapPolylinePathDivider';

// Note: Google Maps libraries must to be included this way, not inside useJsApiLoader()
// See: https://github.com/JustFly1984/react-google-maps-api/issues/238
const google_maps_libraries = ['marker'];

const google_map_options = {
	mapId: '79d9a14a9062e60f',
	fullscreenControl: false,
	mapTypeControlOptions: { position: 11.0 },
};

// Update data every 10 minutes
const seconds_between_automatic_updates = 10 * 60;

// Use Rosario coordinates as default
// Must to be to initialize map correctly
const default_center_coordinates = { lat: -32.953191, lng: -60.665572 };

window.GPS_MAP_DEV_MODE = false;

const AgentsTracking = () => {
	document.title = 'Geolocalización';

	const dispatch = useDispatch();

	const mapRef = useRef();

	const [activeTab, setActiveTab] = useState('last-positions');
	const [secondsRemainingForNextUpdate, setSecondsRemainingForNextUpdate] = useState(0);

	const [centerCoordinates, setCenterCoordinates] = useState(null);
	const [usersLastPositionEvents, setLastPositionsEvents] = useState([]);
	const [usersLastPositionCoordinates, setLastPositionsCoordinates] = useState([]);

	const [selectedUserId, setSelectedUserId] = useState('');
	const [selectedDate, setSelectedDate] = useState(new Date());

	const [endpointsCoordinates, setEndpointsCoordinates] = useState([]);
	const [allCoordinates, setAllCoordinates] = useState([]);
	const [stationaryPeriods, setStationaryPeriods] = useState([]);
	const [operationsCoordinates, setOperationsCoordinates] = useState([]);
	const [polylinesGroups, setPolylinesGroups] = useState([]);
	const [eventGroups, setEventGroups] = useState([]);
	const [trackingStats, setTrackingStats] = useState({});

	const [focusedElement, setFocusedElement] = useState({
		type: null,
		id: null,
	});

	const [hoveredElement, setHoveredElement] = useState({
		type: null,
		id: null,
	});

	// * Load Google Maps
	const { isLoaded: apiIsLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyDKCWMiYWIz431eGyRSLjpOP9w4aCHMPSc',
		libraries: google_maps_libraries,
	});

	const onLoad = useCallback(map => {
		mapRef.current = {
			map,
			infoWindow: new window.google.maps.InfoWindow(),
		};
	});

	// * Last positions
	const selectUsersLastPosition = createSelector(
		state => state.UsersLastPosition,
		state => ({
			users_last_position: state.users_last_position,
			loading_users_last_position: state.loading_users_last_position,
			error: state.error,
		})
	);

	const { users_last_position, loading_users_last_position } = useSelector(selectUsersLastPosition);

	// Get users last position
	// And update automatically every 5 minutes
	useEffect(() => {
		dispatch(onGetUsersLastPosition());
	}, []);

	useEffect(() => {
		if (activeTab === 'last-positions') {
			loadLastPositions();
		}
	}, [users_last_position]);

	const loadLastPositions = () => {
		if (!users_last_position) {
			return;
		}

		const { divided_last_positions_events, last_positions_coordinates, center_coordinates } =
			processLastPositions(users_last_position);

		setLastPositionsEvents(divided_last_positions_events);
		setLastPositionsCoordinates(last_positions_coordinates);
		setCenterCoordinates(center_coordinates);
	};

	// * Tracking session
	const selectUserTracking = createSelector(
		state => state.UserTracking,
		state => ({
			user_tracking: state.user_tracking,
			loading_user_tracking: state.loading_user_tracking,
			error: state.error,
		})
	);

	const { user_tracking, loading_user_tracking } = useSelector(selectUserTracking);

	useEffect(() => {
		if (selectedUserId && selectedDate) {
			dispatch(onGetUserTracking({ user_id: selectedUserId, date: moment(selectedDate).format('YYYY-MM-DD') }));
		}
	}, [selectedUserId, selectedDate]);

	useEffect(() => {
		if (activeTab === 'tracking-session') {
			loadTrackingSession();
		}
	}, [user_tracking]);

	const loadTrackingSession = () => {
		if (!user_tracking) {
			return;
		}

		const { event_groups, merged_data, tracking_stats, center_coordinates } = processTrackingData(user_tracking);

		const { operations_coordinates, endpoints_coordinates, stationary_periods, all_coordinates, polylines_groups } =
			merged_data;

		setEventGroups(event_groups);
		setTrackingStats(tracking_stats);
		setCenterCoordinates(center_coordinates);

		setOperationsCoordinates(operations_coordinates);
		setEndpointsCoordinates(endpoints_coordinates);
		setStationaryPeriods(stationary_periods);
		setAllCoordinates(all_coordinates);
		setPolylinesGroups(polylines_groups);

		setFocusedElement({
			type: null,
			id: null,
		});

		setHoveredElement({
			type: null,
			id: null,
		});
	};

	// Remove focused elements on InfoWindows close
	useEffect(() => {
		if (!mapRef?.current?.infoWindow) {
			return;
		}

		const listener = window.google.maps.event.addListener(mapRef.current.infoWindow, 'closeclick', function () {
			setFocusedElement({ type: null, id: null });
		});

		// eslint-disable-next-line consistent-return
		return () => {
			window.google.maps.event.removeListener(listener);
		};
	}, [mapRef?.current?.infoWindow]);

	// If a polyline is selected and InfoWindow is open, close
	useEffect(() => {
		if (focusedElement.type === 'polyline') {
			mapRef.current?.infoWindow?.close();
		}
	}, [focusedElement]);

	// Handle Escape key press
	useEffect(() => {
		const handleEscapePress = event => {
			if (event.key === 'Escape') {
				setFocusedElement({ type: null, id: null });

				// Close all InfoWindows popups
				mapRef.current?.infoWindow?.close();
			}
		};

		window.addEventListener('keydown', handleEscapePress);

		return () => {
			window.removeEventListener('keydown', handleEscapePress);
		};
	}, []);

	const getPolylineStroke = id => {
		if (focusedElement.type) {
			if (focusedElement.type === 'polyline' && focusedElement.id === id) {
				return 1;
			}

			return 0.4;
		}

		if (hoveredElement.type) {
			if (hoveredElement.type === 'polyline' && hoveredElement.id === id) {
				return 1;
			}

			return 0.4;
		}

		return 1;
	};

	const goToUsersLastPosition = () => {
		cleanTrackingSession();
		setActiveTab('last-positions');

		loadLastPositions();
	};

	const goToTrackingSession = user_id => {
		cleanLastPositions();

		if (user_id) {
			setSelectedUserId(user_id);
			setSelectedDate(new Date());
		} else {
			loadTrackingSession();
		}

		setActiveTab('tracking-session');
	};

	const cleanLastPositions = () => {
		setLastPositionsEvents([]);
		setLastPositionsCoordinates([]);
	};

	const cleanTrackingSession = () => {
		setEventGroups([]);
		setTrackingStats({});
		setCenterCoordinates(null);

		setOperationsCoordinates([]);
		setEndpointsCoordinates([]);
		setStationaryPeriods([]);
		setAllCoordinates([]);
		setPolylinesGroups([]);
	};

	// * Periodic updates
	useEffect(() => {
		// Update data every 10 minutes
		const interval_id = setInterval(() => {
			dispatch(onGetUsersLastPosition());
		}, seconds_between_automatic_updates * 1000);

		return () => clearInterval(interval_id);
	}, []);

	useEffect(() => {
		if (secondsRemainingForNextUpdate === 0) {
			setSecondsRemainingForNextUpdate(seconds_between_automatic_updates);
			return;
		}

		const interval_id = setInterval(() => {
			setSecondsRemainingForNextUpdate(secondsRemainingForNextUpdate - 1);
		}, 1000);

		// eslint-disable-next-line consistent-return
		return () => clearInterval(interval_id);
	}, [secondsRemainingForNextUpdate]);

	return (
		<div className="full-page-content">
			<div className="container-full-page">
				<div className="map-tracking-panel">
					<div
						className="map-tracking-section-selection w-100 justify-content-center align-content-center bg-dark bg-opacity-10 rounded shadow p-1"
						style={{ height: 58 }}>
						<Row className="mx-0 px-2 gap-2">
							<Col className="px-0">
								<Button
									onClick={() => goToUsersLastPosition()}
									className="w-100 btn-border rounded px-1"
									color={activeTab === 'last-positions' ? 'secondary' : 'light'}
									style={{ height: 40 }}>
									<div className="d-flex justify-content-center align-content-center align-items-center">
										<i
											className="ri-map-pin-user-fill fs-16 me-1"
											style={{ lineHeight: '16px' }}></i>

										<span style={{ paddingTop: '1px' }}>Ultima ubicación</span>
									</div>
								</Button>
							</Col>

							<Col className="px-0">
								<Button
									onClick={() => goToTrackingSession()}
									className="w-100 btn-border rounded px-1"
									color={activeTab === 'tracking-session' ? 'secondary' : 'light'}
									style={{ height: 40 }}>
									<div className="d-flex justify-content-center align-content-center align-items-center">
										<i
											className="ri-pin-distance-fill fs-16 me-2"
											style={{ lineHeight: '16px' }}></i>

										<span style={{ paddingTop: '1px' }}>Recorridos</span>
									</div>
								</Button>
							</Col>
						</Row>
					</div>

					<div
						className="d-flex border border-1 bg-light rounded shadow"
						style={{ height: 'calc(100% - 10px - 58px - 16px)', marginTop: 10 }}>
						{activeTab === 'last-positions' ? (
							<div className="d-flex w-100 flex-column">
								{usersLastPositionEvents?.today ? (
									<>
										<div className="d-flex flex-column flex-grow-1 overflow-auto pt-2 pb-4">
											{usersLastPositionEvents.today.length ? (
												<div className="d-flex justify-content-center align-items-center mb-2">
													<i className="ri-map-pin-line fs-15 text-primary me-1"></i>
													<span className="fs-15">Hoy</span>
												</div>
											) : null}

											{usersLastPositionEvents.today.map(item => (
												<UserLastPositionItem
													key={item.id}
													userLastPosition={item}
													canBeSelected={true}
													setCenterCoordinates={setCenterCoordinates}
													goToTrackingSession={goToTrackingSession}
												/>
											))}

											{usersLastPositionEvents.yesterday.length ? (
												<div className="d-flex justify-content-center align-items-center mb-2">
													<i className="ri-map-pin-line fs-15 text-primary me-1"></i>
													<span className="fs-15">Ayer</span>
												</div>
											) : null}

											{usersLastPositionEvents.yesterday.map(item => (
												<UserLastPositionItem
													key={item.id}
													userLastPosition={item}
													canBeSelected={true}
													setCenterCoordinates={setCenterCoordinates}
													goToTrackingSession={goToTrackingSession}
												/>
											))}

											{usersLastPositionEvents.older.length ? (
												<div className="row text-center mt-1 mb-2 mx-0">
													<div className="d-flex justify-content-center align-items-center">
														<i className="ri-map-pin-line fs-15 text-primary me-1"></i>
														<span className="fs-15">Hace más de 2 días</span>
													</div>

													<span className="fs-11 fw-normal px-2">(No son visibles)</span>
												</div>
											) : null}

											{usersLastPositionEvents.older.map(item => (
												<UserLastPositionItem
													key={item.id}
													userLastPosition={item}
													canBeSelected={false}
													goToTrackingSession={goToTrackingSession}
												/>
											))}
										</div>

										<div
											className="px-3 border-top border-2 bg-light-subtle rounded-bottom"
											style={{ paddingTop: 10, paddingBottom: 10 }}>
											<div className="d-flex justify-content-center">
												<span className="fs-12">
													Proxima actualización en:{' '}
													{getHumanTimeRemaining(secondsRemainingForNextUpdate)}
												</span>
											</div>
										</div>
									</>
								) : (
									<div className="flex-grow-1 text-center mt-5">
										{loading_user_tracking ? (
											<Spinner size="sm" className="flex-shrink-0"></Spinner>
										) : (
											<p className="mb-0">No hay usuarios disponibles</p>
										)}
									</div>
								)}
							</div>
						) : null}

						{activeTab === 'tracking-session' ? (
							<div className="d-flex w-100 flex-column">
								<div
									className="px-3 border-bottom border-2 bg-light-subtle rounded-top"
									style={{ paddingTop: 10, paddingBottom: 10 }}>
									<Row className="px-2 gap-3">
										<Col className="mx-0 px-0">
											<Label htmlFor="track-user" className="form-label mb-1">
												Usuario
											</Label>

											<div className="input-group">
												<select
													className="form-select fs-13"
													id="track-user"
													onChange={event => setSelectedUserId(event.target.value)}
													value={selectedUserId}
													disabled={
														loading_users_last_position || !users_last_position?.length
													}>
													<option value="" hidden disabled>
														Seleccionar usuario
													</option>

													{(users_last_position || [])?.map(item => (
														<option key={item.user_id} value={item.user_id}>
															{`${item.first_name} ${item.last_name}`}
														</option>
													))}
												</select>
											</div>
										</Col>

										<Col className="col-4 mx-0 px-0">
											<Label htmlFor="track-date" className="form-label mb-1">
												Fecha
											</Label>

											<div className="input-group">
												<Flatpickr
													id="deadline-date-field"
													className="form-control fs-13"
													options={{
														minDate: moment().subtract(10, 'day').startOf('day').toDate(),
														maxDate: new Date(),
														dateFormat: 'd/m/Y',
														locale: Spanish,
													}}
													value={selectedDate}
													onChange={([date]) => {
														setSelectedDate(date);
													}}
													title="Modificar fecha de recorrido"
												/>
											</div>
										</Col>
									</Row>
								</div>

								<div className="d-flex flex-column flex-grow-1 overflow-auto pt-2 pb-4">
									{eventGroups?.length ? (
										<div className="d-flex justify-content-center align-items-center bg-info-subtle text-info mb-2 px-3 py-2">
											<i className="ri-information-fill fs-15 me-1"></i>
											Los cálculos de tiempo son aproximados
										</div>
									) : null}

									{eventGroups?.length ? (
										eventGroups.map(event_group => {
											if (event_group.type === 'tracking_session') {
												const { endpoints_coordinates, events } = event_group.data;

												return (
													<div key={events[0].id} style={{ marginBottom: 15 }}>
														<StartEventItem
															event={endpoints_coordinates.start_coordinates}
															mapRef={mapRef}
															focusedElement={focusedElement}
															setFocusedElement={setFocusedElement}
															hoveredElement={hoveredElement}
															setHoveredElement={setHoveredElement}
														/>

														{events.map(event => (
															<EventItem
																key={event.id}
																mapRef={mapRef}
																event={event}
																focusedElement={focusedElement}
																setFocusedElement={setFocusedElement}
																hoveredElement={hoveredElement}
																setHoveredElement={setHoveredElement}
															/>
														))}

														<LastEventItem
															event={endpoints_coordinates.finish_coordinates}
															mapRef={mapRef}
															focusedElement={focusedElement}
															setFocusedElement={setFocusedElement}
															hoveredElement={hoveredElement}
															setHoveredElement={setHoveredElement}
														/>
													</div>
												);
											}

											if (event_group.type === 'operations_outside_tracking') {
												const event = event_group.data;

												return (
													<OperationEventItem
														key={event.id}
														mapRef={mapRef}
														event={event}
														isInsideTrackingSession={false}
													/>
												);
											}

											return null;
										})
									) : (
										<div className="flex-grow-1 text-center mt-5">
											{loading_user_tracking ? (
												<Spinner size="sm" className="flex-shrink-0"></Spinner>
											) : (
												<p className="mb-0">No hay datos para el dia seleccionado</p>
											)}
										</div>
									)}
								</div>

								{eventGroups?.length ? (
									<div className="border-top border-2 bg-light-subtle rounded-bottom px-0">
										<Row className="px-0 mx-0">
											<Col className="col-4 mx-0 py-2 px-0">
												<Row className="mx-0 px-0 justify-content-center">
													<Col className="col-auto align-content-center px-0 pe-2">
														<i className="ri-truck-fill fs-20 text-body text-opacity-75"></i>
													</Col>

													<Col className="col-auto px-0">
														<p className="mb-0">
															{(trackingStats?.total_driving_distance / 1000).toFixed(2)}{' '}
															km
														</p>

														<p className="mb-0">
															{getHumanTimeDifference(
																trackingStats?.total_driving_duration
															)}
														</p>
													</Col>
												</Row>
											</Col>

											<Col className="col-4 mx-0 py-2 px-0 border-start border-end border-1">
												<Row className="mx-0 px-0 justify-content-center">
													<Col className="col-auto align-content-center px-0 pe-2">
														<i className="ri-walk-fill fs-21 text-body text-opacity-75"></i>
													</Col>

													<Col className="col-auto px-0">
														<p className="mb-0">
															{(trackingStats?.total_walking_distance / 1000).toFixed(2)}{' '}
															km
														</p>

														<p className="mb-0">
															{getHumanTimeDifference(
																trackingStats?.total_walking_duration
															)}
														</p>
													</Col>
												</Row>
											</Col>

											<Col className="col-4 mx-0 py-2 px-0">
												<Row className="mx-0 px-0 justify-content-center">
													<Col className="col-auto align-content-center px-0 pe-2">
														<i className="ri-time-fill fs-20 text-body text-opacity-75"></i>
													</Col>

													<Col className="col-auto px-0 align-content-center">
														<p className="mb-0">Inactivo</p>

														<p className="mb-0">
															{getHumanTimeDifference(
																trackingStats?.total_stationary_duration
															)}
														</p>
													</Col>
												</Row>
											</Col>
										</Row>
									</div>
								) : null}
							</div>
						) : null}
					</div>
				</div>
			</div>

			{apiIsLoaded && (
				<GoogleMap
					mapContainerClassName="map-container"
					zoom={centerCoordinates ? 13 : 6}
					center={centerCoordinates || default_center_coordinates}
					onLoad={onLoad}
					options={google_map_options}>
					{usersLastPositionCoordinates.map(item => {
						return (
							<>
								<MapCurrentLocationMarker
									key={item.id}
									mapRef={mapRef}
									id={item.id}
									title={`${item.first_name} ${item.last_name}`}
									subTitle={humanReadableTimeElapsedForLastPosition(
										item.last_coordinates.recorded_at
									)}
									position={{
										lat: item.last_coordinates.latitude,
										lng: item.last_coordinates.longitude,
									}}
									heading={item.last_coordinates.heading}
									focusedElement={focusedElement}
									setFocusedElement={setFocusedElement}
									setHoveredElement={setHoveredElement}
								/>

								<MapPolyline
									key={`last-route-polyline-${item.id}`}
									path={item.last_route_polyline.map(({ latitude, longitude }) => ({
										lat: latitude,
										lng: longitude,
									}))}
									isWalking={false}
									hasDotsOnStart={true}
									strokeOpacity={0.4}
									onClick={() => {}}
								/>
							</>
						);
					})}

					{window.GPS_MAP_DEV_MODE
						? allCoordinates.map(item => (
								<MapPolylinePathDivider
									key={`path-divider-${item.recorded_at}`}
									mapRef={mapRef}
									data={item}
								/>
						  ))
						: null}

					{polylinesGroups.map(group => (
						<MapPolyline
							key={group.id}
							path={group.polylines.map(item => ({
								lat: item.latitude,
								lng: item.longitude,
							}))}
							isWalking={group.is_walking}
							strokeOpacity={getPolylineStroke(group.id)}
							onClick={() => setFocusedElement({ type: 'polyline', id: group.id })}
							onMouseOver={() => setHoveredElement({ type: 'polyline', id: group.id })}
							onMouseOut={() => setHoveredElement({ type: null, id: null })}
						/>
					))}

					{endpointsCoordinates.map(item => (
						<AdvancedMapMarker
							key={item.start_coordinates.id}
							mapRef={mapRef}
							id={item.start_coordinates.id}
							position={{
								lat: item.start_coordinates.latitude,
								lng: item.start_coordinates.longitude,
							}}
							infoWindowContent={item.start_coordinates.info_window_content}
							iconName="ri-flag-fill"
							iconColor="#0ab39c"
							iconSize={14}
							borderColor="#0ab39c"
							backgroundColor="#daf4f0"
							focusedElement={focusedElement}
							setFocusedElement={setFocusedElement}
							hoveredElement={hoveredElement}
							setHoveredElement={setHoveredElement}
						/>
					))}

					{endpointsCoordinates.map(item => {
						if (item.finish_coordinates.is_the_last_position_of_the_day) {
							return (
								<MapCurrentLocationMarker
									key={item.finish_coordinates.id}
									mapRef={mapRef}
									id={item.finish_coordinates.id}
									label={''}
									position={{
										lat: item.finish_coordinates.latitude,
										lng: item.finish_coordinates.longitude,
									}}
									heading={item.finish_coordinates.heading}
									infoWindowContent={item.finish_coordinates.info_window_content}
									focusedElement={focusedElement}
									setFocusedElement={setFocusedElement}
									setHoveredElement={setHoveredElement}
								/>
							);
						}

						return (
							<AdvancedMapMarker
								key={item.finish_coordinates.id}
								mapRef={mapRef}
								id={item.finish_coordinates.id}
								position={{
									lat: item.finish_coordinates.latitude,
									lng: item.finish_coordinates.longitude,
								}}
								infoWindowContent={item.finish_coordinates.info_window_content}
								iconName="ri-flag-fill"
								iconColor="#f06548"
								iconSize={14}
								borderColor="#f06548"
								backgroundColor="#fde8e4"
								focusedElement={focusedElement}
								setFocusedElement={setFocusedElement}
								hoveredElement={hoveredElement}
								setHoveredElement={setHoveredElement}
							/>
						);
					})}

					{stationaryPeriods.map(item => (
						<AdvancedMapMarker
							key={item.id}
							mapRef={mapRef}
							id={item.id}
							position={{ lat: item.coordinates.latitude, lng: item.coordinates.longitude }}
							infoWindowContent={item.info_window_content}
							iconName="ri-time-fill"
							iconSize={15}
							iconColor="#6d6f78"
							borderColor="#6d6f78"
							backgroundColor="#e9ebec"
							focusedElement={focusedElement}
							setFocusedElement={setFocusedElement}
							hoveredElement={hoveredElement}
							setHoveredElement={setHoveredElement}
						/>
					))}

					{operationsCoordinates.map(item => (
						<OperationMapMarker
							key={item.id}
							mapRef={mapRef}
							operation={item}
							position={{ lat: item.coordinates.latitude, lng: item.coordinates.longitude }}
							focusedElement={focusedElement}
							setFocusedElement={setFocusedElement}
							hoveredElement={hoveredElement}
							setHoveredElement={setHoveredElement}
						/>
					))}
				</GoogleMap>
			)}
		</div>
	);
};

export { AgentsTracking };
