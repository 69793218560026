/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Container, Card, CardBody, Button, Spinner, Input } from 'reactstrap';

import { ToastContainer } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import { number as YupNumber } from 'yup';
import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es';

import TableContainer from '../../components/common/TableContainer';
import { setAxiosHeader } from '../../helpers/api_helper';
import { useProfile } from '../../components/hooks/UserHooks';
import BreadCrumb from '../../components/common/BreadCrumb';

import {
	getMergedConfigurations as onGetMergedConfigurations,
	updateMergedConfigurations as onUpdateMergedConfigurations,
	replaceMergedConfigurations as onReplaceMergedConfigurations,
} from '../../slices/thunks';

const InputValue = ({ value, ...props }) => {
	return <input type="text" className="form-control apikey-value" defaultValue={value} {...props} />;
};

const InputNumeric = ({ initialValue, onChange, disabled }) => {
	const [value, setValue] = useState(Number(initialValue));

	const add = () => {
		const new_value = value + 1;

		setValue(new_value);
		onChange(new_value.toString());
	};

	const subtract = () => {
		const new_value = value - 1;

		if (!new_value) {
			return;
		}

		setValue(new_value);
		onChange(new_value.toString());
	};

	return (
		<div className="input-step">
			<button
				type="button"
				className="minus cursor-not-allowed-if-disabled"
				onClick={subtract}
				disabled={disabled}>
				–
			</button>

			<Input
				type="number"
				className="product-quantity cursor-not-allowed-if-disabled"
				value={value}
				min="0"
				readOnly
				disabled={disabled}
			/>

			<button type="button" className="plus cursor-not-allowed-if-disabled" onClick={add} disabled={disabled}>
				+
			</button>
		</div>
	);
};

const InputPercent = ({ initialValue, onChange, disabled }) => {
	const initial_value = Number(initialValue || 0);

	const [value, setValue] = useState(initial_value);

	const checkValue = async new_value => {
		const is_valid_schema = YupNumber()
			.required()
			.positive()
			.min(0)
			.max(100)
			.test(
				'noEOrSign',
				"Number had an 'e' or sign.",
				test_value => typeof test_value === 'number' && !/[eE+-]/.test(test_value.toString())
			);

		// If not valid force to a fixed value
		const is_valid = await is_valid_schema.isValid(new_value);

		if (!is_valid) {
			if (new_value > 100) new_value = 100;
			else new_value = 0;
		}

		// Round to 2 decimals if necessary
		const numeric_new_value = Number(new_value);

		if (!Number.isInteger(numeric_new_value)) {
			new_value = Math.round((numeric_new_value + Number.EPSILON) * 100) / 100;
		}

		setValue(new_value);
		onChange(new_value);
	};

	const handleFocus = event => {
		event.target.select();
	};

	return (
		<div className="form-icon right" style={{ width: 112 }}>
			<Input
				type="tel"
				min={0}
				max={100}
				className="form-control form-control-icon product-quantity cursor-not-allowed-if-disabled"
				value={value}
				onChange={event => checkValue(event.target.value)}
				onFocus={handleFocus}
				disabled={disabled}
			/>
			<i className="ri-percent-line"></i>
		</div>
	);
};

const UserConfigurations = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		userProfile: {
			user: { role_name },
		},
	} = useProfile();

	const { user_id } = useParams();

	const [updatesList, setUpdatesList] = useState([]);
	const [loadingSendUpdates, setLoadingSendUpdates] = useState(false);

	// * Check if updates are saved before close
	useEffect(() => {
		const handleBeforeUnload = event => {
			if (!updatesList?.length) {
				return;
			}

			const mensaje = 'Los cambios no han sido guardados. ¿Esta seguro que desea salir?';
			event.returnValue = mensaje;

			// eslint-disable-next-line consistent-return
			return mensaje;
		};

		const handlePopstate = () => {
			if (!updatesList?.length) {
				return;
			}

			// eslint-disable-next-line no-alert
			const confirm_status = window.confirm('Los cambios no han sido guardados. ¿Esta seguro que desea salir?');

			if (!confirm_status) {
				window.history.pushState({}, '');
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);
		window.addEventListener('popstate', handlePopstate);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
			window.removeEventListener('popstate', handlePopstate);
		};
	}, [updatesList]);

	const handleGoBack = () => {
		// * Check if updates are saved before close
		if (updatesList?.length) {
			// eslint-disable-next-line no-alert
			const confirm_status = window.confirm('Los cambios no han sido guardados. ¿Esta seguro que desea salir?');

			if (confirm_status) {
				if (role_name === 'admin') {
					navigate(-1);
				} else {
					navigate('/users');
				}
			}

			return;
		}

		if (role_name === 'admin') {
			navigate(-1);
		} else {
			navigate('/users');
		}
	};

	// * Get user data
	const [fullName, setFullName] = useState('');

	const selectUsers = createSelector(
		state => state.Users,
		state => ({
			users: state.users,
		})
	);

	const { users } = useSelector(selectUsers);

	useEffect(() => {
		if (!users?.length) {
			navigate('/users');
			return;
		}

		// eslint-disable-next-line eqeqeq
		const { first_name, last_name } = users.find(item => item.user_id == user_id);

		setFullName(`${first_name} ${last_name}`);
	}, [users]);

	// * Get all merged configurations list
	useEffect(() => {
		setAxiosHeader('user-id', user_id);

		dispatch(onGetMergedConfigurations());
	}, []);

	const selectConfigurations = createSelector(
		state => state.MergedConfigurations,
		state => ({
			merged_configurations: state.merged_configurations,
			merged_configurations_array: state.merged_configurations_array,
			loading_merged_configurations: state.loading_merged_configurations,

			isMergedConfigurationUpdate: state.isMergedConfigurationUpdate,
			isMergedConfigurationUpdateFail: state.isMergedConfigurationUpdateFail,
		})
	);

	const {
		merged_configurations,
		merged_configurations_array,
		loading_merged_configurations,
		isMergedConfigurationUpdate,
		isMergedConfigurationUpdateFail,
	} = useSelector(selectConfigurations);

	useEffect(() => {
		setLoadingSendUpdates(false);

		if (isMergedConfigurationUpdate) {
			setUpdatesList([]);
		}
	}, [isMergedConfigurationUpdate, isMergedConfigurationUpdateFail]);

	// Save changes on temporary list
	const saveUpdatesList = (original_item, columns_updates) => {
		setUpdatesList(updates_list => {
			// eslint-disable-next-line eqeqeq
			const index = updates_list.findIndex(item => item.configuration_id == original_item.configuration_id);

			if (index === -1) {
				return [...updates_list, { ...original_item, ...columns_updates }];
			}

			const updated_item = { ...updates_list[index], ...columns_updates };

			// If equal to original, delete item
			if (JSON.stringify(updated_item) === JSON.stringify(original_item)) {
				updates_list.splice(index, 1);
				return updates_list;
			}

			updates_list[index] = updated_item;

			return updates_list;
		});
	};

	const sendUpdates = () => {
		if (role_name === 'admin') {
			sendUpdatesAdmin();
		}

		sendUpdatesOwner();
	};

	const sendUpdatesAdmin = () => {
		setLoadingSendUpdates(true);

		const updates = updatesList.map(item => {
			const { configuration_id, value, is_modifiable, is_visible } = item;

			return {
				configuration_id: configuration_id.toString(),
				value: value.toString(),
				is_modifiable_by_user: is_modifiable.toString(),
				is_visible_to_user: is_visible.toString(),
			};
		});

		const data = { configurations: updates };

		dispatch(onReplaceMergedConfigurations(data));
	};

	const sendUpdatesOwner = () => {
		setLoadingSendUpdates(true);

		const updates = updatesList.map(item => {
			const { configuration_id, value } = item;

			return {
				configuration_id: configuration_id.toString(),
				value: value.toString(),
			};
		});

		const data = { configurations: updates };

		dispatch(onUpdateMergedConfigurations(data));
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Nombre',
				accessor: 'name',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.name;
				},
			},
			{
				Header: 'Valor',
				accessor: 'value',
				filterable: true,
				border: '1px solid black',
				Cell: ({ row }) => {
					return (
						<InputValue
							value={row.original.value}
							style={{ width: 'auto' }}
							onChange={event => saveUpdatesList(row.original, { value: event.target.value })}
						/>
					);
				},
			},
			{
				Header: 'Modificable por usuario',
				accessor: 'is_modifiable',
				filterable: true,
				Cell: ({ row }) => {
					return (
						<div className="form-check form-switch form-switch-lg d-flex justify-content-center mt-1">
							<Input
								className="form-check-input"
								type="checkbox"
								role="switch"
								id={`switch-is_modifiable-${row.original.name}`}
								defaultChecked={Number(row.original.is_modifiable)}
								onChange={event =>
									saveUpdatesList(row.original, { is_modifiable: Number(event.target.checked) })
								}
							/>
						</div>
					);
				},
			},
			{
				Header: 'Visible por usuario',
				accessor: 'is_visible',
				filterable: true,
				style: {
					width: 150,
				},
				Cell: ({ row }) => {
					return (
						<div className="form-check form-switch form-switch-lg d-flex justify-content-center mt-1">
							<Input
								className="form-check-input ms-3"
								type="checkbox"
								role="switch"
								id={`switch-is_visible-${row.original.name}`}
								defaultChecked={Number(row.original.is_visible)}
								onChange={event =>
									saveUpdatesList(row.original, { is_visible: Number(event.target.checked) })
								}
							/>
						</div>
					);
				},
			},
		],
		[]
	);

	const ConfigurationSwitch = useCallback(
		({ mergedConfigurations, configurationName, disabled }) => (
			<div className="form-check form-switch form-switch-lg mt-1">
				<Input
					className="form-check-input ms-2 cursor-not-allowed-if-disabled"
					type="checkbox"
					role="switch"
					id={`switch-${configurationName}`}
					defaultChecked={Number(mergedConfigurations?.[configurationName]?.value) || false}
					onChange={event =>
						saveUpdatesList(
							{
								...mergedConfigurations?.[configurationName],
								name: configurationName,
							},
							{ value: event.target.checked ? '1' : '0' }
						)
					}
					disabled={disabled}
				/>
			</div>
		),
		[]
	);

	const getDefaultDeadlineTime = () => {
		const deadline_time = merged_configurations?.app_default_deadline_time?.value;

		const [hours, minutes] = deadline_time.split(':');

		const now = new Date();

		now.setHours(parseInt(hours, 10));
		now.setMinutes(parseInt(minutes, 10));

		return now;
	};

	const getTimeFromDate = date => {
		const time = date.toLocaleTimeString('en-US', {
			hour: '2-digit',
			minute: '2-digit',
			hour12: false,
		});

		if (time === '24:00') {
			return '00:00';
		}

		return time;
	};

	return (
		<>
			<div className="page-content">
				<Container fluid className="container-fluid">
					<BreadCrumb
						customTitle={
							<>
								Configuraciones
								<span className="mb-0 text-muted">&nbsp; ({fullName})</span>
							</>
						}
						onGoBack={handleGoBack}
						customRightComponent={
							<div className="hstack text-nowrap gap-2">
								<Button
									type="button"
									color="success"
									className="btn btn-label left mt-3 mt-md-0"
									onClick={sendUpdates}
									disabled={updatesList.length === 0 || loadingSendUpdates}
									title="Guardar las modificaciones realizadas">
									{loadingSendUpdates ? (
										<span className="d-flex align-items-center">
											<Spinner size="sm" className="flex-shrink-0"></Spinner>
											<span className="flex-grow-1 ms-2"></span>
										</span>
									) : (
										<>
											<i className="ri-save-3-line label-icon align-bottom"></i>
											Guardar cambios
										</>
									)}
								</Button>
							</div>
						}
					/>

					{role_name === 'admin' && !loading_merged_configurations ? (
						<Card>
							<CardBody className="pt-0">
								<TableContainer
									columns={columns}
									data={merged_configurations_array}
									noDataMessage={'No hay configuraciones disponibles para mostrar'}
									divClass="table-responsive table-card mb-3"
									tableClass="table align-middle table-nowrap mb-0"
									theadClass="table-light"
								/>
							</CardBody>
						</Card>
					) : null}

					{role_name === 'company_owner' && !loading_merged_configurations ? (
						<Card>
							<CardBody className="mt-3 pt-0">
								{!merged_configurations_array?.length ? (
									<div className="d-flex py-5 justify-content-center align-content-center">
										<Spinner />
									</div>
								) : (
									<div className="table-responsive table-card">
										<table className="table table-nowrap align-middle table-borderless table-hover mb-0">
											<thead className="table-light text-muted">
												<tr>
													<th scope="col">Nombre</th>
													<th scope="col" style={{ width: 160 }}>
														Valor
													</th>
												</tr>
											</thead>

											<tbody>
												<tr>
													<td
														colSpan="7"
														className="stripes-background text-center border-0"
														style={{ pointerEvents: 'none' }}>
														<div className="fs-14 pt-1 pb-0">Nuevos pedidos</div>
													</td>
												</tr>

												{merged_configurations?.app_always_use_default_sales_agent
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Usar vendedor designado</div>

															<small className="text-muted">
																Se utilizara el vendedor designado, en caso contrario se
																utilizara el vendedor por defecto del cliente a quien se
																realiza la venta.
															</small>
														</td>

														<td>
															<ConfigurationSwitch
																mergedConfigurations={merged_configurations}
																configurationName={'app_always_use_default_sales_agent'}
																disabled={
																	!merged_configurations
																		?.app_always_use_default_sales_agent
																		.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}

												{merged_configurations?.app_can_see_all_clients
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Ver todos los usuarios</div>

															<small className="text-muted">
																El vendedor podrá ver y realizar un pedido para todos
																los clientes de la empresa.
															</small>
														</td>

														<td>
															<ConfigurationSwitch
																mergedConfigurations={merged_configurations}
																configurationName={'app_can_see_all_clients'}
																disabled={
																	!merged_configurations?.app_can_see_all_clients
																		.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}

												{merged_configurations?.app_price_list_is_modifiable
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Lista de precio modificable</div>

															<small className="text-muted">
																El vendedor podrá elegir la lista de precio que desee
																para el producto seleccionado.
															</small>
														</td>

														<td>
															<ConfigurationSwitch
																mergedConfigurations={merged_configurations}
																configurationName={'app_price_list_is_modifiable'}
																disabled={
																	!merged_configurations?.app_price_list_is_modifiable
																		.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}

												{merged_configurations?.app_is_order_deadline_mandatory
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Fecha de entrega obligatoria</div>

															<small className="text-muted">
																Seleccione si la fecha de entrega es un valor
																obligatorio o no.
															</small>
														</td>

														<td>
															<ConfigurationSwitch
																mergedConfigurations={merged_configurations}
																configurationName={'app_is_order_deadline_mandatory'}
																disabled={
																	!merged_configurations
																		?.app_is_order_deadline_mandatory.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}

												{merged_configurations?.app_default_days_to_deadline_date
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Días para entrega (fecha de entrega)</div>

															<small className="text-muted">
																Número de días a sumar para la fecha de entrega por
																defecto.
															</small>
														</td>

														<td>
															<InputNumeric
																initialValue={
																	merged_configurations
																		?.app_default_days_to_deadline_date?.value
																}
																onChange={new_value =>
																	saveUpdatesList(
																		{
																			...merged_configurations?.app_default_days_to_deadline_date,
																			name: 'app_default_days_to_deadline_date',
																		},
																		{ value: new_value }
																	)
																}
																disabled={
																	!merged_configurations
																		?.app_default_days_to_deadline_date
																		.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}

												{merged_configurations?.app_default_deadline_time
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Horario por defecto (fecha de entrega)</div>

															<small className="text-muted">
																Hora por defecto para la fecha de entrega.
															</small>
														</td>

														<td>
															<Flatpickr
																className="form-control cursor-not-allowed-if-disabled"
																options={{
																	enableTime: true,
																	noCalendar: true,
																	dateFormat: 'H:i',
																	time_24hr: true,
																	defaultDate: getDefaultDeadlineTime(),
																	locale: Spanish,
																}}
																onChange={([date]) => {
																	const time = getTimeFromDate(date);

																	saveUpdatesList(
																		{
																			...merged_configurations?.app_default_deadline_time,
																			name: 'app_default_deadline_time',
																		},
																		{ value: time }
																	);
																}}
																style={{ width: 112 }}
																disabled={
																	!merged_configurations?.app_default_deadline_time
																		.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}

												{merged_configurations?.app_minimum_stock_for_green_indicator_on_product
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Indicador de stock sobrante</div>

															<small className="text-muted">
																Número mínimo para indicador de stock sobrante.
															</small>
														</td>

														<td>
															<InputNumeric
																initialValue={
																	merged_configurations
																		?.app_minimum_stock_for_green_indicator_on_product
																		?.value
																}
																onChange={new_value =>
																	saveUpdatesList(
																		{
																			...merged_configurations?.app_minimum_stock_for_green_indicator_on_product,
																			name: 'app_minimum_stock_for_green_indicator_on_product',
																		},
																		{ value: new_value }
																	)
																}
																disabled={
																	!merged_configurations
																		?.app_minimum_stock_for_green_indicator_on_product
																		.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}

												{merged_configurations
													?.app_minimum_stock_for_orange_indicator_on_product
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Indicador de poco stock</div>

															<small className="text-muted">
																Número mínimo para indicador de poco stock.
															</small>
														</td>

														<td>
															<InputNumeric
																initialValue={
																	merged_configurations
																		?.app_minimum_stock_for_orange_indicator_on_product
																		?.value
																}
																onChange={new_value =>
																	saveUpdatesList(
																		{
																			...merged_configurations?.app_minimum_stock_for_orange_indicator_on_product,
																			name: 'app_minimum_stock_for_orange_indicator_on_product',
																		},
																		{ value: new_value }
																	)
																}
																disabled={
																	!merged_configurations
																		?.app_minimum_stock_for_orange_indicator_on_product
																		.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}

												{/* {merged_configurations?.app_amount_to_add_to_item
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Cantidad a sumar en item</div>

															<small className="text-muted">
																El valor se utilizara en el botón de adicción rápida de
																cantidad.
															</small>
														</td>

														<td>
															<InputNumeric
																initialValue={
																	merged_configurations?.app_amount_to_add_to_item
																		?.value
																}
																onChange={new_value =>
																	saveUpdatesList(
																		{
																			...merged_configurations?.app_amount_to_add_to_item,
																			name: 'app_amount_to_add_to_item',
																		},
																		{ value: new_value }
																	)
																}
																disabled={
																	!merged_configurations?.app_amount_to_add_to_item
																		.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}

												{merged_configurations?.app_amount_to_subtract_to_item
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Cantidad a restar en item</div>

															<small className="text-muted">
																El valor se utilizara en el botón de sustracción rápida
																de cantidad.
															</small>
														</td>

														<td>
															<InputNumeric
																initialValue={
																	merged_configurations
																		?.app_amount_to_subtract_to_item?.value
																}
																onChange={new_value =>
																	saveUpdatesList(
																		{
																			...merged_configurations?.app_amount_to_subtract_to_item,
																			name: 'app_amount_to_subtract_to_item',
																		},
																		{ value: new_value }
																	)
																}
																disabled={
																	!merged_configurations
																		?.app_amount_to_subtract_to_item.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}

												{merged_configurations?.app_discount_to_add_to_item
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Descuento a sumar en item</div>

															<small className="text-muted">
																El valor se utilizara en el botón de adicción rápida de
																descuento.
															</small>
														</td>

														<td>
															<InputNumeric
																initialValue={
																	merged_configurations?.app_discount_to_add_to_item
																		?.value
																}
																onChange={new_value =>
																	saveUpdatesList(
																		{
																			...merged_configurations?.app_discount_to_add_to_item,
																			name: 'app_discount_to_add_to_item',
																		},
																		{ value: new_value }
																	)
																}
																disabled={
																	!merged_configurations?.app_discount_to_add_to_item
																		.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}

												{merged_configurations?.app_discount_to_subtract_to_item
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Descuento a restar en item</div>

															<small className="text-muted">
																El valor se utilizara en el botón de sustracción rápida
																de descuento.
															</small>
														</td>

														<td>
															<InputNumeric
																initialValue={
																	merged_configurations
																		?.app_discount_to_subtract_to_item?.value
																}
																onChange={new_value =>
																	saveUpdatesList(
																		{
																			...merged_configurations?.app_discount_to_subtract_to_item,
																			name: 'app_discount_to_subtract_to_item',
																		},
																		{ value: new_value }
																	)
																}
																disabled={
																	!merged_configurations
																		?.app_discount_to_subtract_to_item
																		.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}

												{merged_configurations
													?.app_go_to_order_items_automatically_on_save_items
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Ir al carrito automáticamente</div>

															<small className="text-muted">
																Se navegara hasta el carrito automáticamente luego de
																enviar items.
															</small>
														</td>

														<td>
															<ConfigurationSwitch
																mergedConfigurations={merged_configurations}
																configurationName={
																	'app_go_to_order_items_automatically_on_save_items'
																}
																disabled={
																	!merged_configurations
																		?.app_go_to_order_items_automatically_on_save_items
																		.is_overridable
																}
															/>
														</td>
													</tr>
												) : null} */}

												<tr className="border-top border-top-dashed">
													<td
														colSpan="7"
														className="stripes-background text-center border-0"
														style={{ pointerEvents: 'none' }}>
														<div className="fs-14 pt-1 pb-0">Nuevos clientes</div>
													</td>
												</tr>

												{merged_configurations?.app_default_discount_percent_for_new_user
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Descuento por defecto</div>

															<small className="text-muted">
																Descuento global por defecto del nuevo cliente.
															</small>
														</td>

														<td>
															<InputPercent
																initialValue={
																	merged_configurations
																		?.app_default_discount_percent_for_new_user
																		?.value
																}
																onChange={new_value =>
																	saveUpdatesList(
																		{
																			...merged_configurations?.app_default_discount_percent_for_new_user,
																			name: 'app_default_discount_percent_for_new_user',
																		},
																		{ value: new_value }
																	)
																}
																disabled={
																	!merged_configurations
																		?.app_default_discount_percent_for_new_user
																		.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}

												{merged_configurations?.app_can_apply_any_discount_to_new_user
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Descuento modificable</div>

															<small className="text-muted">
																El vendedor podrá modificar el descuento global por
																defecto del nuevo cliente.
															</small>
														</td>

														<td>
															<ConfigurationSwitch
																mergedConfigurations={merged_configurations}
																configurationName={
																	'app_can_apply_any_discount_to_new_user'
																}
																disabled={
																	!merged_configurations
																		?.app_can_apply_any_discount_to_new_user
																		.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}

												{merged_configurations?.app_can_apply_any_sales_agent_to_new_user
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Vendedor modificable</div>

															<small className="text-muted">
																El vendedor podrá elegir a otro vendedor como designado
																para el nuevo cliente.
															</small>
														</td>

														<td>
															<ConfigurationSwitch
																mergedConfigurations={merged_configurations}
																configurationName={
																	'app_can_apply_any_sales_agent_to_new_user'
																}
																disabled={
																	!merged_configurations
																		?.app_can_apply_any_sales_agent_to_new_user
																		.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}

												{merged_configurations?.app_can_apply_any_price_list_to_new_user
													.is_modifiable_by_company_owner ? (
													<tr className="border-top border-top-dashed">
														<td>
															<div>Lista de precios modificable</div>

															<small className="text-muted">
																El vendedor podrá modificar la lista de precios por
																defecto del nuevo cliente.
															</small>
														</td>

														<td>
															<ConfigurationSwitch
																mergedConfigurations={merged_configurations}
																configurationName={
																	'app_can_apply_any_price_list_to_new_user'
																}
																disabled={
																	!merged_configurations
																		?.app_can_apply_any_price_list_to_new_user
																		.is_overridable
																}
															/>
														</td>
													</tr>
												) : null}
											</tbody>
										</table>
									</div>
								)}
							</CardBody>
						</Card>
					) : null}

					{loading_merged_configurations ? (
						<Card>
							<CardBody className="pt-0">
								<div className="text-center pt-5 mb-4 pb-1">
									<Spinner />
								</div>
							</CardBody>
						</Card>
					) : null}

					<div className="d-flex align-items-center pb-5">
						<i className="ri-alert-line label-icon text-warning fs-18 me-2"></i>
						Los cambios impactaran en todos los dispositivos en un plazo de 5 horas.
					</div>
				</Container>
			</div>

			<ToastContainer closeButton={false} />
		</>
	);
};

export { UserConfigurations };
