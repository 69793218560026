import { DateTime } from 'luxon';

const getHumanTimeDifference = difference_on_seconds => {
	const seconds = Math.floor(difference_on_seconds);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);

	const remaining_seconds = seconds % 60;
	const remaining_minutes = minutes % 60;
	const remaining_hours = hours % 24;

	let result = '';

	if (days > 0) {
		result += `${days}d`;
		if (remaining_hours > 0) result += ` ${remaining_hours}hs`;
	} else if (hours > 0) {
		result += `${hours}hs`;
		if (remaining_minutes > 0) result += ` ${remaining_minutes}m`;
	} else if (minutes > 0) {
		result += `${minutes}m`;
		if (remaining_seconds > 0) result += ` ${remaining_seconds}s`;
	} else {
		result += `${remaining_seconds}s`;
	}

	return result.trim();
};

const humanReadableTimeElapsedForLastPosition = recorded_at => {
	if (!recorded_at) {
		return 'Hace más de 3 días';
	}

	const date_now = DateTime.now();
	const date_from = DateTime.fromISO(recorded_at, { zone: 'UTC' }).setZone('America/Buenos_Aires');

	const days_difference = parseInt(date_now.diff(date_from, 'days').days || 0, 10);
	const minutes_difference = parseInt(date_now.diff(date_from, 'minutes').minutes || 0, 10);
	const hours_difference = Math.floor(minutes_difference / 60);

	let human_readable_time_elapsed;

	if (minutes_difference < 1) {
		human_readable_time_elapsed = 'Hace unos segundos';
	} else if (minutes_difference >= 1 && minutes_difference < 2) {
		human_readable_time_elapsed = `Hace 1 minuto`;
	} else if (minutes_difference < 60) {
		human_readable_time_elapsed = `Hace ${minutes_difference} minutos`;
	} else if (minutes_difference >= 60 && minutes_difference < 120) {
		human_readable_time_elapsed = `Hace 1 hora`;
	} else if (minutes_difference < 8 * 60) {
		human_readable_time_elapsed = `Hace ${hours_difference} horas`;
	} else if (date_now.hasSame(date_from, 'day')) {
		human_readable_time_elapsed = `Hoy ${date_from.toFormat('HH:mm')} hs`;
	} else if (days_difference < 2) {
		human_readable_time_elapsed = `Ayer ${date_from.toFormat('HH:mm')} hs`;
	} else if (days_difference < 4) {
		human_readable_time_elapsed = `Hace más de ${days_difference} días`;
	} else {
		human_readable_time_elapsed = date_from.toFormat('dd/MM/yy HH:mm');
	}

	return human_readable_time_elapsed;
};

const formatHour = string_date => {
	if (!string_date) {
		return null;
	}

	const date = new Date(string_date);

	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');

	return `${hours}:${minutes}`;
};

function getHumanTimeRemaining(time_remaining, with_details = true) {
	if (!time_remaining) {
		return null;
	}

	if (time_remaining < 0) {
		return null;
	}

	const years = Math.floor(time_remaining / 31536000);
	const months = Math.floor(time_remaining / 2628000);
	const days = Math.floor(time_remaining / 86400);
	const hours = Math.floor(time_remaining / 3600);
	const minutes = Math.floor((time_remaining % 3600) / 60);
	const seconds = time_remaining % 60;

	const formatted_hours = hours < 10 ? `0${hours}` : hours;
	const formatted_minutes = minutes < 10 ? `0${minutes}` : minutes;
	const formatted_seconds = seconds < 10 ? `0${seconds}` : seconds;

	if (years > 0) {
		return years + (years === 1 ? ' año' : ' años'); // Example: 1 año, 2 años
	}

	if (months > 0) {
		return months + (months === 1 ? ' mes' : ' meses'); // Example: 1 mes, 2 meses
	}

	if (days > 0) {
		return days + (days === 1 ? ' día' : ' días'); // Example: 1 día, 2 días
	}

	if (formatted_hours !== '00') {
		if (with_details) {
			return `${formatted_hours}:${formatted_minutes}:${formatted_seconds} horas`; // Example: 05:52:10 horas
		}

		return hours + (hours === 1 ? ' hora' : ' horas'); // Example: 1 hora, 5 horas
	}

	if (formatted_minutes !== '00') {
		if (with_details) {
			return `${formatted_minutes}:${formatted_seconds} minutos`; // Example: 52:10 minutos
		}

		return minutes + (minutes === 1 ? ' minuto' : ' minutos'); // Example: 1 minuto, 52 minutos
	}

	if (with_details) {
		return `${formatted_seconds} segundos`; // Example: 08 segundos
	}

	return seconds + (seconds === 1 ? ' segundo' : ' segundos'); // Example: 1 segundo, 8 segundos
}

const formatStartEndDuration = (start_coordinates, finish_coordinates) => {
	if (!start_coordinates || !finish_coordinates) {
		return null;
	}

	const start_hour = formatHour(start_coordinates);
	const finish_hour = formatHour(finish_coordinates);

	return `${start_hour} a ${finish_hour}`;
};

export {
	getHumanTimeDifference,
	humanReadableTimeElapsedForLastPosition,
	formatHour,
	getHumanTimeRemaining,
	formatStartEndDuration,
};
