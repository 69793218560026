import { createSlice } from '@reduxjs/toolkit';

import { getMergedPermissions, updateMergedPermissions } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	merged_permissions: [],
	loading_merged_permissions: false,
	error: null,

	isMergedPermissionUpdate: false,
	isMergedPermissionUpdateFail: false,
};

const mergedPermissionsSlice = createSlice({
	name: 'merged_permissions',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getMergedPermissions.pending, state => {
			state.loading_merged_permissions = true;
		});

		builder.addCase(getMergedPermissions.fulfilled, (state, action) => {
			const merged_permissions = action.payload?.data?.permissions || [];

			const merged_permissions_array = Object.keys(merged_permissions).map(key => ({
				name: key,
				...merged_permissions[key],
			}));

			state.merged_permissions = merged_permissions_array;
			state.loading_merged_permissions = false;
		});

		builder.addCase(getMergedPermissions.rejected, (state, action) => {
			state.error = action?.error || null;
			state.loading_merged_permissions = false;
		});

		// * Update
		builder.addCase(updateMergedPermissions.pending, state => {
			state.isMergedPermissionUpdate = false;
			state.isMergedPermissionUpdateFail = false;
		});

		builder.addCase(updateMergedPermissions.fulfilled, (state, action) => {
			state.merged_permissions = state.merged_permissions.map(merged_permission => {
				const new_permission = action.payload.permissions.find(
					item => item.permission_id.toString() === merged_permission.permission_id.toString()
				);

				if (!new_permission) {
					return merged_permission;
				}

				return { ...merged_permission, ...new_permission };
			});

			state.isMergedPermissionUpdate = true;
			state.isMergedPermissionUpdateFail = false;
		});

		builder.addCase(updateMergedPermissions.rejected, (state, action) => {
			state.error = action?.error || null;

			state.isMergedPermissionUpdate = false;
			state.isMergedPermissionUpdateFail = true;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default mergedPermissionsSlice.reducer;
