import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	getAssignments as getAssignmentsAPI,
	replaceAssignments as replaceAssignmentsAPI,
} from '../../helpers/backend_helper';

export const getAssignments = createAsyncThunk('user/getAssignments', async () => {
	return getAssignmentsAPI();
});

export const replaceAssignments = createAsyncThunk('user/replaceAssignments', async data => {
	try {
		const response = await replaceAssignmentsAPI(data);

		if (!response.success) {
			throw new Error(response);
		}

		toast.success('Edición de asociación realizada con éxito', { autoClose: 3000 });

		return data;
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error';

		toast.error(message, { autoClose: 3000 });
		throw error;
	}
});
