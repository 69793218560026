import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row, Button, Label } from 'reactstrap';

const BreadCrumb = ({
	title,
	customTitle = null,
	pageTitle,
	onGoBack = null,
	goBackTo = null,
	customRightComponent = null,
}) => {
	const navigate = useNavigate();

	return (
		<React.Fragment>
			<Row>
				<Col xs={12}>
					<div className="page-title-box d-sm-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center">
							{onGoBack || goBackTo ? (
								<>
									<Button
										id="go-back-button"
										onClick={() => (onGoBack ? onGoBack() : navigate(goBackTo))}
										className="btn btn-light btn-label px-3"
										style={{ height: 30, width: 40 }}>
										<i className="ri-arrow-left-line align-bottom label-icon text-body-emphasis w-100 fs-13 ps-1"></i>
									</Button>

									<Label
										htmlFor="go-back-button"
										className="h4 cursor-pointer mb-0 pt-1 pb-1 ps-3 pe-2">
										{customTitle || title}
									</Label>
								</>
							) : (
								<h4 className="mb-0">{title}</h4>
							)}
						</div>

						<div className="page-title-right d-none d-sm-block">
							{customRightComponent || (
								<ol className="breadcrumb m-0">
									<li className="breadcrumb-item">
										<Link to="#">{pageTitle}</Link>
									</li>

									<li className="breadcrumb-item active">{title}</li>
								</ol>
							)}
						</div>
					</div>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default BreadCrumb;
