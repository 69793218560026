import { createSlice } from '@reduxjs/toolkit';

import { getPaginatedCollectionsAgents } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	collections_agents: [],
	pagination: {},
	error: null,
};

const collections_agentsSlice = createSlice({
	name: 'collections_agents',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getPaginatedCollectionsAgents.fulfilled, (state, action) => {
			state.collections_agents = action.payload?.data?.collections_agents || [];
			state.pagination = action.payload?.pagination || {};
		});

		builder.addCase(getPaginatedCollectionsAgents.rejected, (state, action) => {
			state.error = action?.error || null;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default collections_agentsSlice.reducer;
