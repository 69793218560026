/* eslint-disable react/jsx-indent */
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { Container, Card, CardBody, Button, Spinner, Input } from 'reactstrap';

import { ToastContainer } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import { setAxiosHeader } from '../../helpers/api_helper';
import TableContainer from '../../components/common/TableContainer';
import { useProfile } from '../../components/hooks/UserHooks';
import BreadCrumb from '../../components/common/BreadCrumb';

import {
	getMergedPermissions as onGetMergedPermissions,
	updateMergedPermissions as onUpdateMergedPermissions,
} from '../../slices/thunks';

const UserPermissions = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		userProfile: {
			user: { role_name: administrator_role_name },
		},
	} = useProfile();

	const { user_id } = useParams();

	const [updatesList, setUpdatesList] = useState([]);
	const [loadingSendUpdates, setLoadingSendUpdates] = useState(false);

	// * Check if updates are saved before close
	useEffect(() => {
		const handleBeforeUnload = event => {
			if (!updatesList?.length) {
				return;
			}

			const mensaje = 'Los cambios no han sido guardados. ¿Esta seguro que desea salir?';
			event.returnValue = mensaje;

			// eslint-disable-next-line consistent-return
			return mensaje;
		};

		const handlePopstate = () => {
			if (!updatesList?.length) {
				return;
			}

			// eslint-disable-next-line no-alert
			const confirm_status = window.confirm('Los cambios no han sido guardados. ¿Esta seguro que desea salir?');

			if (!confirm_status) {
				window.history.pushState({}, '');
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);
		window.addEventListener('popstate', handlePopstate);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
			window.removeEventListener('popstate', handlePopstate);
		};
	}, [updatesList]);

	const handleGoBack = () => {
		// * Check if updates are saved before close
		if (updatesList?.length) {
			// eslint-disable-next-line no-alert
			const confirm_status = window.confirm('Los cambios no han sido guardados. ¿Esta seguro que desea salir?');

			if (confirm_status) {
				if (administrator_role_name === 'admin') {
					navigate(-1);
				} else {
					navigate('/users');
				}
			}

			return;
		}

		if (administrator_role_name === 'admin') {
			navigate(-1);
		} else {
			navigate('/users');
		}
	};

	// * Get user data
	const [userFullName, setUserFullName] = useState('');
	const [userRoleName, setUserRoleName] = useState(null);

	const selectUsers = createSelector(
		state => state.Users,
		state => ({
			users: state.users,
		})
	);

	const { users } = useSelector(selectUsers);

	useEffect(() => {
		if (!users?.length) {
			navigate('/users');
			return;
		}

		// eslint-disable-next-line eqeqeq
		const { first_name, last_name, role_name } = users.find(item => item.user_id == user_id);

		setUserFullName(`${first_name} ${last_name}`);
		setUserRoleName(role_name);
	}, [users]);

	// * Get all users list
	useEffect(() => {
		setAxiosHeader('user-id', user_id);

		dispatch(onGetMergedPermissions());
	}, []);

	const selectMergedPermissions = createSelector(
		state => state.MergedPermissions,
		state => ({
			merged_permissions: state.merged_permissions,
			loading_merged_permissions: state.loading_merged_permissions,
			error: state.error,

			isMergedPermissionUpdate: state.isMergedPermissionUpdate,
			isMergedPermissionUpdateFail: state.isMergedPermissionUpdateFail,
		})
	);

	const { merged_permissions, loading_merged_permissions, isMergedPermissionUpdate, isMergedPermissionUpdateFail } =
		useSelector(selectMergedPermissions);

	useEffect(() => {
		setLoadingSendUpdates(false);

		if (isMergedPermissionUpdate) {
			setUpdatesList([]);
		}
	}, [isMergedPermissionUpdate, isMergedPermissionUpdateFail]);

	// Save changes on temporary list
	const saveUpdatesList = (original_item, columns_updates) => {
		setUpdatesList(updates_list => {
			// eslint-disable-next-line eqeqeq
			const index = updates_list.findIndex(item => item.permission_id == original_item.permission_id);

			if (index === -1) {
				return [...updates_list, { ...original_item, ...columns_updates }];
			}

			const updated_item = { ...updates_list[index], ...columns_updates };

			// If equal to original, delete item
			if (JSON.stringify(updated_item) === JSON.stringify(original_item)) {
				updates_list.splice(index, 1);
				return updates_list;
			}

			updates_list[index] = updated_item;

			return updates_list;
		});
	};

	const sendUpdates = () => {
		setLoadingSendUpdates(true);

		const updates = updatesList.map(item => {
			const { permission_id, value } = item;

			return {
				permission_id: permission_id.toString(),
				value: value.toString(),
			};
		});

		const data = { permissions: updates };

		dispatch(onUpdateMergedPermissions(data));
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Nombre',
				accessor: 'name',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.name;
				},
			},
			{
				Header: 'Valor',
				accessor: 'value',
				filterable: true,
				Cell: ({ row }) => {
					return (
						<div className="form-check form-switch form-switch-lg mt-1">
							<Input
								className="form-check-input ms-3"
								type="checkbox"
								role="switch"
								id={`switch-value-${row.original.name}`}
								defaultChecked={Number(row.original.value)}
								onChange={event =>
									saveUpdatesList(row.original, { value: Number(event.target.checked) })
								}
							/>
						</div>
					);
				},
			},
		],
		[]
	);

	const PermissionSwitch = useCallback(({ mergedPermissions, permissionName }) => {
		const permission = mergedPermissions.find(item => item.name === permissionName);

		const default_checked = Number(permission?.value) || false;

		const onChange = event => {
			saveUpdatesList(permission, { value: event.target.checked ? 1 : 0 });
		};

		return (
			<div className="form-check form-switch form-switch-lg mt-1">
				<Input
					className="form-check-input ms-2"
					type="checkbox"
					role="switch"
					id={`switch-${permissionName}`}
					defaultChecked={default_checked}
					onChange={onChange}
				/>
			</div>
		);
	}, []);

	return (
		<>
			<div className="page-content">
				<Container fluid className="container-fluid">
					<BreadCrumb
						customTitle={
							<>
								Permisos
								<span className="mb-0 text-muted">&nbsp; ({userFullName})</span>
							</>
						}
						onGoBack={handleGoBack}
						customRightComponent={
							<div className="hstack text-nowrap gap-2">
								<Button
									type="button"
									color="success"
									className="btn btn-label left mt-3 mt-md-0"
									onClick={sendUpdates}
									disabled={updatesList.length === 0 || loadingSendUpdates}
									title="Guardar las modificaciones realizadas">
									{loadingSendUpdates ? (
										<span className="d-flex align-items-center">
											<Spinner size="sm" className="flex-shrink-0"></Spinner>
											<span className="flex-grow-1 ms-2"></span>
										</span>
									) : (
										<>
											<i className="ri-save-3-line label-icon align-bottom"></i>
											Guardar cambios
										</>
									)}
								</Button>
							</div>
						}
					/>

					{administrator_role_name === 'admin' && !loading_merged_permissions ? (
						<Card>
							<CardBody className="pt-0">
								<div>
									<TableContainer
										columns={columns}
										data={merged_permissions}
										noDataMessage={'No hay permisos disponibles para mostrar'}
										divClass="table-responsive table-card mb-3"
										tableClass="table align-middle table-nowrap mb-0"
										theadClass="table-light"
									/>
								</div>
							</CardBody>
						</Card>
					) : null}

					{administrator_role_name === 'company_owner' && !loading_merged_permissions ? (
						<Card>
							<CardBody className="mt-3 pt-0">
								{!merged_permissions?.length ? (
									<div className="d-flex py-5 justify-content-center align-content-center">
										<Spinner />
									</div>
								) : (
									<div className="table-responsive table-card">
										<table className="table table-nowrap align-middle table-borderless table-hover mb-0">
											<thead className="table-light text-muted">
												<tr>
													<th scope="col">Nombre</th>
													<th scope="col">Valor</th>
												</tr>
											</thead>

											<tbody>
												{userRoleName === 'company_agent' ? (
													<tr className="border-top border-top-dashed">
														<td>Acceso a nuevos pedidos</td>

														<td>
															<PermissionSwitch
																mergedPermissions={merged_permissions}
																permissionName={'can_access_to_new_order'}
															/>
														</td>
													</tr>
												) : null}

												<tr className="border-top border-top-dashed">
													<td>Acceso a productos</td>

													<td>
														<PermissionSwitch
															mergedPermissions={merged_permissions}
															permissionName={'can_access_to_products'}
														/>
													</td>
												</tr>

												<tr className="border-top border-top-dashed">
													<td>Acceso a clientes</td>

													<td>
														<PermissionSwitch
															mergedPermissions={merged_permissions}
															permissionName={'can_access_to_clients'}
														/>
													</td>
												</tr>

												{userRoleName === 'company_agent' ? (
													<tr className="border-top border-top-dashed">
														<td>Crear nuevos clientes</td>

														<td>
															<PermissionSwitch
																mergedPermissions={merged_permissions}
																permissionName={'can_access_to_new_clients'}
															/>
														</td>
													</tr>
												) : null}

												<tr className="border-top border-top-dashed">
													<td>Acceso a deudas de clientes</td>

													<td>
														<PermissionSwitch
															mergedPermissions={merged_permissions}
															permissionName={'can_access_to_clients_debts'}
														/>
													</td>
												</tr>

												<tr className="border-top border-top-dashed">
													<td>Acceso a proveedores</td>

													<td>
														<PermissionSwitch
															mergedPermissions={merged_permissions}
															permissionName={'can_access_to_suppliers'}
														/>
													</td>
												</tr>

												<tr className="border-top border-top-dashed">
													<td>Acceso a deudas de proveedores</td>

													<td>
														<PermissionSwitch
															mergedPermissions={merged_permissions}
															permissionName={'can_access_to_suppliers_debts'}
														/>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								)}
							</CardBody>
						</Card>
					) : null}

					{loading_merged_permissions ? (
						<Card>
							<CardBody className="pt-0">
								<div className="text-center pt-5 mb-4 pb-1">
									<Spinner />
								</div>
							</CardBody>
						</Card>
					) : null}

					<div className="d-flex  align-items-center">
						<i className="ri-alert-line label-icon text-warning fs-18 me-2"></i>
						Los cambios impactaran en todos los dispositivos en un plazo de 5 horas.
					</div>
				</Container>
			</div>

			<ToastContainer closeButton={false} />
		</>
	);
};

export { UserPermissions };
