import { createSlice } from '@reduxjs/toolkit';

import { getAllClientOrders, addNewOrder } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	client_orders: [],
	loading_client_orders: false,
	sending_client_orders: false,
	error: null,
};

const clientOrdersSlice = createSlice({
	name: 'client_orders',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getAllClientOrders.pending, state => {
			state.loading_client_orders = true;
		});

		builder.addCase(getAllClientOrders.fulfilled, (state, action) => {
			state.client_orders = action.payload?.data?.orders || [];
			state.loading_client_orders = false;
		});

		builder.addCase(getAllClientOrders.rejected, (state, action) => {
			state.error = action?.error || null;
			state.loading_client_orders = false;
		});

		// * Add
		builder.addCase(addNewOrder.pending, state => {
			state.sending_client_orders = true;
		});

		builder.addCase(addNewOrder.fulfilled, (state, action) => {
			const {
				order_id,
				client_id,
				client_legal_name,
				sales_agent_id,
				sales_agent_name,
				deadline,
				final_price_without_iva,
				final_price_with_iva,
				total_discount_with_iva,
				observations,
			} = action.payload;

			const new_client_order = {
				order_id,
				company_id: null,
				user_id: null,
				client_id,
				client_legal_name,
				sales_agent_id,
				sales_agent_name,
				deadline: new Date(deadline).toISOString(),
				final_price_without_iva,
				final_price_with_iva,
				total_discount_with_iva,
				observations,
				status: 'confirmed',
				execute_auto_confirm_at: null,
				updated_at: null,
				created_at: new Date(),
				deleted_at: null,
			};

			state.client_orders.push(new_client_order);

			state.sending_client_orders = false;
		});

		builder.addCase(addNewOrder.rejected, (state, action) => {
			state.error = action?.error || null;

			state.sending_client_orders = false;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default clientOrdersSlice.reducer;
