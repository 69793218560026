import { createSlice } from '@reduxjs/toolkit';

import { getAllUsers, addNewUser, updateUser, deleteUser } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	users: [],
	error: null,

	isUserAdded: false,
	isUserAddedFail: false,
	isUserUpdate: false,
	isUserUpdateFail: false,
	isUserDelete: false,
	isUserDeleteFail: false,
};

const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		// * Update user data
		updateUserData(state, action) {
			const { user_id, data } = action.payload;

			state.users = state.users?.map(item => {
				if (item.user_id === user_id) {
					item = { ...item, ...data };
				}

				return item;
			});
		},
	},

	extraReducers: builder => {
		// * Get
		builder.addCase(getAllUsers.fulfilled, (state, action) => {
			state.users = action.payload?.data?.users || {};
		});

		builder.addCase(getAllUsers.rejected, (state, action) => {
			state.error = action?.error || null;
		});

		// * Add
		builder.addCase(addNewUser.pending, state => {
			state.isUserAdded = false;
			state.isUserAddedFail = false;
		});

		builder.addCase(addNewUser.fulfilled, (state, action) => {
			state.users.push(action.payload);

			state.isUserAdded = true;
			state.isUserAddedFail = false;
		});

		builder.addCase(addNewUser.rejected, (state, action) => {
			state.error = action?.error || null;

			state.isUserAdded = false;
			state.isUserAddedFail = true;
		});

		// * Update
		builder.addCase(updateUser.pending, state => {
			state.isUserUpdate = false;
			state.isUserUpdateFail = false;
		});

		builder.addCase(updateUser.fulfilled, (state, action) => {
			state.users = state.users.map(item =>
				item.user_id.toString() === action.payload.user_id.toString() ? { ...item, ...action.payload } : item
			);

			state.isUserUpdate = true;
			state.isUserUpdateFail = false;
		});

		builder.addCase(updateUser.rejected, (state, action) => {
			state.error = action?.error || null;

			state.isUserUpdate = false;
			state.isUserUpdateFail = true;
		});

		// * Delete
		builder.addCase(deleteUser.pending, state => {
			state.isUserDelete = false;
			state.isUserDeleteFail = false;
		});

		builder.addCase(deleteUser.fulfilled, (state, action) => {
			state.users = (state.users || []).filter(
				item => item.user_id.toString() !== action.payload.user_id.toString()
			);

			state.isUserDelete = true;
			state.isUserDeleteFail = false;
		});

		builder.addCase(deleteUser.rejected, (state, action) => {
			state.error = action?.error || null;

			state.isUserDelete = false;
			state.isUserDeleteFail = true;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export const { updateUserData } = usersSlice.actions;

export default usersSlice.reducer;
