/* eslint-disable indent */
import React, { useEffect, useRef, useState } from 'react';
import {
	Container,
	Row,
	Card,
	CardHeader,
	Col,
	Input,
	Pagination,
	PaginationItem,
	PaginationLink,
	Table,
	Button,
	Spinner,
	ModalBody,
	Modal,
	ModalHeader,
	ModalFooter,
} from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { createSelector } from 'reselect';
import styled, { keyframes } from 'styled-components';

// eslint-disable-next-line no-unused-vars
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';

import {
	getClientOrderFilters as onGetClientOrderFilters,
	getPaginatedProducts as onGetPaginatedProducts,
	addCartItem as onAddCartItem,
	removeCartItem as onRemoveCartItem,
} from '../../slices/thunks';

import BreadCrumb from '../../components/common/BreadCrumb';
import useDebounce from '../../components/hooks/UseDebounce';
import { useWindowDimensions } from '../../components/hooks/useWindowDimensions';

const expandAndShrink = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
`;

const StyledButton = styled.button`
	&.animated {
		animation: ${expandAndShrink} 0.3s ease-in-out;
	}
`;

const ProductsSelection = () => {
	document.title = 'Selección de Productos';

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { width: screen_width } = useWindowDimensions();

	// * Filters
	const [clientOrderFilters, setClientOrderFilters] = useState({});

	const selectClientOrderFilters = createSelector(
		state => state.ClientOrderFilters,
		state => ({
			client_order_filters: state.client_order_filters,
			loading_client_order_filters: state.loading_client_order_filters,
			error: state.error,
		})
	);

	const { client_order_filters, loading_client_order_filters } = useSelector(selectClientOrderFilters);

	useEffect(() => {
		if (!Object.keys(client_order_filters).length) {
			dispatch(onGetClientOrderFilters());
		}
	}, []);

	useEffect(() => {
		setClientOrderFilters(client_order_filters);

		const { categories = [], brands = [], groups = [] } = client_order_filters;

		const categories_arranged_alphabetically = [...categories]?.sort((a, b) => a.name.localeCompare(b.name));
		const brands_arranged_alphabetically = [...brands]?.sort((a, b) => a.name.localeCompare(b.name));
		const groups_arranged_alphabetically = [...groups]?.sort((a, b) => a.name.localeCompare(b.name));

		setCategories(categories_arranged_alphabetically);
		setBrands(brands_arranged_alphabetically);
		setGroups(groups_arranged_alphabetically);

		const categorized_categories = getCategorizedCategories(categories_arranged_alphabetically);
		const categorized_brands = getCategorizedCategories(brands_arranged_alphabetically);
		const categorized_groups = getCategorizedCategories(groups_arranged_alphabetically);

		setFilteredCategories(categorized_categories);
		setFilteredBrands(categorized_brands);
		setFilteredGroups(categorized_groups);
	}, [client_order_filters]);

	// * Paginated products
	const [productList, setProductList] = useState([]);
	const [paginationData, setPaginationData] = useState({});
	const [currentPage, setCurrentPage] = useState(1);

	const [minimumPriceSelected, setMinimumPriceSelected] = useState(0); // eslint-disable-line no-unused-vars
	const [maximumPriceSelected, setMaximumPriceSelected] = useState(1); // eslint-disable-line no-unused-vars
	const [priceRange, setPriceRange] = useState([0, 1]);

	const [search, setSearch] = useState(null);

	const [brands, setBrands] = useState([]);
	const [filteredBrands, setFilteredBrands] = useState({}); // eslint-disable-line no-unused-vars
	const [selectedBrandId, setSelectedBrandId] = useState(null);

	const [categories, setCategories] = useState([]);
	const [filteredCategories, setFilteredCategories] = useState({}); // eslint-disable-line no-unused-vars
	const [selectedCategoryId, setSelectedCategoryId] = useState(null);

	const [groups, setGroups] = useState([]);
	const [filteredGroups, setFilteredGroups] = useState({});
	const [selectedGroupId, setSelectedGroupId] = useState(null);

	const selectProducts = createSelector(
		state => state.Products,
		state => ({
			pagination: state.pagination,
			products: state.products,
			loading_products: state.loading_products,
		})
	);

	const { pagination, products, loading_products } = useSelector(selectProducts);

	useDebounce(
		() => {
			dispatch(
				onGetPaginatedProducts({
					page: currentPage || '1',
					description: search || '',
					category_id: selectedCategoryId || '',
					brand_id: selectedBrandId || '',
					group_id: selectedGroupId || '',
					sub_group_id: '',
				})
			);

			setCurrentPage(1);
		},
		[search, selectedCategoryId, selectedBrandId, selectedGroupId],
		500
	);

	const onPageChange = new_page => {
		dispatch(
			onGetPaginatedProducts({
				page: new_page || '',
				description: search || '',
				category_id: selectedCategoryId || '',
				brand_id: selectedBrandId || '',
				group_id: selectedGroupId || '',
				sub_group_id: '',
			})
		);

		setCurrentPage(Number(new_page));
	};

	useEffect(() => {
		if (!Object.keys(clientOrderFilters).length) {
			return;
		}

		const full_product_list = addDataToProducts(products);

		const prices_array = full_product_list.map(item => Number(item.unit_price_with_iva));

		let minimum_price = Math.min(...prices_array);
		let maximum_price = Math.max(...prices_array);

		if (!Number.isFinite(minimum_price)) {
			minimum_price = 0;
		}

		if (!Number.isFinite(maximum_price)) {
			maximum_price = 1;
		}

		onUpdatePriceSelection([minimum_price, maximum_price]);

		setPriceRange([minimum_price, maximum_price]);

		setProductList(full_product_list);

		if (currentPage === 1) {
			setPaginationData(pagination);
		}
	}, [products, pagination, clientOrderFilters]);

	const addDataToProducts = products_array => {
		return products_array.map(product => {
			const {
				unit_price_with_iva,
				unit_price_without_iva,
				discount,
				unit_discount_with_iva,
				strikethrough_price_with_iva,
				unit_price_with_iva_and_discount,
			} = getProductPrice(product);

			return {
				...product,
				unit_price_with_iva,
				unit_price_without_iva,
				discount,
				unit_discount_with_iva,
				strikethrough_price_with_iva,
				unit_price_with_iva_and_discount,
			};
		});
	};

	const getProductPrice = product_data => {
		const { include_iva } = clientOrderFilters.price_list;

		const client_discount = Number(clientOrderFilters?.client?.discount || '0');
		const product_discount = Number(clientOrderFilters?.product?.discount || '0');

		const price_data = clientOrderFilters.prices?.find(item => {
			// eslint-disable-next-line eqeqeq
			return item.product_id == product_data.product_id;
		});

		const item_price = parseFloat(price_data.price);

		const iva = parseFloat(product_data.iva);
		const iva_multiplier = iva > 0 ? iva / 100 + 1 : 1;

		const unit_price_with_iva = include_iva ? item_price : item_price * iva_multiplier;
		const unit_price_without_iva = include_iva ? item_price / iva_multiplier : item_price;

		const discount = client_discount + product_discount;
		const unit_discount_with_iva = discount > 0 ? (unit_price_with_iva * discount) / 100 : 0;

		const getStrikethroughPrice = () => {
			if (client_discount > 0 && product_discount > 0) {
				const client_discount_with_iva = (unit_price_with_iva * client_discount) / 100;

				return unit_price_with_iva - client_discount_with_iva;
			}

			if (product_discount > 0) {
				return unit_price_with_iva;
			}

			return 0;
		};

		const strikethrough_price_with_iva = getStrikethroughPrice();
		const unit_price_with_iva_and_discount = unit_price_with_iva - unit_discount_with_iva;

		return {
			unit_price_with_iva,
			unit_price_without_iva,
			discount,
			unit_discount_with_iva,
			strikethrough_price_with_iva,
			unit_price_with_iva_and_discount,
		};
	};

	const generateButtonsForPagination = () => {
		const { last_page } = paginationData;

		const buttons = [];

		const maximum_buttons = screen_width > 768 ? 6 : screen_width > 400 ? 4 : 3;

		// Calculate the range of buttons to be displayed
		let start = Math.max(1, currentPage - Math.floor(maximum_buttons / 2));
		const end = Math.min(last_page, start + maximum_buttons - 1);

		// Adjust the range if necessary
		if (last_page - end < Math.floor(maximum_buttons / 2)) {
			start = Math.max(1, end - maximum_buttons + 1);
		}

		for (let i = start; i <= end; i++) {
			buttons.push(
				<PaginationItem key={i} active={i === currentPage} className="pagination-item">
					<PaginationLink to="#" onClick={() => onPageChange(i)}>
						{i}
					</PaginationLink>
				</PaginationItem>
			);
		}

		return buttons;
	};

	const onUpdatePriceSelection = values => {
		if (loading_client_order_filters) {
			return;
		}

		const minimum_price_selected = Number(values[0]);
		const maximum_price_selected = Number(values[1]);

		// eslint-disable-next-line prefer-destructuring
		document.getElementById('minCost').value = minimum_price_selected;

		// eslint-disable-next-line prefer-destructuring
		document.getElementById('maxCost').value = maximum_price_selected;

		setMinimumPriceSelected(minimum_price_selected);
		setMaximumPriceSelected(maximum_price_selected);
	};

	// * Brands
	const [isBrandsModalOpen, setIsBrandsModalOpen] = useState(false);

	const openBrandsModal = () => {
		setIsBrandsModalOpen(true);
	};

	const closeBrandsModal = () => {
		setIsBrandsModalOpen(false);

		setTimeout(() => {
			const categorized_brands = getCategorizedBrands(brands);
			setFilteredBrands(categorized_brands);
		}, 200);
	};

	const selectBrandsItem = item => {
		setIsBrandsModalOpen(false);
		selectBrand(item.brand_id);

		closeBrandsModal();
	};

	const searchBrands = search_value => {
		const filtered_brands = clientOrderFilters.brands?.filter(item => {
			if (!search_value) {
				return item;
			}

			const brand_name = item.name?.toLowerCase();

			return brand_name.includes(search_value.toLowerCase());
		});

		const categorized_brands = getCategorizedBrands(filtered_brands);

		setFilteredBrands(categorized_brands);
	};

	const getCategorizedBrands = filtered_categories => {
		const categorized_brands = {};

		filtered_categories.forEach(item => {
			const initial_letter = item.name[0].toUpperCase();

			if (!categorized_brands[initial_letter]) {
				categorized_brands[initial_letter] = [];
			}

			categorized_brands[initial_letter].push(item);
		});

		return categorized_brands;
	};

	const selectBrand = brand_id => {
		setSelectedBrandId(brand_id);
	};

	const getBrandName = brand_id => {
		return clientOrderFilters.brands?.find(item => item.brand_id === brand_id)?.name;
	};

	// * Categories
	const [isCategoriesModalOpen, setIsCategoriesModalOpen] = useState(false);

	const openCategoriesModal = () => {
		setIsCategoriesModalOpen(true);
	};

	const closeCategoriesModal = () => {
		setIsCategoriesModalOpen(false);

		setTimeout(() => {
			const categorized_categories = getCategorizedCategories(categories);
			setFilteredCategories(categorized_categories);
		}, 200);
	};

	const selectCategoriesItem = item => {
		setIsCategoriesModalOpen(false);
		selectCategory(item.category_id);

		closeCategoriesModal();
	};

	const searchCategories = search_value => {
		const filtered_categories = clientOrderFilters.categories?.filter(item => {
			if (!search_value) {
				return item;
			}

			const category_name = item.name?.toLowerCase();

			return category_name.includes(search_value.toLowerCase());
		});

		const categorized_categories = getCategorizedCategories(filtered_categories);

		setFilteredCategories(categorized_categories);
	};

	const getCategorizedCategories = filtered_categories => {
		const categorized_categories = {};

		filtered_categories.forEach(item => {
			const initial_letter = item.name[0].toUpperCase();

			if (!categorized_categories[initial_letter]) {
				categorized_categories[initial_letter] = [];
			}

			categorized_categories[initial_letter].push(item);
		});

		return categorized_categories;
	};

	const selectCategory = category_id => {
		setSelectedCategoryId(category_id);
	};

	const getCategoryName = category_id => {
		return clientOrderFilters.categories?.find(item => item.category_id === category_id)?.name;
	};

	// * Groups
	const [isGroupsModalOpen, setIsGroupsModalOpen] = useState(false);

	const openGroupsModal = () => {
		setIsGroupsModalOpen(true);
	};

	const closeGroupsModal = () => {
		setIsGroupsModalOpen(false);

		setTimeout(() => {
			const categorized_groups = getCategorizedGroups(groups);
			setFilteredGroups(categorized_groups);
		}, 200);
	};

	const selectGroupsItem = item => {
		setIsGroupsModalOpen(false);
		selectGroup(item.group_id);

		closeGroupsModal();
	};

	const searchGroups = search_value => {
		const filtered_groups = clientOrderFilters.groups?.filter(item => {
			if (!search_value) {
				return item;
			}

			const group_name = item.name?.toLowerCase();

			return group_name.includes(search_value.toLowerCase());
		});

		const categorized_groups = getCategorizedGroups(filtered_groups);

		setFilteredGroups(categorized_groups);
	};

	const getCategorizedGroups = filtered_groups => {
		const categorized_groups = {};

		filtered_groups.forEach(item => {
			const initial_letter = item.name[0].toUpperCase();

			if (!categorized_groups[initial_letter]) {
				categorized_groups[initial_letter] = [];
			}

			categorized_groups[initial_letter].push(item);
		});

		return categorized_groups;
	};

	const selectGroup = group_id => {
		setSelectedGroupId(group_id);
	};

	const getGroupName = group_id => {
		return clientOrderFilters.groups?.find(item => item.group_id === group_id)?.name;
	};

	// * Clear all
	const clearAllFilters = () => {
		setSearch(null);

		setSelectedBrandId(null);
		setSelectedCategoryId(null);
		setSelectedGroupId(null);

		setMinimumPriceSelected(priceRange[0]);
		setMaximumPriceSelected(priceRange[1]);

		document.getElementById('search-box').value = '';
	};

	// * Send products to cart
	const selectNewOrder = createSelector(
		state => state.NewOrder,
		state => ({
			cart_items: state.cart_items,
		})
	);

	const { cart_items } = useSelector(selectNewOrder);

	const addItemToCart = (product_data, quantity) => {
		const cart_item = {
			quantity,
			observations: '',
			product_data,
		};

		dispatch(onAddCartItem(cart_item));
	};

	const goToCart = () => {
		navigate('/cart');
	};

	// * Animate cart button
	const [animated, setAnimated] = useState(false);

	useEffect(() => {
		if (!cart_items.length) {
			return;
		}

		setAnimated(true);

		const timeout = setTimeout(() => {
			setAnimated(false);
		}, 300);

		// eslint-disable-next-line consistent-return
		return () => {
			clearTimeout(timeout);
		};
	}, [cart_items]);

	const getQuantityOnCart = product_id => {
		const cart_item = cart_items.find(item => item?.product_data?.product_id === product_id);

		return cart_item?.quantity || 0;
	};

	// * Item modal
	const inputQuantityRef = useRef(null);

	const [isItemModalOpen, setIsItemModalOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);

	const [itemsQuantity, setItemsQuantity] = useState(0);

	const openItemModal = item => {
		setTimeout(() => {
			inputQuantityRef?.current?.focus();
		}, 25);

		setSelectedItem(item);
		setItemsQuantity(1);

		setIsItemModalOpen(true);
	};

	const closeItemModal = () => {
		setIsItemModalOpen(false);
	};

	const onAddItemToCart = () => {
		if (!itemsQuantity) {
			return;
		}

		addItemToCart(selectedItem, itemsQuantity);
		closeItemModal();
	};

	const removeCartItem = product_id => {
		dispatch(onRemoveCartItem(product_id));
		closeItemModal();
	};

	const onInputQuantityChange = event => {
		let items_quantity = event.target.value.replace(/[^0-9]/g, '');

		if (!items_quantity) {
			setItemsQuantity('');
			return;
		}

		items_quantity = Number(items_quantity);

		setItemsQuantity(items_quantity);
	};

	const onInputQuantityFocus = event => {
		event.target.select();
	};

	const onInputQuantityKeyDown = event => {
		if (event.key === 'Enter' && itemsQuantity) {
			onAddItemToCart();
		}
	};

	const addQuantity = () => {
		const new_value = itemsQuantity ? itemsQuantity + 1 : 1;
		setItemsQuantity(new_value);
	};

	const subtractQuantity = () => {
		if (!itemsQuantity) {
			return;
		}

		setItemsQuantity(itemsQuantity - 1);
	};

	// * Filters modal
	const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

	const openFiltersModal = () => {
		setIsFiltersModalOpen(true);
	};

	const closeFiltersModal = () => {
		setIsFiltersModalOpen(false);
	};

	const getActiveFiltersCount = () => {
		return [selectedCategoryId, selectedBrandId, selectedGroupId].filter(item => item !== null).length;
	};

	return (
		<div className="page-content">
			<ToastContainer closeButton={false} />

			<Modal isOpen={isFiltersModalOpen} toggle={closeFiltersModal} fullscreen size="md" centered>
				<ModalHeader className="modal-title pb-3" toggle={closeFiltersModal}>
					<h4 className="pe-3" style={{ marginBottom: -3 }}>
						Filtros
					</h4>
				</ModalHeader>

				<ModalBody className="pt-0">
					{!loading_client_order_filters ? (
						<>
							<div className="accordion accordion-flush">
								{selectedCategoryId !== null || selectedBrandId !== null || selectedGroupId !== null ? (
									<div className="accordion-item pt-2">
										<div className="accordion-collapse collapse show">
											<div className="accordion-body text-body pt-0 px-0">
												<div className="d-flex flex-wrap gap-3">
													{selectedCategoryId !== null ? (
														<Button
															onClick={() => setSelectedCategoryId(null)}
															size="sm"
															className="btn-dark fs-14">
															{getCategoryName(selectedCategoryId)}{' '}
															<i className="ri-close-line align-bottom ms-1"></i>
														</Button>
													) : null}

													{selectedBrandId !== null ? (
														<Button
															onClick={() => setSelectedBrandId(null)}
															size="sm"
															className="btn-dark fs-14">
															{getBrandName(selectedBrandId)}{' '}
															<i className="ri-close-line align-bottom ms-1"></i>
														</Button>
													) : null}

													{selectedGroupId !== null ? (
														<Button
															onClick={() => setSelectedGroupId(null)}
															size="sm"
															className="btn-dark fs-14">
															{getGroupName(selectedGroupId)}{' '}
															<i className="ri-close-line align-bottom ms-1"></i>
														</Button>
													) : null}
												</div>
											</div>
										</div>
									</div>
								) : null}

								<div className="card-body border-bottom d-none">
									<p className="text-muted text-uppercase fs-13 fw-medium mb-4">Precio</p>

									{/* <Nouislider
											range={{ min: priceRange[0], max: priceRange[1] }}
											start={priceRange}
											connect
											onEnd={onUpdatePriceSelection}
											data-slider-color="primary"
											id="product-price-range"
											disabled={!productList || productList.length === 0 || productList.length === 1}
										/> */}

									<div className="formCost d-flex gap-2 align-items-center mt-3">
										<div className="input-group input-group-sm">
											<span className="input-group-text">$</span>
											<input
												className="form-control ps-2"
												type="text"
												id="minCost"
												readOnly
												disabled={
													!productList || productList.length === 0 || productList.length === 1
												}
											/>
										</div>

										<span className="fw-semibold text-muted">A</span>

										<div className="input-group input-group-sm">
											<span className="input-group-text">$</span>
											<input
												className="form-control ps-2"
												type="text"
												id="maxCost"
												readOnly
												disabled={
													!productList || productList.length === 0 || productList.length === 1
												}
											/>
										</div>
									</div>
								</div>

								{selectedCategoryId === null ? (
									<div className="accordion-item">
										<h2 className="accordion-header pt-2 pb-2 px-0">
											<span className="text-muted text-uppercase fs-13 fw-medium">
												Categorías
											</span>
										</h2>

										<div className="accordion-collapse collapse show">
											<div className="accordion-body text-body pt-0 px-0">
												<ul className="list-unstyled mb-0 filter-list">
													<li>
														{categories?.slice(0, 8).map(item => (
															<Link
																key={item.category_id}
																to="#"
																className={'d-flex py-1 align-items-center'}
																onClick={() => selectCategory(item.category_id)}>
																<div className="flex-grow-1">
																	<h5 className="fs-14 mb-0 listname">{item.name}</h5>
																</div>
															</Link>
														))}
													</li>

													<li>
														{categories?.length > 5 ? (
															<Link to="#" className="py-1" onClick={openCategoriesModal}>
																<div className="flex-grow-1">
																	<h5 className="fs-14 mt-2 mb-0 listname text-info">
																		MOSTRAR MÁS
																	</h5>
																</div>
															</Link>
														) : null}
													</li>
												</ul>
											</div>
										</div>
									</div>
								) : null}

								{selectedBrandId === null ? (
									<div className="accordion-item">
										<h2 className="accordion-header pt-2 pb-2 px-0">
											<span className="text-muted text-uppercase fs-13 fw-medium">Marca</span>
										</h2>

										<div className="accordion-collapse collapse show">
											<div className="accordion-body text-body pt-0 px-0">
												<ul className="list-unstyled mb-0 filter-list">
													<li>
														{brands?.slice(0, 8).map(item => (
															<Link
																key={item.brand_id}
																to="#"
																className={'d-flex py-1 align-items-center'}
																onClick={() => selectBrand(item.brand_id)}>
																<div className="flex-grow-1">
																	<h5 className="fs-14 mb-0 listname">{item.name}</h5>
																</div>
															</Link>
														))}
													</li>

													<li>
														{brands?.length > 5 ? (
															<Link to="#" className="py-1" onClick={openBrandsModal}>
																<div className="flex-grow-1">
																	<h5 className="fs-14 mt-2 mb-0 listname text-info">
																		MOSTRAR MÁS
																	</h5>
																</div>
															</Link>
														) : null}
													</li>
												</ul>
											</div>
										</div>
									</div>
								) : null}

								{selectedGroupId === null ? (
									<div className="accordion-item">
										<h2 className="accordion-header pt-2 pb-2 px-0">
											<span className="text-muted text-uppercase fs-13 fw-medium">Grupos</span>
										</h2>

										<div className="accordion-collapse collapse show">
											<div className="accordion-body text-body pt-0 px-0">
												<ul className="list-unstyled mb-0 filter-list">
													<li>
														{groups?.slice(0, 8).map(item => (
															<Link
																key={item.group_id}
																to="#"
																className={'d-flex py-1 align-items-center'}
																onClick={() => selectGroup(item.group_id)}>
																<div className="flex-grow-1">
																	<h5 className="fs-14 mb-0 listname">{item.name}</h5>
																</div>
															</Link>
														))}
													</li>

													<li>
														{groups?.length > 5 ? (
															<Link to="#" className="py-1" onClick={openGroupsModal}>
																<div className="flex-grow-1">
																	<h5 className="fs-14 mt-2 mb-0 listname text-info">
																		MOSTRAR MÁS
																	</h5>
																</div>
															</Link>
														) : null}
													</li>
												</ul>
											</div>
										</div>
									</div>
								) : null}
							</div>
						</>
					) : (
						<div className="d-flex py-5 justify-content-center align-content-center">
							<Spinner size="sm" />
						</div>
					)}
				</ModalBody>

				<ModalFooter className="pt-3 px-2 bg-light">
					<Row className="w-100 px-0 mx-0">
						<Col>
							<Button
								onClick={() => {
									clearAllFilters();
									closeFiltersModal();
								}}
								className="btn btn-danger btn-lg w-100"
								title="Limpiar filtros">
								Limpiar
							</Button>
						</Col>

						<Col>
							<Button
								onClick={closeFiltersModal}
								className="btn btn-success btn-lg w-100"
								title="Finalizar selección">
								Hecho
							</Button>
						</Col>
					</Row>
				</ModalFooter>
			</Modal>

			<Modal isOpen={isItemModalOpen} toggle={closeItemModal} size="md" centered>
				<ModalHeader className="modal-title" toggle={closeItemModal}>
					<h4 className="pe-3" style={{ marginBottom: -3 }}>
						{selectedItem?.description}
					</h4>
				</ModalHeader>

				<ModalBody className="pt-0">
					<Row className="mt-4">
						<Col xs={12} sm={6}>
							<div className="p-2 border border-dashed rounded">
								<div className="d-flex align-items-center">
									<div className="avatar-sm me-2">
										<div className="avatar-title rounded bg-transparent text-info fs-24">
											<i className="ri-money-dollar-circle-fill"></i>
										</div>
									</div>

									<div className="flex-grow-1">
										<p className="text-muted mb-1">
											<span className="me-2">Precio</span>

											{selectedItem?.strikethrough_price_with_iva > 0 && (
												<span>
													[
													<span className="text-decoration-line-through">
														${selectedItem.strikethrough_price_with_iva.toFixed(2)}
													</span>
													]
												</span>
											)}
										</p>

										<h5 className="mb-0">
											$ {selectedItem?.unit_price_with_iva_and_discount?.toFixed(2)}
										</h5>
									</div>
								</div>
							</div>
						</Col>

						<Col xs={12} sm={6} className="mt-3 mt-sm-0">
							<div className="p-2 border border-dashed rounded">
								<div className="d-flex align-items-center">
									<div className="avatar-sm me-2">
										<div className="avatar-title rounded bg-transparent text-info fs-24">
											<i className="ri-shopping-cart-2-fill"></i>
										</div>
									</div>

									<div className="flex-grow-1">
										<p className="text-muted mb-1">En carrito</p>
										<h5 className="mb-0">{getQuantityOnCart(selectedItem?.product_id) || '-'}</h5>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</ModalBody>

				<ModalFooter className="pt-3 px-2 bg-light">
					<Row className="w-100 px-0 mx-0">
						<Col className="col-auto">
							<Button
								onClick={() => removeCartItem(selectedItem?.product_id)}
								color="danger"
								className="btn-icon btn-lg btn-soft-danger"
								title="Eliminar del carrito"
								disabled={!getQuantityOnCart(selectedItem?.product_id)}>
								<i className="ri-delete-bin-5-line fs-19" />
							</Button>
						</Col>

						<Col>
							<div className="input-step full-height full-width">
								<button
									type="button"
									className="minus cursor-not-allowed-if-disabled"
									onClick={subtractQuantity}
									disabled={!itemsQuantity || itemsQuantity === 0}>
									–
								</button>

								<input
									ref={inputQuantityRef}
									type="text"
									className="product-quantity fs-20"
									value={itemsQuantity}
									onChange={onInputQuantityChange}
									onFocus={onInputQuantityFocus}
									onKeyDown={onInputQuantityKeyDown}
								/>

								<button type="button" className="plus" onClick={addQuantity}>
									+
								</button>
							</div>
						</Col>

						<Col className="col-12 col-sm-auto mt-3 mt-sm-0">
							<Button
								onClick={onAddItemToCart}
								className="btn btn-success btn-lg w-100"
								title="Sumar al carrito"
								disabled={!itemsQuantity || itemsQuantity === 0}>
								Sumar al carrito
							</Button>
						</Col>
					</Row>
				</ModalFooter>
			</Modal>

			<Modal
				isOpen={isCategoriesModalOpen}
				toggle={closeCategoriesModal}
				scrollable={true}
				contentClassName="select-filters-modal"
				centered
				size="lg">
				<ModalHeader className="modal-title" toggle={closeCategoriesModal}>
					Categorías
				</ModalHeader>

				<ModalBody className="pt-0 px-2 px-lg-3">
					<div className="search-box search-box-sm px-1">
						<Input
							type="text"
							size="lg"
							className="form-control bg-light border-0"
							placeholder="Buscar categorías"
							onChange={event => searchCategories(event.target.value)}
						/>

						<i className="ri-search-line search-icon ms-2"></i>
					</div>

					{Object.entries(filteredCategories).map(([initial_letter, filtered_categories], index) => (
						<div key={initial_letter} className="mt-4 px-2">
							{index !== 0 ? (
								<div className="w-100 mb-4" style={{ border: '1px solid var(--vz-border-color)' }} />
							) : null}

							<h2 className="mb-2 fs-24">{initial_letter}</h2>

							<Row className="g-3">
								{filtered_categories?.map(item => (
									<Col key={item.group_id} xs={12} sm={6} md={4} lg={3}>
										<Link
											to="#"
											className={'d-flex pb-1 align-items-center'}
											onClick={() => selectCategoriesItem(item)}>
											<div className="flex-grow-1">
												<h5 className="fs-14 mb-0 listname">{item.name}</h5>
											</div>
										</Link>
									</Col>
								))}
							</Row>
						</div>
					))}
				</ModalBody>
			</Modal>

			<Modal
				isOpen={isBrandsModalOpen}
				toggle={closeBrandsModal}
				scrollable={true}
				contentClassName="select-filters-modal"
				centered
				size="lg">
				<ModalHeader className="modal-title" toggle={closeBrandsModal}>
					Marcas
				</ModalHeader>

				<ModalBody className="pt-0 px-2 px-lg-3">
					<div className="search-box search-box-sm px-1">
						<Input
							type="text"
							size="lg"
							className="form-control bg-light border-0"
							placeholder="Buscar marcas"
							onChange={event => searchBrands(event.target.value)}
						/>

						<i className="ri-search-line search-icon ms-2"></i>
					</div>

					{Object.entries(filteredBrands).map(([initial_letter, filtered_brands], index) => (
						<div key={initial_letter} className="mt-4 px-2">
							{index !== 0 ? (
								<div className="w-100 mb-4" style={{ border: '1px solid var(--vz-border-color)' }} />
							) : null}

							<h2 className="mb-2 fs-24">{initial_letter}</h2>

							<Row className="g-3">
								{filtered_brands?.map(item => (
									<Col key={item.group_id} xs={12} sm={6} md={4} lg={3}>
										<Link
											to="#"
											className={'d-flex pb-1 align-items-center'}
											onClick={() => selectBrandsItem(item)}>
											<div className="flex-grow-1">
												<h5 className="fs-14 mb-0 listname">{item.name}</h5>
											</div>
										</Link>
									</Col>
								))}
							</Row>
						</div>
					))}
				</ModalBody>
			</Modal>

			<Modal
				isOpen={isGroupsModalOpen}
				toggle={closeGroupsModal}
				scrollable={true}
				contentClassName="select-filters-modal"
				centered
				size="lg">
				<ModalHeader className="modal-title" toggle={closeGroupsModal}>
					Grupos
				</ModalHeader>

				<ModalBody className="pt-0 px-2 px-lg-3">
					<div className="search-box search-box-sm px-1">
						<Input
							type="text"
							size="lg"
							className="form-control bg-light border-0"
							placeholder="Buscar grupos"
							onChange={event => searchGroups(event.target.value)}
						/>

						<i className="ri-search-line search-icon ms-2"></i>
					</div>

					{Object.entries(filteredGroups).map(([initial_letter, filtered_groups], index) => (
						<div key={initial_letter} className="mt-4 px-2">
							{index !== 0 ? (
								<div className="w-100 mb-4" style={{ border: '1px solid var(--vz-border-color)' }} />
							) : null}

							<h2 className="mb-3">{initial_letter}</h2>

							<Row className="g-3">
								{filtered_groups?.map(item => (
									<Col key={item.group_id} xs={12} sm={6} md={4} lg={3}>
										<Link
											to="#"
											className={'d-flex pb-1 align-items-center'}
											onClick={() => selectGroupsItem(item)}>
											<div className="flex-grow-1">
												<h5 className="fs-14 mb-0 listname">{item.name}</h5>
											</div>
										</Link>
									</Col>
								))}
							</Row>
						</div>
					))}
				</ModalBody>
			</Modal>

			<Container fluid>
				<BreadCrumb title="Selección de Productos" pageTitle="Nuevo Pedido" goBackTo={'/'} />

				<Row>
					<Col xl={3} lg={4} className="d-none d-lg-block">
						<Card>
							<CardHeader>
								<div className="d-flex">
									<div className="flex-grow-1">
										<h5 className="fs-16 mb-0">Filtros</h5>
									</div>

									<div className="flex-shrink-0">
										<Link
											to="#"
											className="text-decoration-underline"
											onClick={clearAllFilters}
											title="Limpiar todos los filtros aplicados">
											Limpiar filtros
										</Link>
									</div>
								</div>

								{selectedCategoryId !== null || selectedBrandId !== null || selectedGroupId !== null ? (
									<div className="d-flex flex-wrap gap-3 mt-3">
										{selectedCategoryId !== null ? (
											<Button
												onClick={() => setSelectedCategoryId(null)}
												size="sm"
												className="btn-dark">
												{getCategoryName(selectedCategoryId)}{' '}
												<i className="ri-close-line align-bottom ms-1"></i>
											</Button>
										) : null}

										{selectedBrandId !== null ? (
											<Button
												onClick={() => setSelectedBrandId(null)}
												size="sm"
												className="btn-dark">
												{getBrandName(selectedBrandId)}{' '}
												<i className="ri-close-line align-bottom ms-1"></i>
											</Button>
										) : null}

										{selectedGroupId !== null ? (
											<Button
												onClick={() => setSelectedGroupId(null)}
												size="sm"
												className="btn-dark">
												{getGroupName(selectedGroupId)}{' '}
												<i className="ri-close-line align-bottom ms-1"></i>
											</Button>
										) : null}
									</div>
								) : null}
							</CardHeader>

							{!loading_client_order_filters ? (
								<div className="accordion accordion-flush">
									<div className="card-body border-bottom d-none">
										<p className="text-muted text-uppercase fs-12 fw-medium mb-4">Precio</p>

										{/* <Nouislider
											range={{ min: priceRange[0], max: priceRange[1] }}
											start={priceRange}
											connect
											onEnd={onUpdatePriceSelection}
											data-slider-color="primary"
											id="product-price-range"
											disabled={!productList || productList.length === 0 || productList.length === 1}
										/> */}

										<div className="formCost d-flex gap-2 align-items-center mt-3">
											<div className="input-group input-group-sm">
												<span className="input-group-text">$</span>
												<input
													className="form-control ps-2"
													type="text"
													id="minCost"
													readOnly
													disabled={
														!productList ||
														productList.length === 0 ||
														productList.length === 1
													}
												/>
											</div>

											<span className="fw-semibold text-muted">A</span>

											<div className="input-group input-group-sm">
												<span className="input-group-text">$</span>
												<input
													className="form-control ps-2"
													type="text"
													id="maxCost"
													readOnly
													disabled={
														!productList ||
														productList.length === 0 ||
														productList.length === 1
													}
												/>
											</div>
										</div>
									</div>

									{selectedCategoryId === null ? (
										<div className="accordion-item">
											<h2 className="accordion-header ms-1 px-3 pb-2">
												<span className="text-muted text-uppercase fs-12 fw-medium">
													Categorías
												</span>
											</h2>

											<div className="accordion-collapse collapse show">
												<div className="accordion-body text-body pt-0">
													<ul className="list-unstyled mb-0 filter-list">
														<li>
															{categories?.slice(0, 8).map(item => (
																<Link
																	key={item.category_id}
																	to="#"
																	className={'d-flex py-1 align-items-center'}
																	onClick={() => selectCategory(item.category_id)}>
																	<div className="flex-grow-1">
																		<h5 className="fs-13 mb-0 listname">
																			{item.name}
																		</h5>
																	</div>
																</Link>
															))}
														</li>

														<li>
															{categories?.length > 5 ? (
																<Link
																	to="#"
																	className="py-1"
																	onClick={openCategoriesModal}>
																	<div className="flex-grow-1">
																		<h5 className="fs-13 mt-2 mb-0 listname text-info">
																			MOSTRAR MÁS
																		</h5>
																	</div>
																</Link>
															) : null}
														</li>
													</ul>
												</div>
											</div>
										</div>
									) : null}

									{selectedBrandId === null ? (
										<div className="accordion-item">
											<h2 className="accordion-header ms-1 px-3 pb-2">
												<span className="text-muted text-uppercase fs-12 fw-medium">Marca</span>
											</h2>

											<div className="accordion-collapse collapse show">
												<div className="accordion-body text-body pt-0">
													<ul className="list-unstyled mb-0 filter-list">
														<li>
															{brands?.slice(0, 8).map(item => (
																<Link
																	key={item.brand_id}
																	to="#"
																	className={'d-flex py-1 align-items-center'}
																	onClick={() => selectBrand(item.brand_id)}>
																	<div className="flex-grow-1">
																		<h5 className="fs-13 mb-0 listname">
																			{item.name}
																		</h5>
																	</div>
																</Link>
															))}
														</li>

														<li>
															{brands?.length > 5 ? (
																<Link to="#" className="py-1" onClick={openBrandsModal}>
																	<div className="flex-grow-1">
																		<h5 className="fs-13 mt-2 mb-0 listname text-info">
																			MOSTRAR MÁS
																		</h5>
																	</div>
																</Link>
															) : null}
														</li>
													</ul>
												</div>
											</div>
										</div>
									) : null}

									{selectedGroupId === null ? (
										<div className="accordion-item">
											<h2 className="accordion-header ms-1 px-3 pb-2">
												<span className="text-muted text-uppercase fs-12 fw-medium">
													Grupos
												</span>
											</h2>

											<div className="accordion-collapse collapse show">
												<div className="accordion-body text-body pt-0">
													<ul className="list-unstyled mb-0 filter-list">
														<li>
															{groups?.slice(0, 8).map(item => (
																<Link
																	key={item.group_id}
																	to="#"
																	className={'d-flex py-1 align-items-center'}
																	onClick={() => selectGroup(item.group_id)}>
																	<div className="flex-grow-1">
																		<h5 className="fs-13 mb-0 listname">
																			{item.name}
																		</h5>
																	</div>
																</Link>
															))}
														</li>

														<li>
															{groups?.length > 5 ? (
																<Link to="#" className="py-1" onClick={openGroupsModal}>
																	<div className="flex-grow-1">
																		<h5 className="fs-13 mt-2 mb-0 listname text-info">
																			MOSTRAR MÁS
																		</h5>
																	</div>
																</Link>
															) : null}
														</li>
													</ul>
												</div>
											</div>
										</div>
									) : null}
								</div>
							) : (
								<div className="d-flex py-5 justify-content-center align-content-center">
									<Spinner size="sm" />
								</div>
							)}
						</Card>
					</Col>

					<Col xl={9} lg={8}>
						<div>
							<Card className="product-selection-search-bar">
								<CardHeader className="border-0 rounded">
									<Row className="g-3">
										<Col className="col-auto d-block d-lg-none">
											<Button onClick={openFiltersModal} className="btn-soft-dark">
												<i className="ri-filter-2-line align-bottom fs-18"></i>

												{getActiveFiltersCount() > 0 ? (
													<div
														className="position-absolute w-100 text-center"
														style={{ top: -5, right: -20 }}>
														<span className="badge bg-primary text-white fs-12">
															{getActiveFiltersCount()}
														</span>
													</div>
												) : null}
											</Button>
										</Col>

										<Col className="d-flex align-items-center">
											<div className="search-box h-100 w-100">
												<input
													id="search-box"
													type="text"
													className="form-control search h-100"
													placeholder="Buscar por descripción"
													value={search}
													onChange={event => setSearch(event.target.value)}
												/>

												<i className="ri-search-line search-icon"></i>
											</div>
										</Col>

										<Col className="col-auto d-none d-sm-block text-center">
											<div
												className="d-flex justify-content-center align-items-center text-center pt-2"
												style={{
													marginTop: 2,
													visibility: loading_products ? 'visible' : 'hidden',
												}}>
												<Spinner size="sm" color="gradient" />
											</div>
										</Col>

										<Col className="col-auto">
											<StyledButton
												to="#"
												onClick={goToCart}
												disabled={!cart_items?.length}
												color="success"
												className={`d-none d-md-block btn btn-success btn-label rounded-pill d-flex justify-content-center align-items-center h-100 py-2 px-0 ${
													animated ? 'animated' : ''
												}`}
												title="Ir al carrito">
												<div className="px-4">
													<i className="ri-shopping-cart-2-line align-middle fs-17 me-2"></i>
													Ir al Carrito
												</div>

												{cart_items?.length >= 1 ? (
													<div
														className="position-absolute w-100 text-center"
														style={{ bottom: -13, marginLeft: 2 }}>
														<span className="badge bg-danger text-white fs-12">
															{cart_items.length}{' '}
															{cart_items?.length === 1 ? 'producto' : 'productos'}
														</span>
													</div>
												) : null}
											</StyledButton>

											<StyledButton
												to="#"
												onClick={goToCart}
												disabled={!cart_items?.length}
												color="success"
												className={`d-block d-md-none btn btn-success d-flex justify-content-center align-items-center ${
													animated ? 'animated' : ''
												}`}
												title="Ir al carrito">
												<i className="ri-shopping-cart-2-line align-middle fs-18 me-0"></i>

												{cart_items?.length >= 1 ? (
													<div
														className="position-absolute w-100 text-center"
														style={{ top: -5, right: -20 }}>
														<span className="badge bg-danger text-white fs-12">
															{cart_items.length}
														</span>
													</div>
												) : null}
											</StyledButton>
										</Col>
									</Row>
								</CardHeader>
							</Card>
						</div>

						<div>
							{productList?.length ? (
								<>
									<Card className="d-none d-md-block">
										<div className="card-body">
											<div className="">
												<Table className="align-middle table-striped table-hover mb-0">
													<thead className="table-light">
														<tr>
															<th scope="col">Descripción</th>
															<th scope="col"></th>
															<th scope="col">
																Precio <small className="fw-normal">(unitario)</small>
															</th>
														</tr>
													</thead>

													<tbody>
														{productList?.map(item => (
															<tr
																key={item.product_id}
																onClick={() => openItemModal(item)}
																className="cursor-pointer">
																<td className="fs-14 py-3 pe-3">{item.description}</td>

																<td>
																	{getQuantityOnCart(item?.product_id) ? (
																		<span className="badge bg-dark-subtle text-body fs-13">
																			{getQuantityOnCart(item?.product_id)} en
																			carrito
																		</span>
																	) : null}
																</td>

																<td className="fs-14 fw-medium">
																	{item.strikethrough_price_with_iva > 0 && (
																		<small className="text-muted text-decoration-line-through">
																			$
																			{item.strikethrough_price_with_iva.toFixed(
																				2
																			)}
																		</small>
																	)}

																	<div>
																		${' '}
																		{item.unit_price_with_iva_and_discount.toFixed(
																			2
																		)}
																	</div>
																</td>
															</tr>
														))}
													</tbody>
												</Table>
											</div>
										</div>
									</Card>

									<div className="d-block d-md-none">
										{productList?.map(item => (
											<Card
												key={item.product_id}
												onClick={() => openItemModal(item)}
												className="cursor-pointer mb-3 p-3">
												<span className="fs-15">{item.description}</span>

												<Row className="d-flex align-items-center mt-2">
													<Col className="col pe-4">
														<span className="fs-18 fw-medium">
															$ {item.unit_price_with_iva_and_discount.toFixed(2)}
														</span>

														{item.strikethrough_price_with_iva > 0 && (
															<span className="text-muted text-decoration-line-through fs-13 ms-2">
																${item.strikethrough_price_with_iva.toFixed(2)}
															</span>
														)}
													</Col>

													{getQuantityOnCart(item?.product_id) ? (
														<Col className="col-auto">
															<span className="badge bg-dark-subtle text-body fs-13">
																{getQuantityOnCart(item?.product_id)} en carrito
															</span>
														</Col>
													) : null}
												</Row>
											</Card>
										))}
									</div>

									<div className="my-4 py-2">
										<Pagination
											size="md"
											listClassName="pagination-separated justify-content-center">
											<PaginationItem disabled={currentPage === 1}>
												<PaginationLink to="#" onClick={() => onPageChange(currentPage - 1)}>
													Atrás
												</PaginationLink>
											</PaginationItem>

											{generateButtonsForPagination()}

											<PaginationItem disabled={currentPage === paginationData.last_page}>
												<PaginationLink to="#" onClick={() => onPageChange(currentPage + 1)}>
													Siguiente
												</PaginationLink>
											</PaginationItem>
										</Pagination>
									</div>
								</>
							) : null}

							{!productList?.length && !loading_products ? (
								<div className="py-4 text-center">
									<div className="mt-2">
										<h5>No hay resultados para la búsqueda</h5>
									</div>
								</div>
							) : null}
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export { ProductsSelection };
