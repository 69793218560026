import React, { useRef, useEffect } from 'react';

const MapCurrentLocationMarker = ({
	mapRef,
	id,
	title,
	subTitle,
	position,
	heading,
	infoWindowContent,
	focusedElement,
	setFocusedElement,
	setHoveredElement,
}) => {
	const markerRef = useRef();

	useEffect(() => {
		const location_marker = document.createElement('div');

		location_marker.innerHTML = `
			<div class="map-current-location-marker">
				<div class="information">
					<div class="title">${title || ''}</div>
					<div class="subtitle">${subTitle || ''}</div>
				</div>

				<div class="outer-circle"></div>
				<div class="inner-circle"></div>

				<svg 
					class="inner-arrow" 
					xmlns="http://www.w3.org/2000/svg" 
					width="12" 
					height="13" 
					viewBox="0 0 20 22" 
					fill="none" 
					style="transform: rotate(${heading || 0}deg);">
					<path d="M 1.189 3.502 L 8.551 20.012 C 9.142 21.336 10.906 21.336 11.496 20.012 L 18.859 3.502 C 19.526 2.007 18.045 0.455 16.657 1.196 L 10.753 4.348 C 10.294 4.593 9.754 4.593 9.294 4.348 L 3.391 1.196 C 2.003 0.455 0.522 2.007 1.189 3.502 Z" style="fill: rgb(255, 255, 255); fill-rule: nonzero; stroke: rgb(255, 255, 255); stroke-width: 2px; transform-box: fill-box; transform-origin: 50% 50%;" id="object-0" transform="matrix(-1, 0, 0, -1, 0, 0.000002)"/>
				</svg>
			</div>`;

		const advanced_marker = new window.google.maps.marker.AdvancedMarkerElement({
			content: location_marker,
		});

		// On click
		advanced_marker.addListener('click', () => {
			setFocusedElement({ type: 'marker', id });
		});

		// On mouse over
		advanced_marker.content.addEventListener('mouseenter', () => {
			setHoveredElement({ type: 'marker', id });
		});

		// On mouse out
		advanced_marker.content.addEventListener('mouseleave', () => {
			setHoveredElement({ type: null, id: null });
		});

		markerRef.current = advanced_marker;

		return () => {
			advanced_marker.map = null;
		};
	}, []);

	useEffect(() => {
		if (!markerRef?.current) {
			return;
		}

		if (!mapRef?.current) {
			return;
		}

		if (!position?.lat || !position?.lng) {
			return;
		}

		markerRef.current.map = mapRef.current.map;
		markerRef.current.position = position;
	}, [mapRef, position]);

	useEffect(() => {
		if (focusedElement?.id === id) {
			openInfoWindow();
		}
	}, [focusedElement]);

	const openInfoWindow = () => {
		if (!infoWindowContent) {
			return;
		}

		mapRef.current.infoWindow.close();
		mapRef.current.infoWindow.setHeaderDisabled(true);
		mapRef.current.infoWindow.setContent(infoWindowContent);
		mapRef.current.infoWindow.setOptions({ minWidth: 240, maxWidth: 350 });
		mapRef.current.infoWindow.open(markerRef.current.map, markerRef.current);

		// Center map on maker
		mapRef.current.map.panTo(position);
	};

	return <></>;
};

export default MapCurrentLocationMarker;
