import { createSlice } from '@reduxjs/toolkit';

import { getPaginatedProducts } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	pagination: {},
	products: [],
	loading_products: false,
	error: null,
};

const productsSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getPaginatedProducts.pending, state => {
			state.loading_products = true;
		});

		builder.addCase(getPaginatedProducts.fulfilled, (state, action) => {
			state.pagination = action.payload?.pagination || {};
			state.products = action.payload?.data?.products || [];
			state.loading_products = false;
		});

		builder.addCase(getPaginatedProducts.rejected, (state, action) => {
			state.error = action?.error || null;
			state.loading_products = false;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default productsSlice.reducer;
