import React, { useMemo, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Button, Spinner } from 'reactstrap';

import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import TableContainer from '../../components/common/TableContainer';

import {
	getCompanyPermissions as onGetCompanyPermissions,
	updateCompanyPermissions as onUpdateCompanyPermissions,
} from '../../slices/thunks';

const InputValue = ({ value, ...props }) => {
	return <input type="text" className="form-control apikey-value" defaultValue={value} {...props} />;
};

const CompanyPermissions = () => {
	const dispatch = useDispatch();

	// Get all company permissions list
	useEffect(() => {
		dispatch(onGetCompanyPermissions());
	}, []);

	const [companyPermissionsList, setCompanyPermissionsList] = useState([]);

	const [updatesList, setUpdatesList] = useState([]);
	const [loadingSendUpdates, setLoadingSendUpdates] = useState(false);

	const selectPermissions = createSelector(
		state => state.CompanyPermissions,
		state => ({
			company_permissions: state.company_permissions,
			isCompanyPermissionUpdate: state.isCompanyPermissionUpdate,
			isCompanyPermissionUpdateFail: state.isCompanyPermissionUpdateFail,
		})
	);

	const { company_permissions, isCompanyPermissionUpdate, isCompanyPermissionUpdateFail } =
		useSelector(selectPermissions);

	useEffect(() => {
		setCompanyPermissionsList(company_permissions);
	}, [company_permissions]);

	useEffect(() => {
		setLoadingSendUpdates(false);

		if (isCompanyPermissionUpdate) {
			setUpdatesList([]);
		}
	}, [isCompanyPermissionUpdate, isCompanyPermissionUpdateFail]);

	// Save changes on temporary list
	const saveUpdatesList = (original_item, columns_updates) => {
		setUpdatesList(updates_list => {
			// eslint-disable-next-line eqeqeq
			const index = updates_list.findIndex(item => item.permission_id == original_item.permission_id);

			if (index === -1) {
				return [...updates_list, { ...original_item, ...columns_updates }];
			}

			const updated_item = { ...updates_list[index], ...columns_updates };

			// If equal to original, delete item
			if (JSON.stringify(updated_item) === JSON.stringify(original_item)) {
				updates_list.splice(index, 1);
				return updates_list;
			}

			updates_list[index] = updated_item;

			return updates_list;
		});
	};

	const sendUpdates = () => {
		setLoadingSendUpdates(true);

		const updates = updatesList.map(item => {
			const { permission_id, value } = item;

			return {
				permission_id: permission_id.toString(),
				value: value.toString(),
			};
		});

		const data = { permissions: updates };

		dispatch(onUpdateCompanyPermissions(data));
	};

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'permission_id',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.permission_id;
				},
			},
			{
				Header: 'Nombre',
				accessor: 'name',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.name;
				},
			},
			{
				Header: 'Valor',
				accessor: 'value',
				filterable: true,
				border: '1px solid black',
				Cell: ({ row }) => {
					return (
						<InputValue
							value={row.original.value}
							style={{ width: 'auto' }}
							onChange={event => saveUpdatesList(row.original, { value: Number(event.target.value) })}
						/>
					);
				},
			},
			{
				Header: 'Updated at',
				accessor: 'updated_at',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.updated_at;
				},
			},
		],
		[]
	);

	return (
		<React.Fragment>
			<Card id="apiKeyList">
				<CardHeader className="d-flex align-items-center">
					<h5 className="card-title flex-grow-1 mb-0">Permisos</h5>

					<div className="d-flex gap-1 flex-wrap">
						<Button
							type="button"
							color="success"
							className="btn create-btn"
							data-bs-toggle="modal"
							onClick={sendUpdates}
							data-bs-target="#api-key-modal"
							disabled={updatesList.length === 0 || loadingSendUpdates}>
							{loadingSendUpdates ? (
								<span className="d-flex align-items-center">
									<Spinner size="sm" className="flex-shrink-0">
										{' '}
										Loading...{' '}
									</Spinner>
									<span className="flex-grow-1 ms-2">Loading...</span>
								</span>
							) : (
								<>
									<i className="ri-save-3-line align-bottom me-1"></i> Guardar cambios
								</>
							)}
						</Button>
					</div>
				</CardHeader>

				<CardBody>
					<div>
						<TableContainer
							columns={columns}
							data={companyPermissionsList}
							hadleApiKey={() => {}}
							noDataMessage={'No hay permisos disponibles para mostrar'}
							divClass="table-responsive table-card mb-3  "
							tableClass="table align-middle table-nowrap mb-0"
							theadClass="table-light"
						/>
					</div>
				</CardBody>
			</Card>

			<div className="d-flex align-items-center pb-5">
				<i className="ri-alert-line label-icon text-warning fs-18 me-2"></i>
				Los cambios impactaran en todos los dispositivos en un plazo de 5 horas.
			</div>
		</React.Fragment>
	);
};

export { CompanyPermissions };
