// Earth radius on meters
const R = 6371000;

// Convert degrees to radians
const toRadians = degrees => {
	return (degrees * Math.PI) / 180;
};

// Convert radians to degrees
const toDegrees = radians => {
	return (radians * 180) / Math.PI;
};

// Get distance between 2 coordinates
// Using Haversine formula
const getDistanceDifference = (coordinates_1, coordinates_2) => {
	const latitude_1 = coordinates_1.latitude;
	const longitude_1 = coordinates_1.longitude;

	const latitude_2 = coordinates_2.latitude;
	const longitude_2 = coordinates_2.longitude;

	const φ1 = (latitude_1 * Math.PI) / 180;
	const φ2 = (latitude_2 * Math.PI) / 180;
	const Δφ = ((latitude_2 - latitude_1) * Math.PI) / 180;
	const Δλ = ((longitude_2 - longitude_1) * Math.PI) / 180;

	const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

	return R * c;
};

// Get seconds difference between 2 coordinates
const getSecondsDifference = (coordinates_1, coordinates_2) => {
	return (new Date(coordinates_2.recorded_at).getTime() - new Date(coordinates_1.recorded_at).getTime()) / 1000;
};

// Get total duration between coordinates, on seconds
function getTotalDuration(coordinates) {
	if (coordinates.length < 2) {
		return 0;
	}

	return (
		(new Date(coordinates.at(-1).recorded_at).getTime() - new Date(coordinates.at(0).recorded_at).getTime()) / 1000
	);
}

// Calculate speed between coordinates, on km/h
const getSpeed = (coordinates_1, coordinates_2) => {
	const distance = getDistanceDifference(coordinates_1, coordinates_2);

	const seconds_difference = getSecondsDifference(coordinates_1, coordinates_2);

	return (distance / seconds_difference) * 3.6; // m/s to km/h
};

// Get average speed between multiple coordinates
const getAverageSpeed = coordinates => {
	if (coordinates.length < 2) {
		return 0;
	}

	const speeds = coordinates.slice(0, -1).map((coordinate, index) => getSpeed(coordinate, coordinates[index + 1]));

	return speeds.reduce((sum, speed) => sum + speed, 0) / speeds.length;
};

// Get total distance between coordinates, on meters
const getTotalDistance = coordinates => {
	const distances = coordinates.slice(0, -1).map((item, index) => {
		const coordinates_1 = item;
		const coordinates_2 = coordinates[index + 1];

		return getDistanceDifference(coordinates_1, coordinates_2);
	});

	const total_distance = distances.reduce((sum, distance) => sum + distance, 0);

	return total_distance;
};

// Get a middle coordinate between 2 coordinates
const getMiddleCoordinates = (coordinates_1, coordinates_2) => {
	const latitude = (coordinates_1.latitude + coordinates_2.latitude) / 2;
	const longitude = (coordinates_1.longitude + coordinates_2.longitude) / 2;

	return { latitude, longitude };
};

// Get middle coordinate from an array of coordinates
const getCenterCoordinates = coordinates => {
	if (!coordinates?.length) {
		return null;
	}

	let x = 0;
	let y = 0;
	let z = 0;

	coordinates.forEach(coordinate => {
		const latitude = toRadians(coordinate.latitude);
		const longitude = toRadians(coordinate.longitude);

		x += Math.cos(latitude) * Math.cos(longitude);
		y += Math.cos(latitude) * Math.sin(longitude);
		z += Math.sin(latitude);
	});

	const total = coordinates.length;

	x /= total;
	y /= total;
	z /= total;

	const hypotenuse = Math.sqrt(x * x + y * y);

	const central_longitude = Math.atan2(y, x);
	const central_latitude = Math.atan2(z, hypotenuse);

	return { lat: toDegrees(central_latitude), lng: toDegrees(central_longitude) };
};

function getBearing(coordinates_1, coordinates_2) {
	const latitude_1 = toRadians(coordinates_1.latitude);
	const latitude_2 = toRadians(coordinates_2.latitude);
	const deltaLon = toRadians(coordinates_2.longitude - coordinates_1.longitude);

	const y = Math.sin(deltaLon) * Math.cos(latitude_2);
	const x =
		Math.cos(latitude_1) * Math.sin(latitude_2) - Math.sin(latitude_1) * Math.cos(latitude_2) * Math.cos(deltaLon);

	return (toDegrees(Math.atan2(y, x)) + 360) % 360;
}

function generateImaginaryCoordinate(coordinates_1, coordinates_2, distance) {
	const bearing = getBearing(coordinates_1, coordinates_2);

	// Get new coordinate
	const latitude_1 = toRadians(coordinates_1.latitude);
	const longitude_1 = toRadians(coordinates_1.longitude);
	const radians_bearing = toRadians(bearing);

	const newLat = Math.asin(
		Math.sin(latitude_1) * Math.cos(distance / R) +
			Math.cos(latitude_1) * Math.sin(distance / R) * Math.cos(radians_bearing)
	);

	const newLon =
		longitude_1 +
		Math.atan2(
			Math.sin(radians_bearing) * Math.sin(distance / R) * Math.cos(latitude_1),
			Math.cos(distance / R) - Math.sin(latitude_1) * Math.sin(newLat)
		);

	const new_coordinate = {
		latitude: toDegrees(newLat),
		longitude: toDegrees(newLon),
	};

	const total_distance = getDistanceDifference(coordinates_1, coordinates_2);

	// Get new recorded_at
	const time_1_date = new Date(coordinates_1.recorded_at);
	const time_2_date = new Date(coordinates_2.recorded_at);

	const time_difference = time_2_date - time_1_date;
	const factor = distance / total_distance;
	const interpolated_time = new Date(time_1_date.getTime() + time_difference * factor);

	const new_recorded_at = interpolated_time.toISOString(); // ISO format

	return {
		...new_coordinate,
		recorded_at: new_recorded_at,
	};
}

export {
	getDistanceDifference,
	getSecondsDifference,
	getTotalDuration,
	getSpeed,
	getAverageSpeed,
	getTotalDistance,
	getMiddleCoordinates,
	getCenterCoordinates,
	generateImaginaryCoordinate,
};
