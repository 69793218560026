import React, { useEffect, useState, useMemo } from 'react';
import { Card, CardBody, Container } from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TableContainer from '../../components/common/TableContainer';
import BreadCrumb from '../../components/common/BreadCrumb';
import OperationStatusBadge from '../../components/atoms/OperationStatusBadge';

import { getAllPaymentCollections as onGetAllPaymentCollections } from '../../slices/thunks';

const PaymentCollections = () => {
	document.title = 'Cobranzas';

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const selectPaymentCollections = createSelector(
		state => state.PaymentCollections,
		state => ({
			payment_collections: state.payment_collections,
			error: state.error,
		})
	);

	const { payment_collections } = useSelector(selectPaymentCollections);

	const [paymentCollectionsList, setPaymentCollectionsList] = useState([]);

	useEffect(() => {
		setPaymentCollectionsList(payment_collections);
	}, [payment_collections]);

	useEffect(() => {
		if (!payment_collections?.length) {
			dispatch(onGetAllPaymentCollections());
		}
	}, []);

	const goToPaymentCollectionsDetails = payment_collection_id => {
		navigate(`/payment-collection/${payment_collection_id}/details`);
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Cliente',
				accessor: 'client_legal_name',
				filterable: false,
				Cell: ({ row }) => {
					return (
						<span>
							<span className="text-muted">
								[{row.original.is_a_new_client ? '-' : row.original.client_id}]
							</span>{' '}
							{row.original.client_legal_name}
						</span>
					);
				},
			},
			{
				Header: 'Cobrador',
				accessor: 'payment_collections_agent_name',
				filterable: false,
				Cell: ({ row }) => {
					return (
						<span>
							<span className="text-muted">[{row.original.payment_collections_agent_id}]</span>{' '}
							{row.original.payment_collections_agent_name}
						</span>
					);
				},
			},
			{
				Header: 'Importe pagado',
				accessor: 'amount',
				filterable: false,
				Cell: ({ row }) => {
					return `$ ${Number(row.original.debts_total_amount).toFixed(2)}`;
				},
			},
			{
				Header: 'Creado el',
				accessor: 'created_at',
				Cell: ({ row }) => {
					const date = moment(row.original.created_at).format('DD/MM/YYYY');
					const time = moment(row.original.created_at).format('HH:mm');

					return (
						<span>
							{date}
							<small className="text-muted ms-2">{time}</small>
						</span>
					);
				},
			},
			{
				Header: 'Estado',
				accessor: 'status',
				style: {
					width: 150,
				},
				Cell: ({ row }) => {
					return <OperationStatusBadge operationData={row.original} />;
				},
			},
		],
		[]
	);

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb title="Cobranzas" pageTitle="Cobranzas" />

				<Card>
					<CardBody className="pt-0">
						<TableContainer
							columns={columns}
							data={paymentCollectionsList}
							sortOptions={[{ id: 'created_at', desc: true }]}
							onRowClick={row => goToPaymentCollectionsDetails(row.original.payment_collection_id)}
							noDataMessage={'No hay cobranzas disponibles para mostrar'}
							isGlobalFilter={false}
							isAddUserList={false}
							customPageSize={10}
							divClass="table-responsive table-card mb-1"
							tableClass="align-middle table-nowrap"
							theadClass="table-light text-muted"
							footerComment={
								<span>
									Cobranzas de los <b>últimos 7 días</b>
								</span>
							}
						/>
					</CardBody>
				</Card>
			</Container>

			<ToastContainer closeButton={false} />
		</div>
	);
};

export { PaymentCollections };
