/* eslint-disable indent */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { setAuthorization } from '../../../helpers/api_helper';
import { postLogin, postLogout } from '../../../helpers/backend_helper';
import { resetAuthState } from '../../../actions/resetState';
import { getDeviceOSData } from '../../../helpers/login_helper';

export const loginUser = createAsyncThunk('auth/login', async ({ user, navigate }) => {
	try {
		const { device_name, device_type, device_os_name: device_os, device_os_version } = getDeviceOSData();

		const response = await postLogin({
			username: user.username,
			password: user.password,

			source: 'web',
			device_id: '0000-0000-0000',
			device_type,
			device_os,
			device_os_version,
			device_name,
		});

		localStorage.setItem('authUser', JSON.stringify(response));

		setAuthorization(response.token);

		navigate('/');

		return response;
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error';

		toast.error(message, { autoClose: 3000 });
		throw error;
	}
});

export const logoutUser = () => async dispatch => {
	try {
		const response = await postLogout();

		dispatch(logoutClear());

		return response;
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error';

		toast.error(message, { autoClose: 3000 });
		return null;
	}
};

export const logoutClear = () => async dispatch => {
	localStorage.removeItem('authUser');

	// * Clean redux state
	dispatch(resetAuthState());
};
