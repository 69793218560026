import { createSlice } from '@reduxjs/toolkit';

import { getAssignments, replaceAssignments } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	assignments: [],
	error: null,

	isAssignmentsReplace: false,
	isAssignmentsReplaceFail: false,
};

const assignmentsSlice = createSlice({
	name: 'assignments',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getAssignments.fulfilled, (state, action) => {
			state.assignments = action.payload?.data?.assignments || [];
		});

		builder.addCase(getAssignments.rejected, (state, action) => {
			state.error = action?.error || null;
		});

		// * Replace
		builder.addCase(replaceAssignments.pending, state => {
			state.isAssignmentsReplace = false;
			state.isAssignmentsReplaceFail = false;
		});

		builder.addCase(replaceAssignments.fulfilled, state => {
			state.isAssignmentsReplace = true;
			state.isAssignmentsReplaceFail = false;
		});

		builder.addCase(replaceAssignments.rejected, (state, action) => {
			state.error = action?.error || null;

			state.isAssignmentsReplace = false;
			state.isAssignmentsReplaceFail = true;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default assignmentsSlice.reducer;
