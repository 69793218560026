import { createSlice } from '@reduxjs/toolkit';

import { getAllPaymentCollectionDebts } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	payment_collection_debts: [],
	loading_debts: false,
	error: null,
};

const paymentCollectionDebtsSlice = createSlice({
	name: 'payment_collection_debts',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getAllPaymentCollectionDebts.pending, state => {
			state.loading_items = true;
		});

		builder.addCase(getAllPaymentCollectionDebts.fulfilled, (state, action) => {
			state.payment_collection_debts = action.payload?.data?.payment_collection_debts || [];
			state.loading_items = false;
		});

		builder.addCase(getAllPaymentCollectionDebts.rejected, (state, action) => {
			state.error = action?.error || null;
			state.loading_items = false;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default paymentCollectionDebtsSlice.reducer;
