import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

import PropTypes from 'prop-types';

const DeleteModal = ({ show, onDeleteClick, onCloseClick, title, subTitle }) => {
	return (
		<Modal fade={true} isOpen={show} toggle={onCloseClick} centered={true}>
			<ModalBody className="my-2 py-4">
				<div className="text-center">
					<div className="fs-15">
						<h4>{title}</h4>
						<p className="text-muted">{subTitle}</p>
					</div>
				</div>

				<div className="d-flex gap-2 justify-content-center mt-4 pt-2">
					<button
						type="button"
						className="btn w-sm btn-light px-4"
						data-bs-dismiss="modal"
						onClick={onCloseClick}>
						Cancelar
					</button>

					<button
						type="button"
						className="btn w-sm btn-danger px-4"
						id="delete-record"
						onClick={onDeleteClick}>
						Eliminar
					</button>
				</div>
			</ModalBody>
		</Modal>
	);
};

DeleteModal.propTypes = {
	onCloseClick: PropTypes.func,
	onDeleteClick: PropTypes.func,
	show: PropTypes.any,
};

export default DeleteModal;
