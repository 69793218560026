import React, { useEffect, useState, useMemo } from 'react';
import { Card, CardBody, Col, Container, Row, Button, Spinner } from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getAllClientOrders as onGetAllClientOrders } from '../../slices/thunks';
import TableContainer from '../../components/common/TableContainer';

const ClientOrders = () => {
	document.title = 'Pedidos';

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const selectClientOrders = createSelector(
		state => state.ClientOrders,
		state => ({
			client_orders: state.client_orders,
			loading_client_orders: state.loading_client_orders,
			error: state.error,
		})
	);

	const { client_orders, loading_client_orders } = useSelector(selectClientOrders);

	const [clientOrdersList, setClientOrdersList] = useState([]);

	useEffect(() => {
		setClientOrdersList(client_orders);
	}, [client_orders]);

	useEffect(() => {
		if (!client_orders?.length) {
			dispatch(onGetAllClientOrders());
		}
	}, []);

	const goToNewOrder = () => {
		navigate('/new-order');
	};

	const goToOrderDetails = order_id => {
		navigate(`/client-order/${order_id}/items`);
	};

	const columns = useMemo(
		() => [
			{
				Header: '#',
				accessor: 'order_id',
				Cell: ({ row }) => {
					return row.original.order_id;
				},
			},
			{
				Header: 'Total',
				accessor: 'amount',
				filterable: false,
				Cell: ({ row }) => {
					return `$ ${Number(row.original.final_price_with_iva || 0).toFixed(2)}`;
				},
			},
			{
				Header: 'Fecha de entrega',
				accessor: 'deadline',
				filterable: false,
				Cell: ({ row }) => {
					const date = moment(row.original.deadline).format('DD/MM/YYYY');
					const time = moment(row.original.deadline).format('HH:mm');

					return (
						<span>
							{date}
							<small className="text-muted ms-2">{time}</small>
						</span>
					);
				},
			},
			{
				Header: 'Creado el',
				accessor: 'created_at',
				style: {
					width: 195,
				},
				Cell: ({ row }) => {
					const date = moment(row.original.created_at).format('DD/MM/YYYY');
					const time = moment(row.original.created_at).format('HH:mm');

					return (
						<span>
							{date}
							<small className="text-muted ms-2">{time}</small>
						</span>
					);
				},
			},
		],
		[]
	);

	return (
		<div className="page-content">
			<Container fluid>
				<Row>
					<Col xs={12}>
						<div className="page-title-box d-flex align-items-center justify-content-between">
							<h4 className="mb-0">Pedidos</h4>

							<div className="page-title-right">
								<div className="hstack text-nowrap gap-2">
									<Button
										onClick={goToNewOrder}
										className="btn btn-success btn-label left"
										title="Nuevo pedido">
										<i className="ri-add-fill align-bottom label-icon"></i> Nuevo pedido
									</Button>
								</div>
							</div>
						</div>
					</Col>
				</Row>

				<Row>
					<Col lg={12}>
						<Card id="orderList">
							<CardBody className="pt-0">
								{!loading_client_orders ? (
									<TableContainer
										columns={columns}
										data={clientOrdersList}
										sortOptions={[{ id: 'created_at', desc: true }]}
										onRowClick={row => goToOrderDetails(row.original.order_id)}
										noDataMessage={'No hay pedidos disponibles para mostrar'}
										isGlobalFilter={false}
										isAddUserList={false}
										customPageSize={25}
										divClass="table-responsive table-card mb-1"
										tableClass="align-middle table-nowrap"
										theadClass="table-light text-muted"
										isOrderFilter={false}
										footerComment={
											<span>
												Pedidos de los <b>últimos 3 meses</b>
											</span>
										}
									/>
								) : (
									<div className="text-center pt-5 mb-4 pb-1">
										<Spinner />
									</div>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>

			<ToastContainer closeButton={false} />
		</div>
	);
};

export { ClientOrders };
