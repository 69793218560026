import React from 'react';

const ClientOrderProduct = ({ item }) => {
	const iva = parseFloat(item.iva);
	const iva_multiplier = iva > 0 ? iva / 100 + 1 : 1;

	const quantity = Number(item.quantity || 0);
	const unit_price_with_iva = (item.unit_price_without_iva * iva_multiplier).toFixed(2);
	const final_price_with_iva = Number(item.final_price_with_iva || 0).toFixed(2);

	return (
		<tr>
			<td>{item.description}</td>
			<td>{quantity}</td>
			<td>$ {unit_price_with_iva}</td>
			<td className="fw-medium text-end">$ {final_price_with_iva}</td>
		</tr>
	);
};

export { ClientOrderProduct };
