import { createSlice } from '@reduxjs/toolkit';

import { getPaginatedClients } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	clients: [],
	pagination: {},
	error: null,
};

const clientsSlice = createSlice({
	name: 'clients',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getPaginatedClients.fulfilled, (state, action) => {
			state.clients = action.payload?.data?.clients || [];
			state.pagination = action.payload?.pagination || {};
		});

		builder.addCase(getPaginatedClients.rejected, (state, action) => {
			state.error = action?.error || null;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default clientsSlice.reducer;
