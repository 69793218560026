import React, { useEffect } from 'react';
import { Container } from 'reactstrap';

import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { setAxiosHeader } from '../../helpers/api_helper';
import { CompanyHeader } from './CompanyHeader';

import { getCompany as onGetCompany } from '../../slices/thunks';

const Company = () => {
	document.title = 'Empresas';

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { company_id } = useParams();

	useEffect(() => {
		if (!company_id) {
			navigate('/');
		}

		setAxiosHeader('company-id', company_id);
		dispatch(onGetCompany());
	}, []);

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<CompanyHeader />
				</Container>
			</div>

			<ToastContainer closeButton={false} />
		</React.Fragment>
	);
};

export { Company };
