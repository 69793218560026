export const POST_LOGIN = '/auth/login';
export const POST_LOGOUT = '/auth/logout';

export const GET_COMBINED_LOGS = '/files/logs/combined';
export const GET_ERROR_LOGS = 'files/logs/error';

export const GET_All_COMPANIES = '/companies/all';
export const GET_COMPANY = '/companies/own';
export const ADD_COMPANY = '/companies';
export const UPDATE_COMPANY = '/companies';
export const DELETE_COMPANY = '/companies';

export const GET_COMPANY_PERMISSIONS = '/permissions/company';
export const UPDATE_COMPANY_PERMISSIONS = '/permissions/company';

export const GET_MERGED_PERMISSIONS = '/permissions/merged';
export const UPDATE_MERGED_PERMISSIONS = '/permissions/merged';

export const GET_COMPANY_CONFIGURATIONS = '/configurations/company';
export const UPDATE_COMPANY_CONFIGURATIONS = '/configurations/company';

export const GET_MERGED_CONFIGURATIONS = '/configurations/merged';
export const UPDATE_MERGED_CONFIGURATIONS = '/configurations/merged';
export const REPLACE_MERGED_CONFIGURATIONS = '/configurations/merged';

export const GET_ALL_USERS = '/users/all';
export const ADD_USER = '/users';
export const UPDATE_USER = '/users';
export const DELETE_USER = '/users';

export const GET_ALL_ORDERS = '/orders/all';
export const GET_ALL_ORDER_ITEMS = '/orders/items';
export const ADD_ORDER = '/orders';

export const GET_ALL_CLIENT_ORDERS = '/orders/client';
export const GET_CLIENT_ORDER_FILTERS = '/orders/client_order_filters';

export const GET_API_TOKEN = '/companies/api_token';
export const REFRESH_API_TOKEN = '/companies/api_token/refresh';

export const GET_MAXIMUM_AGENT_USERS = '/companies/maximum_agent_users';

export const GET_PAGINATED_CLIENTS = '/clients/paginated';
export const GET_PAGINATED_SALES_AGENT = '/sales_agents/paginated';
export const GET_PAGINATED_COLLECTIONS_AGENT = '/collections_agents/paginated';
export const GET_PAGINATED_DELIVERY_AGENT = '/delivery_agents/paginated';
export const GET_PAGINATED_PRODUCTS = '/products/paginated';

export const GET_ASSIGNMENTS = '/assignments/all';
export const REPLACE_ASSIGNMENTS = '/assignments/replace';

export const GET_ALL_PAYMENT_COLLECTIONS = '/payment_collections/all';
export const GET_ALL_PAYMENT_COLLECTION_DEBTS = '/payment_collections/debts';
export const GET_ALL_PAYMENT_COLLECTION_METHODS = '/payment_collections/methods';

export const GET_ALL_NEW_CLIENTS = '/new_clients/all';

export const GET_USER_TRACKING = '/tracking_coordinates/user';
export const GET_USERS_LAST_POSITION = '/tracking_coordinates/users/last_position';
