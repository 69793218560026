/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Input, Row, CardHeader, CardFooter, Button } from 'reactstrap';

import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BreadCrumb from '../../components/common/BreadCrumb';

import {
	removeCartItem as onRemoveCartItem,
	addProductItem as onAddProductItem,
	removeProductItem as onRemoveProductItem,
} from '../../slices/thunks';

const Cart = () => {
	document.title = 'Carrito';

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [cartItems, setCartItems] = useState([]);

	const selectNewOrder = createSelector(
		state => state.NewOrder,
		state => ({
			cart_items: state.cart_items,
		})
	);

	const { cart_items } = useSelector(selectNewOrder);

	useEffect(() => {
		setCartItems(cart_items);
	}, [cart_items]);

	const removeCartItem = product_id => {
		dispatch(onRemoveCartItem(product_id));
	};

	const addProductItem = product_id => {
		dispatch(onAddProductItem(product_id));
	};

	const removeProductItem = product_id => {
		dispatch(onRemoveProductItem(product_id));
	};

	const getTotalProductPrice = cart_item => {
		const {
			quantity,
			product_data: { unit_price_with_iva_and_discount },
		} = cart_item;

		return Number(quantity) * Number(unit_price_with_iva_and_discount);
	};

	const getTotalPrice = () => {
		let total_price = 0;

		cartItems.forEach(item => {
			total_price += Number(getTotalProductPrice(item));
		});

		return total_price;
	};

	const goToCheckout = () => {
		navigate('/checkout');
	};

	return (
		<>
			<div className="page-content">
				<Container fluid>
					<BreadCrumb title="Carrito" pageTitle="Nuevo pedido" goBackTo={'/new-order'} />

					<Row className="mb-3">
						<Col xl={8} className="new-order-mobile-bottom-padding">
							<Row className="align-items-center gy-3 mb-3">
								<div className="col">
									<div>
										<h5 className="fs-14 mb-0">{cartItems.length} items</h5>
									</div>
								</div>

								<div className="col-auto">
									<Link to="/new-order" className="link-primary text-decoration-underline">
										Continuar comprando
									</Link>
								</div>
							</Row>

							{cartItems.length ? (
								cartItems.map(cart_item => (
									<React.Fragment key={cart_item.product_data.product_id}>
										<Card className="product">
											<CardBody>
												{/* Desktop item card */}
												<Row className="d-none d-md-flex gap-2">
													<div className="col mt-0">
														<h5 className="fs-14">{cart_item.product_data.description}</h5>

														<div className="d-flex gap-5" style={{ marginBottom: -4 }}>
															<Link
																to="#"
																className="d-block text-body pr-2 text-danger-emphasis"
																onClick={() =>
																	removeCartItem(
																		cart_item.product_data.product_id
																		// eslint-disable-next-line react/jsx-indent
																	)
																}>
																<i className="ri-delete-bin-fill text-muted align-bottom me-1 text-danger-emphasis"></i>
																Eliminar
															</Link>

															<div className="d-flex gap-2 align-items-center">
																<div className="text-muted">Precio unitario:</div>

																<b className="fs-14 text-muted">
																	${' '}
																	<span id="ticket_price" className="product-price">
																		{cart_item.product_data.unit_price_with_iva_and_discount.toFixed(
																			2
																		)}
																	</span>
																</b>

																{cart_item.product_data.strikethrough_price_with_iva >
																	0 && (
																	<small className="text-muted">
																		[
																		<span className=" text-decoration-line-through">
																			$
																			{cart_item.product_data.strikethrough_price_with_iva.toFixed(
																				2
																			)}
																		</span>
																		]
																	</small>
																)}
															</div>
														</div>
													</div>

													<div className="col-auto mt-0 d-flex align-items-center">
														<div className="input-step">
															<button
																type="button"
																className="minus cursor-not-allowed-if-disabled"
																onClick={() => {
																	removeProductItem(
																		cart_item.product_data.product_id
																	);
																}}
																disabled={cart_item.quantity === 1}>
																–
															</button>

															<Input
																type="text"
																className="product-quantity"
																value={cart_item.quantity}
																readOnly
															/>

															<button
																type="button"
																className="plus"
																onClick={() => {
																	addProductItem(cart_item.product_data.product_id);
																}}>
																+
															</button>
														</div>
													</div>

													<div
														className="col-auto mt-0 d-flex justify-content-end align-items-center"
														style={{ width: 145 }}>
														<div
															className="text-center text-muted"
															style={{ flexDirection: 'column' }}>
															<div>Total</div>
															<h5 className="fs-15 mb-0">
																${' '}
																<span className="product-line-price">
																	{getTotalProductPrice(cart_item)?.toFixed(2)}
																</span>
															</h5>
														</div>
													</div>
												</Row>

												{/* Mobile item card */}
												<div className="d-md-none">
													<div className="mt-0 pb-3">
														<h5 className="fs-14">{cart_item.product_data.description}</h5>

														<div className="d-flex gap-2">
															<div className="text-muted">Precio unitario</div>

															<b className="fs-14 text-muted">
																${' '}
																<span id="ticket_price" className="product-price">
																	{
																		cart_item.product_data
																			.unit_price_with_iva_and_discount
																	}
																</span>
															</b>
														</div>
													</div>

													<Row className="gap-2 pt-3 justify-content-center align-items-center border-top border-top-dashed">
														<Link
															to="#"
															className="col-auto d-block text-body pr-2 pl-2 text-danger-emphasis"
															onClick={() =>
																removeCartItem(
																	cart_item.product_data.product_id
																	// eslint-disable-next-line react/jsx-indent
																)
															}>
															<i className="ri-delete-bin-fill text-muted align-bottom me-1 text-danger-emphasis fs-18"></i>
														</Link>

														<div className="col text-center">
															<div className="input-step">
																<button
																	type="button"
																	className="minus cursor-not-allowed-if-disabled"
																	onClick={() => {
																		removeProductItem(
																			cart_item.product_data.product_id
																		);
																	}}
																	disabled={cart_item.quantity === 1}>
																	–
																</button>

																<Input
																	type="text"
																	className="product-quantity"
																	value={cart_item.quantity}
																	readOnly
																/>

																<button
																	type="button"
																	className="plus"
																	onClick={() => {
																		addProductItem(
																			cart_item.product_data.product_id
																		);
																	}}>
																	+
																</button>
															</div>
														</div>

														<div className="col-auto d-flex justify-content-end align-items-center mt-0 ps-0">
															<div
																className="text-center text-muted"
																style={{ flexDirection: 'column' }}>
																<div>Total</div>
																<h5 className="fs-15 mb-0">
																	${' '}
																	<span className="product-line-price">
																		{getTotalProductPrice(cart_item)?.toFixed(2)}
																	</span>
																</h5>
															</div>
														</div>
													</Row>
												</div>
											</CardBody>
										</Card>
									</React.Fragment>
								))
							) : (
								<Card>
									<div className="py-4 text-center">
										<div className="mt-3 mb-3">
											<h5>No hay items en el carrito</h5>

											<Link to="/new-order" className="link-primary text-decoration-underline">
												Volver
											</Link>
										</div>
									</div>
								</Card>
							)}
						</Col>

						<Col xl={4}>
							<div className="sticky-side-checkout-div">
								<Card className="mb-0">
									<CardHeader className="border-bottom-dashed">
										<h5 className="card-title mb-0">Resumen</h5>
									</CardHeader>

									<CardBody className="pt-0 pb-0">
										<div className="d-flex w-100 justify-content-between fs-17 my-3">
											<div>
												Total
												<div className="fs-13">({cartItems.length} items)</div>
											</div>

											<div className="d-flex align-items-center">
												$ {getTotalPrice()?.toFixed(2)}
											</div>
										</div>
									</CardBody>

									<CardFooter>
										<div className="">
											<Button
												onClick={goToCheckout}
												className="btn btn-success btn-label right ms-auto fs-16 w-100"
												style={{
													height: 50,
												}}
												disabled={!cartItems.length}
												title="Checkout">
												<i
													className="ri-arrow-right-line label-icon align-bottom fs-20 ms-2"
													style={{ width: 50 }}></i>
												Continuar
											</Button>
										</div>
									</CardFooter>
								</Card>
							</div>
						</Col>
					</Row>
				</Container>
			</div>

			<ToastContainer closeButton={false} />
		</>
	);
};

export { Cart };
