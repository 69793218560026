import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	getAllCompanies as getAllCompaniesAPI,
	postNewCompany as addNewCompanyAPI,
	deleteCompany as deleteCompanyAPI,
} from '../../helpers/backend_helper';

export const getAllCompanies = createAsyncThunk('companies/getAll', async () => {
	return getAllCompaniesAPI();
});

export const addNewCompany = createAsyncThunk('companies/addNewCompany', async data => {
	try {
		const response = await addNewCompanyAPI(data);

		if (!response.success) {
			throw new Error(response);
		}

		toast.success('Empresa creada con éxito', { autoClose: 3000 });

		return {
			company_id: response.company_id,
			name: data.get('name'),
			short_name: data.get('short_name'),
			api_token: response.api_token,
			logo_file: response.logo_file,
			administrator_password: response.administrator_password,
			updater_password: response.updater_password,
			created_at: new Date().toDateString(),
			...data,
		};
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error';

		toast.error(message, { autoClose: 3000 });
		throw error;
	}
});

export const deleteCompany = createAsyncThunk('companies/deleteCompany', async data => {
	try {
		const response = await deleteCompanyAPI(data);

		if (!response.success) {
			throw new Error(response);
		}

		toast.success('Eliminación de empresa realizada con éxito', { autoClose: 3000 });

		return data;
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error';

		toast.error(message, { autoClose: 3000 });
		throw error;
	}
});
