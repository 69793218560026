import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row, CardHeader, Spinner, CardFooter } from 'reactstrap';

import { useNavigate, useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import BreadCrumb from '../../components/common/BreadCrumb';
import tax_conditions from '../../assets/data/tax_conditions.json';
import OperationStatusBadge from '../../components/atoms/OperationStatusBadge';

const NewClientDetails = () => {
	document.title = 'Detalle de cliente';

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { new_client_id } = useParams();

	// * New client
	const [newClientData, setNewClientData] = useState({});

	const selectNewClients = createSelector(
		state => state.NewClients,
		state => ({
			newClients: state.newClients,
			error: state.error,
		})
	);

	const { newClients } = useSelector(selectNewClients);

	useEffect(() => {
		// eslint-disable-next-line eqeqeq
		const new_client_data = newClients.find(item => item.new_client_id == new_client_id);

		if (!new_client_data) {
			navigate('/');
		}

		setNewClientData(new_client_data);
	}, [newClients]);

	const getTaxConditionName = code => {
		// eslint-disable-next-line eqeqeq
		const tax_conditions_data = tax_conditions.find(item => item.code == code);

		return tax_conditions_data?.name || null;
	};

	const getIdentificationTitle = () => {
		if (newClientData.tax_condition_code === 'consumidor_final') {
			return 'DNI';
		}

		return 'CUIT';
	};

	const getFormattedIdentification = () => {
		const { identification } = newClientData;

		if (newClientData.tax_condition_code !== 'consumidor_final' && identification?.length === 11) {
			return identification.replace(/^(\d{2})(\d{8})(\d{1})$/, '$1-$2-$3');
		}

		return identification || '—';
	};

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb title="Detalle de cliente" pageTitle="Nuevos clientes" goBackTo={'/new-clients'} />

				<Row className="flex-column-reverse flex-xl-row">
					<Col xl={9}>
						<Card>
							<CardHeader>
								<h5 className="card-title mb-0">Datos</h5>
							</CardHeader>

							<CardBody>
								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0" style={{ width: 160 }}>
											Nombre
										</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">{newClientData?.first_name || '—'}</h6>
									</div>
								</div>

								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0" style={{ width: 160 }}>
											Apellido
										</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">{newClientData?.last_name || '—'}</h6>
									</div>
								</div>

								<div className="d-flex  mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0" style={{ width: 160 }}>
											Dirección
										</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											{newClientData?.address || '—'}, {newClientData?.locality || '—'} (
											{newClientData?.postal_code || '—'}), {newClientData?.state || '—'}
										</h6>
									</div>
								</div>

								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0" style={{ width: 160 }}>
											Teléfono
										</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											<span className="text-muted">
												{newClientData?.phone_line_number
													? `+${newClientData?.phone_country_code} `
													: null}
											</span>
											{newClientData?.phone_line_number
												? `${newClientData?.phone_prefix} ${newClientData?.phone_line_number}`
												: '—'}
										</h6>
									</div>
								</div>

								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0" style={{ width: 160 }}>
											Condición fiscal
										</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											{newClientData?.tax_condition_code
												? getTaxConditionName(newClientData?.tax_condition_code) || '—'
												: '—'}
										</h6>
									</div>
								</div>

								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0" style={{ width: 160 }}>
											{getIdentificationTitle()}
										</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">{getFormattedIdentification()}</h6>
									</div>
								</div>

								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0" style={{ width: 160 }}>
											Email
										</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">{newClientData?.email || '—'}</h6>
									</div>
								</div>

								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0" style={{ width: 160 }}>
											Vendedor asociado
										</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											<span className="text-muted">
												[
												{newClientData?.sales_agent_name
													? newClientData?.sales_agent_id?.toString() || '—'
													: '—'}
												]
											</span>{' '}
											{newClientData?.sales_agent_name || '—'}
										</h6>
									</div>
								</div>

								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0" style={{ width: 160 }}>
											Zona
										</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											<span className="text-muted">
												[
												{newClientData?.zone_name
													? newClientData?.zone_id?.toString() || '—'
													: '—'}
												]
											</span>{' '}
											{newClientData?.zone_name || '—'}
										</h6>
									</div>
								</div>

								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0" style={{ width: 160 }}>
											Lista de precios
										</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											<span className="text-muted">
												[
												{newClientData?.price_list_name
													? newClientData?.price_list_id?.toString() || '—'
													: '—'}
												]
											</span>{' '}
											{newClientData?.price_list_name || '—'}
										</h6>
									</div>
								</div>

								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0" style={{ width: 160 }}>
											Descuento por defecto
										</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											{newClientData?.discount ? `${newClientData?.discount}%` : '—'}
										</h6>
									</div>
								</div>
							</CardBody>

							<CardFooter>
								<div className="d-flex align-items-center">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Observaciones:</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">{newClientData?.observations || '—'}</h6>
									</div>
								</div>
							</CardFooter>
						</Card>
					</Col>

					<Col xl={3}>
						<Card>
							<CardHeader>
								<h5 className="card-title mb-0">Detalles</h5>
							</CardHeader>

							<CardBody>
								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Estado</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<OperationStatusBadge operationData={newClientData} />
									</div>
								</div>

								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">ID Interno</p>
									</div>

									<div className="flex-grow-1 ms-2">{new_client_id}</div>
								</div>

								<div className="d-flex align-items-center">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Creado el</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											<span>
												{moment(newClientData?.created_at).format('DD/MM/YYYY')}

												<span className="text-muted ms-1">
													{moment(newClientData?.created_at).format('HH:mm')}
												</span>
											</span>
										</h6>
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export { NewClientDetails };
