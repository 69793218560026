import { createSlice } from '@reduxjs/toolkit';

import { getMergedConfigurations, updateMergedConfigurations, replaceMergedConfigurations } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	merged_configurations: {},
	merged_configurations_array: [],
	loading_merged_configurations: false,
	error: null,

	isMergedConfigurationUpdate: false,
	isMergedConfigurationUpdateFail: false,
};

const mergedConfigurationsSlice = createSlice({
	name: 'merged_configurations',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getMergedConfigurations.pending, state => {
			state.loading_merged_configurations = true;
		});

		builder.addCase(getMergedConfigurations.fulfilled, (state, action) => {
			const merged_configurations = action.payload?.data?.configurations || [];

			const merged_configurations_array = Object.keys(merged_configurations).map(key => ({
				name: key,
				...merged_configurations[key],
			}));

			state.merged_configurations = merged_configurations;
			state.merged_configurations_array = merged_configurations_array;
			state.loading_merged_configurations = false;
		});

		builder.addCase(getMergedConfigurations.rejected, (state, action) => {
			state.error = action?.error || null;
			state.loading_merged_configurations = false;
		});

		// * Update
		builder.addCase(updateMergedConfigurations.pending, state => {
			state.isMergedConfigurationUpdate = false;
			state.isMergedConfigurationUpdateFail = false;
		});

		builder.addCase(updateMergedConfigurations.fulfilled, (state, action) => {
			// Update on object
			action.payload.configurations.forEach(merged_configuration => {
				const { name } = state.merged_configurations_array.find(
					item => item.configuration_id.toString() === merged_configuration.configuration_id.toString()
				);

				state.merged_configurations[name] = {
					...state.merged_configurations[name],
					...merged_configuration,
				};
			});

			// Update on array
			state.merged_configurations_array = state.merged_configurations_array.map(merged_configuration => {
				const new_configuration = action.payload.configurations.find(
					item => item.configuration_id.toString() === merged_configuration.configuration_id.toString()
				);

				if (!new_configuration) {
					return merged_configuration;
				}

				return { ...merged_configuration, ...new_configuration };
			});

			state.isMergedConfigurationUpdate = true;
			state.isMergedConfigurationUpdateFail = false;
		});

		builder.addCase(updateMergedConfigurations.rejected, (state, action) => {
			state.error = action?.error || null;

			state.isMergedConfigurationUpdate = false;
			state.isMergedConfigurationUpdateFail = true;
		});

		// * Replace
		builder.addCase(replaceMergedConfigurations.pending, state => {
			state.isMergedConfigurationUpdate = false;
			state.isMergedConfigurationUpdateFail = false;
		});

		builder.addCase(replaceMergedConfigurations.fulfilled, (state, action) => {
			// Replace on object
			action.payload.configurations.forEach(merged_configuration => {
				const { name } = state.merged_configurations_array.find(
					item => item.configuration_id.toString() === merged_configuration.configuration_id.toString()
				);

				state.merged_configurations[name] = {
					...state.merged_configurations[name],
					...merged_configuration,
				};
			});

			// Replace on array
			state.merged_configurations_array = state.merged_configurations_array.map(merged_configuration => {
				const new_configuration = action.payload.configurations.find(
					item => item.configuration_id.toString() === merged_configuration.configuration_id.toString()
				);

				if (!new_configuration) {
					return merged_configuration;
				}

				return { ...merged_configuration, ...new_configuration };
			});

			state.isMergedConfigurationUpdate = true;
			state.isMergedConfigurationUpdateFail = false;
		});

		builder.addCase(replaceMergedConfigurations.rejected, (state, action) => {
			state.error = action?.error || null;

			state.isMergedConfigurationUpdate = false;
			state.isMergedConfigurationUpdateFail = true;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default mergedConfigurationsSlice.reducer;
