import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'reactstrap';

import classnames from 'classnames';

function formatHour(recorded_at) {
	if (!recorded_at) {
		return null;
	}

	const date = new Date(recorded_at);

	const hours = date.getHours().toString().padStart(2, '0');
	const minutes = date.getMinutes().toString().padStart(2, '0');

	return `${hours}:${minutes}`;
}

const LastEventItem = ({ mapRef, event, focusedElement, setFocusedElement, hoveredElement, setHoveredElement }) => {
	const item_ref = useRef(null);

	const { id, is_the_last_position_of_the_day } = event;

	const finish_at = formatHour(event?.recorded_at);

	useEffect(() => {
		const is_focused = focusedElement?.type === 'marker' && focusedElement?.id === id;

		if (is_focused && item_ref.current) {
			const rect = item_ref.current.getBoundingClientRect();
			const is_visible =
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth);

			if (!is_visible) {
				item_ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
	}, [focusedElement]);

	const onClick = () => {
		setFocusedElement({ type: 'marker', id });

		// Center map on event marker
		mapRef.current.map.panTo({
			lat: event.latitude,
			lng: event.longitude,
		});
	};

	const onMouseEnter = () => {
		setHoveredElement({ type: 'marker', id });
	};

	const onMouseLeave = () => {
		setHoveredElement({ type: null, id: null });
	};

	return (
		<div ref={item_ref}>
			<Row
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				className={classnames(
					'd-flex align-items-center gap-2 mx-2 px-2 rounded cursor-pointer',
					{ 'map-last-position-event-item': is_the_last_position_of_the_day },
					{ 'map-finish-event-item': !is_the_last_position_of_the_day },
					{ hovered: hoveredElement?.type === 'marker' && hoveredElement?.id === id },
					{ selected: focusedElement?.type === 'marker' && focusedElement?.id === id }
				)}>
				<Col className="col-auto mx-0 px-0" style={{ paddingTop: 7, paddingBottom: 7 }}>
					<div
						className="position-absolute"
						style={{
							top: 0,
							height: 7,
							width: 8,
							padding: 0,
							marginLeft: 11,
							backgroundColor: '#299CDB',
						}}
					/>

					{is_the_last_position_of_the_day ? (
						<div
							className="d-flex justify-content-center align-items-center bg-info-subtle shadow"
							style={{
								display: 'inline-flex',
								height: 30,
								minWidth: 30,
								borderRadius: 15,
							}}>
							<div
								className="bg-info border border-1 border-light rounded-circle"
								style={{ height: 12, width: 12 }}
							/>
						</div>
					) : (
						<div
							className="bg-danger-subtle shadow"
							style={{
								display: 'inline-flex',
								height: 30,
								minWidth: 30,
								borderRadius: 15,
							}}>
							<div className="d-flex h-100 w-100 justify-content-center align-items-center px-0 mx-0">
								<i className={'ri-flag-fill text-danger'} style={{ fontSize: 15 }}></i>
							</div>
						</div>
					)}
				</Col>

				<Col className="mx-0 px-0">
					<h5 className="fs-14 mb-0 mx-0 px-0">
						{is_the_last_position_of_the_day ? 'Ultima ubicación' : 'Fin'}
					</h5>
				</Col>

				<Col className="col-auto mx-0 px-0">
					<p className="mb-0 fs-12">{finish_at} hs</p>
				</Col>
			</Row>
		</div>
	);
};

export default LastEventItem;
