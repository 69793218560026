import { createSlice } from '@reduxjs/toolkit';

import { getAllPaymentCollections } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	payment_collections: [],
	error: null,
};

const paymentCollectionsSlice = createSlice({
	name: 'payment_collections',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getAllPaymentCollections.fulfilled, (state, action) => {
			state.payment_collections = action.payload?.data?.payment_collections || [];
		});

		builder.addCase(getAllPaymentCollections.rejected, (state, action) => {
			state.error = action?.error || null;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default paymentCollectionsSlice.reducer;
