import { createSlice } from '@reduxjs/toolkit';

import { getCompanyConfigurations, updateCompanyConfigurations } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	company_configurations: [],
	error: null,

	isCompanyConfigurationUpdate: false,
	isCompanyConfigurationUpdateFail: false,
};

const companyConfigurationsSlice = createSlice({
	name: 'company_configurations',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getCompanyConfigurations.fulfilled, (state, action) => {
			state.company_configurations = action.payload?.data?.company_configurations || [];
		});

		builder.addCase(getCompanyConfigurations.rejected, (state, action) => {
			state.error = action?.error || null;
		});

		// * Update
		builder.addCase(updateCompanyConfigurations.pending, state => {
			state.isCompanyConfigurationUpdate = false;
			state.isCompanyConfigurationUpdateFail = false;
		});

		builder.addCase(updateCompanyConfigurations.fulfilled, (state, action) => {
			state.company_configurations = state.company_configurations.map(company_configuration => {
				const new_configuration = action.payload.configurations.find(
					item => item.configuration_id.toString() === company_configuration.configuration_id.toString()
				);

				if (!new_configuration) {
					return company_configuration;
				}

				return { ...company_configuration, ...new_configuration };
			});

			state.isCompanyConfigurationUpdate = true;
			state.isCompanyConfigurationUpdateFail = false;
		});

		builder.addCase(updateCompanyConfigurations.rejected, (state, action) => {
			state.error = action?.error || null;

			state.isCompanyConfigurationUpdate = false;
			state.isCompanyConfigurationUpdateFail = true;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default companyConfigurationsSlice.reducer;
