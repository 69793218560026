import { createSlice } from '@reduxjs/toolkit';

import { getApiToken, refreshApiToken } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	api_token: null,
	error: null,

	isRefreshApiToken: false,
	isRefreshApiTokenFail: false,
};

const apiTokenSlice = createSlice({
	name: 'api_token',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getApiToken.fulfilled, (state, action) => {
			state.api_token = action.payload?.data?.api_token || null;
		});

		builder.addCase(getApiToken.rejected, (state, action) => {
			state.error = action?.error || null;
		});

		// * Refresh
		builder.addCase(refreshApiToken.pending, state => {
			state.isRefreshApiToken = false;
			state.isRefreshApiTokenFail = false;
		});

		builder.addCase(refreshApiToken.fulfilled, (state, action) => {
			state.api_token = action.payload?.data?.api_token || null;

			state.isRefreshApiToken = true;
			state.isRefreshApiTokenFail = false;
		});

		builder.addCase(refreshApiToken.rejected, (state, action) => {
			state.error = action?.error || null;

			state.isRefreshApiToken = false;
			state.isRefreshApiTokenFail = true;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default apiTokenSlice.reducer;
