/* eslint-disable class-methods-use-this */
import axios from 'axios';

import { api } from '../config';

// * Saved token
const token = JSON.parse(localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).token : null;

const axiosInstance = axios.create({
	baseURL: api.API_URL,

	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json; charset=utf-8',
		Authorization: token ? `Bearer ${token}` : null,
	},

	timeout: 10000,
});

const addAxiosRequestInterceptors = (response_callback, error_callback) => {
	const interceptor = axiosInstance.interceptors.request.use(response_callback, error_callback);

	return () => axios.interceptors.request.eject(interceptor);
};

const addAxiosResponseInterceptors = (response_callback, error_callback) => {
	const interceptor = axiosInstance.interceptors.response.use(response_callback, error_callback);

	return () => axios.interceptors.response.eject(interceptor);
};

const setAuthorization = bearer_token => {
	setAxiosHeader('Authorization', `Bearer ${bearer_token}`);
};

const clearAuthorization = () => {
	cleanAxiosHeader('Authorization');
};

const setAxiosHeader = (header_name, value) => {
	axiosInstance.defaults.headers[header_name] = value;
};

const cleanAxiosHeader = header_name => {
	axiosInstance.defaults.headers[header_name] = '';
};

class APIClient {
	get = (url, params, config = undefined) => {
		let response;

		const paramKeys = [];

		if (params) {
			Object.keys(params).map(key => {
				paramKeys.push(`${key}=${params[key]}`);
				return paramKeys;
			});

			const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : '';
			response = axiosInstance.get(`${url}?${queryString}`, params, config);
		} else {
			response = axiosInstance.get(`${url}`, config);
		}

		return response;
	};

	create = (url, data, config = undefined) => {
		return axiosInstance.post(url, data, config);
	};

	update = (url, data, config = undefined) => {
		return axiosInstance.patch(url, data, config);
	};

	put = (url, data, config = undefined) => {
		return axiosInstance.put(url, data, config);
	};

	delete = (url, data, config) => {
		return axiosInstance.delete(url, { ...config, data });
	};
}

const getLoggedInUser = () => {
	const user = localStorage.getItem('authUser');

	if (!user) {
		return null;
	}

	return JSON.parse(user);
};

export {
	APIClient,
	addAxiosRequestInterceptors,
	addAxiosResponseInterceptors,
	setAuthorization,
	clearAuthorization,
	setAxiosHeader,
	cleanAxiosHeader,
	getLoggedInUser,
};
