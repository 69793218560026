import { createSlice } from '@reduxjs/toolkit';

import { getCompanyPermissions, updateCompanyPermissions } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	company_permissions: [],
	error: null,

	isCompanyPermissionUpdate: false,
	isCompanyPermissionUpdateFail: false,
};

const companyPermissionsSlice = createSlice({
	name: 'company_permissions',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getCompanyPermissions.fulfilled, (state, action) => {
			state.company_permissions = action.payload?.data?.company_permissions || [];
		});

		builder.addCase(getCompanyPermissions.rejected, (state, action) => {
			state.error = action?.error || null;
		});

		// * Update
		builder.addCase(updateCompanyPermissions.pending, state => {
			state.isCompanyPermissionUpdate = false;
			state.isCompanyPermissionUpdateFail = false;
		});

		builder.addCase(updateCompanyPermissions.fulfilled, (state, action) => {
			state.company_permissions = state.company_permissions.map(company_permission => {
				const new_permission = action.payload.permissions.find(
					item => item.permission_id.toString() === company_permission.permission_id.toString()
				);

				if (!new_permission) {
					return company_permission;
				}

				return { ...company_permission, ...new_permission };
			});

			state.isCompanyPermissionUpdate = true;
			state.isCompanyPermissionUpdateFail = false;
		});

		builder.addCase(updateCompanyPermissions.rejected, (state, action) => {
			state.error = action?.error || null;

			state.isCompanyPermissionUpdate = false;
			state.isCompanyPermissionUpdateFail = true;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default companyPermissionsSlice.reducer;
