/* eslint-disable indent */
import React, { useEffect, useCallback, useRef, useState } from 'react';
import {
	Col,
	Input,
	Row,
	ModalBody,
	Label,
	Modal,
	ModalHeader,
	Form,
	ModalFooter,
	Button,
	FormFeedback,
	Spinner,
} from 'reactstrap';

import * as Yup from 'yup';
import { createSelector } from 'reselect';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { api } from '../../config';

import { addNewCompany as onAddNewCompany, updateCompany as onUpdateCompany } from '../../slices/thunks';

import multiUser from '../../assets/images/users/multi-user.jpg';

const getImageWidthAndHeight = image_file => {
	const image_dimensions = { width: null, height: null };

	return new Promise(resolve => {
		const file_reader = new FileReader();

		file_reader.readAsDataURL(image_file);

		file_reader.onload = function () {
			const image = new Image();
			image.src = file_reader.result;

			image.onload = function () {
				image_dimensions.width = image.width;
				image_dimensions.height = image.height;

				resolve(image_dimensions);
			};
		};
	});
};

const CompanyModal = ({ companyData, isModalOpen, setIsModalOpen }) => {
	const dispatch = useDispatch();

	const logoImageRef = useRef();
	const canvasImageRef = useRef(null);

	const [loadingCompanyCreate, setLoadingCompanyCreate] = useState(false);

	const isEdition = !!companyData;

	const selectCompanies = createSelector(
		state => state.Companies,
		state => ({
			isCompanyAdded: state.isCompanyAdded,
			isCompanyAddedFail: state.isCompanyAddedFail,
		})
	);

	const { isCompanyAdded, isCompanyAddedFail } = useSelector(selectCompanies);

	const selectCompany = createSelector(
		state => state.Company,
		state => ({
			isCompanyUpdated: state.isCompanyUpdated,
			isCompanyUpdatedFail: state.isCompanyUpdatedFail,
		})
	);

	const { isCompanyUpdated, isCompanyUpdatedFail } = useSelector(selectCompany);

	const toggleNewCompanyModal = useCallback(() => {
		if (isModalOpen) {
			validation.resetForm();

			setSelectedFile(undefined);
			setPreview(undefined);

			setIsModalOpen(false);
			setLoadingCompanyCreate(false);
		} else {
			setIsModalOpen(true);
		}
	}, [isModalOpen]);

	// * Validate
	const company_logo_validation = Yup.mixed()
		.test('invalid-logo-weight', 'El peso máximo permitido es de 300 kb', value => {
			if (value && logoImageRef.current) {
				return logoImageRef.current.files[0].size <= 300000;
			}

			return true;
		})
		.test('invalid-logo-type', 'Formatos aceptados: .png, .jpg y .jpeg', value => {
			if (value && logoImageRef.current) {
				const { type } = logoImageRef.current.files[0];

				return type === 'image/jpg' || type === 'image/jpeg' || type === 'image/png';
			}

			return true;
		})
		.test('invalid-logo-size', 'La resolución permitida es de 512x512 o 1024x256 px', async value => {
			// eslint-disable-next-line no-async-promise-executor
			return new Promise(async resolve => {
				try {
					if (!value || !logoImageRef.current) {
						resolve(true);
					}

					const { height, width } = await getImageWidthAndHeight(logoImageRef.current.files[0]);

					if ((height === 512 && width === 512) || (height === 256 && width === 1024)) {
						resolve(true);
					} else {
						resolve(false);
					}
				} catch (error) {
					resolve(false);
				}
			});
		});

	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			company_logo: '',
			name: (companyData && companyData.name) || '',
			maximum_agent_users: (companyData && companyData.maximum_agent_users) || '',
			city: (companyData && companyData.city) || '',
			postal_code: (companyData && companyData.postal_code) || '',
			state: (companyData && companyData.state) || '',
			country: (companyData && companyData.country) || '',
			email: (companyData && companyData.email) || '',
			observations: (companyData && companyData.observations) || '',
		},

		validationSchema: Yup.object().shape({
			company_logo: company_logo_validation,
			name: Yup.string().required('Ingresar nombre'),
			maximum_agent_users: Yup.string().required('Ingresar cantidad maxima'),
			city: Yup.string().required('Ingresar ciudad'),
			postal_code: Yup.string().required('Ingresar código postal'),
			state: Yup.string().required('Ingresar provincia'),
			country: Yup.string().required('Ingresar país'),
			email: Yup.string().required('Ingresar email'),
		}),

		onSubmit: values => {
			setLoadingCompanyCreate(true);

			const form_data = new FormData();

			const cleaned_observations = values.observations?.trim().replace(/\s+/g, ' ') || '';

			if (selectedFile) {
				form_data.append('company_logo', selectedFile);
			}

			form_data.append('name', values.name);
			form_data.append('maximum_agent_users', Number(values.maximum_agent_users));
			form_data.append('city', values.city);
			form_data.append('postal_code', values.postal_code);
			form_data.append('state', values.state);
			form_data.append('country', values.country);
			form_data.append('email', values.email);
			form_data.append('observations', cleaned_observations);

			if (isEdition) {
				dispatch(onUpdateCompany(form_data));
			} else {
				dispatch(onAddNewCompany(form_data));
			}
		},
	});

	// On create error
	useEffect(() => {
		if (isCompanyAddedFail) {
			setLoadingCompanyCreate(false);
		}
	}, [isCompanyAddedFail]);

	// On create success
	useEffect(() => {
		if (isCompanyAdded) {
			toggleNewCompanyModal();
		}
	}, [isCompanyAdded]);

	// On update error
	useEffect(() => {
		if (isCompanyUpdatedFail) {
			setLoadingCompanyCreate(false);
		}
	}, [isCompanyUpdatedFail]);

	// On update success
	useEffect(() => {
		if (isCompanyUpdated) {
			toggleNewCompanyModal();
		}
	}, [isCompanyUpdated]);

	const [selectedFile, setSelectedFile] = useState();
	const [preview, setPreview] = useState();

	// create a preview as a side effect, whenever selected file is changed
	useEffect(() => {
		if (!selectedFile) {
			if (isEdition) {
				setPreview(`${api.API_URL}/files/images/${companyData?.logo_file}`);
				return;
			}

			setPreview(undefined);
			return;
		}

		const objectUrl = URL.createObjectURL(selectedFile);
		setPreview(objectUrl);

		// free memory when ever this component is unmounted
		// eslint-disable-next-line consistent-return
		return () => URL.revokeObjectURL(objectUrl);
	}, [selectedFile, companyData, isModalOpen]);

	const onSelectFile = event => {
		if (!event.target.files || event.target.files.length === 0) {
			setSelectedFile(undefined);
			return;
		}

		setSelectedFile(event.target.files[0]);
	};

	// Generate an automatic image for company based on first letter of name
	const handleCompanyNameChange = event => {
		const company_name = event.target.value;

		if (!company_name) {
			setSelectedFile(null);
			return;
		}

		if (company_name.length > 1 || selectedFile) {
			return;
		}

		const canvas = canvasImageRef.current;
		const ctx = canvas.getContext('2d');

		// Style
		ctx.fillStyle = '#195EC8';
		ctx.fillRect(0, 0, canvas.width, canvas.height);
		ctx.font = 'bold 286px Arial';
		ctx.fillStyle = '#FFFFFF';
		ctx.textAlign = 'center';
		ctx.textBaseline = 'middle';

		// Add first letter
		ctx.fillText(company_name.charAt(0).toUpperCase(), canvas.width / 2, canvas.height / 2);

		// Convert canvas to blob file
		canvas.toBlob(blob => {
			const file = new File([blob], 'automatic_image.png', {
				type: 'image/png',
			});

			// Add image
			const dataTransfer = new DataTransfer();
			dataTransfer.items.add(file);
			logoImageRef.current.files = dataTransfer.files;

			setSelectedFile(dataTransfer.files[0]);
		});
	};

	return (
		<Modal id="showModal" isOpen={isModalOpen} toggle={toggleNewCompanyModal} centered size="lg">
			<ModalHeader className="bg-info-subtle p-3" toggle={toggleNewCompanyModal}>
				{isEdition ? 'Editar empresa' : 'Nueva empresa'}
			</ModalHeader>

			<Form
				className="tablelist-form"
				onSubmit={e => {
					e.preventDefault();
					validation.handleSubmit();
					return false;
				}}>
				<ModalBody>
					<input type="hidden" id="id-field" />

					<Row className="g-3">
						<Col lg={12} className="mb-3">
							<Row className="justify-content-center align-items-center">
								<div className="col-auto position-relative d-inline-block">
									<div className="position-absolute bottom-0 end-0">
										{validation.errors.company_logo ? (
											<div className="avatar-xs">
												<div className="avatar-title bg-danger border rounded-circle text-white fs-15">
													<i className="ri-error-warning-line"></i>
												</div>
											</div>
										) : null}

										<input
											ref={logoImageRef}
											id="company-logo-input"
											name="company_logo"
											type="file"
											accept="image/png, image/jpeg"
											onChange={event => {
												validation.handleChange(event);
												onSelectFile(event);
											}}
											onBlur={validation.handleBlur}
											value={validation.values.company_logo || null}
											className="form-control d-none"
										/>

										<canvas
											ref={canvasImageRef}
											width={512}
											height={512}
											style={{ display: 'none' }}
										/>
									</div>

									<Label htmlFor="company-logo-input" className="avatar-lg p-1 cursor-pointer">
										<div className="avatar-title bg-light">
											<img
												src={preview || multiUser}
												alt="company_logo"
												className="avatar-md object-fit-cover"
											/>
										</div>
									</Label>
								</div>

								<div className="col-auto d-flex align-items-center ps-1">
									<div>
										<Label>Logo (obligatorio)</Label>

										<p className="fs-12 text-muted mb-0">Resolución: 512x512 o 1024x256 px</p>
										<p className="fs-12 text-muted mb-0">Peso máximo: 300 kb</p>
										<p className="fs-12 text-muted mb-0">Formatos: PNG - JPG</p>
									</div>
								</div>
							</Row>
						</Col>

						<Col lg={5}>
							<div>
								<Label htmlFor="name-field" className="form-label">
									Nombre
								</Label>

								<Input
									name="name"
									id="name-field"
									className="form-control"
									placeholder="Nombre"
									type="text"
									validate={{
										required: { value: true },
									}}
									onChange={event => {
										validation.handleChange(event);
										handleCompanyNameChange(event);
									}}
									onBlur={validation.handleBlur}
									value={validation.values.name || ''}
									invalid={!!(validation.touched.name && validation.errors.name)}
								/>

								{validation.touched.name && validation.errors.name ? (
									<FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
								) : null}
							</div>
						</Col>

						<Col lg={3}>
							<div>
								<Label htmlFor="short-name-field" className="form-label">
									Nombre corto
								</Label>

								<Input
									name="short_name"
									id="short-name-field"
									className="form-control"
									placeholder="Nombre corto"
									type="text"
									value={companyData.short_name || ''}
									disabled={true}
								/>
							</div>
						</Col>

						<Col lg={4}>
							<div>
								<Label htmlFor="maximum-agent-users-field" className="form-label">
									Cantidad máxima de Agentes
								</Label>

								<Input
									name="maximum_agent_users"
									id="maximum-agent-users-field"
									className="form-control"
									placeholder="Cantidad máxima de agentes"
									type="text"
									validate={{
										required: { value: true },
									}}
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.maximum_agent_users || ''}
									invalid={
										validation.touched.maximum_agent_users && validation.errors.maximum_agent_users
									}
								/>

								{validation.touched.maximum_agent_users && validation.errors.maximum_agent_users ? (
									<FormFeedback type="invalid">{validation.errors.maximum_agent_users}</FormFeedback>
								) : null}
							</div>
						</Col>

						<Col lg={3}>
							<div>
								<Label htmlFor="city-field" className="form-label">
									Ciudad
								</Label>

								<Input
									name="city"
									id="city-field"
									className="form-control"
									placeholder="Ciudad"
									type="text"
									validate={{
										required: { value: true },
									}}
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.city || ''}
									invalid={!!(validation.touched.city && validation.errors.city)}
								/>

								{validation.touched.city && validation.errors.city ? (
									<FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
								) : null}
							</div>
						</Col>

						<Col lg={3}>
							<div>
								<Label htmlFor="postal-code-field" className="form-label">
									Código postal
								</Label>

								<Input
									name="postal_code"
									id="postal-code-field"
									className="form-control"
									placeholder="Código postal"
									type="text"
									validate={{
										required: { value: true },
									}}
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.postal_code || ''}
									invalid={!!(validation.touched.postal_code && validation.errors.postal_code)}
								/>

								{validation.touched.postal_code && validation.errors.postal_code ? (
									<FormFeedback type="invalid">{validation.errors.postal_code}</FormFeedback>
								) : null}
							</div>
						</Col>

						<Col lg={3}>
							<div>
								<Label htmlFor="state-field" className="form-label">
									Provincia
								</Label>

								<Input
									name="state"
									id="state-field"
									className="form-control"
									placeholder="Provincia"
									type="text"
									validate={{
										required: { value: true },
									}}
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.state || ''}
									invalid={!!(validation.touched.state && validation.errors.state)}
								/>

								{validation.touched.state && validation.errors.state ? (
									<FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
								) : null}
							</div>
						</Col>

						<Col lg={3}>
							<div>
								<Label htmlFor="country-field" className="form-label">
									País
								</Label>

								<Input
									name="country"
									id="country-field"
									className="form-control"
									placeholder="País"
									type="text"
									validate={{
										required: { value: true },
									}}
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.country || ''}
									invalid={!!(validation.touched.country && validation.errors.country)}
								/>

								{validation.touched.country && validation.errors.country ? (
									<FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
								) : null}
							</div>
						</Col>

						<Col>
							<div>
								<Label htmlFor="email-field" className="form-label">
									Email
								</Label>

								<Input
									name="email"
									id="email-field"
									className="form-control"
									placeholder="Email"
									type="text"
									validate={{
										required: { value: true },
									}}
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.email || ''}
									invalid={!!(validation.touched.email && validation.errors.email)}
								/>

								{validation.touched.email && validation.errors.email ? (
									<FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
								) : null}
							</div>
						</Col>

						<Col lg={12}>
							<div>
								<Label htmlFor="observations-field" className="form-label">
									Observaciones
								</Label>

								<textarea
									rows="3"
									name="observations"
									id="observations-field"
									className="form-control"
									placeholder="Observaciones"
									type="text"
									maxLength={500}
									validate={{
										required: { value: true },
									}}
									onChange={validation.handleChange}
									onBlur={validation.handleBlur}
									value={validation.values.observations || ''}
									invalid={validation.touched.observations && validation.errors.observations}
								/>

								{validation.touched.observations && validation.errors.observations ? (
									<FormFeedback type="invalid">{validation.errors.observations}</FormFeedback>
								) : null}
							</div>
						</Col>
					</Row>
				</ModalBody>

				<ModalFooter>
					<Row className="w-100 align-items-center">
						<Col className="col-12 col-md-8 mb-3 mb-md-0 ps-0 pe-0 pe-md-5 text-muted hstack">
							{validation.errors.company_logo ? (
								<>
									<div>
										<i className="ri-error-warning-line label-icon text-danger fs-18 me-2"></i>
									</div>

									<div>{validation.errors.company_logo}</div>
								</>
							) : null}
						</Col>

						<Col className="col-12 col-md-4 hstack gap-2 justify-content-end px-0">
							<Button
								color="light"
								onClick={() => {
									setIsModalOpen(false);
								}}
								disabled={loadingCompanyCreate}>
								Cerrar
							</Button>

							<Button type="submit" color="success" id="add-btn" disabled={loadingCompanyCreate}>
								{loadingCompanyCreate ? (
									<Spinner size="sm" className="mx-2" />
								) : isEdition ? (
									'Editar empresa'
								) : (
									'Nueva empresa'
								)}
							</Button>
						</Col>
					</Row>
				</ModalFooter>
			</Form>
		</Modal>
	);
};

export { CompanyModal };
