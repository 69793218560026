import { createSlice } from '@reduxjs/toolkit';

import { getPaginatedDeliveryAgents } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	delivery_agents: [],
	pagination: {},
	error: null,
};

const delivery_agentsSlice = createSlice({
	name: 'delivery_agents',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getPaginatedDeliveryAgents.fulfilled, (state, action) => {
			state.delivery_agents = action.payload?.data?.delivery_agents || [];
			state.pagination = action.payload?.pagination || {};
		});

		builder.addCase(getPaginatedDeliveryAgents.rejected, (state, action) => {
			state.error = action?.error || null;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default delivery_agentsSlice.reducer;
