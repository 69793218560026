import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row, Button, Spinner } from 'reactstrap';

import { useParams, useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import {
	getApiToken as onGetApiToken,
	refreshApiToken as onRefreshApiToken,
	deleteCompany as onDeleteCompany,
} from '../../slices/thunks';

import { getCombinedLogs, getErrorLogs } from '../../helpers/backend_helper';
import { setAxiosHeader } from '../../helpers/api_helper';

const CompanyOverview = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { company_id } = useParams();

	const [companyData, setCompanyData] = useState({});

	const selectCompany = createSelector(
		state => state.Company,
		state => ({
			company: state.company,

			isCompanyDelete: state.isCompanyDelete,
			isCompanyDeleteFail: state.isCompanyDeleteFail,
		})
	);

	const { company, isCompanyDelete, isCompanyDeleteFail } = useSelector(selectCompany);

	useEffect(() => {
		setCompanyData(company);
	}, [company]);

	// * Api token
	const [companyApiToken, setCompanyApiToken] = useState(null);
	const [loadingRefreshApiToken, setLoadingRefreshApiToken] = useState(false);

	const selectApiToken = createSelector(
		state => state.ApiToken,
		state => ({
			api_token: state.api_token,

			isRefreshApiToken: state.isRefreshApiToken,
			isRefreshApiTokenFail: state.isRefreshApiTokenFail,
		})
	);

	const { api_token, isRefreshApiToken, isRefreshApiTokenFail } = useSelector(selectApiToken);

	useEffect(() => {
		setCompanyApiToken(api_token);
	}, [api_token]);

	useEffect(() => {
		if (isRefreshApiToken || isRefreshApiTokenFail) {
			setLoadingRefreshApiToken(false);
		}
	}, [isRefreshApiToken, isRefreshApiTokenFail]);

	useEffect(() => {
		setAxiosHeader('company-id', company_id);
		dispatch(onGetApiToken());
	}, []);

	const confirmRefreshApiToken = () => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Esta seguro de renovar el API token')) {
			const first_number = Math.floor(Math.random() * 10) + 1;
			const second_number = Math.floor(Math.random() * 10) + 1;

			const result = first_number + second_number;

			// eslint-disable-next-line no-alert
			const response = prompt(`Ingrese la suma de ${first_number} y ${second_number}`);

			if (Number(response) === result) {
				refreshApiToken();
			} else {
				// eslint-disable-next-line no-alert
				alert('Renovación de API token cancelada');
			}
		}
	};

	const refreshApiToken = () => {
		setLoadingRefreshApiToken(true);
		dispatch(onRefreshApiToken());
	};

	// * Delete company
	const [loadingDeleteCompany, setLoadingDeleteCompany] = useState(false);

	const confirmDeleteCompany = () => {
		// eslint-disable-next-line no-alert
		if (window.confirm('Esta seguro de ELIMINAR la empresa?')) {
			deleteCompany();
		}
	};

	const deleteCompany = () => {
		dispatch(onDeleteCompany({ company_id: Number(company_id) }));
	};

	useEffect(() => {
		if (isCompanyDelete) {
			navigate('/companies');
		}
	}, [isCompanyDelete]);

	useEffect(() => {
		if (isCompanyDeleteFail) {
			setLoadingDeleteCompany(false);
		}
	}, [isCompanyDeleteFail]);

	// * Combined logs
	const [downloadingCombinedLogs, setDownloadingCombinedLogs] = useState(false);
	const [downloadingErrorLogs, setDownloadingErrorLogs] = useState(false);

	function downloadThroughAnchorLink(download_url, file_name) {
		const a = document.createElement('a');
		a.style.display = 'none';

		a.href = download_url;
		a.download = file_name;

		document.body.appendChild(a);
		a.click();
	}

	const downloadCombinedLogs = async () => {
		try {
			setDownloadingCombinedLogs(true);

			const response = await getCombinedLogs();

			const date_string = new Date()
				.toLocaleString('es-ES', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
				})
				.replace(/\//g, '-');

			// Prepare download
			const blob = new Blob([JSON.stringify(response)], { type: 'text/json' });
			const download_url = window.URL.createObjectURL(blob);
			downloadThroughAnchorLink(download_url, `combined-logs-${date_string}.json`);
			window.URL.revokeObjectURL(download_url);
		} catch (error) {
			toast.error('Ha ocurrido un error en la descarga.', { toastId: 'combined-logs-error', autoClose: 3000 });
		} finally {
			setDownloadingCombinedLogs(false);
		}
	};

	const downloadErrorLogs = async () => {
		try {
			setDownloadingErrorLogs(true);

			const response = await getErrorLogs();

			const date_string = new Date()
				.toLocaleString('es-ES', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
				})
				.replace(/\//g, '-');

			// Prepare download
			const blob = new Blob([JSON.stringify(response)], { type: 'text/json' });
			const download_url = window.URL.createObjectURL(blob);
			downloadThroughAnchorLink(download_url, `error-logs-${date_string}.json`);
			window.URL.revokeObjectURL(download_url);
		} catch (error) {
			toast.error('Ha ocurrido un error en la descarga.', { toastId: 'combined-logs-error', autoClose: 3000 });
		} finally {
			setDownloadingErrorLogs(false);
		}
	};

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<div className="text-muted">
						{/* <h6 className="mb-3 fw-semibold text-uppercase">Maxima cantidad de agentes</h6>
						<div className="row">
							<input
								type="text"
								className="form-control"
								readOnly
								defaultValue={companyData.maximum_agent_users}
							/>
						</div> */}

						<div className="pt-0">
							<h6 className="mb-3 fw-semibold text-uppercase">Maxima cantidad de agentes</h6>
							<p>{companyData?.maximum_agent_users}</p>
						</div>

						<div className="pt-3 border-top border-top-dashed mt-3">
							<Row>
								<Col lg={3} sm={6}>
									<div>
										<p className="mb-2 text-uppercase fw-medium">CIUDAD</p>
										<h5 className="fs-15 mb-0">{companyData?.city}</h5>
									</div>
								</Col>

								<Col lg={3} sm={6}>
									<div>
										<p className="mb-2 text-uppercase fw-medium">CÓDIGO POSTAL</p>
										<h5 className="fs-15 mb-0">{companyData?.postal_code}</h5>
									</div>
								</Col>

								<Col lg={3} sm={6}>
									<div>
										<p className="mb-2 text-uppercase fw-medium">PROVINCIA :</p>
										<h5 className="fs-15 mb-0">{companyData?.state}</h5>
									</div>
								</Col>

								<Col lg={3} sm={6}>
									<div>
										<p className="mb-2 text-uppercase fw-medium">PAÍS :</p>
										<h5 className="fs-15 mb-0">{companyData?.country}</h5>
									</div>
								</Col>
							</Row>
						</div>

						<div className="pt-3 border-top border-top-dashed mt-3">
							<h6 className="mb-3 fw-semibold text-uppercase">Email</h6>
							<p>{companyData?.email || '-'}</p>
						</div>

						<div className="pt-3 border-top border-top-dashed mt-3">
							<h6 className="mb-3 fw-semibold text-uppercase">Observaciones</h6>
							<p className="mb-0">{companyData?.observations || '-'}</p>
						</div>
					</div>
				</CardBody>
			</Card>

			<Card>
				<CardBody className="p-0">
					<div className="text-muted">
						<div className="p-3">
							<h6 className="mb-3 fw-semibold text-uppercase">Logs</h6>

							<Row className="mx-0 gap-4">
								<Button
									type="button"
									color="info"
									className="col-auto btn btn-info"
									onClick={downloadCombinedLogs}
									disabled={downloadingCombinedLogs}>
									{downloadingCombinedLogs ? (
										<span className="d-flex align-items-center">
											<Spinner size="sm" className="flex-shrink-0">
												{' '}
												Cargando...{' '}
											</Spinner>

											<span className="flex-grow-1 ms-2">Cargando...</span>
										</span>
									) : (
										<>
											<i className="ri-file-text-line align-bottom me-1"></i> Combined Logs
										</>
									)}
								</Button>

								<Button
									type="button"
									color="info"
									className="col-auto btn btn-danger"
									onClick={downloadErrorLogs}
									disabled={downloadingErrorLogs}>
									{downloadingErrorLogs ? (
										<span className="d-flex align-items-center">
											<Spinner size="sm" className="flex-shrink-0">
												{' '}
												Cargando...{' '}
											</Spinner>

											<span className="flex-grow-1 ms-2">Cargando...</span>
										</span>
									) : (
										<>
											<i className="ri-bug-line align-bottom me-1"></i> Error Logs
										</>
									)}
								</Button>
							</Row>
						</div>
					</div>
				</CardBody>
			</Card>

			<Card>
				<CardBody className="p-0 bg-danger bg-opacity-10">
					<div className="text-muted">
						<div className="p-3">
							<h6 className="mb-3 fw-semibold text-uppercase">Api Token</h6>

							<Row className="mx-0 gap-4">
								<input
									type="text"
									className="col form-control apikey-value"
									readOnly
									defaultValue={companyApiToken}
								/>

								<Button
									type="button"
									color="success"
									className="col-auto btn btn-warning px-4"
									data-bs-toggle="modal"
									onClick={confirmRefreshApiToken}
									data-bs-target="#api-key-modal"
									disabled={loadingRefreshApiToken}>
									{loadingRefreshApiToken ? (
										<span className="d-flex align-items-center">
											<Spinner size="sm" className="flex-shrink-0">
												{' '}
												Cargando...{' '}
											</Spinner>

											<span className="flex-grow-1 ms-2">Cargando...</span>
										</span>
									) : (
										<>
											<i className="ri-save-3-line align-bottom me-1"></i> Renovar Token
										</>
									)}
								</Button>
							</Row>
						</div>

						<div className="p-3 border-top border-top-dashed border-danger border-opacity-25">
							<h6 className="mb-3 fw-semibold text-uppercase">Danger Zone</h6>

							<Button
								type="button"
								color="danger"
								className="btn create-btn px-4"
								data-bs-toggle="modal"
								onClick={confirmDeleteCompany}
								data-bs-target="#api-key-modal"
								disabled={true}>
								{loadingDeleteCompany ? (
									<span className="d-flex align-items-center">
										<Spinner size="sm" className="flex-shrink-0">
											{' '}
											Loading...{' '}
										</Spinner>

										<span className="flex-grow-1 ms-2">Loading...</span>
									</span>
								) : (
									<>
										<i className="ri-save-3-line align-bottom me-1"></i> Eliminar empresa
									</>
								)}
							</Button>
						</div>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export { CompanyOverview };
