import React, { useEffect, useState, useMemo } from 'react';
import { Card, CardBody, Container } from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getAllOrders as onGetAllOrders } from '../../slices/thunks';
import TableContainer from '../../components/common/TableContainer';
import BreadCrumb from '../../components/common/BreadCrumb';
import OperationStatusBadge from '../../components/atoms/OperationStatusBadge';

const Orders = () => {
	document.title = 'Pedidos';

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const selectOrders = createSelector(
		state => state.Orders,
		state => ({
			orders: state.orders,
			error: state.error,
		})
	);

	const { orders } = useSelector(selectOrders);

	const [ordersList, setOrdersList] = useState([]);

	useEffect(() => {
		setOrdersList(orders);
	}, [orders]);

	useEffect(() => {
		if (!orders?.length) {
			dispatch(onGetAllOrders());
		}
	}, []);

	const goToOrderDetails = order_id => {
		navigate(`/order/${order_id}/items`);
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Cliente',
				accessor: 'client_legal_name',
				filterable: false,
				Cell: ({ row }) => {
					return (
						<span>
							<span className="text-muted">
								[{row.original.is_a_new_client ? '-' : row.original.client_id}]
							</span>{' '}
							{row.original.client_legal_name}
						</span>
					);
				},
			},
			{
				Header: 'Vendedor',
				accessor: 'sales_agent_name',
				filterable: false,
				Cell: ({ row }) => {
					return (
						<span>
							<span className="text-muted">[{row.original.sales_agent_id}]</span>{' '}
							{row.original.sales_agent_name}
						</span>
					);
				},
			},
			{
				Header: 'Total',
				accessor: 'amount',
				filterable: false,
				Cell: ({ row }) => {
					return `$ ${Number(row.original.final_price_with_iva || 0).toFixed(2)}`;
				},
			},
			{
				Header: 'Fecha de entrega',
				accessor: 'deadline',
				filterable: false,
				Cell: ({ row }) => {
					const date = moment(row.original.deadline).format('DD/MM/YYYY');
					const time = moment(row.original.deadline).format('HH:mm');

					return (
						<span>
							{date}
							<small className="text-muted ms-2">{time}</small>
						</span>
					);
				},
			},
			{
				Header: 'Creado el',
				accessor: 'created_at',
				Cell: ({ row }) => {
					const date = moment(row.original.created_at).format('DD/MM/YYYY');
					const time = moment(row.original.created_at).format('HH:mm');

					return (
						<span>
							{date}
							<small className="text-muted ms-2">{time}</small>
						</span>
					);
				},
			},
			{
				Header: 'Estado',
				accessor: 'status',
				style: {
					width: 150,
				},
				Cell: ({ row }) => {
					return <OperationStatusBadge operationData={row.original} />;
				},
			},
		],
		[]
	);

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb title="Pedidos" pageTitle="Pedidos" />

				<Card>
					<CardBody className="pt-0">
						<TableContainer
							columns={columns}
							data={ordersList}
							sortOptions={[{ id: 'created_at', desc: true }]}
							onRowClick={row => goToOrderDetails(row.original.order_id)}
							noDataMessage={'No hay pedidos disponibles para mostrar'}
							isGlobalFilter={false}
							isAddUserList={false}
							customPageSize={10}
							divClass="table-responsive table-card mb-1"
							tableClass="align-middle table-nowrap"
							theadClass="table-light text-muted"
							footerComment={
								<span>
									Pedidos de los <b>últimos 7 días</b>
								</span>
							}
						/>
					</CardBody>
				</Card>
			</Container>

			<ToastContainer closeButton={false} />
		</div>
	);
};

export { Orders };
