/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
import React, { useMemo, useState, useEffect } from 'react';
import {
	Container,
	Card,
	CardBody,
	Col,
	Input,
	Row,
	ModalBody,
	Label,
	Modal,
	ModalHeader,
	Form,
	ModalFooter,
	Button,
	FormFeedback,
	ListGroup,
	ListGroupItem,
	Spinner,
} from 'reactstrap';

import classnames from 'classnames';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import moment from 'moment';

import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.ar';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { setAxiosHeader } from '../../helpers/api_helper';
import useDebounce from '../../components/hooks/UseDebounce';
import TableContainer from '../../components/common/TableContainer';
import DeleteModal from '../../components/common/DeleteModal';

import argentina_flag from '../../assets/images/argentina-flag.svg';

import {
	getAllUsers as onGetAllUsers,
	getMaximumAgentUsers as onGetMaximumAgentUsers,
	addNewUser as onAddNewUser,
	updateUser as onUpdateUser,
	deleteUser as onDeleteUser,
	getPaginatedClients as onGetPaginatedClients,
	getPaginatedSalesAgents as onGetPaginatedSalesAgents,
	getPaginatedCollectionsAgents as onGetPaginatedCollectionsAgents,
	getPaginatedDeliveryAgents as onGetPaginatedDeliveryAgents,
	replaceAssignments as onReplaceAssignments,
} from '../../slices/thunks';
import { updateUserData } from '../../slices/Users/reducer';

const phoneRegex =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const { company: { short_name = '' } = {} } = JSON.parse(localStorage.getItem('authUser')) || {};

const Users = () => {
	document.title = 'Usuarios';

	const dispatch = useDispatch();

	// * Users
	const [usersList, setUsersList] = useState([]);
	const [loadingUserCreateOrUpdate, setLoadingUserCreateOrUpdate] = useState(false);

	const selectUsers = createSelector(
		state => state.Users,
		state => ({
			users: state.users,
			error: state.error,

			isUserAdded: state.isUserAdded,
			isUserAddedFail: state.isUserAddedFail,
			isUserUpdate: state.isUserUpdate,
			isUserUpdateFail: state.isUserUpdateFail,
			isUserDelete: state.isUserDelete,
			isUserDeleteFail: state.isUserDeleteFail,
		})
	);

	const { users, isUserAdded, isUserAddedFail, isUserUpdate, isUserUpdateFail } = useSelector(selectUsers);

	useEffect(() => {
		setUsersList(users);
	}, [users]);

	// Get all users list
	useEffect(() => {
		if (!users?.length) {
			dispatch(onGetAllUsers());
		}
	}, []);

	// On create/update error
	useEffect(() => {
		if (isUserAddedFail || isUserUpdateFail) {
			setLoadingUserCreateOrUpdate(false);
		}
	}, [isUserAddedFail, isUserUpdateFail]);

	// On create/update success
	useEffect(() => {
		if (isUserAdded || isUserUpdate) {
			closeUserModal();
		}
	}, [isUserAdded, isUserUpdate]);

	const [selectedUser, setSelectedUser] = useState(null);

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isUserModalOpen, setIsUserModalOpen] = useState(false);
	const [isUserEdition, setIsUserEdition] = useState(false);

	const createNewUser = () => {
		setIsUserEdition(false);
		setIsUserModalOpen(true);
	};

	const editUser = user => {
		setSelectedUser(user);
		setIsUserEdition(true);
		setIsUserModalOpen(true);
	};

	const closeUserModal = () => {
		validation.resetForm();
		setIsUserModalOpen(false);
		setSelectedUser(null);
		setLoadingUserCreateOrUpdate(false);
	};

	// * Validate
	const validation = useFormik({
		// enableReinitialize, use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			first_name: (selectedUser && selectedUser.first_name) || '',
			last_name: (selectedUser && selectedUser.last_name) || '',
			username: (selectedUser && selectedUser.username.replace(`${short_name}_`, '')) || '',
			password: (selectedUser && selectedUser.password) || '',
			city: (selectedUser && selectedUser.city) || '',
			postal_code: (selectedUser && selectedUser.postal_code) || '',
			state: (selectedUser && selectedUser.state) || '',
			country: (selectedUser && selectedUser.country) || '',
			role_id: (selectedUser && selectedUser.role_id) || '5',
			email: (selectedUser && selectedUser.email) || '',
			phone: (selectedUser && selectedUser.phone?.slice(3)) || '',
			observations: (selectedUser && selectedUser.observations) || '',
		},

		validationSchema: Yup.object({
			first_name: Yup.string()
				.required('Ingresar nombre')
				.min(3, 'La longitud mínima requerida es de 3 caracteres.'),

			last_name: Yup.string()
				.required('Ingresar apellido')
				.min(3, 'La longitud mínima requerida es de 3 caracteres.'),

			username: Yup.string()
				.required('Ingresar usuario')
				.min(5, 'La longitud mínima requerida es de 5 caracteres.')
				.matches(
					/^[a-zA-Z0-9_.-]+$/,
					'Solo se permite ingresar letras, números, puntos, guion bajo y guion medio.'
				),

			password: isUserEdition
				? Yup.string().min(5, 'La longitud mínima requerida es de 5 caracteres.')
				: Yup.string()
						.required('Ingresar contraseña')
						.min(5, 'La longitud mínima requerida es de 5 caracteres.'),
			// city: Yup.string().required('Ingresar ciudad'),
			// postal_code: Yup.string().required('Ingresar código postal'),
			// state: Yup.string().required('Ingresar provincia'),
			// country: Yup.string().required('Ingresar país'),
			email: Yup.string().required('Ingresar email.').email('Ingresar un email valido.'),
			phone: Yup.string().required('Ingresar teléfono.').matches(phoneRegex, 'Ingresar un teléfono valido.'),
		}),

		onSubmit: values => {
			setLoadingUserCreateOrUpdate(true);

			const first_name = values.first_name.trim();
			const last_name = values.last_name.trim();

			const username = `${short_name}_${values.username.trim()}`;
			const password = values.password?.trim() || null;

			const city = values.city?.toLowerCase().trim() || null;
			const postal_code = city ? values.postal_code?.trim() : null;
			const state = city ? values.state?.trim() : null;
			const country = city ? 'argentina' : null;

			const { role_id } = values;

			const email = values.email?.trim() || null;

			const sanitized_phone = values.phone.replace(/\s/g, '').replace('+', '');
			const phone = sanitized_phone ? `549${sanitized_phone}` : null;

			const observations = values.observations?.trim().replace(/\s+/g, ' ') || null;

			if (isUserEdition) {
				const data = {
					user_id: selectedUser.user_id.toString(),
					first_name,
					last_name,
					username,
					password,
					city,
					postal_code,
					state,
					country,
					email,
					phone,
					observations,
				};

				dispatch(onUpdateUser(data));
			} else {
				const data = {
					first_name,
					last_name,
					username,
					password,
					city,
					postal_code,
					state,
					country,
					role_id,
					email,
					phone,
					observations,
				};

				dispatch(onAddNewUser(data));
			}
		},
	});

	const deleteUser = () => {
		if (!selectedUser) {
			return;
		}

		dispatch(
			onDeleteUser({
				user_id: selectedUser.user_id.toString(),
			})
		);

		setIsDeleteModalOpen(false);
	};

	const openUserDeletionModal = user => {
		setSelectedUser(user);
		setIsDeleteModalOpen(true);
	};

	// * Maximum agent users
	const selectMaximumAgentUsers = createSelector(
		state => state.MaximumAgentUsers,
		state => ({
			maximum_agent_users: state.maximum_agent_users,
		})
	);

	const { maximum_agent_users } = useSelector(selectMaximumAgentUsers);

	// Get maximum agent users
	useEffect(() => {
		if (!users?.length) {
			dispatch(onGetMaximumAgentUsers());
		}
	}, []);

	const maximumAgentUsersIsReached = () => {
		const agent_users = users?.filter(item => item.role_name === 'company_agent') || [];

		return agent_users?.length >= Number(maximum_agent_users || 0);
	};

	// * Search clients
	const [clientsList, setClientsList] = useState([]);
	const [clientAgentSearch, setClientAgentSearch] = useState(null);
	const [loadingClientsList, setLoadingClientsList] = useState(null);
	const [clientSelected, setClientSelected] = useState(null);
	const [clientAgentAssignments, setClientAgentAssignments] = useState([]);

	const clientsTable = createSelector(
		state => state.Clients,
		state => ({
			clients: state.clients,
		})
	);

	const { clients } = useSelector(clientsTable);

	useEffect(() => {
		const used_clients_ids = clientAgentAssignments.map(item => item.agent_id);

		const filtered_clients = clients.filter(item => used_clients_ids.includes(item.client_id) === false);

		setClientsList(filtered_clients);

		setLoadingClientsList(false);
	}, [clients]);

	useDebounce(
		() => {
			if (clientAgentSearch && clientAgentSearch.length > 2) {
				searchClients();
			}
		},
		[clientAgentSearch],
		800
	);

	const searchClients = () => {
		setLoadingClientsList(true);

		dispatch(
			onGetPaginatedClients({
				page: 1,
				legal_name: clientAgentSearch,
			})
		);
	};

	const addClientAgentAssignment = client_agent => {
		if (!client_agent) {
			return;
		}

		const { client_id, legal_name } = client_agent;

		const is_primary = !clientAgentAssignments?.length;

		const client_agent_data = {
			agent_id: client_id,
			agent_name: legal_name,
			is_primary,
		};

		setClientAgentAssignments(client_agent_assignments => [...client_agent_assignments, client_agent_data]);

		// clean search
		setClientsList([]);
		setClientSelected(null);
	};

	const removeClientAgentAssignment = client_agent => {
		const updated_client_agent_assignments = clientAgentAssignments.filter(item => {
			return item.agent_id !== client_agent.agent_id;
		});

		if (client_agent.is_primary && updated_client_agent_assignments?.length) {
			updated_client_agent_assignments[0] = {
				...updated_client_agent_assignments[0],
				is_primary: true,
			};
		}

		setClientAgentAssignments(updated_client_agent_assignments);
	};

	// * Sales agents
	const [salesAgentsList, setSalesAgentsList] = useState([]);
	const [salesAgentSearch, setSalesAgentSearch] = useState(null);
	const [loadingSalesAgentsList, setLoadingSalesAgentsList] = useState(null);
	const [salesAgentSelected, setSalesAgentSelected] = useState(null);
	const [salesAgentsAssignments, setSalesAgentsAssignments] = useState([]);

	const salesAgentsTable = createSelector(
		state => state.SalesAgents,
		state => ({
			sales_agents: state.sales_agents,
		})
	);

	const { sales_agents } = useSelector(salesAgentsTable);

	useEffect(() => {
		const used_sales_agents_ids = salesAgentsAssignments.map(item => item.agent_id);

		const filtered_sales_agents = sales_agents.filter(
			item => used_sales_agents_ids.includes(item.sales_agent_id) === false
		);

		setSalesAgentsList(filtered_sales_agents);

		setLoadingSalesAgentsList(false);
	}, [sales_agents]);

	useDebounce(
		() => {
			if (salesAgentSearch && salesAgentSearch.length > 2) {
				searchSalesAgents();
			}
		},
		[salesAgentSearch],
		800
	);

	const searchSalesAgents = () => {
		setLoadingSalesAgentsList(true);

		dispatch(
			onGetPaginatedSalesAgents({
				page: 1,
				name: salesAgentSearch,
			})
		);
	};

	const addSalesAgentAssignment = sales_agent => {
		if (!sales_agent) {
			return;
		}

		const { sales_agent_id, name } = sales_agent;

		const is_primary = !salesAgentsAssignments?.length;

		const sales_agent_data = {
			agent_id: sales_agent_id,
			agent_name: name,
			is_primary,
		};

		setSalesAgentsAssignments(sales_agents_assignments => [...sales_agents_assignments, sales_agent_data]);

		// clean search
		setSalesAgentsList([]);
		setSalesAgentSelected(null);
	};

	const changePrimarySalesAgent = agent_id => {
		const updated_sales_agents_assignments = salesAgentsAssignments.map(item => {
			let is_primary = false;

			if (item.agent_id === agent_id) {
				is_primary = true;
			}

			return { ...item, is_primary };
		});

		setSalesAgentsAssignments(updated_sales_agents_assignments);
	};

	const removeSalesAgentAssignment = sales_agent => {
		const updated_sales_agents_assignments = salesAgentsAssignments.filter(item => {
			return item.agent_id !== sales_agent.agent_id;
		});

		if (sales_agent.is_primary && updated_sales_agents_assignments?.length) {
			updated_sales_agents_assignments[0] = {
				...updated_sales_agents_assignments[0],
				is_primary: true,
			};
		}

		setSalesAgentsAssignments(updated_sales_agents_assignments);
	};

	// * Collections agents
	const [collectionsAgentsList, setCollectionsAgentsList] = useState([]);
	const [collectionsAgentSearch, setCollectionsAgentSearch] = useState(null);
	const [loadingCollectionsAgentsList, setLoadingCollectionsAgentsList] = useState(null);
	const [collectionsAgentSelected, setCollectionsAgentSelected] = useState(null);
	const [collectionsAgentsAssignments, setCollectionsAgentsAssignments] = useState([]);

	const collectionsAgentsTable = createSelector(
		state => state.CollectionsAgents,
		state => ({
			collections_agents: state.collections_agents,
		})
	);

	const { collections_agents } = useSelector(collectionsAgentsTable);

	useEffect(() => {
		const used_collections_agents_ids = collectionsAgentsAssignments.map(item => item.agent_id);

		const filtered_collections_agents = collections_agents.filter(
			item => used_collections_agents_ids.includes(item.collections_agent_id) === false
		);

		setCollectionsAgentsList(filtered_collections_agents);

		setLoadingCollectionsAgentsList(false);
	}, [collections_agents]);

	useDebounce(
		() => {
			if (collectionsAgentSearch && collectionsAgentSearch.length > 2) {
				searchCollectionsAgents();
			}
		},
		[collectionsAgentSearch],
		800
	);

	const searchCollectionsAgents = () => {
		setLoadingCollectionsAgentsList(true);

		dispatch(
			onGetPaginatedCollectionsAgents({
				page: 1,
				name: collectionsAgentSearch,
			})
		);
	};

	const addCollectionsAgentAssignment = collections_agent => {
		if (!collections_agent) {
			return;
		}

		const { collections_agent_id, name } = collections_agent;

		const is_primary = !collectionsAgentsAssignments?.length;

		const collections_agent_data = {
			agent_id: collections_agent_id,
			agent_name: name,
			is_primary,
		};

		setCollectionsAgentsAssignments(collections_agents_assignments => [
			...collections_agents_assignments,
			collections_agent_data,
		]);

		// clean search
		setCollectionsAgentsList([]);
		setCollectionsAgentSelected(null);
	};

	const changePrimaryCollectionsAgent = agent_id => {
		const updated_collections_agents_assignments = collectionsAgentsAssignments.map(item => {
			let is_primary = false;

			if (item.agent_id === agent_id) {
				is_primary = true;
			}

			return { ...item, is_primary };
		});

		setCollectionsAgentsAssignments(updated_collections_agents_assignments);
	};

	const removeCollectionsAgentAssignment = collections_agent => {
		const updated_collections_agents_assignments = collectionsAgentsAssignments.filter(item => {
			return item.agent_id !== collections_agent.agent_id;
		});

		if (collections_agent.is_primary && updated_collections_agents_assignments?.length) {
			updated_collections_agents_assignments[0] = {
				...updated_collections_agents_assignments[0],
				is_primary: true,
			};
		}

		setCollectionsAgentsAssignments(updated_collections_agents_assignments);
	};

	// * Delivery agents
	const [deliveryAgentsList, setDeliveryAgentsList] = useState([]);
	const [deliveryAgentSearch, setDeliveryAgentSearch] = useState(null);
	const [loadingDeliveryAgentsList, setLoadingDeliveryAgentsList] = useState(null);
	const [deliveryAgentSelected, setDeliveryAgentSelected] = useState(null);
	const [deliveryAgentsAssignments, setDeliveryAgentsAssignments] = useState([]);

	const deliveryAgentsTable = createSelector(
		state => state.DeliveryAgents,
		state => ({
			delivery_agents: state.delivery_agents,
		})
	);

	const { delivery_agents } = useSelector(deliveryAgentsTable);

	useEffect(() => {
		const used_delivery_agents_ids = deliveryAgentsAssignments.map(item => item.agent_id);

		const filtered_delivery_agents = delivery_agents.filter(
			item => used_delivery_agents_ids.includes(item.delivery_agent_id) === false
		);

		setDeliveryAgentsList(filtered_delivery_agents);

		setLoadingDeliveryAgentsList(false);
	}, [delivery_agents]);

	useDebounce(
		() => {
			if (deliveryAgentSearch && deliveryAgentSearch.length > 2) {
				searchDeliveryAgents();
			}
		},
		[deliveryAgentSearch],
		800
	);

	const searchDeliveryAgents = () => {
		setLoadingDeliveryAgentsList(true);

		dispatch(
			onGetPaginatedDeliveryAgents({
				page: 1,
				name: deliveryAgentSearch,
			})
		);
	};

	const addDeliveryAgentAssignment = delivery_agent => {
		if (!delivery_agent) {
			return;
		}

		const { delivery_agent_id, name } = delivery_agent;

		const is_primary = !deliveryAgentsAssignments?.length;

		const delivery_agent_data = {
			agent_id: delivery_agent_id,
			agent_name: name,
			is_primary,
		};

		setDeliveryAgentsAssignments(delivery_agents_assignments => [
			...delivery_agents_assignments,
			delivery_agent_data,
		]);

		// clean search
		setDeliveryAgentsList([]);
		setDeliveryAgentSelected(null);
	};

	const changePrimaryDeliveryAgent = agent_id => {
		const updated_delivery_agents_assignments = deliveryAgentsAssignments.map(item => {
			let is_primary = false;

			if (item.agent_id === agent_id) {
				is_primary = true;
			}

			return { ...item, is_primary };
		});

		setDeliveryAgentsAssignments(updated_delivery_agents_assignments);
	};

	const removeDeliveryAgentAssignment = delivery_agent => {
		const updated_delivery_agents_assignments = deliveryAgentsAssignments.filter(item => {
			return item.agent_id !== delivery_agent.agent_id;
		});

		if (delivery_agent.is_primary && updated_delivery_agents_assignments?.length) {
			updated_delivery_agents_assignments[0] = {
				...updated_delivery_agents_assignments[0],
				is_primary: true,
			};
		}

		setDeliveryAgentsAssignments(updated_delivery_agents_assignments);
	};

	// * Assignments
	const [isAssignmentsModalOpen, setIsAssignmentsModalOpen] = useState(false);
	const [loadingAssignmentUpdate, setLoadingAssignmentUpdate] = useState(false);

	const assignmentsTable = createSelector(
		state => state.Assignments,
		state => ({
			assignments: state.assignments,

			isAssignmentsReplace: state.isAssignmentsReplace,
			isAssignmentsReplaceFail: state.isAssignmentsReplaceFail,
		})
	);

	const { isAssignmentsReplace, isAssignmentsReplaceFail } = useSelector(assignmentsTable);

	useEffect(() => {
		const client_agent_assignments = [];
		const sales_agents_assignments = [];
		const collections_agents_assignments = [];
		const delivery_agents_assignments = [];

		selectedUser?.assignments?.forEach(item => {
			if (item.agent_type === 'client_agent') {
				client_agent_assignments.push(item);
			}

			if (item.agent_type === 'sales_agent') {
				sales_agents_assignments.push(item);
			}

			if (item.agent_type === 'collections_agent') {
				collections_agents_assignments.push(item);
			}

			if (item.agent_type === 'delivery_agent') {
				delivery_agents_assignments.push(item);
			}
		});

		setClientAgentAssignments(client_agent_assignments);
		setSalesAgentsAssignments(sales_agents_assignments);
		setCollectionsAgentsAssignments(collections_agents_assignments);
		setDeliveryAgentsAssignments(delivery_agents_assignments);
	}, [selectedUser]);

	useEffect(() => {
		if (isAssignmentsReplace) {
			closeAssignmentsModal();
		}
	}, [isAssignmentsReplace]);

	useEffect(() => {
		if (isAssignmentsReplaceFail) {
			setLoadingAssignmentUpdate(false);
		}
	}, [isAssignmentsReplaceFail]);

	const editAssignments = user => {
		setSelectedUser(user);

		setAxiosHeader('user-id', user.user_id);

		setIsAssignmentsModalOpen(true);
	};

	const closeAssignmentsModal = () => {
		setIsAssignmentsModalOpen(false);

		setTimeout(() => {
			setSelectedUser(null);
			setLoadingAssignmentUpdate(false);
		}, 150);
	};

	const submitAssignments = async () => {
		const client_agents_assignments = clientAgentAssignments.map(item => ({
			agent_type: 'client_agent',
			agent_id: item.agent_id,
			agent_name: item.agent_name,
			is_primary: true,
		}));

		const sales_agents_assignments = salesAgentsAssignments.map(item => ({
			agent_type: 'sales_agent',
			agent_id: item.agent_id,
			agent_name: item.agent_name,
			is_primary: item.is_primary,
		}));

		const collections_agents_assignments = collectionsAgentsAssignments.map(item => ({
			agent_type: 'collections_agent',
			agent_id: item.agent_id,
			agent_name: item.agent_name,
			is_primary: item.is_primary,
		}));

		const delivery_agents_assignments = deliveryAgentsAssignments.map(item => ({
			agent_type: 'delivery_agent',
			agent_id: item.agent_id,
			agent_name: item.agent_name,
			is_primary: item.is_primary,
		}));

		// Remove agent_name here because it's not requested by ReplaceAssignments()
		const replace_data = {
			assignments: [
				...client_agents_assignments.map(({ agent_name, ...rest }) => rest),
				...sales_agents_assignments.map(({ agent_name, ...rest }) => rest),
				...collections_agents_assignments.map(({ agent_name, ...rest }) => rest),
				...delivery_agents_assignments.map(({ agent_name, ...rest }) => rest),
			],
		};

		await dispatch(onReplaceAssignments(replace_data));

		const user_updates_data = {
			assignments: [
				...client_agents_assignments,
				...sales_agents_assignments,
				...collections_agents_assignments,
				...delivery_agents_assignments,
			],
		};

		const update_user_data = {
			user_id: selectedUser.user_id,
			data: user_updates_data,
		};

		dispatch(updateUserData(update_user_data));
	};

	const getRoleName = role_name => {
		switch (role_name) {
			case 'admin':
				return 'Admin';

			case 'company_owner':
				return 'Dueño';

			case 'company_updater':
				return 'Updater';

			case 'company_supervisor':
				return 'Supervisor';

			case 'company_agent':
				return 'Vendedor'; // * Note: This is provisional, when collection or distribution is available, it must have the value of "Agente"

			case 'company_client':
				return 'Cliente';

			case 'company_viewer':
				return 'Visor';

			case 'company_api':
				return 'API';

			default:
				return '-';
		}
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Nombre',
				accessor: 'name',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.first_name;
				},
			},
			{
				Header: 'Apellido',
				accessor: 'last_name',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.last_name;
				},
			},
			{
				Header: 'Usuario',
				accessor: 'username',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.username;
				},
			},
			{
				Header: 'Rol',
				accessor: 'rol',
				filterable: true,
				Cell: ({ row }) => {
					return getRoleName(row.original.role_name);
				},
			},
			{
				Header: 'Asociaciones',
				accessor: 'associations',
				style: {
					width: 200,
				},
				Cell: ({ row }) => {
					const { assignments } = row.original;

					return (
						<div className="hstack gap-2">
							{!assignments?.length ? (
								<span
									className="badge text-uppercase bg-warning-subtle text-warning cursor-pointer fs-12"
									onClick={() => editAssignments(row.original)}
									title="El usuario debe poseer como mínimo 1 asociaciones">
									Sin asociación
								</span>
							) : (
								<span className="cursor-pointer" onClick={() => editAssignments(row.original)}>
									{row.original.assignments.length === 1 ? (
										<span>
											<span className="text-muted">[{row.original.assignments[0].agent_id}]</span>{' '}
											{row.original.assignments[0].agent_name}
										</span>
									) : (
										<span>
											<span className="text-muted">[-]</span> Multiples
										</span>
									)}
								</span>
							)}
						</div>
					);
				},
			},
			{
				Header: 'Creado el',
				accessor: 'created_at',
				filterable: true,
				Cell: ({ row }) => {
					const date = moment(row.original.created_at).format('DD/MM/YYYY');
					const time = moment(row.original.created_at).format('HH:mm');

					return (
						<span>
							{date}
							<small className="text-muted ms-2">{time}</small>
						</span>
					);
				},
			},
			{
				Header: 'Acciones',
				accessor: 'actions',
				style: {
					width: 200,
				},
				Cell: ({ row }) => {
					const { role_name, assignments } = row.original;

					return (
						<div className="hstack gap-2 text-end justify-content-end">
							<div className="hstack gap-2 text-end justify-content-end" style={{ width: 180 }}>
								{row.original.role_name === 'company_agent' ||
								row.original.role_name === 'company_client' ? (
									<button
										className={
											assignments?.length
												? 'btn btn-sm btn-soft-success edit-list'
												: 'btn btn-sm btn-soft-warning edit-list'
										}
										onClick={() => editAssignments(row.original)}
										title="Asociaciones">
										<i className="ri-user-received-2-fill align-bottom" />
									</button>
								) : null}

								<button
									className="btn btn-sm btn-soft-info edit-list"
									onClick={() => editUser(row.original)}
									title="Editar">
									<i className="ri-pencil-fill align-bottom" />
								</button>

								<Link
									to={`/user/${row.original.user_id}/permissions`}
									className={classnames(
										{ 'disabled cursor-not-allowed': role_name !== 'company_agent' },
										'btn btn-sm btn-soft-dark edit-list'
									)}
									title="Permisos">
									<i className="ri-key-2-fill align-bottom" />
								</Link>

								<Link
									to={`/user/${row.original.user_id}/configurations`}
									className={classnames(
										{ 'disabled cursor-not-allowed': role_name !== 'company_agent' },
										'btn btn-sm btn-soft-dark edit-list'
									)}
									title="Configuraciones">
									<i className="ri-settings-3-fill align-bottom" />
								</Link>

								<button
									className="btn btn-sm btn-soft-danger remove-list"
									onClick={() => openUserDeletionModal(row.original)}
									title="Eliminar">
									<i className="ri-delete-bin-5-fill align-bottom" />
								</button>
							</div>
						</div>
					);
				},
			},
		],
		[]
	);

	return (
		<div className="page-content">
			<Container fluid className="container-fluid">
				<DeleteModal
					show={isDeleteModalOpen}
					onDeleteClick={deleteUser}
					onCloseClick={() => setIsDeleteModalOpen(false)}
					title="Eliminar usuario"
					subTitle="¿Esta seguro que desea eliminar el usuario?"
				/>

				<Row>
					<Col xs={12}>
						<div className="page-title-box d-flex align-items-center justify-content-between">
							<h4 className="mb-0">Usuarios</h4>

							<div className="page-title-right">
								<div className="hstack text-nowrap gap-2">
									<Button
										onClick={createNewUser}
										className="btn btn-success btn-label left"
										title="Nuevo usuario">
										<i className="ri-add-fill align-bottom label-icon"></i> Nuevo usuario
									</Button>
								</div>
							</div>
						</div>
					</Col>
				</Row>

				<Modal id="showModal" isOpen={isUserModalOpen} toggle={closeUserModal} centered size="lg">
					<ModalHeader className="bg-info-subtle p-3" toggle={closeUserModal}>
						{isUserEdition ? 'Editar Usuario' : 'Nuevo usuario'}
					</ModalHeader>

					<Form
						className="tablelist-form"
						onSubmit={e => {
							e.preventDefault();
							validation.handleSubmit();
							return false;
						}}>
						<ModalBody>
							<input type="hidden" id="id-field" />

							<Row className="g-3">
								<Col lg={6}>
									<div>
										<Label htmlFor="first-name-field" className="form-label">
											Nombre
										</Label>

										<Input
											name="first_name"
											id="first-name-field"
											className="form-control"
											placeholder="Nombre"
											type="text"
											maxLength={30}
											validate={{
												required: { value: true },
											}}
											onChange={validation.handleChange}
											onBlur={validation.handleBlur}
											value={validation.values.first_name || ''}
											invalid={validation.touched.first_name && validation.errors.first_name}
										/>

										{validation.touched.first_name && validation.errors.first_name ? (
											<FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
										) : null}
									</div>
								</Col>

								<Col lg={6}>
									<div>
										<Label htmlFor="last-name-field" className="form-label">
											Apellido
										</Label>

										<Input
											name="last_name"
											id="last-name-field"
											className="form-control"
											placeholder="Apellido"
											type="text"
											maxLength={30}
											validate={{
												required: { value: true },
											}}
											onChange={validation.handleChange}
											onBlur={validation.handleBlur}
											value={validation.values.last_name || ''}
											invalid={validation.touched.last_name && validation.errors.last_name}
										/>

										{validation.touched.last_name && validation.errors.last_name ? (
											<FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
										) : null}
									</div>
								</Col>

								<Col lg={6}>
									<div>
										<Label htmlFor="username-field" className="form-label">
											Usuario
										</Label>

										<div className="input-group">
											<div className="input-group-text">
												<span className="text-muted">{short_name ? `${short_name}_` : ''}</span>
											</div>

											<Input
												name="username"
												id="username-field"
												className="form-control"
												placeholder="Usuario"
												type="text"
												maxLength={50}
												validate={{
													required: { value: true },
												}}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.username || ''}
												invalid={validation.touched.username && validation.errors.username}
											/>
										</div>

										{validation.touched.username && validation.errors.username ? (
											<FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
										) : null}
									</div>
								</Col>

								<Col lg={6}>
									<div>
										<Label htmlFor="password-field" className="form-label">
											<div className="d-flex">
												Contraseña
												{isUserEdition ? (
													<i
														className="ri-alert-line label-icon text-warning fs-14"
														style={{ marginTop: -1, marginLeft: 6 }}></i>
												) : null}
											</div>
										</Label>

										<Input
											name="password"
											id="password-field"
											className="form-control"
											placeholder={isUserEdition ? '************' : 'Contraseña'}
											type="text"
											maxLength={100}
											validate={{
												required: { value: !isUserEdition },
											}}
											onChange={validation.handleChange}
											onBlur={validation.handleBlur}
											value={validation.values.password || ''}
											invalid={validation.touched.password && validation.errors.password}
										/>

										{validation.touched.password && validation.errors.password ? (
											<FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
										) : null}
									</div>
								</Col>

								<Col lg={12}>
									<div>
										<Label htmlFor="role-field" className="form-label">
											<div className="d-flex">
												Rol
												{!isUserEdition && maximumAgentUsersIsReached() ? (
													<i
														className="ri-alert-line label-icon text-warning fs-14"
														style={{ marginTop: -1, marginLeft: 6 }}></i>
												) : null}
											</div>
										</Label>

										<select
											className="form-select"
											name="role_id"
											id="role-field"
											onChange={validation.handleChange}
											value={validation.values.role_id}
											disabled={isUserEdition}>
											<option value="5">Vendedor</option>
											<option value="6">Cliente</option>
											{/* <option value="4">Supervisor</option>
											<option value="7">Viewer</option> */}
										</select>
									</div>
								</Col>

								<Col lg={6} className="d-none">
									<div>
										<Label htmlFor="city-field" className="form-label">
											Ciudad
										</Label>

										<Input
											name="city"
											id="city-field"
											className="form-control"
											placeholder="Ciudad"
											type="text"
											maxLength={50}
											validate={{
												required: { value: true },
											}}
											onChange={validation.handleChange}
											onBlur={validation.handleBlur}
											value={validation.values.city || ''}
											invalid={validation.touched.city && validation.errors.city}
										/>

										{validation.touched.city && validation.errors.city ? (
											<FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
										) : null}
									</div>
								</Col>

								<Col lg={3} className="d-none">
									<div>
										<Label htmlFor="postal-code-field" className="form-label">
											Código postal
										</Label>

										<Input
											name="postal_code"
											id="postal-code-field"
											className="form-control"
											placeholder="Código postal"
											type="text"
											maxLength={20}
											validate={{
												required: { value: true },
											}}
											onChange={validation.handleChange}
											onBlur={validation.handleBlur}
											value={validation.values.postal_code || ''}
											invalid={validation.touched.postal_code && validation.errors.postal_code}
										/>

										{validation.touched.postal_code && validation.errors.postal_code ? (
											<FormFeedback type="invalid">{validation.errors.postal_code}</FormFeedback>
										) : null}
									</div>
								</Col>

								<Col lg={3} className="d-none">
									<div>
										<Label htmlFor="state-field" className="form-label">
											Provincia
										</Label>

										<select
											name="state"
											id="state-field"
											placeholder="Provincia"
											type="text"
											className="form-select"
											onChange={validation.handleChange}
											value={validation.values.state}
											invalid={validation.touched.state && validation.errors.state}>
											<option value="" disabled>
												Seleccionar
											</option>
											<option value="buenos aires">Buenos Aires</option>
											<option value="catamarca">Catamarca</option>
											<option value="chaco">Chaco</option>
											<option value="chubut">Chubut</option>
											<option value="cordoba">Córdoba</option>
											<option value="corrientes">Corrientes</option>
											<option value="entre rios">Entre Ríos</option>
											<option value="formosa">Formosa</option>
											<option value="jujuy">Jujuy</option>
											<option value="la pampa">La Pampa</option>
											<option value="la rioja">La Rioja</option>
											<option value="mendoza">Mendoza</option>
											<option value="misiones">Misiones</option>
											<option value="neuquen">Neuquén</option>
											<option value="rio negro">Río Negro</option>
											<option value="salta">Salta</option>
											<option value="san juan">San Juan</option>
											<option value="san luis">San Luis</option>
											<option value="santa cruz">Santa Cruz</option>
											<option value="santa fe">Santa Fe</option>
											<option value="santiago del estero">Santiago del Estero</option>
											<option value="tierra del fuego">Tierra del Fuego</option>
											<option value="tucuman">Tucumán</option>
										</select>

										{validation.touched.state && validation.errors.state ? (
											<FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
										) : null}
									</div>
								</Col>

								<Col lg={3} className="d-none">
									<div>
										<Label htmlFor="country-field" className="form-label">
											País
										</Label>

										<Input
											name="country"
											id="country-field"
											className="form-control"
											placeholder="País"
											type="text"
											validate={{
												required: { value: true },
											}}
											onChange={validation.handleChange}
											onBlur={validation.handleBlur}
											value={validation.values.country || ''}
											invalid={validation.touched.country && validation.errors.country}
										/>
										{validation.touched.country && validation.errors.country ? (
											<FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
										) : null}
									</div>
								</Col>

								<Col lg={6}>
									<div>
										<Label htmlFor="email-field" className="form-label">
											Email
										</Label>

										<Input
											name="email"
											id="email-field"
											className="form-control"
											placeholder="Email"
											type="text"
											maxLength={100}
											validate={{
												required: { value: true },
											}}
											onChange={validation.handleChange}
											onBlur={validation.handleBlur}
											value={validation.values.email || ''}
											invalid={validation.touched.email && validation.errors.email}
										/>

										{validation.touched.email && validation.errors.email ? (
											<FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
										) : null}
									</div>
								</Col>

								<Col lg={6}>
									<div>
										<Label htmlFor="phone-field" className="form-label">
											Teléfono
										</Label>

										<div className="input-group">
											<div className="input-group-text">
												<img
													src={argentina_flag}
													alt="country flag"
													className="options-flagimg"
													height="18"
												/>

												<span className="text-muted ms-2">+54 (9)</span>
											</div>

											<Cleave
												name="phone"
												id="phone-field"
												placeholder="Teléfono"
												maxLength={40}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.phone || ''}
												className={
													validation.touched.phone && validation.errors.phone
														? 'is-invalid form-control'
														: 'form-control'
												}
												options={{
													phone: true,
													phoneRegionCode: 'AR',
												}}
											/>
										</div>

										{validation.touched.phone && validation.errors.phone ? (
											<FormFeedback type="invalid" style={{ display: 'block' }}>
												{validation.errors.phone}
											</FormFeedback>
										) : null}
									</div>
								</Col>

								<Col lg={12}>
									<div>
										<Label htmlFor="observations-field" className="form-label">
											Observaciones
										</Label>

										<textarea
											rows="3"
											name="observations"
											id="observations-field"
											className="form-control"
											placeholder="Observaciones"
											type="text"
											maxLength={500}
											validate={{
												required: { value: true },
											}}
											onChange={validation.handleChange}
											onBlur={validation.handleBlur}
											value={validation.values.observations || ''}
											invalid={validation.touched.observations && validation.errors.observations}
										/>

										{validation.touched.observations && validation.errors.observations ? (
											<FormFeedback type="invalid">{validation.errors.observations}</FormFeedback>
										) : null}
									</div>
								</Col>
							</Row>
						</ModalBody>

						<ModalFooter>
							<Row className="w-100 align-items-center">
								<Col className="col-12 col-md-9 mb-3 mb-md-0 ps-0 pe-0 pe-md-5 text-muted hstack">
									{isUserEdition ? (
										<>
											<div>
												<i className="ri-alert-line label-icon text-warning fs-18 me-2"></i>
											</div>

											<div>
												La contraseña se encuentra encriptada, no es posible visualizarla.
											</div>
										</>
									) : null}

									{!isUserEdition && maximumAgentUsersIsReached() ? (
										<>
											<div>
												<i className="ri-alert-line label-icon text-warning fs-18 me-2"></i>
											</div>

											<div>Se ha alcanzado el limite de vendedores.</div>
										</>
									) : null}
								</Col>

								<Col className="col-12 col-md-3 hstack gap-2 justify-content-end px-0">
									<Button
										color="light"
										onClick={() => {
											setIsUserModalOpen(false);
										}}
										disabled={loadingUserCreateOrUpdate}>
										Cerrar
									</Button>

									<Button
										type="submit"
										color="success"
										id="add-btn"
										disabled={loadingUserCreateOrUpdate}>
										{loadingUserCreateOrUpdate ? (
											<Spinner size="sm" className="mx-2" />
										) : isUserEdition ? (
											'Editar'
										) : (
											'Crear'
										)}
									</Button>
								</Col>
							</Row>
						</ModalFooter>
					</Form>
				</Modal>

				<Modal id="showModal" isOpen={isAssignmentsModalOpen} toggle={closeAssignmentsModal} centered size="lg">
					<ModalHeader className="bg-info-subtle p-3" toggle={closeAssignmentsModal}>
						Asociaciones
					</ModalHeader>

					<Form
						className="tablelist-form"
						onSubmit={e => {
							e.preventDefault();
							submitAssignments();
							return false;
						}}>
						<ModalBody>
							<div className="hstack text-muted mb-4">
								<div>
									<i className="ri-information-line label-icon text-info fs-18 me-2"></i>
								</div>

								<div>
									Cada usuario debe poseer como mínimo 1 asociación según su rol. <br />{' '}
									<b>Ejemplo:</b> para un nuevo usuario con rol &quot;cliente&quot; sera necesario
									asociar un cliente existente de su ERP.
								</div>
							</div>

							{selectedUser?.role_name === 'company_client' ? (
								<SimpleBar style={{ maxHeight: '215px' }}>
									<ListGroup className="mb-1">
										<ListGroupItem className="bg-light">
											<Row className="d-flex align-items-center">
												<Col className="me-3">
													<div className="flex-shrink-0 ms-2">
														<h6 className="fs-14 mb-0">Cliente</h6>
													</div>
												</Col>

												<Col
													lg={4}
													className="mt-2 mt-lg-0"
													style={{
														visibility:
															clientAgentAssignments?.length === 0 ? 'visible' : 'hidden',
													}}>
													<Select
														value={clientSelected}
														onChange={value => {
															addClientAgentAssignment(value);
														}}
														onInputChange={value => {
															setClientAgentSearch(value);
														}}
														options={clientsList}
														cacheOptions={false}
														getOptionLabel={item => item.legal_name}
														getOptionValue={item => item.client_id}
														isLoading={loadingClientsList}
														isClearable={true}
														menuPortalTarget={document.body}
														placeholder="Buscar cliente"
														noOptionsMessage={() =>
															clientAgentSearch ? 'Sin resultados' : 'Buscar cliente'
														}
														styles={{
															menuPortal: base => ({ ...base, zIndex: 9999 }),
														}}
													/>
												</Col>
											</Row>
										</ListGroupItem>

										{clientAgentAssignments.map(item => (
											<ListGroupItem key={item.agent_id}>
												<div className="d-flex align-items-center">
													<div className="flex-grow-1">
														<div className="d-flex">
															<div className="flex-shrink-0 ms-2">
																<h6 className="fs-14 mb-0">
																	[{item.agent_id}] {item.agent_name}
																</h6>
															</div>
														</div>
													</div>

													<div className="flex-shrink-0">
														<Button
															className="btn-icon btn-soft-danger"
															onClick={() => removeClientAgentAssignment(item)}>
															<i className="ri-delete-bin-5-line" />
														</Button>
													</div>
												</div>
											</ListGroupItem>
										))}

										{!clientAgentAssignments?.length ? (
											<ListGroupItem>
												<div className="d-flex justify-content-center py-3">
													<h6 className="fs-14 mb-0">Sin asociaciones</h6>
												</div>
											</ListGroupItem>
										) : null}
									</ListGroup>
								</SimpleBar>
							) : (
								<>
									<SimpleBar className="mt-2" style={{ maxHeight: '215px' }}>
										<ListGroup className="mb-1">
											<ListGroupItem className="bg-light">
												<Row className="d-flex align-items-center">
													<Col className="me-3">
														<div className="flex-shrink-0 ms-2">
															<h6 className="fs-14 mb-0">Vendedor</h6>
														</div>
													</Col>

													<Col lg={4} className="mt-2 mt-lg-0">
														<Select
															value={salesAgentSelected}
															onChange={value => {
																addSalesAgentAssignment(value);
															}}
															onInputChange={value => {
																setSalesAgentSearch(value);
															}}
															options={salesAgentsList}
															cacheOptions={false}
															getOptionLabel={item => item.name}
															getOptionValue={item => item.sales_agent_id}
															isLoading={loadingSalesAgentsList}
															isClearable={true}
															menuPortalTarget={document.body}
															placeholder="Buscar vendedor"
															noOptionsMessage={() =>
																salesAgentSearch ? 'Sin resultados' : 'Buscar vendedor'
															}
															styles={{
																menuPortal: base => ({
																	...base,
																	zIndex: 9999,
																}),
															}}
														/>
													</Col>
												</Row>
											</ListGroupItem>

											{salesAgentsAssignments.map(item => (
												<ListGroupItem key={item.agent_id}>
													<div className="d-flex align-items-center">
														<div className="flex-grow-1">
															<div className="d-flex">
																<div className="flex-shrink-0 ms-2">
																	<h6 className="fs-14 mb-0">
																		[{item.agent_id}] {item.agent_name}
																	</h6>
																</div>
															</div>
														</div>

														{salesAgentsAssignments.length > 1 ? (
															<div className="form-check form-switch form-switch-md form-switch-success d-flex align-content-center me-5 pe-5">
																<input
																	className="form-check-input"
																	type="radio"
																	role="switch"
																	name="switch-primary-sales-agent"
																	defaultChecked={item.is_primary}
																	onChange={() => {
																		changePrimarySalesAgent(item.agent_id);
																	}}
																/>

																<label
																	className="form-check-label"
																	for="switch-primary-sales-agent"
																	style={{
																		visibility: item.is_primary
																			? 'visible'
																			: 'hidden',
																	}}>
																	Primario
																</label>
															</div>
														) : null}

														<div className="flex-shrink-0">
															<Button
																className="btn-icon btn-soft-danger"
																onClick={() => removeSalesAgentAssignment(item)}>
																<i className="ri-delete-bin-5-line" />
															</Button>
														</div>
													</div>
												</ListGroupItem>
											))}

											{!salesAgentsAssignments?.length ? (
												<ListGroupItem>
													<div className="d-flex justify-content-center py-3">
														<h6 className="fs-14 mb-0">Sin asociaciones</h6>
													</div>
												</ListGroupItem>
											) : null}
										</ListGroup>
									</SimpleBar>

									<SimpleBar className="mt-4 d-none" style={{ maxHeight: '215px' }}>
										<ListGroup className="mb-1">
											<ListGroupItem className="bg-light">
												<Row className="d-flex align-items-center">
													<Col className="me-3">
														<div className="flex-shrink-0 ms-2">
															<h6 className="fs-14 mb-0">Cobrador</h6>
														</div>
													</Col>

													<Col lg={4} className="mt-2 mt-lg-0">
														<Select
															value={collectionsAgentSelected}
															onChange={value => {
																addCollectionsAgentAssignment(value);
															}}
															onInputChange={value => {
																setCollectionsAgentSearch(value);
															}}
															options={collectionsAgentsList}
															cacheOptions={false}
															getOptionLabel={item => item.name}
															getOptionValue={item => item.collections_agent_id}
															isLoading={loadingCollectionsAgentsList}
															isClearable={true}
															menuPortalTarget={document.body}
															placeholder="Buscar cobrador"
															noOptionsMessage={() =>
																collectionsAgentSearch
																	? 'Sin resultados'
																	: 'Buscar cobrador'
															}
															styles={{
																menuPortal: base => ({
																	...base,
																	zIndex: 9999,
																}),
															}}
														/>
													</Col>
												</Row>
											</ListGroupItem>

											{collectionsAgentsAssignments.map(item => (
												<ListGroupItem key={item.agent_id}>
													<div className="d-flex align-items-center">
														<div className="flex-grow-1">
															<div className="d-flex">
																<div className="flex-shrink-0 ms-2">
																	<h6 className="fs-14 mb-0">
																		[{item.agent_id}] {item.agent_name}
																	</h6>
																</div>
															</div>
														</div>

														{collectionsAgentsAssignments.length > 1 ? (
															<div className="form-check form-switch form-switch-md form-switch-success d-flex align-content-center me-5 pe-5">
																<input
																	className="form-check-input"
																	type="radio"
																	role="switch"
																	name="switch-primary-collections-agent"
																	defaultChecked={item.is_primary}
																	onChange={() => {
																		changePrimaryCollectionsAgent(item.agent_id);
																	}}
																/>

																<label
																	className="form-check-label"
																	for="switch-primary-collections-agent"
																	style={{
																		visibility: item.is_primary
																			? 'visible'
																			: 'hidden',
																	}}>
																	Primario
																</label>
															</div>
														) : null}

														<div className="flex-shrink-0">
															<Button
																className="btn-icon btn-soft-danger"
																onClick={() => removeCollectionsAgentAssignment(item)}>
																<i className="ri-delete-bin-5-line" />{' '}
															</Button>
														</div>
													</div>
												</ListGroupItem>
											))}

											{!collectionsAgentsAssignments?.length ? (
												<ListGroupItem>
													<div className="d-flex justify-content-center py-3">
														<h6 className="fs-14 mb-0">Sin asociaciones</h6>
													</div>
												</ListGroupItem>
											) : null}
										</ListGroup>
									</SimpleBar>

									<SimpleBar className="mt-4 d-none" style={{ maxHeight: '215px' }}>
										<ListGroup className="mb-1">
											<ListGroupItem className="bg-light">
												<Row className="d-flex align-items-center">
													<Col className="me-3">
														<div className="flex-shrink-0 ms-2">
															<h6 className="fs-14 mb-0">Repartidor</h6>
														</div>
													</Col>

													<Col lg={4} className="mt-2 mt-lg-0">
														<Select
															value={deliveryAgentSelected}
															onChange={value => {
																addDeliveryAgentAssignment(value);
															}}
															onInputChange={value => {
																setDeliveryAgentSearch(value);
															}}
															options={deliveryAgentsList}
															cacheOptions={false}
															getOptionLabel={item => item.name}
															getOptionValue={item => item.delivery_agent_id}
															isLoading={loadingDeliveryAgentsList}
															isClearable={true}
															menuPortalTarget={document.body}
															placeholder="Buscar repartidor"
															noOptionsMessage={() =>
																deliveryAgentSearch
																	? 'Sin resultados'
																	: 'Buscar repartidor'
															}
															styles={{
																menuPortal: base => ({
																	...base,
																	zIndex: 9999,
																}),
															}}
														/>
													</Col>
												</Row>
											</ListGroupItem>

											{deliveryAgentsAssignments.map(item => (
												<ListGroupItem key={item.agent_id}>
													<div className="d-flex align-items-center">
														<div className="flex-grow-1">
															<div className="d-flex">
																<div className="flex-shrink-0 ms-2">
																	<h6 className="fs-14 mb-0">
																		[{item.agent_id}] {item.agent_name}
																	</h6>
																</div>
															</div>
														</div>

														{deliveryAgentsAssignments.length > 1 ? (
															<div className="form-check form-switch form-switch-md form-switch-success d-flex align-content-center me-5 pe-5">
																<input
																	className="form-check-input"
																	type="radio"
																	role="switch"
																	name="switch-primary-delivery-agent"
																	checked={item.is_primary}
																	onChange={() => {
																		changePrimaryDeliveryAgent(item.agent_id);
																	}}
																/>

																<label
																	className="form-check-label"
																	for="switch-primary-delivery-agent"
																	style={{
																		visibility: item.is_primary
																			? 'visible'
																			: 'hidden',
																	}}>
																	Primario
																</label>
															</div>
														) : null}

														<div className="flex-shrink-0">
															<Button
																className="btn-icon btn-soft-danger"
																onClick={() => removeDeliveryAgentAssignment(item)}>
																<i className="ri-delete-bin-5-line" />
															</Button>
														</div>
													</div>
												</ListGroupItem>
											))}

											{!deliveryAgentsAssignments?.length ? (
												<ListGroupItem>
													<div className="d-flex justify-content-center py-3">
														<h6 className="fs-14 mb-0">Sin asociaciones</h6>
													</div>
												</ListGroupItem>
											) : null}
										</ListGroup>
									</SimpleBar>
								</>
							)}
						</ModalBody>

						<ModalFooter>
							<div className="hstack gap-2 justify-content-end">
								<Button
									color="light"
									onClick={() => {
										setIsAssignmentsModalOpen(false);
									}}
									disabled={loadingAssignmentUpdate}>
									Cerrar
								</Button>

								<Button type="submit" color="success" id="add-btn" disabled={loadingAssignmentUpdate}>
									{loadingAssignmentUpdate ? <Spinner size="sm" className="mx-2" /> : 'Guardar'}
								</Button>
							</div>
						</ModalFooter>
					</Form>
				</Modal>

				<Card>
					<CardBody className="pt-0">
						<TableContainer
							columns={columns}
							data={usersList}
							sortOptions={[{ id: 'created_at', desc: true }]}
							customPageSize={25}
							isGlobalFilter={false}
							isAddUserList={false}
							noDataMessage={'No hay usuarios existentes'}
							divClass="table-responsive table-card mb-1"
							tableClass="align-middle table-nowrap"
							theadClass="table-light text-muted"
						/>
					</CardBody>
				</Card>
			</Container>

			<ToastContainer closeButton={false} />
		</div>
	);
};

export { Users };
