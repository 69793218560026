import React from 'react';

import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

const Error500 = () => {
	document.title = 'Error 500';

	return (
		<React.Fragment>
			<div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100">
				<div className="auth-page-content overflow-hidden p-0">
					<Container fluid={true}>
						<Row className="justify-content-center">
							<Col xl={4} className="text-center">
								<div className="error-500 position-relative">
									<h1 className="title text-muted">500</h1>
								</div>

								<div>
									<h4>Error interno del servidor</h4>

									<p className="text-muted w-75 mx-auto">
										Ha ocurrido un error inesperado en el servidor, por favor vuela a intentarlo.
									</p>

									<Link to="/" className="btn btn-success">
										<i className="mdi mdi-home me-1"></i>Volver al inicio
									</Link>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</React.Fragment>
	);
};

export { Error500 };
