import React, { useEffect, useState, useMemo } from 'react';
import { Card, CardBody, Col, Container, Row, CardHeader, Spinner, CardFooter } from 'reactstrap';

import { useNavigate, useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import TableContainer from '../../components/common/TableContainer';
import BreadCrumb from '../../components/common/BreadCrumb';
import OperationStatusBadge from '../../components/atoms/OperationStatusBadge';

import {
	getAllPaymentCollectionDebts as onGetAllPaymentCollectionDebts,
	getAllPaymentCollectionMethods as onGetAllPaymentCollectionMethods,
} from '../../slices/thunks';

const PaymentCollectionDetails = () => {
	document.title = 'Detalle de cobranza';

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { payment_collection_id } = useParams();

	const selectPaymentCollections = createSelector(
		state => state.PaymentCollections,
		state => ({
			payment_collections: state.payment_collections,
		})
	);

	const selectPaymentCollectionDebts = createSelector(
		state => state.PaymentCollectionDebts,
		state => ({
			payment_collection_debts: state.payment_collection_debts,
			loading_debts: state.loading_debts,
			error: state.error,
		})
	);

	const selectPaymentCollectionMethods = createSelector(
		state => state.PaymentCollectionMethods,
		state => ({
			payment_collection_methods: state.payment_collection_methods,
			loading_methods: state.loading_methods,
			error: state.error,
		})
	);

	const { payment_collections } = useSelector(selectPaymentCollections);
	const { payment_collection_debts, loading_debts } = useSelector(selectPaymentCollectionDebts);
	const { payment_collection_methods, loading_methods } = useSelector(selectPaymentCollectionMethods);

	useEffect(() => {
		dispatch(onGetAllPaymentCollectionDebts(payment_collection_id));
		dispatch(onGetAllPaymentCollectionMethods(payment_collection_id));
	}, []);

	// * PaymentCollection
	const [paymentCollectionData, setPaymentCollectionData] = useState({});

	useEffect(() => {
		const payment_collection_data = payment_collections.find(
			// eslint-disable-next-line eqeqeq
			item => item.payment_collection_id == payment_collection_id
		);

		if (!payment_collection_data) {
			navigate('/');
		}

		setPaymentCollectionData(payment_collection_data);
	}, [payment_collections]);

	const debtsColumns = useMemo(
		() => [
			{
				Header: 'Comprobante',
				accessor: 'invoice_code',
				filterable: false,
				Cell: ({ row }) => {
					return <span>{row.original.invoice_code || '-'}</span>;
				},
			},
			{
				Header: 'Monto',
				accessor: 'original_total_amount',
				filterable: false,
				Cell: ({ row }) => {
					return <span>$ {Number(row.original.original_total_amount || 0).toFixed(2)}</span>;
				},
			},
			{
				Header: 'Importe cancelado',
				headerAlign: 'right',
				accessor: 'paid_amount',
				filterable: false,
				Cell: ({ row }) => {
					return (
						<div className="text-end">
							<span>$ {Number(row.original.paid_amount || 0).toFixed(2)}</span>
						</div>
					);
				},
			},
		],
		[]
	);

	const methodsColumns = useMemo(
		() => [
			{
				Header: 'Tipo',
				accessor: 'payment_method_code',
				filterable: false,
				Cell: ({ row }) => {
					const getMethodName = payment_method_code => {
						switch (payment_method_code) {
							case 'cash':
								return 'Efectivo';
							case 'securities':
								return 'Valores en cartera';
							case 'wire_transfer':
								return 'Transferencia';
							case 'others':
								return 'Varios';
							default:
								return 'Efectivo';
						}
					};

					return <span>{getMethodName(row.original.payment_method_code)}</span>;
				},
			},
			{
				Header: 'Importe pagado',
				headerAlign: 'right',
				accessor: 'amount',
				filterable: false,
				Cell: ({ row }) => {
					return (
						<div className="text-end">
							<span>$ {Number(row.original.amount || 0).toFixed(2)}</span>
						</div>
					);
				},
			},
		],
		[]
	);

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb title="Detalle de cobranza" pageTitle="Cobranzas" goBackTo={'/payment-collections'} />

				<Row className="flex-column-reverse flex-xl-row">
					<Col xl={5}>
						<Card>
							<CardHeader>
								<h5 className="card-title mb-0">Comprobantes</h5>
							</CardHeader>

							<CardBody className="py-0">
								{!loading_debts ? (
									<TableContainer
										columns={debtsColumns}
										data={payment_collection_debts}
										sortOptions={[{ id: 'description', desc: false }]}
										noDataMessage={'No hay comprobantes disponibles para mostrar'}
										isGlobalFilter={false}
										isAddUserList={false}
										divClass="table-responsive table-card"
										tableClass="align-middle table-nowrap"
										theadClass="table-light text-muted"
										footerRows={
											<tr>
												<td colSpan="2" className="bg-light-subtle fw-semibold">
													TOTAL CANCELADO
												</td>

												<td className="bg-light-subtle fw-semibold text-end">
													$ {Number(paymentCollectionData.debts_total_amount || 0).toFixed(2)}
												</td>
											</tr>
										}
									/>
								) : (
									<div className="text-center py-2">
										<Spinner />
									</div>
								)}
							</CardBody>
						</Card>
					</Col>

					<Col xl={4}>
						<Card>
							<CardHeader>
								<div className="d-flex align-items center">
									<h5 className="card-title flex-grow-1 mb-0">Pagos</h5>
								</div>
							</CardHeader>

							<CardBody className="py-0">
								{!loading_methods ? (
									<TableContainer
										columns={methodsColumns}
										data={payment_collection_methods}
										sortOptions={[{ id: 'description', desc: false }]}
										noDataMessage={'No hay pagos disponibles para mostrar'}
										isGlobalFilter={false}
										isAddUserList={false}
										divClass="table-responsive table-card"
										tableClass="align-middle table-nowrap"
										theadClass="table-light text-muted"
										footerRows={
											<tr>
												<td className="bg-light-subtle fw-semibold">TOTAL PAGADO</td>

												<td className="bg-light-subtle fw-semibold text-end">
													${' '}
													{Number(paymentCollectionData.methods_total_amount || 0).toFixed(2)}
												</td>
											</tr>
										}
									/>
								) : (
									<div className="text-center py-2">
										<Spinner />
									</div>
								)}
							</CardBody>
						</Card>
					</Col>

					<Col xl={3}>
						<Card>
							<CardHeader>
								<h5 className="card-title mb-0">Detalles</h5>
							</CardHeader>

							<CardHeader>
								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Estado</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<OperationStatusBadge operationData={paymentCollectionData} />
									</div>
								</div>

								<div className="d-flex align-items-center">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">ID Interno</p>
									</div>

									<div className="flex-grow-1 ms-2">{payment_collection_id}</div>
								</div>
							</CardHeader>

							<CardBody>
								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Cliente</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											<span className="text-muted">[{paymentCollectionData.client_id}]</span>{' '}
											{paymentCollectionData?.client_legal_name || '-'}
										</h6>
									</div>
								</div>

								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Cobrador</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											<span className="text-muted">
												[{paymentCollectionData.payment_collections_agent_id}]
											</span>{' '}
											{paymentCollectionData?.payment_collections_agent_name || '-'}
										</h6>
									</div>
								</div>

								<div className="d-flex align-items-center">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Creado el</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											<span>
												{moment(paymentCollectionData?.created_at).format('DD/MM/YYYY')}

												<span className="text-muted ms-1">
													{moment(paymentCollectionData?.created_at).format('HH:mm')}
												</span>
											</span>
										</h6>
									</div>
								</div>
							</CardBody>

							<CardFooter>
								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Total cancelado</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											$ {Number(paymentCollectionData.debts_total_amount || 0).toFixed(2)}
										</h6>
									</div>
								</div>

								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Total pagado</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											$ {Number(paymentCollectionData.methods_total_amount || 0).toFixed(2)}
										</h6>
									</div>
								</div>

								<div className="d-flex align-items-center">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">A cuenta</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											${' '}
											{(
												Number(paymentCollectionData.methods_total_amount || 0) -
												Number(paymentCollectionData.debts_total_amount || 0)
											).toFixed(2)}
										</h6>
									</div>
								</div>
							</CardFooter>

							<CardFooter>
								<div className="d-flex align-items-center">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Observaciones:</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">{paymentCollectionData?.observations || '-'}</h6>
									</div>
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export { PaymentCollectionDetails };
