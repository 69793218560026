import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row, CardHeader, CardFooter, Spinner } from 'reactstrap';

import { useNavigate, useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { ClientOrderProduct } from './ClientOrderProduct';
import BreadCrumb from '../../components/common/BreadCrumb';
import { getAllOrderItems as onGetAllOrderItems } from '../../slices/thunks';

const ClientOrderDetails = () => {
	document.title = 'Detalle de pedido';

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { order_id } = useParams();

	const selectOrderItems = createSelector(
		state => state.OrderItems,
		state => ({
			order_items: state.order_items,
			loading_items: state.loading_items,
			error: state.error,
		})
	);

	const { order_items, loading_items } = useSelector(selectOrderItems);

	const [sortedOrderItems, setSortedOrderItems] = useState([]);
	const [columnOrderDirection, setColumnOrderDirection] = useState('DESC');

	const [loadingOrderItems, setLoadingOrderItems] = useState(false);

	useEffect(() => {
		setLoadingOrderItems(loading_items);
	}, [loading_items]);

	useEffect(() => {
		sortOrderItems('description');
	}, [order_items]);

	useEffect(() => {
		dispatch(onGetAllOrderItems(order_id));
	}, []);

	const sortOrderItems = column => {
		const order_direction = columnOrderDirection === 'ASC' ? 1 : -1;

		const sorted_order_items = [...order_items].sort((a, b) =>
			a[column] > b[column] ? order_direction : -order_direction
		);

		setSortedOrderItems(sorted_order_items);
		setColumnOrderDirection(columnOrderDirection === 'ASC' ? 'DESC' : 'ASC');
	};

	// * Order
	const [clientOrderData, setClientOrderData] = useState({});

	const clientOrderSelector = createSelector(
		state => state.ClientOrders,
		state => ({
			client_orders: state.client_orders,
		})
	);

	const { client_orders } = useSelector(clientOrderSelector);

	useEffect(() => {
		// eslint-disable-next-line eqeqeq
		const order_data = client_orders.find(item => item.order_id == order_id);

		if (!order_data) {
			navigate('/');
		}

		setClientOrderData(order_data);
	}, [client_orders]);

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb title="Detalle de pedido" pageTitle="Pedidos" goBackTo={'/client-orders'} />

				<Row className="flex-column-reverse flex-xl-row">
					<Col xl={9}>
						<Card>
							<CardHeader>
								<div className="d-flex align-items center mb-3">
									<h5 className="card-title flex-grow-1 mb-0">Pedido #{order_id}</h5>
								</div>
							</CardHeader>

							<CardBody>
								{!loadingOrderItems ? (
									<div className="table-responsive table-card">
										<table className="table table-nowrap align-middle table-borderless mb-0">
											<thead className="table-light text-muted">
												<tr>
													<th
														scope="col"
														className="cursor-pointer"
														onClick={() => sortOrderItems('description')}>
														Descripción
													</th>

													<th
														scope="col"
														className="cursor-pointer"
														onClick={() => sortOrderItems('quantity')}>
														Cantidad
													</th>

													<th
														scope="col"
														className="cursor-pointer"
														onClick={() => sortOrderItems('unit_price_without_iva')}>
														Precio unitario
													</th>

													<th
														scope="col"
														className="cursor-pointer text-end"
														onClick={() => sortOrderItems('final_price_with_iva')}>
														Sub total
													</th>
												</tr>
											</thead>

											<tbody>
												{sortedOrderItems.map(item => (
													<ClientOrderProduct key={item.order_item_id} item={item} />
												))}

												<tr className="border-top border-top-dashed">
													<td colSpan="3"></td>
													<td colSpan="2" className="fw-medium p-0">
														<table className="table table-borderless mb-0">
															<tbody>
																<tr className="table-light">
																	<th scope="row" className="fw-medium">
																		TOTAL
																	</th>

																	<th className="fw-medium text-end">
																		${' '}
																		{Number(
																			clientOrderData.final_price_with_iva || 0
																		).toFixed(2)}
																	</th>
																</tr>
															</tbody>
														</table>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								) : (
									<div className="text-center py-2">
										<Spinner />
									</div>
								)}
							</CardBody>
						</Card>
					</Col>

					<Col xl={3}>
						<Card>
							<CardHeader>
								<h5 className="card-title mb-0">Detalles</h5>
							</CardHeader>

							<CardBody>
								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Fecha de entrega</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											<span>
												{moment(clientOrderData?.deadline).format('DD/MM/YYYY')}

												<span className="text-muted ms-1">
													{moment(clientOrderData?.deadline).format('HH:mm')}
												</span>
											</span>
										</h6>
									</div>
								</div>

								<div className="d-flex align-items-center">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Creado el</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											<span>
												{moment(clientOrderData?.created_at).format('DD/MM/YYYY')}

												<span className="text-muted ms-1">
													{moment(clientOrderData?.created_at).format('HH:mm')}
												</span>
											</span>
										</h6>
									</div>
								</div>
							</CardBody>

							<CardFooter>
								<div className="d-flex align-items-center">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Observaciones:</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">{clientOrderData?.observations || '-'}</h6>
									</div>
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export { ClientOrderDetails };
