/* eslint-disable indent */
import React, { useMemo, useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	Col,
	CardHeader,
	Input,
	Row,
	ModalBody,
	Label,
	Modal,
	ModalHeader,
	Form,
	ModalFooter,
	Button,
	FormFeedback,
	ListGroup,
	ListGroupItem,
	Spinner,
} from 'reactstrap';

import SimpleBar from 'simplebar-react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Select from 'react-select';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { setAxiosHeader } from '../../helpers/api_helper';
import useDebounce from '../../components/hooks/UseDebounce';
import TableContainer from '../../components/common/TableContainer';
import DeleteModal from '../../components/common/DeleteModal';

import {
	getAllUsers as onGetAllUsers,
	addNewUser as onAddNewUser,
	updateUser as onUpdateUser,
	deleteUser as onDeleteUser,
	getPaginatedClients as onGetPaginatedClients,
	getPaginatedSalesAgents as onGetPaginatedSalesAgents,
	getPaginatedCollectionsAgents as onGetPaginatedCollectionsAgents,
	getPaginatedDeliveryAgents as onGetPaginatedDeliveryAgents,
	getAssignments as onGetAssignments,
	replaceAssignments as onReplaceAssignments,
} from '../../slices/thunks';

const CompanyUsers = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { company_id } = useParams();

	const selectCompany = createSelector(
		state => state.Company,
		state => ({
			company: state.company,
		})
	);

	const { company } = useSelector(selectCompany);

	// Get all users list
	useEffect(() => {
		if (!company_id) {
			navigate('/');
		}

		setAxiosHeader('company-id', company_id);
		dispatch(onGetAllUsers());
	}, []);

	const [usersList, setUsersList] = useState([]);
	const [loadingUserCreateOrUpdate, setLoadingUserCreateOrUpdate] = useState(false);

	const selectUsers = createSelector(
		state => state.Users,
		state => ({
			users: state.users,
			error: state.error,

			isUserAdded: state.isUserAdded,
			isUserAddedFail: state.isUserAddedFail,
			isUserUpdate: state.isUserUpdate,
			isUserUpdateFail: state.isUserUpdateFail,
			isUserDelete: state.isUserDelete,
			isUserDeleteFail: state.isUserDeleteFail,
		})
	);

	const { users, isUserAdded, isUserAddedFail, isUserUpdate, isUserUpdateFail } = useSelector(selectUsers);

	useEffect(() => {
		setUsersList(users);
	}, [users]);

	// On create/update error
	useEffect(() => {
		if (isUserAddedFail || isUserUpdateFail) {
			setLoadingUserCreateOrUpdate(false);
		}
	}, [isUserAddedFail, isUserUpdateFail]);

	// On create/update success
	useEffect(() => {
		if (isUserAdded || isUserUpdate) {
			closeUserModal();
		}
	}, [isUserAdded, isUserUpdate]);

	const [selectedUser, setSelectedUser] = useState(null);

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isUserModalOpen, setIsUserModalOpen] = useState(false);
	const [isUserEdition, setIsUserEdition] = useState(false);

	const createNewUser = () => {
		setIsUserEdition(false);
		setIsUserModalOpen(true);
	};

	const editUser = user => {
		setSelectedUser(user);
		setIsUserEdition(true);
		setIsUserModalOpen(true);
	};

	const closeUserModal = () => {
		validation.resetForm();
		setIsUserModalOpen(false);
		setSelectedUser(null);
		setLoadingUserCreateOrUpdate(false);
	};

	// * Validate
	const validation = useFormik({
		// enableReinitialize, use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			first_name: (selectedUser && selectedUser.first_name) || '',
			last_name: (selectedUser && selectedUser.last_name) || '',
			username: (selectedUser && selectedUser.username) || `${company.short_name}_`,
			password: (selectedUser && selectedUser.password) || '',
			city: (selectedUser && selectedUser.city) || '',
			postal_code: (selectedUser && selectedUser.postal_code) || '',
			state: (selectedUser && selectedUser.state) || '',
			country: (selectedUser && selectedUser.country) || '',
			role_id: (selectedUser && selectedUser.role_id) || '',
			for_internal_use: (selectedUser && selectedUser.for_internal_use) || false,
			email: (selectedUser && selectedUser.email) || '',
			phone: (selectedUser && selectedUser.phone) || '',
			observations: (selectedUser && selectedUser.observations) || '',
		},

		validationSchema: Yup.object({
			first_name: Yup.string().required('Please Enter First Name'),
			last_name: Yup.string().required('Please Enter Last Name'),
			username: Yup.string().required('Please Enter Username'),
			password: isUserEdition ? undefined : Yup.string().required('Please Enter Password'),
			city: Yup.string().required('Please Enter City'),
			postal_code: Yup.string().required('Please Enter Postal Code'),
			state: Yup.string().required('Please Enter State'),
			country: Yup.string().required('Please Enter Country'),
			role_id: Yup.string().required('Please Enter Role'),
			email: Yup.string().required('Please Enter Email'),
			phone: Yup.string().required('Please Enter Phone'),
		}),

		onSubmit: values => {
			setLoadingUserCreateOrUpdate(true);

			const cleaned_observations = values.observations?.trim().replace(/\s+/g, ' ') || null;

			if (isUserEdition) {
				const data = {
					user_id: selectedUser.user_id.toString(),
					first_name: values.first_name,
					last_name: values.last_name,
					username: values.username,
					password: values.password || null,
					city: values.city,
					postal_code: values.postal_code,
					state: values.state,
					country: values.country,
					for_internal_use: values.for_internal_use,
					email: values.email,
					phone: values.phone,
					observations: cleaned_observations,
				};

				dispatch(onUpdateUser(data));
			} else {
				const data = {
					first_name: values.first_name,
					last_name: values.last_name,
					username: values.username,
					password: values.password,
					city: values.city,
					postal_code: values.postal_code,
					state: values.state,
					country: values.country,
					role_id: values.role_id,
					for_internal_use: values.for_internal_use,
					email: values.email,
					phone: values.phone,
					observations: cleaned_observations,
				};

				dispatch(onAddNewUser(data));
			}
		},
	});

	const deleteUser = () => {
		if (!selectedUser) {
			return;
		}

		dispatch(
			onDeleteUser({
				user_id: selectedUser.user_id.toString(),
			})
		);

		setIsDeleteModalOpen(false);
	};

	const openUserDeletionModal = user => {
		setSelectedUser(user);
		setIsDeleteModalOpen(true);
	};

	// * Search clients
	const [clientsList, setClientsList] = useState([]);
	const [clientSearch, setClientSearch] = useState(null);
	const [loadingClientsList, setLoadingClientsList] = useState(null);
	const [clientSelected, setClientSelected] = useState(null);
	const [clientsAssignments, setClientsAssignments] = useState([]);

	const clientsTable = createSelector(
		state => state.Clients,
		state => ({
			clients: state.clients,
		})
	);

	const { clients } = useSelector(clientsTable);

	useEffect(() => {
		const used_clients_ids = clientsAssignments.map(item => item.client_id);

		const filtered_clients = clients.filter(item => used_clients_ids.includes(item.client_id) === false);

		setClientsList(filtered_clients);

		setLoadingClientsList(false);
	}, [clients]);

	useDebounce(
		() => {
			if (clientSearch && clientSearch.length > 2) {
				searchClients();
			}
		},
		[clientSearch],
		800
	);

	const searchClients = () => {
		setLoadingClientsList(true);

		dispatch(
			onGetPaginatedClients({
				page: 1,
				legal_name: clientSearch,
			})
		);
	};

	const addClientAssignment = () => {
		setClientsAssignments(clients_assignments => [...clients_assignments, clientSelected]);

		// clean search
		setClientsList([]);
		setClientSelected(null);
	};

	const removeClientAssignment = client_id => {
		setClientsAssignments(clients_assignments => {
			return clients_assignments.filter(item => item.client_id !== client_id);
		});
	};

	// * Sales agents
	const [salesAgentsList, setSalesAgentsList] = useState([]);
	const [salesAgentSearch, setSalesAgentSearch] = useState(null);
	const [loadingSalesAgentsList, setLoadingSalesAgentsList] = useState(null);
	const [salesAgentSelected, setSalesAgentSelected] = useState(null);
	const [salesAgentsAssignments, setSalesAgentsAssignments] = useState([]);

	const salesAgentsTable = createSelector(
		state => state.SalesAgents,
		state => ({
			sales_agents: state.sales_agents,
		})
	);

	const { sales_agents } = useSelector(salesAgentsTable);

	useEffect(() => {
		const used_sales_agents_ids = salesAgentsAssignments.map(item => item.sales_agent_id);

		const filtered_sales_agents = sales_agents.filter(
			item => used_sales_agents_ids.includes(item.sales_agent_id) === false
		);

		setSalesAgentsList(filtered_sales_agents);

		setLoadingSalesAgentsList(false);
	}, [sales_agents]);

	useDebounce(
		() => {
			if (salesAgentSearch && salesAgentSearch.length > 2) {
				searchSalesAgents();
			}
		},
		[salesAgentSearch],
		800
	);

	const searchSalesAgents = () => {
		setLoadingSalesAgentsList(true);

		dispatch(
			onGetPaginatedSalesAgents({
				page: 1,
				name: salesAgentSearch,
			})
		);
	};

	const addSalesAgentAssignment = () => {
		setSalesAgentsAssignments(sales_agents_assignments => [...sales_agents_assignments, salesAgentSelected]);

		// clean search
		setSalesAgentsList([]);
		setSalesAgentSelected(null);
	};

	const removeSalesAgentAssignment = sales_agent_id => {
		setSalesAgentsAssignments(sales_agents_assignments => {
			return sales_agents_assignments.filter(item => item.sales_agent_id !== sales_agent_id);
		});
	};

	// * Collections agents
	const [collectionsAgentsList, setCollectionsAgentsList] = useState([]);
	const [collectionsAgentSearch, setCollectionsAgentSearch] = useState(null);
	const [loadingCollectionsAgentsList, setLoadingCollectionsAgentsList] = useState(null);
	const [collectionsAgentSelected, setCollectionsAgentSelected] = useState(null);
	const [collectionsAgentsAssignments, setCollectionsAgentsAssignments] = useState([]);

	const collectionsAgentsTable = createSelector(
		state => state.CollectionsAgents,
		state => ({
			collections_agents: state.collections_agents,
		})
	);

	const { collections_agents } = useSelector(collectionsAgentsTable);

	useEffect(() => {
		const used_collections_agents_ids = collectionsAgentsAssignments.map(item => item.collections_agent_id);

		const filtered_collections_agents = collections_agents.filter(
			item => used_collections_agents_ids.includes(item.collections_agent_id) === false
		);

		setCollectionsAgentsList(filtered_collections_agents);

		setLoadingCollectionsAgentsList(false);
	}, [collections_agents]);

	useDebounce(
		() => {
			if (collectionsAgentSearch && collectionsAgentSearch.length > 2) {
				searchCollectionsAgents();
			}
		},
		[collectionsAgentSearch],
		800
	);

	const searchCollectionsAgents = () => {
		setLoadingCollectionsAgentsList(true);

		dispatch(
			onGetPaginatedCollectionsAgents({
				page: 1,
				name: collectionsAgentSearch,
			})
		);
	};

	const addCollectionsAgentAssignment = () => {
		setCollectionsAgentsAssignments(collections_agents_assignments => [
			...collections_agents_assignments,
			collectionsAgentSelected,
		]);

		// clean search
		setCollectionsAgentsList([]);
		setCollectionsAgentSelected(null);
	};

	const removeCollectionsAgentAssignment = collections_agent_id => {
		setCollectionsAgentsAssignments(collections_agents_assignments => {
			return collections_agents_assignments.filter(item => item.collections_agent_id !== collections_agent_id);
		});
	};

	// * Delivery agents
	const [deliveryAgentsList, setDeliveryAgentsList] = useState([]);
	const [deliveryAgentSearch, setDeliveryAgentSearch] = useState(null);
	const [loadingDeliveryAgentsList, setLoadingDeliveryAgentsList] = useState(null);
	const [deliveryAgentSelected, setDeliveryAgentSelected] = useState(null);
	const [deliveryAgentsAssignments, setDeliveryAgentsAssignments] = useState([]);

	const deliveryAgentsTable = createSelector(
		state => state.DeliveryAgents,
		state => ({
			delivery_agents: state.delivery_agents,
		})
	);

	const { delivery_agents } = useSelector(deliveryAgentsTable);

	useEffect(() => {
		const used_delivery_agents_ids = deliveryAgentsAssignments.map(item => item.delivery_agent_id);

		const filtered_delivery_agents = delivery_agents.filter(
			item => used_delivery_agents_ids.includes(item.delivery_agent_id) === false
		);

		setDeliveryAgentsList(filtered_delivery_agents);

		setLoadingDeliveryAgentsList(false);
	}, [delivery_agents]);

	useDebounce(
		() => {
			if (deliveryAgentSearch && deliveryAgentSearch.length > 2) {
				searchDeliveryAgents();
			}
		},
		[deliveryAgentSearch],
		800
	);

	const searchDeliveryAgents = () => {
		setLoadingDeliveryAgentsList(true);

		dispatch(
			onGetPaginatedDeliveryAgents({
				page: 1,
				name: deliveryAgentSearch,
			})
		);
	};

	const addDeliveryAgentAssignment = () => {
		setDeliveryAgentsAssignments(delivery_agents_assignments => [
			...delivery_agents_assignments,
			deliveryAgentSelected,
		]);

		// clean search
		setDeliveryAgentsList([]);
		setDeliveryAgentSelected(null);
	};

	const removeDeliveryAgentAssignment = delivery_agent_id => {
		setDeliveryAgentsAssignments(delivery_agents_assignments => {
			return delivery_agents_assignments.filter(item => item.delivery_agent_id !== delivery_agent_id);
		});
	};

	// * Assignments
	const [isAssignmentsModalOpen, setIsAssignmentsModalOpen] = useState(false);
	const [loadingAssignmentUpdate, setLoadingAssignmentUpdate] = useState(false);

	const assignmentsTable = createSelector(
		state => state.Assignments,
		state => ({
			assignments: state.assignments,

			isAssignmentsReplace: state.isAssignmentsReplace,
			isAssignmentsReplaceFail: state.isAssignmentsReplaceFail,
		})
	);

	const { assignments, isAssignmentsReplace, isAssignmentsReplaceFail } = useSelector(assignmentsTable);

	useEffect(() => {
		const clients_assignments = [];
		const sales_agents_assignments = [];
		const collections_agents_assignments = [];
		const delivery_agents_assignments = [];

		assignments.forEach(item => {
			if (item.agent_type === 'client_agent') {
				clients_assignments.push({
					client_id: item.agent_id,
					name: item.agent_name,
				});
			}

			if (item.agent_type === 'sales_agent') {
				sales_agents_assignments.push({
					sales_agent_id: item.agent_id,
					name: item.agent_name,
				});
			}

			if (item.agent_type === 'collections_agent') {
				collections_agents_assignments.push({
					collections_id: item.agent_id,
					name: item.agent_name,
				});
			}

			if (item.agent_type === 'delivery_agent') {
				delivery_agents_assignments.push({
					delivery_agent_id: item.agent_id,
					name: item.agent_name,
				});
			}
		});

		setClientsAssignments(clients_assignments);
		setSalesAgentsAssignments(sales_agents_assignments);
		setCollectionsAgentsAssignments(collections_agents_assignments);
		setDeliveryAgentsAssignments(delivery_agents_assignments);
	}, [assignments]);

	useEffect(() => {
		if (isAssignmentsReplace) {
			closeAssignmentsModal();
		}
	}, [isAssignmentsReplace]);

	useEffect(() => {
		if (isAssignmentsReplaceFail) {
			setLoadingAssignmentUpdate(false);
		}
	}, [isAssignmentsReplaceFail]);

	const editAssignments = user => {
		setSelectedUser(user);

		setAxiosHeader('user-id', user.user_id);

		dispatch(onGetAssignments());

		setIsAssignmentsModalOpen(true);
	};

	const closeAssignmentsModal = () => {
		setIsAssignmentsModalOpen(false);
		setSelectedUser(null);
		setLoadingAssignmentUpdate(false);
	};

	const submitAssignments = () => {
		const client_agents_assignments = clientsAssignments.map(item => ({
			agent_type: 'client_agent',
			agent_id: item.client_id,
			is_primary: true,
		}));

		const sales_agents_assignments = salesAgentsAssignments.map((item, index) => ({
			agent_type: 'sales_agent',
			agent_id: item.sales_agent_id,
			is_primary: index === 0,
		}));

		const collections_agents_assignments = collectionsAgentsAssignments.map((item, index) => ({
			agent_type: 'collections_agent',
			agent_id: item.collections_agent_id,
			is_primary: index === 0,
		}));

		const delivery_agents_assignments = deliveryAgentsAssignments.map((item, index) => ({
			agent_type: 'delivery_agent',
			agent_id: item.delivery_agent_id,
			is_primary: index === 0,
		}));

		const data = {
			assignments: [
				...client_agents_assignments,
				...sales_agents_assignments,
				...collections_agents_assignments,
				...delivery_agents_assignments,
			],
		};

		dispatch(onReplaceAssignments(data));
	};

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'user_id',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.user_id;
				},
			},
			{
				Header: 'Nombre',
				accessor: 'name',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.first_name;
				},
			},
			{
				Header: 'Apellido',
				accessor: 'last_name',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.last_name;
				},
			},
			{
				Header: 'Usuario',
				accessor: 'username',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.username;
				},
			},
			{
				Header: 'Rol',
				accessor: 'rol',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.role_name;
				},
			},
			{
				Header: 'Creado el',
				accessor: 'created_at',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.created_at;
				},
			},
			{
				Header: 'Acciones',
				accessor: 'actions',
				Cell: ({ row }) => {
					return (
						<div className="hstack gap-2">
							{row.original.role_name === 'company_agent' ||
							row.original.role_name === 'company_client' ? (
								<button
									className="btn btn-sm btn-soft-success edit-list"
									onClick={() => editAssignments(row.original)}>
									<i className="ri-user-fill align-bottom" />
								</button>
							) : null}

							<Link
								to={`/user/${row.original.user_id}/permissions`}
								className="btn btn-sm btn-soft-warning edit-list">
								<i className="ri-key-2-fill align-bottom" />
							</Link>

							<Link
								to={`/user/${row.original.user_id}/configurations`}
								className="btn btn-sm btn-soft-warning edit-list">
								<i className="ri-settings-3-fill align-bottom" />
							</Link>

							<button
								className="btn btn-sm btn-soft-info edit-list"
								onClick={() => editUser(row.original)}>
								<i className="ri-pencil-fill align-bottom" />
							</button>

							<button
								className="btn btn-sm btn-soft-danger remove-list"
								onClick={() => openUserDeletionModal(row.original)}
								disabled={
									row.original.role_name === 'company_api' ||
									row.original.role_name === 'company_updater' ||
									row.original.role_name === 'company_owner'
								}>
								<i className="ri-delete-bin-5-fill align-bottom" />
							</button>
						</div>
					);
				},
			},
		],
		[]
	);

	return (
		<React.Fragment>
			<DeleteModal
				show={isDeleteModalOpen}
				onDeleteClick={deleteUser}
				onCloseClick={() => setIsDeleteModalOpen(false)}
				title="Eliminar usuario"
				subTitle="¿Esta seguro que desea eliminar al usuario?"
			/>

			<Modal id="showModal" isOpen={isUserModalOpen} toggle={closeUserModal} centered size="lg">
				<ModalHeader className="bg-info-subtle p-3" toggle={closeUserModal}>
					{isUserEdition ? 'Edit User' : 'Add User'}
				</ModalHeader>

				<Form
					className="tablelist-form"
					onSubmit={e => {
						e.preventDefault();
						validation.handleSubmit();
						return false;
					}}>
					<ModalBody>
						<input type="hidden" id="id-field" />
						<Row className="g-3">
							<Col lg={6}>
								<div>
									<Label htmlFor="first-name-field" className="form-label">
										First Name
									</Label>

									<Input
										name="first_name"
										id="first-name-field"
										className="form-control"
										placeholder="First Name"
										type="text"
										validate={{
											required: { value: true },
										}}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										value={validation.values.first_name || ''}
										invalid={!!(validation.touched.first_name && validation.errors.first_name)}
									/>
									{validation.touched.first_name && validation.errors.first_name ? (
										<FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
									) : null}
								</div>
							</Col>
							<Col lg={6}>
								<div>
									<Label htmlFor="last-name-field" className="form-label">
										Last Name
									</Label>

									<Input
										name="last_name"
										id="last-name-field"
										className="form-control"
										placeholder="Last Name"
										type="text"
										validate={{
											required: { value: true },
										}}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										value={validation.values.last_name || ''}
										invalid={!!(validation.touched.last_name && validation.errors.last_name)}
									/>
									{validation.touched.last_name && validation.errors.last_name ? (
										<FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
									) : null}
								</div>
							</Col>
							<Col lg={6}>
								<div>
									<Label htmlFor="username-field" className="form-label">
										Username
									</Label>

									<Input
										name="username"
										id="username-field"
										className="form-control"
										placeholder="Username"
										type="text"
										validate={{
											required: { value: true },
										}}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										value={validation.values.username || ''}
										invalid={!!(validation.touched.username && validation.errors.username)}
									/>
									{validation.touched.username && validation.errors.username ? (
										<FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
									) : null}
								</div>
							</Col>
							<Col lg={6}>
								<div>
									<Label htmlFor="password-field" className="form-label">
										Password
									</Label>

									<Input
										name="password"
										id="password-field"
										className="form-control"
										placeholder={isUserEdition ? '**********' : 'Password'}
										type="text"
										validate={{
											required: { value: !isUserEdition },
										}}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										value={validation.values.password || ''}
										invalid={!!(validation.touched.password && validation.errors.password)}
									/>
									{validation.touched.password && validation.errors.password ? (
										<FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
									) : null}
								</div>
							</Col>
							<Col lg={3}>
								<div>
									<Label htmlFor="city-field" className="form-label">
										City
									</Label>
									<Input
										name="city"
										id="city-field"
										className="form-control"
										placeholder="City"
										type="text"
										validate={{
											required: { value: true },
										}}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										value={validation.values.city || ''}
										invalid={!!(validation.touched.city && validation.errors.city)}
									/>
									{validation.touched.city && validation.errors.city ? (
										<FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
									) : null}
								</div>
							</Col>
							<Col lg={3}>
								<div>
									<Label htmlFor="postal-code-field" className="form-label">
										Postal Code
									</Label>
									<Input
										name="postal_code"
										id="postal-code-field"
										className="form-control"
										placeholder="Postal Code"
										type="text"
										validate={{
											required: { value: true },
										}}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										value={validation.values.postal_code || ''}
										invalid={!!(validation.touched.postal_code && validation.errors.postal_code)}
									/>
									{validation.touched.postal_code && validation.errors.postal_code ? (
										<FormFeedback type="invalid">{validation.errors.postal_code}</FormFeedback>
									) : null}
								</div>
							</Col>
							<Col lg={3}>
								<div>
									<Label htmlFor="state-field" className="form-label">
										State
									</Label>
									<Input
										name="state"
										id="state-field"
										className="form-control"
										placeholder="State"
										type="text"
										validate={{
											required: { value: true },
										}}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										value={validation.values.state || ''}
										invalid={!!(validation.touched.state && validation.errors.state)}
									/>
									{validation.touched.state && validation.errors.state ? (
										<FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
									) : null}
								</div>
							</Col>
							<Col lg={3}>
								<div>
									<Label htmlFor="country-field" className="form-label">
										Country
									</Label>
									<Input
										name="country"
										id="country-field"
										className="form-control"
										placeholder="country"
										type="text"
										validate={{
											required: { value: true },
										}}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										value={validation.values.country || ''}
										invalid={!!(validation.touched.country && validation.errors.country)}
									/>
									{validation.touched.country && validation.errors.country ? (
										<FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
									) : null}
								</div>
							</Col>
							<Col lg={3}>
								<div>
									<Label htmlFor="role-field" className="form-label">
										Para uso interno
									</Label>

									<div className="form-check form-switch form-switch-lg form-switch-secondary mb-3">
										<Input
											name="for_internal_use"
											id="for-internal-use-field"
											className="form-check-input"
											type="checkbox"
											role="switch"
											onChange={validation.handleChange}
											checked={validation.values.for_internal_use}
										/>
									</div>
								</div>
							</Col>
							<Col lg={9}>
								<div>
									<Label htmlFor="role-field" className="form-label">
										Rol
									</Label>

									<select
										className="form-select mb-3"
										name="role_id"
										id="role-field"
										onChange={validation.handleChange}
										value={validation.values.role_id}
										disabled={isUserEdition}>
										<option value="2">Owner</option>
										<option value="3">Updater</option>
										<option value="4">Supervisor</option>
										<option value="5">Venta / Cobranza / Reparto</option>
										<option value="6">Cliente</option>
										<option value="7">Viewer</option>
										<option value="8" disabled>
											Api
										</option>
									</select>
								</div>
							</Col>
							<Col lg={6}>
								<div>
									<Label htmlFor="email-field" className="form-label">
										Email
									</Label>
									<Input
										name="email"
										id="email-field"
										className="form-control"
										placeholder="Email"
										type="text"
										validate={{
											required: { value: true },
										}}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										value={validation.values.email || ''}
										invalid={!!(validation.touched.email && validation.errors.email)}
									/>
									{validation.touched.email && validation.errors.email ? (
										<FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
									) : null}
								</div>
							</Col>
							<Col lg={6}>
								<div>
									<Label htmlFor="phone-field" className="form-label">
										Phone
									</Label>
									<Input
										name="phone"
										id="phone-field"
										className="form-control"
										placeholder="Phone"
										type="text"
										validate={{
											required: { value: true },
										}}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										value={validation.values.phone || ''}
										invalid={!!(validation.touched.phone && validation.errors.phone)}
									/>
									{validation.touched.phone && validation.errors.phone ? (
										<FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
									) : null}
								</div>
							</Col>
							<Col lg={12}>
								<div>
									<Label htmlFor="observations-field" className="form-label">
										Observations
									</Label>

									<textarea
										rows="3"
										name="observations"
										id="observations-field"
										className="form-control"
										placeholder="Observations"
										type="text"
										validate={{
											required: { value: true },
										}}
										onChange={validation.handleChange}
										onBlur={validation.handleBlur}
										value={validation.values.observations || ''}
										invalid={!!(validation.touched.observations && validation.errors.observations)}
									/>

									{validation.touched.observations && validation.errors.observations ? (
										<FormFeedback type="invalid">{validation.errors.observations}</FormFeedback>
									) : null}
								</div>
							</Col>
						</Row>
					</ModalBody>
					<ModalFooter>
						<div className="hstack gap-2 justify-content-end">
							<Button
								color="light"
								onClick={() => {
									setIsUserModalOpen(false);
								}}
								disabled={loadingUserCreateOrUpdate}>
								Close
							</Button>

							<Button type="submit" color="success" id="add-btn" disabled={loadingUserCreateOrUpdate}>
								{loadingUserCreateOrUpdate ? (
									<span className="d-flex align-items-center">
										<Spinner size="sm" className="flex-shrink-0">
											{' '}
											Loading...{' '}
										</Spinner>
										<span className="flex-grow-1 ms-2">Loading...</span>
									</span>
								) : isUserEdition ? (
									'Edit User'
								) : (
									'Add User'
								)}
							</Button>
						</div>
					</ModalFooter>
				</Form>
			</Modal>

			<Modal id="showModal" isOpen={isAssignmentsModalOpen} toggle={closeAssignmentsModal} centered size="lg">
				<ModalHeader className="bg-info-subtle p-3" toggle={closeAssignmentsModal}>
					Edit Assignments
				</ModalHeader>
				<Form
					className="tablelist-form"
					onSubmit={e => {
						e.preventDefault();
						submitAssignments();
						return false;
					}}>
					<ModalBody>
						<input type="hidden" id="id-field" />

						{selectedUser?.role_name === 'company_client' ? (
							<SimpleBar style={{ maxHeight: '215px' }}>
								<ListGroup className="mb-1">
									<ListGroupItem className="bg-light">
										<Row className="d-flex align-items-center">
											<Col className="me-3">
												<div className="flex-shrink-0 ms-2">
													<h6 className="fs-14 mb-0">Cliente</h6>
												</div>
											</Col>

											<Col lg={6}>
												<Row>
													<Col>
														<Select
															value={clientSelected}
															onChange={value => {
																setClientSelected(value);
															}}
															onInputChange={value => {
																setClientSearch(value);
															}}
															options={clientsList}
															cacheOptions={false}
															getOptionLabel={item => item.legal_name}
															getOptionValue={item => item.client_id}
															classNamePrefix="js-example-data-array"
															isLoading={loadingClientsList}
															isClearable={true}
															menuPortalTarget={document.body}
															styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
															isDisabled={clientsAssignments.length >= 1}
														/>
													</Col>

													<Col lg={'auto'}>
														<Button
															color="success"
															className="btn-icon"
															onClick={addClientAssignment}
															disabled={!clientSelected}>
															{' '}
															<i className="ri-add-line" />
														</Button>
													</Col>
												</Row>
											</Col>
										</Row>
									</ListGroupItem>

									{clientsAssignments.map(item => (
										<ListGroupItem key={item.client_id}>
											<div className="d-flex align-items-center">
												<div className="flex-grow-1">
													<div className="d-flex">
														<div className="flex-shrink-0 ms-2">
															<h6 className="fs-14 mb-0">
																[{item.client_id}] {item.legal_name}
															</h6>
														</div>
													</div>
												</div>

												<div className="flex-shrink-0">
													<Button
														color="danger"
														className="btn-icon"
														onClick={() => removeClientAssignment(item.client_id)}>
														{' '}
														<i className="ri-delete-bin-5-line" />{' '}
													</Button>
												</div>
											</div>
										</ListGroupItem>
									))}
								</ListGroup>
							</SimpleBar>
						) : (
							<>
								<SimpleBar className="mt-4" style={{ maxHeight: '215px' }}>
									<ListGroup className="mb-1">
										<ListGroupItem className="bg-light">
											<Row className="d-flex align-items-center">
												<Col className="me-3">
													<div className="flex-shrink-0 ms-2">
														<h6 className="fs-14 mb-0">Sales Agents</h6>
													</div>
												</Col>

												<Col lg={6}>
													<Row>
														<Col>
															<Select
																value={salesAgentSelected}
																onChange={value => {
																	setSalesAgentSelected(value);
																}}
																onInputChange={value => {
																	setSalesAgentSearch(value);
																}}
																options={salesAgentsList}
																cacheOptions={false}
																getOptionLabel={item => item.name}
																getOptionValue={item => item.sales_agent_id}
																classNamePrefix="js-example-data-array"
																isLoading={loadingSalesAgentsList}
																isClearable={true}
																menuPortalTarget={document.body}
																styles={{
																	menuPortal: base => ({ ...base, zIndex: 9999 }),
																}}
															/>
														</Col>

														<Col lg={'auto'}>
															<Button
																color="success"
																className="btn-icon"
																onClick={addSalesAgentAssignment}
																disabled={!salesAgentSelected}>
																{' '}
																<i className="ri-add-line" />
															</Button>
														</Col>
													</Row>
												</Col>
											</Row>
										</ListGroupItem>

										{salesAgentsAssignments.map(item => (
											<ListGroupItem key={item.sales_agent_id}>
												<div className="d-flex align-items-center">
													<div className="flex-grow-1">
														<div className="d-flex">
															<div className="flex-shrink-0 ms-2">
																<h6 className="fs-14 mb-0">
																	[{item.sales_agent_id}] {item.name}
																</h6>
															</div>
														</div>
													</div>

													{/* <div className="form-check form-switch form-switch-success me-5">
                                              <input className="form-check-input" type="radio" role="switch" id="SwitchCheck3" checked />
                                              <label className="form-check-label" for="SwitchCheck3">Primary</label>
                                          </div> */}

													<div className="flex-shrink-0">
														<Button
															color="danger"
															className="btn-icon"
															onClick={() =>
																removeSalesAgentAssignment(item.sales_agent_id)
															}>
															{' '}
															<i className="ri-delete-bin-5-line" />{' '}
														</Button>
													</div>
												</div>
											</ListGroupItem>
										))}
									</ListGroup>
								</SimpleBar>

								<SimpleBar className="mt-4" style={{ maxHeight: '215px' }}>
									<ListGroup className="mb-1">
										<ListGroupItem className="bg-light">
											<Row className="d-flex align-items-center">
												<Col className="me-3">
													<div className="flex-shrink-0 ms-2">
														<h6 className="fs-14 mb-0">Collections Agent</h6>
													</div>
												</Col>

												<Col lg={6}>
													<Row>
														<Col>
															<Select
																value={collectionsAgentSelected}
																onChange={value => {
																	setCollectionsAgentSelected(value);
																}}
																onInputChange={value => {
																	setCollectionsAgentSearch(value);
																}}
																options={collectionsAgentsList}
																cacheOptions={false}
																getOptionLabel={item => item.name}
																getOptionValue={item => item.collections_agent_id}
																classNamePrefix="js-example-data-array"
																isLoading={loadingCollectionsAgentsList}
																isClearable={true}
																menuPortalTarget={document.body}
																styles={{
																	menuPortal: base => ({ ...base, zIndex: 9999 }),
																}}
															/>
														</Col>

														<Col lg={'auto'}>
															<Button
																color="success"
																className="btn-icon"
																onClick={addCollectionsAgentAssignment}
																disabled={!collectionsAgentSelected}>
																{' '}
																<i className="ri-add-line" />
															</Button>
														</Col>
													</Row>
												</Col>
											</Row>
										</ListGroupItem>

										{collectionsAgentsAssignments.map(item => (
											<ListGroupItem key={item.collections_agent_id}>
												<div className="d-flex align-items-center">
													<div className="flex-grow-1">
														<div className="d-flex">
															<div className="flex-shrink-0 ms-2">
																<h6 className="fs-14 mb-0">
																	[{item.collections_agent_id}] {item.name}
																</h6>
															</div>
														</div>
													</div>

													<div className="flex-shrink-0">
														<Button
															color="danger"
															className="btn-icon"
															onClick={() =>
																removeCollectionsAgentAssignment(
																	item.collections_agent_id
																	// eslint-disable-next-line react/jsx-indent
																)
															}>
															{' '}
															<i className="ri-delete-bin-5-line" />{' '}
														</Button>
													</div>
												</div>
											</ListGroupItem>
										))}
									</ListGroup>
								</SimpleBar>

								<SimpleBar className="mt-4" style={{ maxHeight: '215px' }}>
									<ListGroup className="mb-1">
										<ListGroupItem className="bg-light">
											<Row className="d-flex align-items-center">
												<Col className="me-3">
													<div className="flex-shrink-0 ms-2">
														<h6 className="fs-14 mb-0">Delivery Agent</h6>
													</div>
												</Col>

												<Col lg={6}>
													<Row>
														<Col>
															<Select
																value={deliveryAgentSelected}
																onChange={value => {
																	setDeliveryAgentSelected(value);
																}}
																onInputChange={value => {
																	setDeliveryAgentSearch(value);
																}}
																options={deliveryAgentsList}
																cacheOptions={false}
																getOptionLabel={item => item.name}
																getOptionValue={item => item.delivery_agent_id}
																classNamePrefix="js-example-data-array"
																isLoading={loadingDeliveryAgentsList}
																isClearable={true}
																menuPortalTarget={document.body}
																styles={{
																	menuPortal: base => ({ ...base, zIndex: 9999 }),
																}}
															/>
														</Col>

														<Col lg={'auto'}>
															<Button
																color="success"
																className="btn-icon"
																onClick={addDeliveryAgentAssignment}
																disabled={!deliveryAgentSelected}>
																{' '}
																<i className="ri-add-line" />
															</Button>
														</Col>
													</Row>
												</Col>
											</Row>
										</ListGroupItem>

										{deliveryAgentsAssignments.map(item => (
											<ListGroupItem key={item.delivery_agent_id}>
												<div className="d-flex align-items-center">
													<div className="flex-grow-1">
														<div className="d-flex">
															<div className="flex-shrink-0 ms-2">
																<h6 className="fs-14 mb-0">
																	[{item.delivery_agent_id}] {item.name}
																</h6>
															</div>
														</div>
													</div>

													<div className="flex-shrink-0">
														<Button
															color="danger"
															className="btn-icon"
															onClick={() =>
																removeDeliveryAgentAssignment(item.delivery_agent_id)
															}>
															{' '}
															<i className="ri-delete-bin-5-line" />{' '}
														</Button>
													</div>
												</div>
											</ListGroupItem>
										))}
									</ListGroup>
								</SimpleBar>
							</>
						)}
					</ModalBody>
					<ModalFooter>
						<div className="hstack gap-2 justify-content-end">
							<Button
								color="light"
								onClick={() => {
									setIsAssignmentsModalOpen(false);
								}}
								disabled={loadingAssignmentUpdate}>
								Close
							</Button>

							<Button type="submit" color="success" id="add-btn" disabled={loadingAssignmentUpdate}>
								{loadingAssignmentUpdate ? (
									<span className="d-flex align-items-center">
										<Spinner size="sm" className="flex-shrink-0">
											{' '}
											Loading...{' '}
										</Spinner>
										<span className="flex-grow-1 ms-2">Loading...</span>
									</span>
								) : (
									'Edit Assignments'
								)}
							</Button>
						</div>
					</ModalFooter>
				</Form>
			</Modal>

			<Card id="apiKeyList">
				<CardHeader className="d-flex align-items-center">
					<h5 className="card-title flex-grow-1 mb-0">Usuarios</h5>

					<div className="d-flex gap-1 flex-wrap">
						<Button
							type="button"
							color="primary"
							className="btn create-btn"
							data-bs-toggle="modal"
							onClick={createNewUser}
							data-bs-target="#api-key-modal">
							<i className="ri-add-line align-bottom me-1"></i> Nuevo Usuario
						</Button>
					</div>
				</CardHeader>

				<CardBody>
					<div>
						<TableContainer
							columns={columns}
							data={usersList}
							hadleApiKey={() => {}}
							customPageSize={25}
							noDataMessage={'No hay usuarios existentes'}
							divclassName="table-responsive table-card mb-3"
							tableclassName="table align-middle table-nowrap mb-0"
							theadclassName="table-light"
						/>
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export { CompanyUsers };
