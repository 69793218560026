import { createSlice } from '@reduxjs/toolkit';

import { getUsersLastPosition } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	users_last_position: null,
	loading_users_last_position: false,
	error: null,
};

const usersLastPositionSlice = createSlice({
	name: 'users_last_position',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getUsersLastPosition.pending, state => {
			state.loading_users_last_position = true;
		});

		builder.addCase(getUsersLastPosition.fulfilled, (state, action) => {
			state.users_last_position = action.payload?.data?.last_positions || null;
			state.loading_users_last_position = false;
		});

		builder.addCase(getUsersLastPosition.rejected, (state, action) => {
			state.error = action?.error || null;
			state.loading_users_last_position = false;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default usersLastPositionSlice.reducer;
