import React, { useRef, useEffect } from 'react';

const MapPolylinePathDivider = ({ mapRef, data }) => {
	const markerRef = useRef();

	useEffect(() => {
		const icon = document.createElement('div');

		icon.innerHTML = `<i class="ri-checkbox-blank-circle-fill text-danger" style="font-size: 10px;"></i>`;

		const advanced_marker = new window.google.maps.marker.AdvancedMarkerElement({
			content: icon,
		});

		// On click
		advanced_marker.addListener('click', () => {
			openInfoWindow();
		});

		markerRef.current = advanced_marker;

		return () => {
			advanced_marker.map = null;
		};
	}, []);

	useEffect(() => {
		if (!markerRef?.current) {
			return;
		}

		if (!mapRef?.current) {
			return;
		}

		if (!data?.latitude || !data?.longitude) {
			return;
		}

		markerRef.current.map = mapRef.current.map;
		markerRef.current.position = {
			lat: data.latitude,
			lng: data.longitude,
		};
	}, [mapRef, data]);

	const openInfoWindow = () => {
		const info_window_content = `<div>
			<h5>Coordenada</h5>

			<table style="width: 350px">
				<tr>
					<td>record_id</td>
					<td>${data.record_id || '—'}</td>
				</tr>

				<tr>
					<td>latitude</td>
					<td>${data.latitude || '—'}</td>
				</tr>

				<tr>
					<td>longitude</td>
					<td>${data.longitude || '—'}</td>
				</tr>

				<tr>
					<td>altitude</td>
					<td>${data.altitude || '—'}</td>
				</tr>

				<tr>
					<td>accuracy</td>
					<td>${data.accuracy || '—'}</td>
				</tr>

				<tr>
					<td>altitude_accuracy</td>
					<td>${data.altitude_accuracy || '—'}</td>
				</tr>

				<tr>
					<td>heading</td>
					<td>${data.heading || '—'}</td>
				</tr>

				<tr>
					<td>speed</td>
					<td>${data.speed || '—'}</td>
				</tr>

				<tr>
					<td>battery_percentage</td>
					<td>${data.battery_percentage || '—'}</td>
				</tr>

				<tr>
					<td>tracking_session_uuid</td>
					<td>${data.tracking_session_uuid || '—'}</td>
				</tr>

				<tr>
					<td>recorded_at</td>
					<td>${data.recorded_at || '—'}</td>
				</tr>

				<tr>
					<td>updated_at</td>
					<td>${data.updated_at || '—'}</td>
				</tr>

				<tr>
					<td>created_at</td>
					<td>${data.created_at || '—'}</td>
				</tr>
			</table>
		<div>`;

		mapRef.current.infoWindow.close();
		mapRef.current.infoWindow.setContent(info_window_content);
		mapRef.current.infoWindow.setOptions({ minWidth: 450 });
		mapRef.current.infoWindow.open(markerRef.current.map, markerRef.current);
	};

	return <></>;
};

export default MapPolylinePathDivider;
