import { createSlice } from '@reduxjs/toolkit';

import { getAllPaymentCollectionMethods } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	payment_collection_methods: [],
	loading_methods: false,
	error: null,
};

const paymentCollectionMethodsSlice = createSlice({
	name: 'payment_collection_methods',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getAllPaymentCollectionMethods.pending, state => {
			state.loading_items = true;
		});

		builder.addCase(getAllPaymentCollectionMethods.fulfilled, (state, action) => {
			state.payment_collection_methods = action.payload?.data?.payment_collection_methods || [];
			state.loading_items = false;
		});

		builder.addCase(getAllPaymentCollectionMethods.rejected, (state, action) => {
			state.error = action?.error || null;
			state.loading_items = false;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default paymentCollectionMethodsSlice.reducer;
