import React, { useMemo, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Button, Spinner } from 'reactstrap';

import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import TableContainer from '../../components/common/TableContainer';

import {
	getCompanyConfigurations as onGetCompanyConfigurations,
	updateCompanyConfigurations as onUpdateCompanyConfigurations,
} from '../../slices/thunks';

const InputValue = ({ value, ...props }) => {
	return <input type="text" className="form-control apikey-value" defaultValue={value} {...props} />;
};

const CompanyConfigurations = () => {
	const dispatch = useDispatch();

	// Get all company configurations list
	useEffect(() => {
		dispatch(onGetCompanyConfigurations());
	}, []);

	const [companyConfigurationsList, setCompanyConfigurationsList] = useState([]);

	const [updatesList, setUpdatesList] = useState([]);
	const [loadingSendUpdates, setLoadingSendUpdates] = useState(false);

	const selectConfigurations = createSelector(
		state => state.CompanyConfigurations,
		state => ({
			company_configurations: state.company_configurations,
			isCompanyConfigurationUpdate: state.isCompanyConfigurationUpdate,
			isCompanyConfigurationUpdateFail: state.isCompanyConfigurationUpdateFail,
		})
	);

	const { company_configurations, isCompanyConfigurationUpdate, isCompanyConfigurationUpdateFail } =
		useSelector(selectConfigurations);

	useEffect(() => {
		setCompanyConfigurationsList(company_configurations);
	}, [company_configurations]);

	useEffect(() => {
		setLoadingSendUpdates(false);

		if (isCompanyConfigurationUpdate) {
			setUpdatesList([]);
		}
	}, [isCompanyConfigurationUpdate, isCompanyConfigurationUpdateFail]);

	// Save changes on temporary list
	const saveUpdatesList = (original_item, columns_updates) => {
		setUpdatesList(updates_list => {
			// eslint-disable-next-line eqeqeq
			const index = updates_list.findIndex(item => item.configuration_id == original_item.configuration_id);

			if (index === -1) {
				return [...updates_list, { ...original_item, ...columns_updates }];
			}

			const updated_item = { ...updates_list[index], ...columns_updates };

			// If equal to original, delete item
			if (JSON.stringify(updated_item) === JSON.stringify(original_item)) {
				updates_list.splice(index, 1);
				return updates_list;
			}

			updates_list[index] = updated_item;

			return updates_list;
		});
	};

	const sendUpdates = () => {
		setLoadingSendUpdates(true);

		const updates = updatesList.map(item => {
			const { configuration_id, value, is_modifiable_by_company_owner, is_visible_to_all, is_overridable } = item;

			return {
				configuration_id: configuration_id.toString(),
				value: value.toString(),
				is_modifiable_by_company_owner: is_modifiable_by_company_owner.toString(),
				is_visible_to_all: is_visible_to_all.toString(),
				is_overridable: is_overridable.toString(),
			};
		});

		const data = { configurations: updates };

		dispatch(onUpdateCompanyConfigurations(data));
	};

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'configuration_id',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.configuration_id;
				},
			},
			{
				Header: 'Nombre',
				accessor: 'name',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.name;
				},
			},
			{
				Header: 'Valor',
				accessor: 'value',
				filterable: true,
				border: '1px solid black',
				Cell: ({ row }) => {
					return (
						<InputValue
							value={row.original.value}
							style={{ width: 'auto' }}
							onChange={event => saveUpdatesList(row.original, { value: event.target.value })}
						/>
					);
				},
			},
			{
				Header: 'Modificable by owner',
				accessor: 'is_modifiable_by_company_owner',
				filterable: true,
				Cell: ({ row }) => {
					return (
						<InputValue
							value={row.original.is_modifiable_by_company_owner}
							style={{ maxWidth: '50px' }}
							onChange={event =>
								saveUpdatesList(row.original, {
									is_modifiable_by_company_owner: Number(event.target.value),
								})
							}
						/>
					);
				},
			},
			{
				Header: 'Visible',
				accessor: 'is_visible_to_all',
				filterable: true,
				Cell: ({ row }) => {
					return (
						<InputValue
							value={row.original.is_visible_to_all}
							style={{ maxWidth: '50px' }}
							onChange={event =>
								saveUpdatesList(row.original, { is_visible_to_all: Number(event.target.value) })
							}
						/>
					);
				},
			},
			{
				Header: 'Se puede override',
				accessor: 'is_overridable',
				filterable: true,
				Cell: ({ row }) => {
					return (
						<InputValue
							value={row.original.is_overridable}
							style={{ maxWidth: '50px' }}
							onChange={event =>
								saveUpdatesList(row.original, { is_overridable: Number(event.target.value) })
							}
						/>
					);
				},
			},
			{
				Header: 'Updated at',
				accessor: 'updated_at',
				filterable: true,
				Cell: ({ row }) => {
					return row.original.updated_at;
				},
			},
		],
		[]
	);

	return (
		<React.Fragment>
			<Card id="apiKeyList">
				<CardHeader className="d-flex align-items-center">
					<h5 className="card-title flex-grow-1 mb-0">Configuraciones</h5>

					<div className="d-flex gap-1 flex-wrap">
						<Button
							type="button"
							color="success"
							className="btn create-btn"
							data-bs-toggle="modal"
							onClick={sendUpdates}
							data-bs-target="#api-key-modal"
							disabled={updatesList.length === 0 || loadingSendUpdates}>
							{loadingSendUpdates ? (
								<span className="d-flex align-items-center">
									<Spinner size="sm" className="flex-shrink-0">
										Loading...
									</Spinner>
									<span className="flex-grow-1 ms-2">Loading...</span>
								</span>
							) : (
								<>
									<i className="ri-save-3-line align-bottom me-1"></i> Guardar cambios
								</>
							)}
						</Button>
					</div>
				</CardHeader>

				<CardBody>
					<div>
						<TableContainer
							columns={columns}
							data={companyConfigurationsList}
							hadleApiKey={() => {}}
							noDataMessage={'No hay configuraciones disponibles para mostrar'}
							divClass="table-responsive table-card mb-3  "
							tableClass="table align-middle table-nowrap mb-0"
							theadClass="table-light"
						/>
					</div>
				</CardBody>
			</Card>

			<div className="d-flex align-items-center pb-5">
				<i className="ri-alert-line label-icon text-warning fs-18 me-2"></i>
				Los cambios impactaran en todos los dispositivos en un plazo de 5 horas.
			</div>
		</React.Fragment>
	);
};

export { CompanyConfigurations };
