/* eslint-disable indent */
import React, { useRef } from 'react';

import { Polyline } from '@react-google-maps/api';

const MapPolyline = ({ isWalking, hasDotsOnStart, strokeOpacity, onClick, onMouseOver, onMouseOut, ...props }) => {
	const polylineRef = useRef();

	const strokeColor = strokeOpacity === 1 ? '#299CDB' : '#667085';
	const strokeWeight = isWalking ? 0 : 5;

	const icons = isWalking
		? [
				{
					icon: {
						path: window.google.maps.SymbolPath.CIRCLE,
						fillOpacity: 1,
						scale: 2.5,
					},
					offset: '0',
					repeat: '15px',
				},
		  ]
		: [
				{
					icon: {
						path: window.google.maps.SymbolPath.CIRCLE,
						fillOpacity: hasDotsOnStart ? 0.7 : 0,
						fillColor: '#054AB4',
						scale: 2,
						strokeColor: '#054AB4',
						strokeOpacity: hasDotsOnStart ? 0.7 : 0,
					},
					offset: '0%',
				},
				{
					icon: {
						path: window.google.maps.SymbolPath.CIRCLE,
						fillOpacity: hasDotsOnStart ? 0.7 : 0,
						fillColor: '#054AB4',
						scale: 2,
						strokeColor: '#054AB4',
						strokeOpacity: hasDotsOnStart ? 0.7 : 0,
					},
					offset: '2%',
				},
				{
					icon: {
						path: window.google.maps.SymbolPath.CIRCLE,
						fillOpacity: hasDotsOnStart ? 0.7 : 0,
						fillColor: '#054AB4',
						scale: 2,
						strokeColor: '#054AB4',
						strokeOpacity: hasDotsOnStart ? 0.7 : 0,
					},
					offset: '4%',
				},
				{
					icon: {
						path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						fillOpacity: 1,
						fillColor: '#054AB4',
						scale: 2.1,
						strokeColor: '#054AB4',
					},
					offset: '90%',
					repeat: '150px',
				},
				{
					icon: {
						path: window.google.maps.SymbolPath.CIRCLE,
						fillOpacity: 1,
						fillColor: '#054AB4',
						scale: 2,
						strokeColor: '#054AB4',
					},
					offset: '100%',
				},
		  ];

	return (
		<>
			<Polyline
				ref={polylineRef}
				options={{
					strokeColor,
					strokeWeight,
					strokeOpacity,
					icons,
				}}
				onClick={event => onClick(event, polylineRef)}
				onMouseOver={onMouseOver}
				{...props}
			/>

			{/* Extra polyline to extend mouse area */}
			<Polyline
				options={{
					strokeColor: 'transparent',
					strokeWeight: 30,
					strokeOpacity: 1,
				}}
				onClick={event => onClick(event, polylineRef)}
				onMouseOver={onMouseOver}
				onMouseOut={onMouseOut}
				{...props}
			/>
		</>
	);
};

export default MapPolyline;
