import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import withRouter from '../components/common/withRouter';
import { useProfile } from '../components/hooks/UserHooks';

import { changeHTMLAttribute } from '../slices/layouts/utils';

import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';

import {
	changeLayout,
	changeSidebarTheme,
	changeLayoutMode,
	changeLayoutWidth,
	changeLayoutPosition,
	changeTopbarTheme,
	changeLeftsidebarSizeType,
	changeLeftsidebarViewType,
	changeSidebarImageType,
	changeSidebarVisibility,
} from '../slices/thunks';

const Layout = props => {
	const [headerClass, setHeaderClass] = useState('');
	const dispatch = useDispatch();

	const selectLayoutState = state => state.Layout;
	const selectLayoutProperties = createSelector(selectLayoutState, layout => ({
		layoutType: layout.layoutType,
		leftSidebarType: layout.leftSidebarType,
		layoutModeType: layout.layoutModeType,
		layoutWidthType: layout.layoutWidthType,
		layoutPositionType: layout.layoutPositionType,
		topbarThemeType: layout.topbarThemeType,
		leftsidbarSizeType: layout.leftsidbarSizeType,
		leftSidebarViewType: layout.leftSidebarViewType,
		leftSidebarImageType: layout.leftSidebarImageType,
		preloader: layout.preloader,
		sidebarVisibilitytype: layout.sidebarVisibilitytype,
	}));
	const {
		layoutType,
		leftSidebarType,
		layoutModeType,
		layoutWidthType,
		layoutPositionType,
		topbarThemeType,
		leftsidbarSizeType,
		leftSidebarViewType,
		leftSidebarImageType,
		sidebarVisibilitytype,
	} = useSelector(selectLayoutProperties);

	/*
    layout settings
    */
	useEffect(() => {
		if (
			layoutType ||
			leftSidebarType ||
			layoutModeType ||
			layoutWidthType ||
			layoutPositionType ||
			topbarThemeType ||
			leftsidbarSizeType ||
			leftSidebarViewType ||
			leftSidebarImageType ||
			sidebarVisibilitytype
		) {
			window.dispatchEvent(new Event('resize'));
			dispatch(changeLeftsidebarViewType(leftSidebarViewType));
			dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
			dispatch(changeSidebarTheme(leftSidebarType));
			dispatch(changeLayoutMode(layoutModeType));
			dispatch(changeLayoutWidth(layoutWidthType));
			dispatch(changeLayoutPosition(layoutPositionType));
			dispatch(changeTopbarTheme(topbarThemeType));
			dispatch(changeLayout(layoutType));
			dispatch(changeSidebarImageType(leftSidebarImageType));
			dispatch(changeSidebarVisibility(sidebarVisibilitytype));
		}
	}, [
		layoutType,
		leftSidebarType,
		layoutModeType,
		layoutWidthType,
		layoutPositionType,
		topbarThemeType,
		leftsidbarSizeType,
		leftSidebarViewType,
		leftSidebarImageType,
		sidebarVisibilitytype,
		dispatch,
	]);
	/*
    call dark/light mode
    */
	const onChangeLayoutMode = value => {
		if (changeLayoutMode) {
			dispatch(changeLayoutMode(value));
		}
	};

	// class add remove in header
	useEffect(() => {
		window.addEventListener('scroll', scrollNavigation, true);
	});

	function scrollNavigation() {
		const scrollup = document.documentElement.scrollTop;
		if (scrollup > 50) {
			setHeaderClass('topbar-shadow');
		} else {
			setHeaderClass('');
		}
	}

	const { userProfile } = useProfile();

	useEffect(() => {
		const role_name = userProfile?.user?.role_name || null;

		if (role_name === 'company_owner') {
			dispatch(changeSidebarVisibility('show'));
			changeHTMLAttribute('data-layout-sidebar', 'show');
		} else {
			dispatch(changeSidebarVisibility('hidden'));
			changeHTMLAttribute('data-layout-sidebar', 'hidden');
		}
	}, [userProfile]);

	return (
		<React.Fragment>
			<div id="layout-wrapper">
				<Header
					headerClass={headerClass}
					layoutModeType={layoutModeType}
					onChangeLayoutMode={onChangeLayoutMode}
				/>

				{sidebarVisibilitytype === 'show' ? <Sidebar layoutType={layoutType} /> : null}

				<div className="main-content">
					{props.children}
					{/* <Footer /> */}
				</div>
			</div>
		</React.Fragment>
	);
};

Layout.propTypes = {
	children: PropTypes.object,
};

export default withRouter(Layout);
