import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	getMergedPermissions as getMergedPermissionsAPI,
	updateMergedPermissions as updateMergedPermissionsAPI,
} from '../../helpers/backend_helper';

export const getMergedPermissions = createAsyncThunk('user/getMergedPermissions', async () => {
	return getMergedPermissionsAPI();
});

export const updateMergedPermissions = createAsyncThunk('user/updateMergedPermissions', async data => {
	try {
		const response = await updateMergedPermissionsAPI(data);

		if (!response.success) {
			throw new Error(response);
		}

		toast.success('Edición de permiso realizada con éxito', { autoClose: 3000 });

		return data;
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error';

		toast.error(message, { autoClose: 3000 });
		throw error;
	}
});
