import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	getCompanyPermissions as getCompanyPermissionsAPI,
	updateCompanyPermissions as updateCompanyPermissionsAPI,
} from '../../helpers/backend_helper';

export const getCompanyPermissions = createAsyncThunk('company/getPermissions', async () => {
	return getCompanyPermissionsAPI();
});

export const updateCompanyPermissions = createAsyncThunk('company/updatePermissions', async data => {
	try {
		const response = await updateCompanyPermissionsAPI(data);

		if (!response.success) {
			throw new Error(response);
		}

		toast.success('Edición de permiso realizada con éxito', { autoClose: 3000 });

		return data;
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error';

		toast.error(message, { autoClose: 3000 });
		throw error;
	}
});
