import React from 'react';

const OperationStatusBadge = ({ operationData }) => {
	const status = operationData?.sent_to_company ? 'received_by_company' : operationData?.status; // TODO Undo after remove "sent_to_company" column

	switch (status) {
		case 'waiting_confirmation':
			return (
				<span className="badge text-uppercase bg-warning-subtle text-warning fs-12">
					<i className="ri-time-line label-icon align-bottom me-1"></i>
					Esperando Confirmación
				</span>
			);

		case 'confirmed':
			return (
				<span className="badge text-uppercase bg-success-subtle text-success fs-12">
					<i className="ri-check-fill label-icon align-bottom me-1"></i>
					Confirmado
				</span>
			);

		case 'received_by_company':
			return (
				<span className="badge text-uppercase bg-success-subtle text-success fs-12">
					<i className="ri-check-double-fill label-icon align-bottom me-1"></i>
					Recibido en sistema
				</span>
			);

		default:
			return null;
	}
};

export default OperationStatusBadge;
