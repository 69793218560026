import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	getAllClientOrders as getAllClientOrdersAPI,
	addNewOrder as addNewOrderAPI,
} from '../../helpers/backend_helper';

export const getAllClientOrders = createAsyncThunk('orders/getAllClient', async () => {
	return getAllClientOrdersAPI();
});

export const addNewOrder = createAsyncThunk('orders/addNew', async ({ data, navigate }) => {
	try {
		const response = await addNewOrderAPI(data);

		if (!response.success) {
			throw new Error(response);
		}

		const { order_id } = response;

		navigate('/order-successfully-sent', { state: { order_id } });

		return {
			order_id,
			...data.order,
		};
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error al enviar el pedido. Por favor vuelva a intentarlo';

		toast.error(message, { autoClose: 4500 });
		throw error;
	}
});
