import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane, Button } from 'reactstrap';

import classnames from 'classnames';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

import { api } from '../../config';

import { CompanyOverview } from './CompanyOverview';
import { CompanyPermissions } from './CompanyPermissions';
import { CompanyConfigurations } from './CompanyConfigurations';
import { CompanyUsers } from './CompanyUsers';

import { CompanyModal } from '../../components/modals';

const CompanyHeader = () => {
	const [companyData, setCompanyData] = useState({});
	const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);

	const selectCompany = createSelector(
		state => state.Company,
		state => ({
			company: state.company,
		})
	);

	const { company } = useSelector(selectCompany);

	useEffect(() => {
		setCompanyData(company);
	}, [company]);

	// * Tabs
	// Save last active tab on storage in order to remember on go back
	const last_active_tab = sessionStorage.getItem('companyOverviewActiveTab') || '1';

	const [activeTab, setActiveTab] = useState(last_active_tab);

	const toggleTab = tab => {
		if (activeTab !== tab) {
			setActiveTab(tab);
			sessionStorage.setItem('companyOverviewActiveTab', tab);
		}
	};

	return (
		<React.Fragment>
			<Row>
				<Col lg={12}>
					<Card className="mt-n4 mx-n4">
						<div className="bg-warning-subtle">
							<CardBody className="pb-0 px-4">
								<Row className="mb-3">
									<div className="col-md">
										<Row className="align-items-center g-3">
											<div className="col-md-auto">
												{companyData?.logo_file ? (
													<img
														src={`${api.API_URL}/files/images/${companyData?.logo_file}`}
														alt="company-logo"
														style={{ height: 48 }}
													/>
												) : null}
											</div>

											<div className="col-md">
												<div>
													<h4 className="fw-bold">{companyData?.name}</h4>

													<div className="hstack gap-3 flex-wrap">
														<div>
															Creada el:{' '}
															<span className="fw-medium">
																{new Date(companyData?.created_at).toLocaleString()}
															</span>
														</div>
													</div>
												</div>
											</div>

											<div className="col-auto">
												<Button
													type="button"
													color="success"
													className="col-auto btn btn-primary px-3"
													data-bs-toggle="modal"
													onClick={() => setIsCompanyModalOpen(true)}
													data-bs-target="#api-key-modal">
													<i className="ri-pencil-fill align-bottom me-2"></i> Editar
												</Button>
											</div>
										</Row>
									</div>
								</Row>

								<Nav className="nav-tabs-custom border-bottom-0" role="tablist">
									<NavItem>
										<NavLink
											className={classnames({ active: activeTab === '1' }, 'fw-semibold')}
											onClick={() => {
												toggleTab('1');
											}}
											href="#">
											Detalles
										</NavLink>
									</NavItem>

									<NavItem>
										<NavLink
											className={classnames({ active: activeTab === '2' }, 'fw-semibold')}
											onClick={() => {
												toggleTab('2');
											}}
											href="#">
											Permisos
										</NavLink>
									</NavItem>

									<NavItem>
										<NavLink
											className={classnames({ active: activeTab === '3' }, 'fw-semibold')}
											onClick={() => {
												toggleTab('3');
											}}
											href="#">
											Configuraciones
										</NavLink>
									</NavItem>

									<NavItem>
										<NavLink
											className={classnames({ active: activeTab === '4' }, 'fw-semibold')}
											onClick={() => {
												toggleTab('4');
											}}
											href="#">
											Usuarios
										</NavLink>
									</NavItem>
								</Nav>
							</CardBody>
						</div>
					</Card>
				</Col>
			</Row>

			<Row>
				<Col lg={12}>
					<TabContent activeTab={activeTab} className="text-muted">
						<TabPane tabId="1">
							<CompanyOverview />
						</TabPane>

						<TabPane tabId="2">
							<CompanyPermissions />
						</TabPane>

						<TabPane tabId="3">
							<CompanyConfigurations />
						</TabPane>

						<TabPane tabId="4">
							<CompanyUsers />
						</TabPane>
					</TabContent>
				</Col>
			</Row>

			<CompanyModal
				companyData={companyData}
				isModalOpen={isCompanyModalOpen}
				setIsModalOpen={setIsCompanyModalOpen}
			/>
		</React.Fragment>
	);
};

export { CompanyHeader };
