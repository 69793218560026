function getDeviceOSData() {
	const user_agent = navigator.userAgent;

	const device_name = navigator.userAgent;
	const device_type = /Mobile|Tablet|iPad|iPhone|Android/.test(navigator.userAgent) ? 'phone' : 'desktop';

	let device_os_name = 'other';
	let device_os_version = '';

	const sources = [
		{ name: 'Windows 10', regex: /(Windows 10.0|Windows NT 10.0)/ },
		{ name: 'Windows 8.1', regex: /(Windows 8.1|Windows NT 6.3)/ },
		{ name: 'Windows 8', regex: /(Windows 8|Windows NT 6.2)/ },
		{ name: 'Windows 7', regex: /(Windows 7|Windows NT 6.1)/ },
		{ name: 'Windows Vista', regex: /Windows NT 6.0/ },
		{ name: 'Windows Server 2003', regex: /Windows NT 5.2/ },
		{ name: 'Windows XP', regex: /(Windows NT 5.1|Windows XP)/ },
		{ name: 'Windows 2000', regex: /(Windows NT 5.0|Windows 2000)/ },
		{ name: 'Windows ME', regex: /(Win 9x 4.90|Windows ME)/ },
		{ name: 'Windows 98', regex: /(Windows 98|Win98)/ },
		{ name: 'Windows 95', regex: /(Windows 95|Win95|Windows_95)/ },
		{ name: 'Windows NT 4.0', regex: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
		{ name: 'Windows CE', regex: /Windows CE/ },
		{ name: 'Windows 3.11', regex: /Win16/ },
		{ name: 'Android', regex: /Android/ },
		{ name: 'Open BSD', regex: /OpenBSD/ },
		{ name: 'Sun OS', regex: /SunOS/ },
		{ name: 'Chrome OS', regex: /CrOS/ },
		{ name: 'Linux', regex: /(Linux|X11(?!.*CrOS))/ },
		{ name: 'iOS', regex: /(iPhone|iPad|iPod)/ },
		{ name: 'Mac OS X', regex: /Mac OS X/ },
		{ name: 'Mac OS', regex: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
		{ name: 'QNX', regex: /QNX/ },
		{ name: 'UNIX', regex: /UNIX/ },
		{ name: 'BeOS', regex: /BeOS/ },
		{ name: 'OS/2', regex: /OS\/2/ },
		{ name: 'Search Bot', regex: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ },
	];

	for (let i = 0; i < sources.length; i++) {
		const { name, regex } = sources[i];

		if (regex.test(user_agent)) {
			device_os_name = name;
			break;
		}
	}

	if (/Windows/.test(device_os_name)) {
		// eslint-disable-next-line prefer-destructuring
		device_os_version = /Windows (.*)/.exec(device_os_name)[1];
		device_os_name = 'Windows';
	}

	if (device_os_name === 'Mac OS' || device_os_name === 'Mac OS X' || device_os_name === 'Android') {
		// eslint-disable-next-line prefer-destructuring
		device_os_version = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([._\d]+)/.exec(
			user_agent
		)[1];
	}

	if (device_os_name === 'iOS' && navigator.appVersion) {
		device_os_version = /OS (\d+)_(\d+)_?(\d+)?/.exec(navigator.appVersion);
		device_os_version = `${device_os_version[1]}.${device_os_version[2]}.${device_os_version[3] || 0}`;
	}

	return { device_name, device_type, device_os_name, device_os_version };
}

export { getDeviceOSData };
