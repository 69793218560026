import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';

import { logoutUser } from '../../slices/thunks';

const ProfileDropdown = () => {
	const dispatch = useDispatch();

	const [userFullName, setUserFullName] = useState('');
	const [username, setUsername] = useState('');

	const selectUser = createSelector(
		state => state.Login,
		user => user.user
	);

	const user = useSelector(selectUser);

	useEffect(() => {
		if (localStorage.getItem('authUser')) {
			const { user: userData } = JSON.parse(localStorage.getItem('authUser'));

			const user_full_name = `${userData.first_name || ''} ${userData.last_name || ''}`;

			setUserFullName(user_full_name);
			setUsername(userData.username);
		}
	}, [user]);

	// Dropdown Toggle
	const [isProfileDropdown, setIsProfileDropdown] = useState(false);
	const toggleProfileDropdown = () => {
		setIsProfileDropdown(!isProfileDropdown);
	};

	const logout = () => {
		dispatch(logoutUser());
	};

	function getProfileLetter() {
		return userFullName?.[0]?.toUpperCase() || '-';
	}

	return (
		<React.Fragment>
			<Dropdown
				isOpen={isProfileDropdown}
				toggle={toggleProfileDropdown}
				className="ms-sm-3 header-item topbar-user">
				<DropdownToggle tag="button" type="button" className="btn">
					<span className="d-flex align-items-center">
						<div className="rounded-circle header-profile-user d-flex justify-content-center align-items-center bg-dark-subtle">
							<b className="text-muted fs-17">{getProfileLetter()}</b>
						</div>

						<span className="text-start ms-xl-2">
							<span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
								{userFullName}
							</span>

							<span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
								{username}
							</span>
						</span>
					</span>
				</DropdownToggle>

				<DropdownMenu className="dropdown-menu-end">
					<DropdownItem className="p-0">
						<div onClick={logout} className="dropdown-item">
							<i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{' '}
							<span className="align-middle" data-key="t-logout">
								Cerrar sesión
							</span>
						</div>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</React.Fragment>
	);
};

export default ProfileDropdown;
