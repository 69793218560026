import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'reactstrap';

import classnames from 'classnames';

import { formatHour } from '../../helpers/dates_helper';

const operations = {
	order: {
		title: 'Pedido',
		background_color: '#FEF7C3',
		icon_name: 'ri-shopping-cart-2-fill',
		icon_size: 16,
		icon_color: '#EAAA08',
	},

	payment_collection: {
		title: 'Cobranza',
		background_color: '#E6F4D7',
		icon_name: 'ri-hand-coin-fill',
		icon_size: 16,
		icon_color: '#669F2A',
	},

	new_client: {
		title: 'Nuevo cliente',
		background_color: '#ECE9FE',
		icon_name: 'ri-user-add-fill',
		icon_size: 16,
		icon_color: '#7A5AF8',
	},
};

const OperationEventItem = ({
	mapRef,
	event,
	isInsideTrackingSession = true,
	setFocusedElement,
	focusedElement,
	hoveredElement,
	setHoveredElement,
}) => {
	const item_ref = useRef(null);

	const { title, background_color, icon_name, icon_size, icon_color } = operations[event.type] || {};

	const start_at = formatHour(event?.data.generated_at);

	useEffect(() => {
		const is_focused = focusedElement?.type === 'marker' && focusedElement?.id === event?.id;

		if (is_focused && item_ref.current) {
			const rect = item_ref.current.getBoundingClientRect();
			const is_visible =
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth);

			if (!is_visible) {
				item_ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
	}, [focusedElement]);

	const onClick = () => {
		if (!isInsideTrackingSession) return;
		setFocusedElement({ type: 'marker', id: event.id });

		// Center map on operation
		mapRef.current.map.panTo({
			lat: event.coordinates.latitude,
			lng: event.coordinates.longitude,
		});
	};

	const onMouseEnter = () => {
		if (!isInsideTrackingSession) return;
		setHoveredElement({ type: 'marker', id: event.id });
	};

	const onMouseLeave = () => {
		if (!isInsideTrackingSession) return;
		setHoveredElement({ type: null, id: null });
	};

	return (
		<div
			ref={item_ref}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			style={{ height: 72 }}>
			<div style={{ marginLeft: 10 }}>
				<div
					style={{
						visibility: isInsideTrackingSession ? 'visible' : 'hidden',
						height: 7,
						width: 8,
						marginLeft: 17,
						backgroundColor: '#299CDB',
					}}
				/>
			</div>

			<Row
				className={classnames(
					'd-flex align-items-center border border-1 rounded shadow mx-2 px-2 bg-body-secondary',
					{ 'map-operation-event-item cursor-pointer': isInsideTrackingSession },
					{ hovered: hoveredElement?.type === 'marker' && hoveredElement?.id === event?.id },
					{ selected: focusedElement?.type === 'marker' && focusedElement?.id === event?.id }
				)}
				style={{ height: 58 }}>
				<Col className="col-auto mx-0 px-0">
					<div
						className="shadow"
						style={{
							display: 'inline-flex',
							height: 30,
							minWidth: 30,
							borderRadius: 15,
							backgroundColor: background_color,
						}}>
						<div className="d-flex h-100 w-100 justify-content-center align-items-center">
							<i className={icon_name} style={{ fontSize: icon_size, color: icon_color }}></i>
						</div>

						{event.operation_number ? (
							<div
								className="position-absolute shadow"
								style={{
									display: 'inline-flex',
									top: -8.5,
									left: -4,
									height: 18,
									minWidth: 18,
									borderRadius: 9,
									borderWidth: 1.5,
									borderStyle: 'solid',
									borderColor: '#878a99',
									backgroundColor: 'white',
								}}>
								<div
									className="d-flex h-100 w-100 justify-content-center align-items-center fs-11 fw-bold"
									style={{ color: '#6d6f78' }}>
									{event.operation_number}
								</div>
							</div>
						) : null}
					</div>
				</Col>

				<Col className="justify-content-center mx-0 px-0 ps-2">
					<Row style={{ marginTop: 2 }}>
						<h5 className="col my-0 mx-0 fs-14">{title}</h5>

						{start_at ? <span className="col-auto my-0 mx-0 fs-12">{start_at} hs</span> : null}
					</Row>

					<div style={{ paddingTop: 2 }}>
						<span className="mx-0">
							{event.data.client_id ? (
								<small className="text-muted">[{event.data.client_id}] </small>
							) : null}

							{event.data.client_legal_name || event.data.legal_name || '—'}
						</span>
					</div>
				</Col>
			</Row>

			<div style={{ marginLeft: 10 }}>
				<div
					style={{
						visibility: isInsideTrackingSession ? 'visible' : 'hidden',
						height: 7,
						width: 8,
						marginLeft: 17,
						backgroundColor: '#299CDB',
					}}
				/>
			</div>
		</div>
	);
};

export default OperationEventItem;
