import React from 'react';

import { Navigate, Routes as RoutesDOM, Route } from 'react-router-dom';

import NonAuthLayout from '../layouts/NonAuthLayout';
import VerticalLayout from '../layouts/index';
import { useProfile } from '../components/hooks/UserHooks';

import { publicRoutes, authenticatedRoutes, defaultRoutes } from './allRoutes';
import { AuthProtected } from './AuthProtected';

const getDefaultPage = user_profile => {
	const role_name = user_profile?.user?.role_name || null;

	return defaultRoutes[role_name] || defaultRoutes.default;
};

const Routes = () => {
	const { userProfile } = useProfile();

	return (
		<>
			<RoutesDOM>
				<Route>
					{publicRoutes.map((route, idx) => (
						<Route
							key={idx}
							path={route.path}
							element={<NonAuthLayout>{route.component}</NonAuthLayout>}
							exact={true}
						/>
					))}
				</Route>

				<Route>
					{authenticatedRoutes.map((route, idx) => (
						<Route
							key={idx}
							path={route.path}
							element={
								<AuthProtected roles={route.roles}>
									<VerticalLayout>{route.component}</VerticalLayout>
								</AuthProtected>
							}
							exact={true}
						/>
					))}

					<Route
						path={'/'}
						element={
							<AuthProtected>
								<Navigate to={getDefaultPage(userProfile)} />
							</AuthProtected>
						}
						exact={true}
					/>
				</Route>
			</RoutesDOM>
		</>
	);
};

export { Routes };
