import moment from 'moment';
import { DateTime } from 'luxon';

import {
	getDistanceDifference,
	getSecondsDifference,
	getTotalDuration,
	getSpeed,
	getAverageSpeed,
	getTotalDistance,
	getMiddleCoordinates,
	getCenterCoordinates,
	generateImaginaryCoordinate,
} from './coordinates_helper';

import { getHumanTimeDifference, formatHour, formatStartEndDuration } from './dates_helper';

let operation_number = 0;

const getFormattedOperations = (orders, new_clients, payment_collections) => {
	const orders_operations = orders.map(item => {
		const id = `order_${item.order_id}`;
		const type = 'order';
		const data = item;

		return { type, id, data };
	});

	const new_clients_operations = new_clients.map(item => {
		const id = `new_client_${item.new_client_id}`;
		const type = 'new_client';
		const data = item;

		return { type, id, data };
	});

	const payment_collections_operations = payment_collections.map(item => {
		const id = `payment_collection_${item.payment_collection_id}`;
		const type = 'payment_collection';
		const data = item;

		return { type, id, data };
	});

	return { orders_operations, new_clients_operations, payment_collections_operations };
};

// Get content for InfoWindow (popup after press on map marker)
const getInfoWindowContent = (operation_type, data) => {
	if (operation_type === 'order') {
		const start_end_duration = formatStartEndDuration(
			data?.start_coordinates?.recorded_at,
			data?.finish_coordinates?.recorded_at
		);

		const time_difference = getHumanTimeDifference(data.duration);

		return `<div style="background-color: #FEF7C3">
			<div class="card-header border-0" style="padding: 12px">
				<button 
					onclick="document.dispatchEvent(new KeyboardEvent('keydown', {key: 'Escape', keyCode: 27, bubbles: true}))" 
					type="button" 
					class="btn-close float-end fs-12 gm-ui-hover-effect" 
					draggable="false" 
					aria-label="Close">
				</button>

				<h6 class="card-title mb-0">
					Pedido
				</h6>

				<p class="card-subtitle poi-info-window view-link fw-normal text-muted mt-1 mb-0">
					<a href="/order/${data.order_id}/items" target="_blank" class="bg-transparent"><span>Ir al detalle completo</span></a>
				</p>
			</div>

			<div style="background-color: white; padding: 12px;">
				<table>
					<tr>
						<td class="fw-normal text-muted pb-2" style="width: 90px">Cliente</td>
						<td class="pb-2">
							<b>${data.client_legal_name || '−'}</b>
						</td>
					</tr>
					<tr class="mt-2">
						<td class="fw-normal text-muted pb-2" style="width: 90px">Total</td>
						<td class="pb-2">
							<b>$ ${data.final_price_with_iva || '−'}</b>
						</td>
					</tr>
					<tr>
						<td class="fw-normal text-muted pb-2" style="width: 90px">Creado</td>
						<td class="pb-2">
							<b>${moment(data.generated_at).format('HH:mm')} hs</b>
						</td>
					</tr>
					<tr>
						<td class="fw-normal text-muted" style="width: 90px">Duración</td>
						<td>
							<b>${time_difference}</b>
							<p class="fw-normal text-muted mt-1 mb-0">(${start_end_duration} hs)</p>
						</td>
					</tr>
				</table>
			<div>
		</div>`;
	}

	if (operation_type === 'new_client') {
		const start_end_duration = formatStartEndDuration(
			data?.start_coordinates?.recorded_at,
			data?.finish_coordinates?.recorded_at
		);

		const time_difference = getHumanTimeDifference(data.duration);

		return `<div style="background-color: #ECE9FE">
			<div class="card-header border-0" style="padding: 12px">
				<button 
					onclick="document.dispatchEvent(new KeyboardEvent('keydown', {key: 'Escape', keyCode: 27, bubbles: true}))" 
					type="button" 
					class="btn-close float-end fs-12 gm-ui-hover-effect" 
					draggable="false" 
					aria-label="Close">
				</button>

				<h6 class="card-title mb-0">
					Nuevo cliente
				</h6>

				<p class="card-subtitle poi-info-window view-link fw-normal text-muted mt-1 mb-0">
					<a href="/new-client/${data.new_client_id}" target="_blank" class="bg-transparent">
						<span>Ir al detalle completo</span>
					</a>
				</p>
			</div>

			<div style="background-color: white; padding: 12px;">
				<table>
					<tr>
						<td class="fw-normal text-muted pb-2" style="width: 90px">Cliente</td>
						<td class="pb-2">
							<b>${data.legal_name || '−'}</b>
						</td>
					</tr>
					<tr>
						<td class="fw-normal text-muted pb-2" style="width: 90px">Creado</td>
						<td class="pb-2">
							<b>${moment(data.generated_at).format('HH:mm')} hs</b>
						</td>
					</tr>
					<tr>
						<td class="fw-normal text-muted" style="width: 90px">Duración</td>
						<td>
							<b>${time_difference}</b>
							<p class="fw-normal text-muted mt-1 mb-0">(${start_end_duration} hs)</p>
						</td>
					</tr>
				</table>
			<div>
		</div>`;
	}

	if (operation_type === 'payment_collection') {
		const start_end_duration = formatStartEndDuration(
			data?.start_coordinates?.recorded_at,
			data?.finish_coordinates?.recorded_at
		);

		const time_difference = getHumanTimeDifference(data.duration);

		return `<div style="background-color: #E6F4D7">
			<div class="card-header border-0" style="padding: 12px">
				<button 
					onclick="document.dispatchEvent(new KeyboardEvent('keydown', {key: 'Escape', keyCode: 27, bubbles: true}))" 
					type="button" 
					class="btn-close float-end fs-12 gm-ui-hover-effect" 
					draggable="false" 
					aria-label="Close">
				</button>

				<h6 class="card-title mb-0">
					Cobranza
				</h6>

				<p class="card-subtitle poi-info-window view-link fw-normal text-muted mt-1 mb-0">
					<a href="/payment-collection/${data.payment_collection_id}/items" target="_blank" class="bg-transparent">
						<span>Ir al detalle completo</span>
					</a>
				</p>
			</div>

			<div style="background-color: white; padding: 12px;">
				<table>
					<tr>
						<td class="fw-normal text-muted pb-2" style="width: 90px">Cliente</td>
						<td class="pb-2">
							<b>${data.client_legal_name || '−'}</b>
						</td>
					</tr>
					<tr>
						<td class="fw-normal text-muted pb-2" style="width: 90px">Importe cancelado</td>
						<td class="pb-2">
							<b>$ ${data.debts_total_amount || '−'}</b>
						</td>
					</tr>
					<tr>
						<td class="fw-normal text-muted pb-2" style="width: 90px">Importe pagado</td>
						<td class="pb-2">
							<b>$ ${data.methods_total_amount || '−'}</b>
						</td>
					</tr>
					<tr>
						<td class="fw-normal text-muted pb-2" style="width: 90px">Creado</td>
						<td class="pb-2">
							<b>${moment(data.generated_at).format('HH:mm')} hs</b>
						</td>
					</tr>
					<tr>
						<td class="fw-normal text-muted" style="width: 90px">Duración</td>
						<td>
							<b>${time_difference}</b>
							<p class="fw-normal text-muted mt-1 mb-0">(${start_end_duration} hs)</p>
						</td>
					</tr>
				</table>
			<div>
		</div>`;
	}

	if (operation_type === 'stationary_period') {
		const start_end_duration = formatStartEndDuration(
			data?.start_coordinates?.recorded_at,
			data?.finish_coordinates?.recorded_at
		);

		const time_difference = getHumanTimeDifference(data.duration);

		return `<div class="bg-dark-subtle">
			<div class="card-header border-0" style="padding: 12px">
				<button 
					onclick="document.dispatchEvent(new KeyboardEvent('keydown', {key: 'Escape', keyCode: 27, bubbles: true}))" 
					type="button" 
					class="btn-close float-end fs-12 gm-ui-hover-effect" 
					draggable="false" 
					aria-label="Close">
				</button>

				<h6 class="card-title mb-0">
					Inactividad
				</h6>

				<p class="card-subtitle fw-normal text-muted mt-1 mb-0">
					La duración es aproximada
				</p>
			</div>

			<div style="background-color: white; padding: 12px;">
				<table>
					<tr>
						<td class="fw-normal text-muted" style="width: 90px">Duración</td>
						<td>
							<b>${start_end_duration} hs</b>
							<p class="fw-normal text-muted mt-1 mb-0">(${time_difference})</p>
						</td>
					</tr>
				</table>
			<div>
		</div>`;
	}

	if (operation_type === 'start_tracking_session') {
		return `<div class="bg-success-subtle">
			<div class="card-header border-0" style="padding: 12px">
				<button 
					onclick="document.dispatchEvent(new KeyboardEvent('keydown', {key: 'Escape', keyCode: 27, bubbles: true}))" 
					type="button" 
					class="btn-close float-end fs-12 gm-ui-hover-effect" 
					draggable="false" 
					aria-label="Close">
				</button>

				<h6 class="card-title mb-0">
					Inicio
				</h6>
			</div>

			<div style="background-color: white; padding: 12px;">
				<table>
					<tr>
						<td class="fw-normal text-muted" style="width: 90px">Hora</td>
						<td>
							<b>${formatHour(data.start_coordinates.recorded_at)} hs</b>
						</td>
					</tr>
				</table>
			<div>
		</div>`;
	}

	if (operation_type === 'finish_tracking_session') {
		return `<div class="${data.is_the_last_position_of_the_day ? 'bg-info-subtle' : 'bg-danger-subtle'}">
			<div class="card-header border-0" style="padding: 12px">
				<button 
					onclick="document.dispatchEvent(new KeyboardEvent('keydown', {key: 'Escape', keyCode: 27, bubbles: true}))" 
					type="button" 
					class="btn-close float-end fs-12 gm-ui-hover-effect" 
					draggable="false" 
					aria-label="Close">
				</button>

				<h6 class="card-title mb-0">
					${data.is_the_last_position_of_the_day ? 'Ultima ubicación' : 'Fin'}
				</h6>
			</div>

			<div style="background-color: white; padding: 12px;">
				<table>
					<tr>
						<td class="fw-normal text-muted" style="width: 90px">Hora</td>
						<td>
							<b>${formatHour(data.finish_coordinates.recorded_at)} hs</b>
						</td>
					</tr>
				</table>
			<div>
		</div>`;
	}

	return null;
};

// Get coordinates where user get stationary for at least X time
const getStationaryPeriods = (coordinates, operations_coordinates) => {
	if (coordinates?.length < 2) {
		return [];
	}

	const stationary_periods = [];
	const minutes_to_consider_stationary = 15;

	coordinates.forEach((current_coordinates, index) => {
		if (index === 0) {
			return;
		}

		const last_coordinates = coordinates[index - 1];

		const duration = getSecondsDifference(last_coordinates, current_coordinates);

		if (duration > minutes_to_consider_stationary * 60) {
			const start_coordinates = { ...last_coordinates, index: index - 1 };
			const finish_coordinates = { ...current_coordinates, index };
			const middle_coordinates = getMiddleCoordinates(last_coordinates, current_coordinates);

			const info_window_content = getInfoWindowContent('stationary_period', {
				duration,
				start_coordinates,
				finish_coordinates,
			});

			stationary_periods.push({
				id: `stationary-period-${last_coordinates.recorded_at}-${current_coordinates.recorded_at}`,
				type: 'stationary_period',
				start_coordinates,
				finish_coordinates,
				middle_coordinates,
				info_window_content,
				duration,
				coordinates: start_coordinates,
			});
		}
	});

	return getCleanedStationaryPeriods(stationary_periods, operations_coordinates);
};

// Remove stationary periods thats coincide with operations coordinates
const getCleanedStationaryPeriods = (stationary_periods, operations_coordinates) => {
	return stationary_periods.filter(stationary => {
		const stationary_started_at = new Date(stationary.start_coordinates.recorded_at);
		const stationary_finished_at = new Date(stationary.finish_coordinates.recorded_at);

		const exists_an_event_coincidence = operations_coordinates.some(operation => {
			const event_started_at = new Date(operation.start_coordinates.recorded_at);
			const event_finished_at = new Date(operation.finish_coordinates.recorded_at);

			if (event_finished_at <= stationary_started_at) {
				return false;
			}

			if (event_started_at >= stationary_finished_at) {
				return false;
			}

			return true;
		});

		return !exists_an_event_coincidence;
	});
};

// Remove all coordinates inside operations and stationary periods
// This is useful if multiple positions are saved for same event in a nearby space
function getCleanedCoordinates(coordinates, operations_coordinates, stationary_periods) {
	return coordinates.filter(coordinate => {
		const recorded_at = new Date(coordinate.recorded_at);

		const coordinate_inside_operation = operations_coordinates.some(item => {
			const started_at = new Date(item.start_coordinates.recorded_at);
			const finished_at = new Date(item.finish_coordinates.recorded_at);

			return recorded_at >= started_at && recorded_at <= finished_at;
		});

		const coordinate_inside_stationary_period = stationary_periods.some(item => {
			const started_at = new Date(item.start_coordinates.recorded_at);
			const finished_at = new Date(item.finish_coordinates.recorded_at);

			return recorded_at >= started_at && recorded_at <= finished_at;
		});

		return !(coordinate_inside_operation || coordinate_inside_stationary_period);
	});
}

function getEndpointsCoordinates(
	tracking_session_uuid,
	coordinates,
	is_the_first_tracking_session,
	is_the_last_tracking_session
) {
	const start_coordinates = coordinates.at(0);
	const finish_coordinates = coordinates.at(-1);

	const start_at_same_day = moment.utc(start_coordinates.recorded_at).isSame(moment.utc(), 'day');
	const finish_at_same_day = moment.utc(finish_coordinates.recorded_at).isSame(moment.utc(), 'day');

	const is_the_first_position_of_the_day = is_the_first_tracking_session && start_at_same_day;
	const is_the_last_position_of_the_day = is_the_last_tracking_session && finish_at_same_day;

	const start_info_window_content = getInfoWindowContent('start_tracking_session', {
		start_coordinates,
		is_the_first_position_of_the_day,
	});

	const finish_info_window_content = getInfoWindowContent('finish_tracking_session', {
		finish_coordinates,
		is_the_last_position_of_the_day,
	});

	return {
		start_coordinates: {
			id: `start_tracking_session_${tracking_session_uuid}`,
			start_at_same_day,
			is_the_first_position_of_the_day,
			info_window_content: start_info_window_content,
			...start_coordinates,
		},

		finish_coordinates: {
			id: `finish_tracking_session_${tracking_session_uuid}`,
			finish_at_same_day,
			is_the_last_position_of_the_day,
			info_window_content: finish_info_window_content,
			...finish_coordinates,
		},
	};
}

function getOperationsInsideTrackingSession(operations, tracking_session_started_at, tracking_session_finished_at) {
	const operations_inside_tracking = [];

	operations.forEach(item => {
		const generated_at = new Date(item.data.generated_at);

		if (generated_at >= tracking_session_started_at && generated_at <= tracking_session_finished_at) {
			operations_inside_tracking.push(item);
		}
	});

	return operations_inside_tracking;
}

function getOperationsOutsideTrackingSession(operations, tracking_sessions) {
	const operations_outside_tracking = [];

	operations.forEach(item => {
		const generated_at = new Date(item.data.generated_at);

		const tracking_session_index = tracking_sessions.findIndex(({ started_at, finished_at }) => {
			return generated_at >= started_at && generated_at <= finished_at;
		});

		if (tracking_session_index !== -1) {
			return;
		}

		operations_outside_tracking.push(item);
	});

	return operations_outside_tracking;
}

function getOperationsCoordinates(coordinates, operations) {
	if (!coordinates?.length) {
		return [];
	}

	return operations.map(operation => {
		const operation_generated_at = new Date(operation.data.generated_at);

		// * Get closest coordinate for the operation generated_at
		const closest_coordinates = coordinates.reduce((previous_value, current_value, index) => {
			const current_recorded_at = new Date(current_value.recorded_at);

			const difference = Math.abs(current_recorded_at - operation_generated_at);

			const closest_difference = previous_value
				? Math.abs(new Date(previous_value.recorded_at) - operation_generated_at)
				: Infinity;

			return difference < closest_difference ? { ...current_value, index } : previous_value;
		}, null);

		let start_coordinates = { ...closest_coordinates };
		let finish_coordinates = { ...closest_coordinates };

		// * Find start coordinates that starts the operation
		for (let i = closest_coordinates.index - 1; i >= 0; i--) {
			const distance = getDistanceDifference(closest_coordinates, coordinates[i]);

			// If distance is more than 140 meters, stop.
			if (distance > 140) {
				break;
			}

			start_coordinates = { ...coordinates[i], index: i };
		}

		// * Find end coordinates that finishes the operation
		for (let i = closest_coordinates.index + 1; i < coordinates.length; i++) {
			const distance = getDistanceDifference(closest_coordinates, coordinates[i]);

			// If distance is more than 140 meters, stop.
			if (distance > 140) {
				break;
			}

			finish_coordinates = { ...coordinates[i], index: i };
		}

		const duration =
			(new Date(finish_coordinates.recorded_at).getTime() - new Date(start_coordinates.recorded_at).getTime()) /
			1000;

		// * Add info window content
		const info_window_content = getInfoWindowContent(operation.type, {
			...operation.data,
			start_coordinates,
			finish_coordinates,
			duration,
		});

		operation.data = {
			...operation.data,
			info_window_content,
		};

		operation_number += 1;

		return {
			...operation,
			coordinates: closest_coordinates,
			start_coordinates,
			finish_coordinates,
			duration,
			operation_number,
		};
	});
}

const getPolylinesGroups = (coordinates, operations_coordinates, stationary_periods) => {
	if (!coordinates.length) {
		return [];
	}

	const polylines_groups = [];

	// * Polylines groups breaks on every operation marker or stationary period marker
	// So get all starts and finish points
	const indexes = [...operations_coordinates, ...stationary_periods].map(item => item.coordinates.index);

	// Remove duplicates and sort
	const cleaned_indexes = [...new Set(indexes)];

	const sorted_indexes = cleaned_indexes.sort((a, b) => {
		return a - b;
	});

	// * Add latest coordinate, if necessary
	// In order to have the last polyline
	const sorted_indexes_last_finish_index = sorted_indexes.at(-1)?.[1];
	const last_coordinates_index = coordinates.length - 1;

	if (sorted_indexes_last_finish_index !== last_coordinates_index) {
		sorted_indexes.push(last_coordinates_index);
	}

	let last_event_index = 0;

	sorted_indexes.forEach(event_index => {
		// Note: Add +1, slice() do not include the end index
		const polylines = [...coordinates].slice(last_event_index, event_index + 1);

		const total_distance = getTotalDistance(polylines);

		// * To calculate duration and speed, remove coordinates that coincide with stationary period or operation
		// Because, the duration time of this events is calculated from the time difference of 2 coordinates
		// And if the same first coordinate, of this events, is used on polyline calculations, the time will be the travel duration plus event duration
		const cleaned_polylines = [...polylines];

		if (polylines.length > 2) {
			cleaned_polylines.shift();
		}

		const total_duration = getTotalDuration(cleaned_polylines);
		const average_speed = getAverageSpeed(cleaned_polylines);
		const is_walking = average_speed < 8.5;

		const start_coordinates = { ...coordinates[last_event_index], index: last_event_index };
		const finish_coordinates = { ...coordinates[event_index], index: event_index };
		const middle_coordinates = getMiddleCoordinates(start_coordinates, finish_coordinates);

		polylines_groups.push({
			id: `polyline-group-${coordinates[last_event_index].recorded_at}-${coordinates[event_index].recorded_at}`,
			type: 'polyline_group',
			total_distance,
			total_duration,
			average_speed,
			is_walking,
			polylines,
			start_coordinates,
			finish_coordinates,
			middle_coordinates,
		});

		last_event_index = event_index;
	});

	return polylines_groups;
};

function getEventGroups(tracking_sessions, operations_outside_tracking) {
	const combined_array = [];

	tracking_sessions.forEach(item => {
		combined_array.push({
			type: 'tracking_session',
			data: item,
		});
	});

	operations_outside_tracking.forEach(item => {
		combined_array.push({
			type: 'operations_outside_tracking',
			data: item,
		});
	});

	combined_array.sort((a, b) => {
		const current_date = a.type === 'tracking_session' ? a.started_at : new Date(a.recorded_at);
		const next_date = b.type === 'tracking_session' ? b.started_at : new Date(b.recorded_at);

		return current_date - next_date;
	});

	return combined_array;
}

function getTrackingSessions(all_coordinates, operations) {
	const tracking_sessions = [];
	const grouped_by_session_uuid = {};

	operation_number = 0;

	// Group coordinates by session
	all_coordinates.forEach(item => {
		const { tracking_session_uuid } = item;

		if (!grouped_by_session_uuid[tracking_session_uuid]) {
			grouped_by_session_uuid[tracking_session_uuid] = [];
		}

		grouped_by_session_uuid[tracking_session_uuid].push(item);
	});

	// Get start and finish of each session
	// And all corresponding data
	Object.entries(grouped_by_session_uuid).forEach(([tracking_session_uuid, coordinates], index, array) => {
		const is_the_first_session = index === 1;
		const is_the_last_session = index === array.length - 1;

		const start_coordinates = coordinates.at(0);
		const finish_coordinates = coordinates.at(-1);

		const started_at = new Date(start_coordinates.recorded_at);
		const finished_at = new Date(finish_coordinates.recorded_at);

		const endpoints_coordinates = getEndpointsCoordinates(
			tracking_session_uuid,
			coordinates,
			is_the_first_session,
			is_the_last_session
		);

		const operations_inside_tracking = getOperationsInsideTrackingSession(operations, started_at, finished_at);
		const operations_coordinates = getOperationsCoordinates(coordinates, operations_inside_tracking);
		const stationary_periods = getStationaryPeriods(coordinates, operations_coordinates);
		const cleaned_coordinates = getCleanedCoordinates(coordinates, operations_coordinates, stationary_periods);
		const polylines_groups = getPolylinesGroups(coordinates, operations_coordinates, stationary_periods);

		const events = [...operations_coordinates, ...stationary_periods, ...polylines_groups].sort((a, b) => {
			return a.start_coordinates.index - b.start_coordinates.index;
		});

		tracking_sessions.push({
			tracking_session_uuid,
			coordinates,
			endpoints_coordinates,
			started_at,
			finished_at,
			operations_inside_tracking,
			operations_coordinates,
			stationary_periods,
			cleaned_coordinates,
			polylines_groups,
			events,
		});
	});

	return tracking_sessions;
}

const getMergedData = tracking_sessions => {
	return tracking_sessions.reduce(
		(data, current_value) => {
			return {
				operations_coordinates: [...data.operations_coordinates, ...current_value.operations_coordinates],
				endpoints_coordinates: [...data.endpoints_coordinates, current_value.endpoints_coordinates],
				stationary_periods: [...data.stationary_periods, ...current_value.stationary_periods],
				all_coordinates: [...data.all_coordinates, ...current_value.coordinates],
				cleaned_coordinates: [...data.cleaned_coordinates, ...current_value.cleaned_coordinates],
				polylines_groups: [...data.polylines_groups, ...current_value.polylines_groups],
			};
		},
		{
			operations_coordinates: [],
			endpoints_coordinates: [],
			stationary_periods: [],
			all_coordinates: [],
			cleaned_coordinates: [],
			polylines_groups: [],
		}
	);
};

const getTrackingStats = (polylines_groups, stationary_periods) => {
	const tracking_stats = polylines_groups.reduce(
		(previous_value, current_value) => {
			const { is_walking, total_duration, total_distance } = current_value;

			if (is_walking) {
				previous_value.total_walking_duration += total_duration;
				previous_value.total_walking_distance += total_distance;
			} else {
				previous_value.total_driving_duration += total_duration;
				previous_value.total_driving_distance += total_distance;
			}

			return previous_value;
		},
		{
			total_walking_duration: 0,
			total_walking_distance: 0,
			total_driving_duration: 0,
			total_driving_distance: 0,
		}
	);

	const total_stationary_duration = stationary_periods.reduce((previous_value, current_value) => {
		previous_value += current_value.duration;
		return previous_value;
	}, 0);

	tracking_stats.total_stationary_duration = total_stationary_duration;

	return tracking_stats;
};

// Cut polyline by distance
// If necessary function will make an imaginary coordinate to satisfy maximum distance
const cutPolylineBasedOnDistance = (coordinates, max_distance_on_meters = 1000, reverse_mode = false) => {
	if (!coordinates?.length) {
		return [];
	}

	if (coordinates.length <= 2) {
		return coordinates;
	}

	if (reverse_mode) {
		coordinates = [...coordinates].reverse();
	}

	const total_distance = getTotalDistance(coordinates);

	if (total_distance <= max_distance_on_meters) {
		if (reverse_mode) return coordinates.reverse();
		return coordinates;
	}

	const first_two_coordinates = coordinates.slice(0, 2);
	const first_two_coordinates_distance = getTotalDistance(first_two_coordinates);

	if (first_two_coordinates_distance > max_distance_on_meters) {
		if (reverse_mode) return first_two_coordinates.reverse();
		return first_two_coordinates;
	}

	const result = first_two_coordinates;

	for (let i = 2; i < coordinates.length; i++) {
		const last_coordinates = coordinates.at(i - 1);
		const current_coordinates = coordinates.at(i);

		const last_total_distance = getTotalDistance(result);

		result.push(current_coordinates);

		// If next coordinates make polyline bigger that permitted
		// Make new imaginary coordinates that satisfy length
		const new_total_distance = getTotalDistance(result);

		if (new_total_distance > max_distance_on_meters) {
			const remaining_distance = max_distance_on_meters - last_total_distance;

			const imaginary_coordinate = generateImaginaryCoordinate(
				last_coordinates,
				current_coordinates,
				remaining_distance
			);

			result.pop();
			result.push(imaginary_coordinate);
			break;
		}
	}

	if (reverse_mode) {
		return result.reverse();
	}

	return result;
};

const processTrackingData = user_tracking => {
	const { tracking_coordinates, orders, new_clients, payment_collections } = user_tracking;

	// Formatted operations
	const { orders_operations, new_clients_operations, payment_collections_operations } = getFormattedOperations(
		orders,
		new_clients,
		payment_collections
	);

	// Sort all coordinates
	const sorted_tracking_coordinates = [...tracking_coordinates].sort((a, b) => {
		return new Date(a.recorded_at) - new Date(b.recorded_at);
	});

	// Sort all operations by date
	const sorted_operations = [...orders_operations, ...new_clients_operations, ...payment_collections_operations].sort(
		(a, b) => {
			return new Date(b.recorded_at) - new Date(a.recorded_at);
		}
	);

	const tracking_sessions = getTrackingSessions(sorted_tracking_coordinates, sorted_operations);
	const operations_outside_tracking = getOperationsOutsideTrackingSession(sorted_operations, tracking_sessions);

	const event_groups = getEventGroups(tracking_sessions, operations_outside_tracking);
	const merged_data = getMergedData(tracking_sessions);
	const tracking_stats = getTrackingStats(merged_data.polylines_groups, merged_data.stationary_periods);
	const center_coordinates = getCenterCoordinates(sorted_tracking_coordinates);

	return { event_groups, merged_data, tracking_stats, center_coordinates };
};

const processLastPositions = last_positions => {
	// Sort
	const sorted_last_positions = [...last_positions].sort((a, b) => {
		const a_complete_name = `${a.first_name} ${a.last_name}`;
		const b_complete_name = `${b.first_name} ${b.last_name}`;

		return a_complete_name.localeCompare(b_complete_name);
	});

	// Events
	const divided_last_positions_events = {
		today: [],
		yesterday: [],
		older: [],
	};

	const last_positions_events = sorted_last_positions.map(item => {
		const id = `user_${item.user_id}_last_position`;
		const type = 'last_position';
		const data = item;

		return { id, type, data };
	});

	last_positions_events.forEach(item => {
		const now = DateTime.utc();
		const recorded_at = DateTime.fromISO(item.data?.last_coordinates?.recorded_at, { zone: 'UTC' });

		const start_of_today = now.startOf('day');
		const start_of_yesterday = start_of_today.minus({ days: 1 });

		if (recorded_at >= start_of_today) {
			divided_last_positions_events.today.push(item);
			return;
		}

		if (recorded_at >= start_of_yesterday) {
			divided_last_positions_events.yesterday.push(item);
			return;
		}

		divided_last_positions_events.older.push(item);
	});

	// Coordinates
	const last_positions_coordinates = sorted_last_positions
		.map(item => {
			return {
				id: `user_${item.user_id}_last_position`,
				...item,
			};
		})
		.filter(item => {
			if (item.last_coordinates) {
				return true;
			}

			return false;
		})
		.map(item => {
			const last_route_polyline = cutPolylineBasedOnDistance(item.last_five_coordinates, 2000, true);
			return { ...item, last_route_polyline };
		});

	const all_last_coordinates = last_positions_coordinates.map(item => item.last_coordinates);
	const center_coordinates = getCenterCoordinates(all_last_coordinates);

	return { divided_last_positions_events, last_positions_coordinates, center_coordinates };
};

export { processLastPositions, processTrackingData };
