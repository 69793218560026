import React from 'react';

import { Navigate, Route } from 'react-router-dom';

import { useProfile } from '../components/hooks/UserHooks';

const AuthProtected = props => {
	const { userProfile } = useProfile();

	if (props.roles && !props.roles.includes(userProfile?.user?.role_name)) {
		return <Navigate to={{ pathname: '/' }} />;
	}

	return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props => {
				return (
					<>
						<Component {...props} />
					</>
				);
			}}
		/>
	);
};

export { AuthProtected, AccessRoute };
