import { createAsyncThunk } from '@reduxjs/toolkit';

import { getAllPaymentCollectionMethods as getAllPaymentCollectionMethodsAPI } from '../../helpers/backend_helper';

export const getAllPaymentCollectionMethods = createAsyncThunk(
	'paymentCollection/methods',
	async payment_collection_id => {
		return getAllPaymentCollectionMethodsAPI(payment_collection_id);
	}
);
