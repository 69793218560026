import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	getAllUsers as getAllUsersAPI,
	postNewUser as addNewUserAPI,
	updateUser as updateUserAPI,
	deleteUser as deleteUserAPI,
} from '../../helpers/backend_helper';

export const getAllUsers = createAsyncThunk('users/all', async () => {
	return getAllUsersAPI();
});

export const addNewUser = createAsyncThunk('users/addNewUser', async data => {
	try {
		const response = await addNewUserAPI(data);

		if (!response.success) {
			throw new Error(response);
		}

		toast.success('Usuario creado con éxito', { autoClose: 3000 });

		// Remove password from data
		data.password = null;

		return {
			user_id: response.user_id,
			role_name: response.role_name,
			created_at: new Date().toISOString(),
			...data,
		};
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error';

		toast.error(message, { autoClose: 3000 });
		throw error;
	}
});

export const updateUser = createAsyncThunk('users/updateUser', async data => {
	try {
		const response = await updateUserAPI(data);

		if (!response.success) {
			throw new Error(response);
		}

		toast.success('Edición del usuario realizada con éxito', { autoClose: 3000 });

		// Remove password from data
		data.password = null;

		return data;
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error';

		toast.error(message, { autoClose: 3000 });
		throw error;
	}
});

export const deleteUser = createAsyncThunk('users/deleteUser', async data => {
	try {
		const response = await deleteUserAPI(data);

		if (!response.success) {
			throw new Error(response);
		}

		toast.success('Eliminación del usuario realizada con éxito', { autoClose: 3000 });

		return data;
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error';

		toast.error(message, { autoClose: 3000 });
		throw error;
	}
});
