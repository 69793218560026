import { createSlice } from '@reduxjs/toolkit';

import { getCompany, updateCompany } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	company: {},
	permissions: [],
	configurations: [],
	users: [],
	error: null,

	isCompanyUpdated: false,
	isCompanyUpdatedFail: false,
};

const companiesSlice = createSlice({
	name: 'company',
	initialState,
	reducers: {},
	extraReducers: builder => {
		// * Get
		builder.addCase(getCompany.fulfilled, (state, action) => {
			const { company, permissions, configurations, users } = action.payload.data;

			state.company = company;
			state.permissions = permissions;
			state.configurations = configurations;
			state.users = users;
		});

		builder.addCase(getCompany.rejected, (state, action) => {
			state.error = action?.error || null;
		});

		// * Update
		builder.addCase(updateCompany.pending, state => {
			state.isCompanyUpdated = false;
			state.isCompanyUpdatedFail = false;
		});

		builder.addCase(updateCompany.fulfilled, (state, action) => {
			const { logo_file, form_data } = action.payload;

			// This is needed to get FormData as Object
			const { company_logo, ...rest } = Object.fromEntries(form_data);

			if (logo_file) {
				state.company = { ...state.company, ...rest, logo_file };
			} else {
				state.company = { ...state.company, ...rest };
			}

			state.isCompanyUpdated = true;
			state.isCompanyUpdatedFail = false;
		});

		builder.addCase(updateCompany.rejected, (state, action) => {
			state.error = action?.error || null;

			state.isCompanyUpdated = false;
			state.isCompanyUpdatedFail = true;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default companiesSlice.reducer;
