import { combineReducers } from 'redux';

import LayoutReducer from './layouts/reducer';
import LoginReducer from './auth/login/reducer';
import CompaniesReducer from './Companies/reducer';
import CompanyReducer from './Company/reducer';
import CompanyPermissionsReducer from './CompanyPermissions/reducer';
import MergedPermissionsReducer from './MergedPermissions/reducer';
import CompanyConfigurationsReducer from './CompanyConfigurations/reducer';
import MergedConfigurationsReducer from './MergedConfigurations/reducer';
import UsersReducer from './Users/reducer';
import NewOrderReducer from './NewOrder/reducer';
import OrdersReducer from './Orders/reducer';
import OrderItemsReducer from './OrderItems/reducer';
import ClientOrdersReducer from './ClientOrders/reducer';
import ClientOrderFiltersReducer from './ClientOrderFilters/reducer';
import ApiTokenReducer from './ApiToken/reducer';
import MaximumAgentUsersReducer from './MaximumAgentUsers/reducer';
import ClientsReducer from './Clients/reducer';
import SalesAgentsReducer from './SalesAgents/reducer';
import CollectionsAgentsReducer from './CollectionsAgents/reducer';
import DeliveryAgentsReducer from './DeliveryAgents/reducer';
import ProductsReducer from './Products/reducer';
import AssignmentsReducer from './Assignments/reducer';
import PaymentCollectionsReducer from './PaymentCollections/reducer';
import PaymentCollectionDebtsReducer from './PaymentCollectionDebts/reducer';
import PaymentCollectionMethodsReducer from './PaymentCollectionMethods/reducer';
import NewClientsReducer from './NewClients/reducer';
import UserTrackingReducer from './UserTracking/reducer';
import UsersLastPositionReducer from './UsersLastPosition/reducer';

const rootReducer = combineReducers({
	Layout: LayoutReducer,
	Login: LoginReducer,
	Companies: CompaniesReducer,
	Company: CompanyReducer,
	CompanyPermissions: CompanyPermissionsReducer,
	MergedPermissions: MergedPermissionsReducer,
	CompanyConfigurations: CompanyConfigurationsReducer,
	MergedConfigurations: MergedConfigurationsReducer,
	Users: UsersReducer,
	NewOrder: NewOrderReducer,
	Orders: OrdersReducer,
	OrderItems: OrderItemsReducer,
	ClientOrders: ClientOrdersReducer,
	ClientOrderFilters: ClientOrderFiltersReducer,
	ApiToken: ApiTokenReducer,
	MaximumAgentUsers: MaximumAgentUsersReducer,
	Clients: ClientsReducer,
	SalesAgents: SalesAgentsReducer,
	CollectionsAgents: CollectionsAgentsReducer,
	DeliveryAgents: DeliveryAgentsReducer,
	Products: ProductsReducer,
	Assignments: AssignmentsReducer,
	PaymentCollections: PaymentCollectionsReducer,
	PaymentCollectionDebts: PaymentCollectionDebtsReducer,
	PaymentCollectionMethods: PaymentCollectionMethodsReducer,
	NewClients: NewClientsReducer,
	UserTracking: UserTrackingReducer,
	UsersLastPosition: UsersLastPositionReducer,
});

export default rootReducer;
