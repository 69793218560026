import React, { useEffect, useState, useMemo } from 'react';
import { Card, CardBody, Col, Container, Row, CardHeader, Spinner, CardFooter } from 'reactstrap';

import { useNavigate, useParams } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import TableContainer from '../../components/common/TableContainer';
import BreadCrumb from '../../components/common/BreadCrumb';
import OperationStatusBadge from '../../components/atoms/OperationStatusBadge';

import { getAllOrderItems as onGetAllOrderItems } from '../../slices/thunks';

const OrderDetails = () => {
	document.title = 'Detalle de pedido';

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { order_id } = useParams();

	const selectOrderItems = createSelector(
		state => state.OrderItems,
		state => ({
			order_items: state.order_items,
			loading_items: state.loading_items,
			error: state.error,
		})
	);

	const { order_items, loading_items } = useSelector(selectOrderItems);

	const [orderItems, setOrderItems] = useState([]);

	const [loadingOrderItems, setLoadingOrderItems] = useState(false);

	useEffect(() => {
		setLoadingOrderItems(loading_items);
	}, [loading_items]);

	useEffect(() => {
		const order_items_with_more_data = order_items.map(item => {
			const iva = Number(item.iva);
			const iva_multiplier = iva > 0 ? iva / 100 + 1 : 1;

			const quantity = Number(item.quantity || 0);
			const unit_price_with_iva = Number(item.unit_price_without_iva || 0) * iva_multiplier;
			const final_price_with_iva = Number(item.final_price_with_iva || 0);

			const discount_percentage = Number(item.discount || 0);
			const discount_amount = unit_price_with_iva * quantity * (discount_percentage / 100);

			return {
				...item,
				iva,
				iva_multiplier,
				quantity,
				unit_price_with_iva,
				final_price_with_iva,
				discount_percentage,
				discount_amount,
			};
		});

		setOrderItems(order_items_with_more_data);
	}, [order_items]);

	useEffect(() => {
		dispatch(onGetAllOrderItems(order_id));
	}, []);

	// * Order
	const [orderData, setOrderData] = useState({});

	const ordersSelector = createSelector(
		state => state.Orders,
		state => ({
			orders: state.orders,
		})
	);

	const { orders } = useSelector(ordersSelector);

	useEffect(() => {
		// eslint-disable-next-line eqeqeq
		const order_data = orders.find(item => item.order_id == order_id);

		if (!order_data) {
			navigate('/');
		}

		setOrderData(order_data);
	}, [orders]);

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'product_id',
				filterable: false,
				Cell: ({ row }) => {
					return <span className="text-muted">[{row.original.product_id || '-'}]</span>;
				},
			},
			{
				Header: 'Descripción',
				accessor: 'description',
				filterable: false,
				Cell: ({ row }) => {
					return <span>{row.original.description}</span>;
				},
			},
			{
				Header: 'Cantidad',
				accessor: 'quantity',
				filterable: false,
				Cell: ({ row }) => {
					return <span>{row.original.quantity}</span>;
				},
			},
			{
				Header: 'Precio unitario',
				accessor: 'unit_price_without_iva',
				filterable: false,
				Cell: ({ row }) => {
					return <span>$ {row.original.unit_price_with_iva.toFixed(2)}</span>;
				},
			},
			{
				Header: 'Descuento',
				accessor: 'discount',
				Cell: ({ row }) => {
					return (
						<span>
							$ -{row.original.discount_amount.toFixed(2)}{' '}
							<span className="text-muted">[{row.original.discount_percentage.toFixed(2)}%]</span>
						</span>
					);
				},
			},
			{
				Header: () => 'Sub total',
				headerAlign: 'right',
				accessor: 'final_price_with_iva',
				Cell: ({ row }) => {
					return (
						<div className="text-end">
							<span className="fw-medium">$ {row.original.final_price_with_iva.toFixed(2)}</span>
						</div>
					);
				},
			},
		],
		[]
	);

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb title="Detalle de pedido" pageTitle="Pedidos" goBackTo={'/orders'} />

				<Row className="flex-column-reverse flex-xl-row">
					<Col xl={9}>
						<Card>
							<CardHeader>
								<div className="d-flex align-items center">
									<h5 className="card-title flex-grow-1 mb-0">Productos</h5>
								</div>
							</CardHeader>

							<CardBody className="py-0">
								{!loadingOrderItems ? (
									<TableContainer
										columns={columns}
										data={orderItems}
										sortOptions={[{ id: 'description', desc: false }]}
										noDataMessage={'No hay productos disponibles para mostrar'}
										isGlobalFilter={false}
										isAddUserList={false}
										divClass="table-responsive table-card"
										tableClass="align-middle table-nowrap"
										theadClass="table-light text-muted"
										footerRows={
											<>
												<tr>
													<td colSpan="4" className="bg-light-subtle border-bottom-0" />

													<td className="bg-light-subtle border-bottom-dashed fw-semibold">
														Subtotal
													</td>

													<td className="bg-light-subtle border-bottom-dashed fw-semibold text-end">
														${' '}
														{(
															Number(orderData.final_price_with_iva || 0) +
															Number(
																orderData.global_discount_by_percentage_with_iva || 0
															)
														).toFixed(2)}
													</td>
												</tr>

												<tr>
													<td colSpan="4" className="bg-light-subtle border-bottom-0" />

													<td className="bg-light-subtle border-bottom-dashed fw-semibold">
														Descuento global{' '}
														<span className="text-muted">
															[
															{Number(orderData.global_percentage_discount || 2).toFixed(
																2
															)}
															%]
														</span>
													</td>

													<td className="bg-light-subtle border-bottom-dashed fw-semibold text-end">
														$ -
														{Number(
															orderData.global_discount_by_percentage_with_iva || 0
														).toFixed(2)}
													</td>
												</tr>

												<tr>
													<td colSpan="4" className="bg-light-subtle" />

													<td className="bg-light-subtle fw-semibold">TOTAL FINAL</td>

													<td className="bg-light-subtle fw-semibold text-end">
														$ {Number(orderData.final_price_with_iva || 0).toFixed(2)}
													</td>
												</tr>
											</>
										}
									/>
								) : (
									<div className="text-center py-2">
										<Spinner />
									</div>
								)}
							</CardBody>
						</Card>
					</Col>

					<Col xl={3}>
						<Card>
							<CardHeader>
								<h5 className="card-title mb-0">Detalles</h5>
							</CardHeader>

							<CardHeader>
								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Estado</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<OperationStatusBadge operationData={orderData} />
									</div>
								</div>

								<div className="d-flex align-items-center">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">ID Interno</p>
									</div>

									<div className="flex-grow-1 ms-2">{order_id}</div>
								</div>
							</CardHeader>

							<CardBody>
								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Cliente</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											<span className="text-muted">
												[{orderData?.is_a_new_client_id ? '-' : orderData?.client_id || '-'}]
											</span>{' '}
											{orderData?.client_legal_name || '-'}
										</h6>
									</div>
								</div>

								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Vendedor</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											<span className="text-muted">[{orderData?.sales_agent_id || '-'}]</span>{' '}
											{orderData?.sales_agent_name || '-'}
										</h6>
									</div>
								</div>

								<div className="d-flex align-items-center mb-2">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Fecha de entrega</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											<span>
												{moment(orderData?.deadline).format('DD/MM/YYYY')}

												<span className="text-muted ms-1">
													{moment(orderData?.deadline).format('HH:mm')}
												</span>
											</span>
										</h6>
									</div>
								</div>

								<div className="d-flex align-items-center">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Creado el</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">
											<span>
												{moment(orderData?.created_at).format('DD/MM/YYYY')}

												<span className="text-muted ms-1">
													{moment(orderData?.created_at).format('HH:mm')}
												</span>
											</span>
										</h6>
									</div>
								</div>
							</CardBody>

							<CardFooter>
								<div className="d-flex align-items-center">
									<div className="flex-shrink-0">
										<p className="text-muted mb-0">Observaciones:</p>
									</div>

									<div className="flex-grow-1 ms-2">
										<h6 className="mb-0">{orderData?.observations || '-'}</h6>
									</div>
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export { OrderDetails };
