import React, { useEffect, useState } from 'react';

import { useProfile } from '../components/hooks/UserHooks';

const menu_items_by_role_name = {
	company_owner: [
		{
			label: 'Pedidos',
			isHeader: true,
		},
		{
			id: 'orders',
			label: 'Pedidos',
			link: '/orders',
			icon: 'ri-shopping-cart-2-line',
		},

		{
			label: 'Cobranzas',
			isHeader: true,
		},
		{
			id: 'payment-collections',
			label: 'Cobranzas',
			link: '/payment-collections',
			icon: 'ri-hand-coin-line',
		},

		{
			label: 'Nuevos clientes',
			isHeader: true,
		},
		{
			id: 'new-clients',
			label: 'Nuevos clientes',
			link: '/new-clients',
			icon: 'ri-user-add-line',
		},

		{
			label: 'Geolocalización (Beta)',
			isHeader: true,
		},
		{
			id: 'tracking',
			label: 'Geolocalización (Beta)',
			link: '/tracking',
			icon: 'ri-map-pin-line',
		},

		// {
		// 	label: 'Usuarios',
		// 	isHeader: true,
		// },
		// {
		// 	id: 'users',
		// 	label: 'Usuarios',
		// 	link: '/users',
		// 	icon: 'ri-group-line',
		// },
	],
};

const LayoutMenuData = () => {
	const { userProfile } = useProfile();

	const [menuItems, setMenuItems] = useState([]);

	useEffect(() => {
		const role_name = userProfile?.user?.role_name || null;

		const menu_items = menu_items_by_role_name[role_name] || [];

		setMenuItems(menu_items);
	}, [userProfile]);

	return <>{menuItems}</>;
};
export { LayoutMenuData };
