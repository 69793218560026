import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	getCompanyConfigurations as getCompanyConfigurationsAPI,
	updateCompanyConfigurations as updateCompanyConfigurationsAPI,
} from '../../helpers/backend_helper';

export const getCompanyConfigurations = createAsyncThunk('company/getConfigurations', async () => {
	return getCompanyConfigurationsAPI();
});

export const updateCompanyConfigurations = createAsyncThunk('company/updateConfigurations', async data => {
	try {
		const response = await updateCompanyConfigurationsAPI(data);

		if (!response.success) {
			throw new Error(response);
		}

		toast.success('Edición de configuración realizada con éxito', { autoClose: 3000 });

		return data;
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error';

		toast.error(message, { autoClose: 3000 });
		throw error;
	}
});
