import { createSlice } from '@reduxjs/toolkit';

import { getAllNewClients } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	newClients: [],
	error: null,
};

const newClientsSlice = createSlice({
	name: 'newClients',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getAllNewClients.fulfilled, (state, action) => {
			state.newClients = action.payload?.data?.new_clients || [];
		});

		builder.addCase(getAllNewClients.rejected, (state, action) => {
			state.error = action?.error || null;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default newClientsSlice.reducer;
