import React, { useEffect, useState, useMemo } from 'react';
import { Card, CardBody, Container } from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getAllNewClients as onGetAllNewClients } from '../../slices/thunks';
import TableContainer from '../../components/common/TableContainer';
import BreadCrumb from '../../components/common/BreadCrumb';
import OperationStatusBadge from '../../components/atoms/OperationStatusBadge';

const NewClients = () => {
	document.title = 'Nuevos clientes';

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const selectNewClients = createSelector(
		state => state.NewClients,
		state => ({
			newClients: state.newClients,
			error: state.error,
		})
	);

	const { newClients } = useSelector(selectNewClients);

	const [newClientsList, setNewClientsList] = useState([]);

	useEffect(() => {
		setNewClientsList(newClients);
	}, [newClients]);

	useEffect(() => {
		if (!newClients?.length) {
			dispatch(onGetAllNewClients());
		}
	}, []);

	const goToNewClientDetails = new_client_id => {
		navigate(`/new-client/${new_client_id}`);
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Nombre',
				accessor: 'first_name',
				filterable: false,
				Cell: ({ row }) => {
					return row.original.first_name;
				},
			},
			{
				Header: 'Apellido',
				accessor: 'last_name',
				filterable: false,
				Cell: ({ row }) => {
					return row.original.last_name;
				},
			},
			{
				Header: 'Domicilio',
				accessor: 'address',
				filterable: false,
				Cell: ({ row }) => {
					return row.original.address;
				},
			},
			{
				Header: 'Localidad',
				accessor: 'locality',
				filterable: false,
				Cell: ({ row }) => {
					return row.original.locality;
				},
			},
			{
				Header: 'Teléfono',
				accessor: 'phone',
				filterable: false,
				Cell: ({ row }) => {
					if (row.original.phone_prefix) {
						return (
							<span>
								<span className="text-muted">+{row.original.phone_country_code}</span>{' '}
								{row.original.phone_prefix} {row.original.phone_line_number}
							</span>
						);
					}

					return '—';
				},
			},
			{
				Header: 'Vendedor asociado',
				accessor: 'sales_agent_id',
				filterable: false,
				Cell: ({ row }) => {
					if (row.original.sales_agent_id !== null) {
						return (
							<span>
								<span className="text-muted">[{row.original.sales_agent_id}]</span>{' '}
								{row.original.sales_agent_name}
							</span>
						);
					}

					return '—';
				},
			},
			{
				Header: 'Creado el',
				accessor: 'created_at',
				Cell: ({ row }) => {
					const date = moment(row.original.created_at).format('DD/MM/YYYY');
					const time = moment(row.original.created_at).format('HH:mm');

					return (
						<span>
							{date}
							<small className="text-muted ms-2">{time}</small>
						</span>
					);
				},
			},
			{
				Header: 'Estado',
				accessor: 'status',
				style: {
					width: 150,
				},
				Cell: ({ row }) => {
					return <OperationStatusBadge operationData={row.original} />;
				},
			},
		],
		[]
	);

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb title="Nuevos clientes" pageTitle="Nuevos clientes" />

				<Card>
					<CardBody className="pt-0">
						<TableContainer
							columns={columns}
							data={newClientsList || []}
							sortOptions={[{ id: 'created_at', desc: true }]}
							onRowClick={row => goToNewClientDetails(row.original.new_client_id)}
							noDataMessage={'No hay nuevos clientes disponibles para mostrar'}
							isGlobalFilter={false}
							isAddUserList={false}
							customPageSize={10}
							divClass="table-responsive table-card mb-1"
							tableClass="align-middle table-nowrap"
							theadClass="table-light text-muted"
							footerComment={
								<span>
									Nuevos clientes de los <b>últimos 7 días</b>
								</span>
							}
						/>
					</CardBody>
				</Card>
			</Container>

			<ToastContainer closeButton={false} />
		</div>
	);
};

export { NewClients };
