import React, { useRef, useEffect } from 'react';

const AdvancedMapMarker = ({
	mapRef,
	id,
	position,
	infoWindowContent,
	focusedElement,
	setFocusedElement,
	setHoveredElement,
	label = null,
	backgroundColor = 'white',
	borderColor = '#054AB4',
	iconName = 'ri-flag-fill',
	iconSize = 18,
	iconColor = '#054AB4',
	textColor = '#054AB4',
}) => {
	const markerRef = useRef();

	useEffect(() => {
		const marker_content_div = document.createElement('div');

		marker_content_div.innerHTML = `
			<div class="marker-content-div" style="background-color: ${backgroundColor}; border: solid 1.5px ${borderColor}; color: ${textColor};">
				<div class="d-flex justify-content-center align-items-center gap-1">
					<i class="${iconName}" style="font-size: ${iconSize}px; color: ${iconColor}"></i>
					${label ?? ''}
				</div>
			</div>

			<div class="marker-content-div-triangle">
				<svg viewBox="-0.75 -3.5 20.75 15.90" width="16px" height="12.25px" xmlns="http://www.w3.org/2000/svg"><defs><bx:export><bx:file format="svg" /></bx:export><bx:guide x="9.60552991161127" y="12.682402164894462" angle="0"/></defs><path style="fill: ${backgroundColor}; stroke: ${backgroundColor}; stroke-width: 1.5px;" d="M 0 -1.435 L 19.309 -1.435"/><path style="fill: ${backgroundColor}; stroke: ${backgroundColor}; stroke-width: 1.5px;" d="M 0.025 0.027 L 19.334 0.027"/><path style="stroke-linejoin: round; stroke: ${borderColor}; fill: ${backgroundColor}; stroke-linecap: round; stroke-width: 1.5px;" d="M 19.23 0 L 9.606 11.655 L 0 0.027"/><path style="fill: ${backgroundColor}; stroke: ${backgroundColor}; stroke-width: 1.5px;" d="M 0 -2.769 L 19.309 -2.769"/></svg>
			</div>
		`;

		const advanced_marker = new window.google.maps.marker.AdvancedMarkerElement({
			content: marker_content_div,
			gmpClickable: true,
		});

		// On click
		advanced_marker.addListener('click', () => {
			setFocusedElement({ type: 'marker', id });
		});

		// On mouse over
		advanced_marker.content.addEventListener('mouseenter', () => {
			setHoveredElement({ type: 'marker', id });
		});

		// On mouse out
		advanced_marker.content.addEventListener('mouseleave', () => {
			setHoveredElement({ type: null, id: null });
		});

		markerRef.current = advanced_marker;

		return () => {
			advanced_marker.map = null;
		};
	}, []);

	useEffect(() => {
		if (!markerRef?.current) {
			return;
		}

		if (!mapRef?.current) {
			return;
		}

		if (!position?.lat || !position?.lng) {
			return;
		}

		markerRef.current.map = mapRef.current.map;
		markerRef.current.position = position;
	}, [mapRef, position]);

	useEffect(() => {
		if (focusedElement?.id === id) {
			openInfoWindow();
		}
	}, [focusedElement]);

	const openInfoWindow = () => {
		if (!infoWindowContent) {
			return;
		}

		mapRef.current.infoWindow.close();
		mapRef.current.infoWindow.setHeaderDisabled(true);
		mapRef.current.infoWindow.setContent(infoWindowContent);
		mapRef.current.infoWindow.setOptions({ minWidth: 240, maxWidth: 350 });
		mapRef.current.infoWindow.open(markerRef.current.map, markerRef.current);
	};

	return <></>;
};

export default AdvancedMapMarker;
