import React from 'react';
import { Input } from 'reactstrap';

export const Filter = ({ column }) => {
	return <div style={{ marginTop: 5 }}>{column.canFilter && column.render('Filter')}</div>;
};

export const DefaultColumnFilter = ({
	column: {
		filterValue,
		setFilter,
		preFilteredRows: { length },
	},
}) => {
	return (
		<Input
			value={filterValue || ''}
			onChange={e => {
				setFilter(e.target.value || undefined);
			}}
			placeholder={`search (${length}) ...`}
		/>
	);
};

export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
	const options = React.useMemo(() => {
		const new_options = new Set();

		preFilteredRows.forEach(row => {
			new_options.add(row.values[id]);
		});

		return [...new_options.values()];
	}, [id, preFilteredRows]);

	return (
		<select
			id="custom-select"
			className="form-select"
			value={filterValue}
			onChange={e => {
				setFilter(e.target.value || undefined);
			}}>
			<option value="">All</option>
			{options.map(option => (
				<option key={option} value={option}>
					{option}
				</option>
			))}
		</select>
	);
};
