/* eslint-disable no-alert */
import React, { useEffect, useState } from 'react';
import {
	Container,
	Form,
	Row,
	Col,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Modal,
	ModalFooter,
	ModalHeader,
	ModalBody,
	Label,
	Spinner,
	Input,
	Button,
} from 'reactstrap';

import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate, Link } from 'react-router-dom';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import {
	addObservations as onAddObservations,
	addNewOrder as onAddNewOrder,
	addDeadline as onAddDeadline,
} from '../../slices/thunks';

import BreadCrumb from '../../components/common/BreadCrumb';

const phoneRegex =
	/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const Checkout = () => {
	document.title = 'Checkout';

	const dispatch = useDispatch();
	const navigate = useNavigate();

	// * Client order filters
	const selectClientOrderFilters = createSelector(
		state => state.ClientOrderFilters,
		state => ({
			client_order_filters: state.client_order_filters,
		})
	);

	const { client_order_filters } = useSelector(selectClientOrderFilters);

	// * Addresses
	const [isClientAddressCreateModalOpen, setIsClientAddressCreateModalOpen] = useState(false);
	const [isClientAddressDeleteModalOpen, setIsClientAddressDeleteModalOpen] = useState(false);

	const [clientAddresses, setClientAddresses] = useState([]);
	const [loadingClientAddressCreation, setLoadingClientAddressCreation] = useState(false);

	const toggleClientAddressCreateModal = () => {
		if (isClientAddressCreateModalOpen) {
			validation.resetForm();
			setLoadingClientAddressCreation(false);
			setIsClientAddressCreateModalOpen(false);
		} else {
			setIsClientAddressCreateModalOpen(true);
		}
	};

	const toggleClientAddressDeleteModal = () => {
		setIsClientAddressDeleteModalOpen(!isClientAddressDeleteModalOpen);
	};

	const selectClientAddresses = createSelector(
		state => state.ClientAddresses,
		state => ({
			client_addresses: state?.client_addresses,
			error: state?.error,

			isClientAddressAdded: state?.isClientAddressAdded,
			isClientAddressAddedFail: state?.isClientAddressAddedFail,
			isClientAddressDelete: state?.isClientAddressDelete,
			isClientAddressDeleteFail: state?.isClientAddressDeleteFail,
		})
	);

	const { client_addresses, isClientAddressAdded, isClientAddressAddedFail } = useSelector(selectClientAddresses);

	useEffect(() => {
		setClientAddresses(client_addresses);
	}, [client_addresses]);

	// Get all client addresses list
	useEffect(() => {
		if (!client_addresses?.length) {
			// TODO dispatch(onGetAllClientAddresses());
		}
	}, []);

	// On create error
	useEffect(() => {
		if (isClientAddressAddedFail) {
			setLoadingClientAddressCreation(false);
		}
	}, [isClientAddressAddedFail]);

	// On create success
	useEffect(() => {
		if (isClientAddressAdded) {
			toggleClientAddressCreateModal();
		}
	}, [isClientAddressAdded]);

	// * Validate
	const validation = useFormik({
		// enableReinitialize, use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			city: '',
			postal_code: '',
			state: '',
			observations: '',
			phone: '',
		},

		validationSchema: Yup.object({
			city: Yup.string().required('Ingresar ciudad'),
			postal_code: Yup.string().required('Ingresar código postal'),
			state: Yup.string().required('Ingresar provincia'),
			phone: Yup.string().required('Ingresar teléfono.').matches(phoneRegex, 'Ingresar un teléfono valido.'),
		}),

		onSubmit: values => {
			setLoadingClientAddressCreation(true);

			const city = values.city?.toLowerCase().trim() || null;
			const postal_code = city ? values.postal_code?.trim() : null;
			const state = city ? values.state?.trim() : null;
			const country = city ? 'argentina' : null;

			const sanitized_phone = values.phone.replace(/\s/g, '').replace('+', '');
			const phone = sanitized_phone ? `549${sanitized_phone}` : null;

			const observations = values.observations?.trim().replace(/\s+/g, ' ') || null;

			const data = {
				city,
				postal_code,
				state,
				country,
				phone,
				observations,
			};

			// console.log(data);

			// dispatch(onAddNewUser(data));
		},
	});

	// * New order data
	const selectNewOrder = createSelector(
		state => state.NewOrder,
		state => ({
			cart_items: state.cart_items,
			deadline: state.deadline,
			observations: state.observations,
		})
	);

	// eslint-disable-next-line no-unused-vars
	const { cart_items, deadline, observations } = useSelector(selectNewOrder);

	useEffect(() => {
		if (!cart_items.length) {
			navigate('/');
		}
	}, [cart_items]);

	const saveObservations = event => {
		dispatch(onAddObservations(event.target.value));
	};

	const getTotalProductPrice = cart_item => {
		const {
			quantity,
			product_data: { unit_price_with_iva_and_discount },
		} = cart_item;

		return Number(quantity) * Number(unit_price_with_iva_and_discount);
	};

	const getTotalPrice = () => {
		let total_price = 0;

		cart_items.forEach(item => {
			total_price += Number(getTotalProductPrice(item));
		});

		return total_price;
	};

	// * Send new order
	const [sendOrderDisabled, setSendOrderDisabled] = useState(false);

	// In order to avoid click on "Send order" after click on "Continue" button of Checkout page
	// Disable button for a few seconds
	useEffect(() => {
		setSendOrderDisabled(true);

		setTimeout(() => {
			setSendOrderDisabled(false);
		}, 2500);
	}, []);

	const selectClientOrder = createSelector(
		state => state.ClientOrders,
		state => ({
			sending_client_order: state.sending_client_order,
		})
	);

	const { sending_client_order } = useSelector(selectClientOrder);

	const verifyOrder = () => {
		if (!deadline) {
			alert('Debe establecer una fecha de entrega.');
			return;
		}

		if (!cart_items?.length) {
			alert('No hay productos en el carrito.');
			return;
		}

		sendNewOrder();
	};

	const sendNewOrder = async () => {
		const cleaned_cart_items = cart_items.map(item => {
			const { product_data, quantity } = item;

			const {
				product_id,
				description,
				unit_type_id,
				quantity_per_unit,
				iva,
				unit_price_without_iva,
				discount,
				unit_price_with_iva_and_discount,
			} = product_data;

			const final_price_with_iva = quantity * unit_price_with_iva_and_discount;

			const { price_list_id, name: price_list_name } = client_order_filters.price_list;

			const unit_type_data = client_order_filters.unit_types?.find(unit_type => {
				// eslint-disable-next-line eqeqeq
				return unit_type.unit_type_id == unit_type_id;
			});

			return {
				product_id: Number(product_id),
				description,
				quantity: Number(quantity),
				discount: Number(discount),
				price_list_id: Number(price_list_id),
				price_list_name,
				unit_type_name: unit_type_data.name,
				quantity_per_unit: Number(quantity_per_unit),
				iva: Number(iva),
				unit_price_without_iva,
				final_price_with_iva,
				observations: null,
			};
		});

		// * Total prices
		let final_price_without_iva = 0;
		let final_price_with_iva = 0;
		let total_discount_with_iva = 0;

		cleaned_cart_items.forEach(item => {
			final_price_without_iva += item.unit_price_without_iva * item.quantity;
			final_price_with_iva += item.final_price_with_iva;
		});

		cart_items.forEach(item => {
			total_discount_with_iva += item.product_data.unit_discount_with_iva;
		});

		// * User data
		const { client_id, legal_name } = client_order_filters.client;

		const { sales_agent_id, name: sales_agent_name } = client_order_filters.sales_agent;

		const cleaned_observations = observations?.trim().replace(/\s+/g, ' ') || null;

		const order_data = {
			initial_status: 'confirmed',
			order: {
				client_id: Number(client_id),
				client_legal_name: legal_name,
				sales_agent_id: Number(sales_agent_id),
				sales_agent_name,
				observations: cleaned_observations,
				deadline: moment(deadline).format('YYYY-MM-DDTHH:mm:ss.SSS'),
				picked_items: cleaned_cart_items,
				final_price_without_iva,
				final_price_with_iva,
				total_discount_with_iva,
			},
		};

		dispatch(onAddNewOrder({ data: order_data, navigate }));
	};

	// * Deadline
	useEffect(() => {
		if (!deadline) {
			const default_deadline_date = moment().add(1, 'day').toDate();
			dispatch(onAddDeadline(default_deadline_date));
		}
	}, []);

	const saveDeadlineDate = date => {
		if (!date) {
			dispatch(onAddDeadline(null));
			return;
		}

		let new_deadline = date;

		if (deadline) {
			// Fusion with Time
			const deadline_date = moment(deadline);
			const new_date = moment(date);

			const time_deadline_date = deadline_date.format('HH:mm:ss.SSS');
			new_deadline = new Date(`${new_date.format('YYYY-MM-DD')}T${time_deadline_date}`);
		} else {
			// If is the first time selecting date, put time to: 00:00:00.000
			const new_date = moment(date).toDate();

			new_date.setHours(0);
			new_date.setMinutes(0);
			new_date.setSeconds(0);
			new_date.setMilliseconds(0);

			new_deadline = new Date(new_date);
		}

		dispatch(onAddDeadline(new_deadline));
	};

	const saveDeadlineTime = time => {
		if (!time) {
			return;
		}

		let new_deadline = deadline;

		if (deadline) {
			// Fusion with Date
			const deadline_date = moment(deadline);

			if (!time) {
				new_deadline = deadline_date.clone().set({
					hour: 0,
					minute: 0,
					second: 0,
					millisecond: 0,
				});
			} else {
				const [hours, minutes] = time.split(':');

				new_deadline = deadline_date.clone().set({
					hour: hours,
					minute: minutes,
					second: 0,
					millisecond: 0,
				});
			}
		}

		dispatch(onAddDeadline(new_deadline));
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<BreadCrumb title="Checkout" pageTitle="Nuevo pedido" goBackTo={'/cart'} />

					<Row>
						<Col xl="8" className="new-order-mobile-bottom-padding">
							<Form action="#">
								<Card>
									<CardHeader className="pb-0">
										<h5 className="mb-1">Información de entrega</h5>
										<p className="text-muted">Seleccione la fecha (estimativa).</p>
									</CardHeader>

									<CardBody className="checkout-tab">
										<Row>
											<Col lg={5} sm={12}>
												<div>
													<Label htmlFor="deadline-date-field" className="form-label">
														Fecha de entrega
													</Label>

													<Flatpickr
														id="deadline-date-field"
														className="form-control"
														options={{
															minDate: new Date(),
															defaultDate: moment().add(1, 'day').toDate(),
															dateFormat: 'd/m/Y',
															locale: Spanish,
														}}
														onChange={([date]) => {
															saveDeadlineDate(date);
														}}
														style={{ fontSize: 14 }}
														title="Modificar fecha de entrega"
													/>
												</div>
											</Col>

											<Col lg={5} sm={12} className="d-none">
												<div className="mb-3">
													<Label htmlFor="deadline-time-field" className="form-label">
														Hora de entrega
													</Label>

													<Input
														id="deadline-time-field"
														type="time"
														className="form-control"
														onChange={event => saveDeadlineTime(event.target.value)}
													/>
												</div>
											</Col>
										</Row>

										{/* <Label htmlFor="delivery-address-field" className="form-label flex-grow-1 mt-4 mb-2">
											Dirección de entrega
										</Label>

										<Row className="gy-3">
											<Col lg={5} sm={12}>
												<div className="form-check card-radio">
													<Input
														id="shippingAddress01"
														name="shippingAddress"
														type="radio"
														className="form-check-input"
														defaultChecked
													/>

													<Label
														className="form-check-label shipping-address-card"
														htmlFor="shippingAddress01">
														<span className="fs-14 mb-2 d-block">
															{client_order_filters?.client?.address}
														</span>

														<span className="text-muted fw-normal text-wrap mb-1 d-block">
															{client_order_filters?.client?.city}
														</span>

														<span className="text-muted fw-normal text-wrap mb-1 d-block">
															{client_order_filters?.client?.state},{' '}
															{client_order_filters?.client?.postal_code}
														</span>

														<span className="text-muted fw-normal d-block">
															Tel.: {client_order_filters?.client?.phone || '-'}
														</span>

														<div className="mt-2">
															<p className="text-muted m-0">Dirección primaria</p>
														</div>
													</Label>
												</div>
											</Col>

											{clientAddresses?.map(item => (
												<Col key={item.client_address_id} lg={5} sm={12}>
													<div className="form-check card-radio">
														<Input
															id="shippingAddress02"
															name="shippingAddress"
															type="radio"
															className="form-check-input"
														/>

														<Label
															className="form-check-label shipping-address-card"
															htmlFor="shippingAddress02">
															<span className="fs-14 mb-2 d-block">{item.address}</span>

															<span className="text-muted fw-normal text-wrap mb-1 d-block">
																{item.city}
															</span>

															<span className="text-muted fw-normal d-block">
																{item.state}, {item.postal_code}
															</span>

															<div className="mt-2">
																<Link
																	to="#"
																	className="text-danger"
																	onClick={toggleClientAddressDeleteModal}>
																	<i className="ri-delete-bin-fill align-bottom fs-14 me-1"></i>
																	Eliminar
																</Link>
															</div>
														</Label>
													</div>
												</Col>
											)) || null}

											<Col
												lg={2}
												sm={12}
												className="d-flex justify-content-center justify-content-lg-start align-items-center">
												<Button
													onClick={toggleClientAddressCreateModal}
													color="info"
													outline
													className="btn-soft-info btn-icon rounded-pill fs-18">
													<i className="ri-add-line" />
												</Button>
											</Col>
										</Row> */}
									</CardBody>
								</Card>

								<Card>
									<CardBody>
										<div>
											<h5 className="mb-1">Observaciones</h5>
											<p className="text-muted mb-3">
												Se valorarán y considerarán los comentarios proporcionados al elaborar
												el pedido.
											</p>
										</div>

										<div>
											<textarea
												className="form-control"
												id="billing-info-address"
												placeholder="Opcional"
												value={observations}
												onChange={saveObservations}
												rows="6"
											/>
										</div>
									</CardBody>
								</Card>
							</Form>
						</Col>

						<Col xl={4}>
							<div className="sticky-side-checkout-div">
								<Card className="mb-0">
									<CardHeader className="border-bottom-dashed">
										<h5 className="card-title mb-0">Resumen</h5>
									</CardHeader>

									<CardBody className="pt-0 pb-0">
										<div className="d-flex w-100 justify-content-between fs-17 my-3">
											<div>
												Total
												<div className="fs-13">({cart_items.length} items)</div>
											</div>

											<div className="d-flex align-items-center">
												$ {getTotalPrice()?.toFixed(2)}
											</div>
										</div>
									</CardBody>

									<CardFooter>
										<div className="">
											<Button
												onClick={verifyOrder}
												className="btn btn-success btn-label right ms-auto fs-16 w-100 px-0"
												style={{
													height: 50,
												}}
												disabled={sending_client_order || sendOrderDisabled}
												title="Finalizar pedido">
												{sending_client_order ? (
													<Spinner size="sm" color="gradient" />
												) : (
													<>
														<i
															className="ri-checkbox-circle-line label-icon align-bottom fs-20 ms-2"
															style={{ width: 50 }}></i>
														Finalizar pedido
													</>
												)}
											</Button>
										</div>
									</CardFooter>
								</Card>
							</div>
						</Col>
					</Row>
				</Container>
			</div>

			{/* Modal delete address */}
			<Modal
				isOpen={isClientAddressDeleteModalOpen}
				role="dialog"
				autoFocus={true}
				centered
				id="removeItemModal"
				toggle={toggleClientAddressDeleteModal}>
				<ModalHeader toggle={toggleClientAddressDeleteModal} />

				<ModalBody>
					<div className="mt-2 text-center">
						<lord-icon
							src="https://cdn.lordicon.com/gsqxdxog.json"
							trigger="loop"
							colors="primary:#f7b84b,secondary:#f06548"
							style={{ width: '100px', height: '100px' }}></lord-icon>

						<div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
							<h4>Eliminar dirección de entrega</h4>

							<p className="text-muted mx-4 mb-0">¿Esta seguro que desea eliminarla?</p>
						</div>
					</div>

					<div className="d-flex gap-2 justify-content-center mt-4 mb-2">
						<button type="button" className="btn w-sm btn-light" onClick={toggleClientAddressDeleteModal}>
							Cerrar
						</button>

						<button type="button" className="btn w-sm btn-danger" onClick={toggleClientAddressDeleteModal}>
							Eliminar
						</button>
					</div>
				</ModalBody>
			</Modal>

			{/* Modal add address */}
			<Modal
				isOpen={isClientAddressCreateModalOpen}
				role="dialog"
				autoFocus={true}
				centered
				id="addAddressModal"
				toggle={toggleClientAddressCreateModal}>
				<ModalHeader toggle={toggleClientAddressCreateModal} className="bg-info-subtle p-3">
					<h5 className="modal-title" id="addAddressModalLabel">
						Nueva dirección de entrega
					</h5>
				</ModalHeader>

				<Form
					className="tablelist-form"
					onSubmit={e => {
						e.preventDefault();
						validation.handleSubmit();
						return false;
					}}>
					<ModalBody>
						<div>
							<div className="mb-3">
								<Label for="add-address-Name" className="form-label">
									Nombre
								</Label>

								<Input
									type="text"
									className="form-control"
									id="add-address-Name"
									placeholder="Nombre"
									maxLength={50}
								/>
							</div>

							<div className="mb-3">
								<Label for="add-address-textarea" className="form-label">
									Dirección
								</Label>

								<textarea
									className="form-control"
									id="add-address-textarea"
									placeholder="Dirección"
									rows="2"></textarea>
							</div>

							<div className="mb-3">
								<Label for="add-address-Name" className="form-label">
									Teléfono
								</Label>

								<Input
									type="text"
									className="form-control"
									id="add-address-Name"
									placeholder="Teléfono"
								/>
							</div>
						</div>
					</ModalBody>

					<ModalFooter>
						<button
							type="button"
							className="btn btn-light"
							onClick={toggleClientAddressCreateModal}
							disabled={loadingClientAddressCreation}>
							Cerrar
						</button>

						<button
							type="button"
							className="btn btn-success"
							onClick={toggleClientAddressCreateModal}
							disabled={loadingClientAddressCreation}>
							{loadingClientAddressCreation ? <Spinner size="sm" className="mx-2" /> : 'Crear'}
						</button>
					</ModalFooter>
				</Form>
			</Modal>

			<ToastContainer closeButton={false} />
		</React.Fragment>
	);
};

export { Checkout };
