/* eslint-disable indent */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
	useTable,
	useGlobalFilter,
	useAsyncDebounce,
	useSortBy,
	useFilters,
	useExpanded,
	usePagination,
	useRowSelect,
} from 'react-table';
import { Table, Row, Col, Button, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { DefaultColumnFilter } from './filters';
import {
	ProductsGlobalFilter,
	CustomersGlobalFilter,
	OrderGlobalFilter,
	ContactsGlobalFilter,
	CompaniesGlobalFilter,
	LeadsGlobalFilter,
	CryptoOrdersGlobalFilter,
	InvoiceListGlobalSearch,
	TicketsListGlobalFilter,
	NFTRankingGlobalFilter,
	TaskListGlobalFilter,
} from './GlobalSearchFilter';

// Define a default UI for filtering
function GlobalFilter({
	globalFilter,
	setGlobalFilter,
	isCustomerFilter,
	isOrderFilter,
	isContactsFilter,
	isCompaniesFilter,
	isCryptoOrdersFilter,
	isInvoiceListFilter,
	isTicketsListFilter,
	isNFTRankingFilter,
	isTaskListFilter,
	isProductsFilter,
	isLeadsFilter,
	SearchPlaceholder,
}) {
	const [value, setValue] = React.useState(globalFilter);

	const onChange = useAsyncDebounce(new_value => {
		setGlobalFilter(new_value || undefined);
	}, 200);

	return (
		<React.Fragment>
			<CardBody className="border border-dashed border-end-0 border-start-0">
				<form>
					<Row>
						<Col sm={5}>
							<div
								className={
									isProductsFilter || isContactsFilter || isCompaniesFilter || isNFTRankingFilter
										? 'search-box me-2 mb-2 d-inline-block'
										: 'search-box me-2 mb-2 d-inline-block col-12'
								}>
								<input
									onChange={e => {
										setValue(e.target.value);
										onChange(e.target.value);
									}}
									id="search-bar-0"
									type="text"
									className="form-control border-0 search /"
									placeholder={SearchPlaceholder}
									value={value || ''}
								/>
								<i className="bx bx-search-alt search-icon"></i>
							</div>
						</Col>
						{isProductsFilter && <ProductsGlobalFilter />}
						{isCustomerFilter && <CustomersGlobalFilter />}
						{isOrderFilter && <OrderGlobalFilter />}
						{isContactsFilter && <ContactsGlobalFilter />}
						{isCompaniesFilter && <CompaniesGlobalFilter />}
						{isLeadsFilter && <LeadsGlobalFilter />}
						{isCryptoOrdersFilter && <CryptoOrdersGlobalFilter />}
						{isInvoiceListFilter && <InvoiceListGlobalSearch />}
						{isTicketsListFilter && <TicketsListGlobalFilter />}
						{isNFTRankingFilter && <NFTRankingGlobalFilter />}
						{isTaskListFilter && <TaskListGlobalFilter />}
					</Row>
				</form>
			</CardBody>
		</React.Fragment>
	);
}

const TableContainer = ({
	columns,
	data,
	footerRows,
	onRowClick,
	noDataMessage,
	isGlobalSearch,
	isGlobalFilter,
	isProductsFilter,
	isCustomerFilter,
	isOrderFilter,
	isContactsFilter,
	isCompaniesFilter,
	isLeadsFilter,
	isCryptoOrdersFilter,
	isInvoiceListFilter,
	isTicketsListFilter,
	isNFTRankingFilter,
	isTaskListFilter,
	isAddOptions,
	isAddUserList,
	handleOrderClicks,
	handleUserClick,
	handleCustomerClick,
	isAddCustList,
	customPageSize,
	tableClass,
	theadClass,
	trClass,
	thClass,
	divClass,
	SearchPlaceholder,
	footerComment,
	sortOptions,
}) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			defaultColumn: { Filter: DefaultColumnFilter },
			useFlexLayout: true,
			initialState: {
				pageIndex: 0,
				pageSize: customPageSize || 10000000,
				selectedRowIds: 0,
				sortBy: sortOptions || [
					{
						desc: true,
					},
				],
			},
		},
		useGlobalFilter,
		useFilters,
		useSortBy,
		useExpanded,
		usePagination,
		useRowSelect
	);

	const generateSortingIndicator = column => {
		if (!column.isSorted) {
			return null;
		}

		if (column.isSortedDesc) {
			return <i className="ri-arrow-down-line ms-2 text-body-emphasis" />;
		}

		return <i className="ri-arrow-up-line ms-2 text-body-emphasis" />;
	};

	const onChangeInSelect = event => {
		setPageSize(Number(event.target.value));
	};

	return (
		<Fragment>
			<Row className="mb-3">
				{isGlobalSearch && (
					<Col md={1}>
						<select className="form-select" value={pageSize} onChange={onChangeInSelect}>
							{[10, 20, 30, 40, 50].map(pageSizeValue => (
								<option key={pageSizeValue} value={pageSizeValue}>
									Show {pageSizeValue}
								</option>
							))}
						</select>
					</Col>
				)}
				{isGlobalFilter && (
					<GlobalFilter
						preGlobalFilteredRows={preGlobalFilteredRows}
						globalFilter={state.globalFilter}
						setGlobalFilter={setGlobalFilter}
						isProductsFilter={isProductsFilter}
						isCustomerFilter={isCustomerFilter}
						isOrderFilter={isOrderFilter}
						isContactsFilter={isContactsFilter}
						isCompaniesFilter={isCompaniesFilter}
						isLeadsFilter={isLeadsFilter}
						isCryptoOrdersFilter={isCryptoOrdersFilter}
						isInvoiceListFilter={isInvoiceListFilter}
						isTicketsListFilter={isTicketsListFilter}
						isNFTRankingFilter={isNFTRankingFilter}
						isTaskListFilter={isTaskListFilter}
						SearchPlaceholder={SearchPlaceholder}
					/>
				)}
				{isAddOptions && (
					<Col sm="7">
						<div className="text-sm-end">
							<Button
								type="button"
								color="success"
								className="rounded-pill  mb-2 me-2"
								onClick={handleOrderClicks}>
								<i className="mdi mdi-plus me-1" />
								Add New Order
							</Button>
						</div>
					</Col>
				)}
				{isAddUserList && (
					<Col sm="7">
						<div className="text-sm-end">
							<Button type="button" color="primary" className="btn mb-2 me-2" onClick={handleUserClick}>
								<i className="mdi mdi-plus-circle-outline me-1" />
								Create New User
							</Button>
						</div>
					</Col>
				)}
				{isAddCustList && (
					<Col sm="7">
						<div className="text-sm-end">
							<Button
								type="button"
								color="success"
								className="rounded-pill mb-2 me-2"
								onClick={handleCustomerClick}>
								<i className="mdi mdi-plus me-1" />
								New Customers
							</Button>
						</div>
					</Col>
				)}
			</Row>

			<div className={divClass}>
				<Table hover {...getTableProps()} className={tableClass}>
					<thead className={theadClass}>
						{headerGroups.map(headerGroup => (
							<tr className={trClass} key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => (
									<th key={column.id} className={thClass} {...column.getSortByToggleProps()}>
										<div
											className={classnames(
												'd-flex',
												{ 'justify-content-start': column?.headerAlign === 'left' },
												{ 'justify-content-center': column?.headerAlign === 'center' },
												{ 'justify-content-end': column?.headerAlign === 'right' }
											)}>
											{column.render('Header')}
											{generateSortingIndicator(column)}
											{/* <Filter column={column} /> */}
										</div>
									</th>
								))}
							</tr>
						))}
					</thead>

					<tbody {...getTableBodyProps()}>
						{page.length ? (
							page.map(row => {
								prepareRow(row);

								return (
									<tr
										key={row.getRowProps().key}
										onClick={() => onRowClick && onRowClick(row)}
										className={onRowClick ? 'cursor-pointer' : null}
										{...row.getRowProps()}>
										{row.cells.map(cell => {
											return (
												<td key={cell.id} style={cell.column?.style} {...cell.getCellProps()}>
													{cell.render('Cell')}
												</td>
											);
										})}
									</tr>
								);
							})
						) : (
							<tr>
								<td
									colSpan={columns?.length || 7}
									className="text-center fs-14 border-0 pt-5 pb-5"
									style={{ pointerEvents: 'none' }}>
									{noDataMessage || 'No hay registros disponibles para mostrar'}
								</td>
							</tr>
						)}
					</tbody>

					{footerRows ? <tfoot className="table-light">{footerRows}</tfoot> : null}
				</Table>
			</div>

			{customPageSize ? (
				<Row className="align-items-center mt-2 g-3 text-center text-sm-start">
					<div className="col-sm">
						{footerComment ? <div className="text-muted">{footerComment}</div> : null}

						<div className="text-muted">
							<span className="fw-semibold">{page.length}</span> de{' '}
							<span className="fw-semibold">{data.length}</span> resultados
						</div>
					</div>

					<div className="col-sm-auto">
						<ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
							<li className={!canPreviousPage ? 'page-item disabled' : 'page-item'}>
								<Link to="#" className="page-link" onClick={previousPage}>
									Atrás
								</Link>
							</li>

							{pageOptions.map((item, key) => (
								<React.Fragment key={key}>
									<li className="page-item">
										<Link
											to="#"
											className={pageIndex === item ? 'page-link active' : 'page-link'}
											onClick={() => gotoPage(item)}>
											{item + 1}
										</Link>
									</li>
								</React.Fragment>
							))}

							<li className={!canNextPage ? 'page-item disabled' : 'page-item'}>
								<Link to="#" className="page-link" onClick={nextPage}>
									Siguiente
								</Link>
							</li>
						</ul>
					</div>
				</Row>
			) : null}
		</Fragment>
	);
};

TableContainer.propTypes = {
	preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
