import {
	addCartItem as addCartItemAction,
	removeCartItem as removeCartItemAction,
	addProductItem as addProductItemAction,
	removeProductItem as removeProductItemAction,
	addDeadline as addDeadlineAction,
	addObservations as addObservationsAction,
	cleanNewOrder as cleanNewOrderAction,
} from './reducer';

export const addCartItem = product => async dispatch => {
	dispatch(addCartItemAction(product));
};

export const removeCartItem = product_id => async dispatch => {
	dispatch(removeCartItemAction(product_id));
};

export const addProductItem = product_id => async dispatch => {
	dispatch(addProductItemAction(product_id));
};

export const removeProductItem = product_id => async dispatch => {
	dispatch(removeProductItemAction(product_id));
};

export const addDeadline = deadline => async dispatch => {
	dispatch(addDeadlineAction(deadline));
};

export const addObservations = observations => async dispatch => {
	dispatch(addObservationsAction(observations));
};

export const cleanNewOrder = () => async dispatch => {
	dispatch(cleanNewOrderAction());
};
