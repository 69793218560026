import { createSlice } from '@reduxjs/toolkit';
// constants
import {
	layoutTypes,
	leftSidebarTypes,
	layoutModeTypes,
	layoutWidthTypes,
	layoutPositionTypes,
	topbarThemeTypes,
	leftsidbarSizeTypes,
	leftSidebarViewTypes,
	leftSidebarImageTypes,
	preloaderTypes,
	sidebarVisibilitytypes,
} from '../../components/constants/layout';

const layout_mode_initial_state = localStorage.getItem('layoutMode') || layoutModeTypes.LIGHTMODE;

export const initialState = {
	layoutType: layoutTypes.HORIZONTAL,
	leftSidebarType: leftSidebarTypes.LIGHT,
	layoutModeType: layout_mode_initial_state,
	layoutWidthType: layoutWidthTypes.FLUID,
	layoutPositionType: layoutPositionTypes.FIXED,
	topbarThemeType: topbarThemeTypes.LIGHT,
	leftsidbarSizeType: leftsidbarSizeTypes.DEFAULT,
	leftSidebarViewType: leftSidebarViewTypes.DEFAULT,
	leftSidebarImageType: leftSidebarImageTypes.NONE,
	preloader: preloaderTypes.DISABLE,
	sidebarVisibilitytype: sidebarVisibilitytypes.HIDDEN,
};

const LayoutSlice = createSlice({
	name: 'LayoutSlice',
	initialState,
	reducers: {
		changeLayoutAction(state, action) {
			state.layoutType = action.payload;
		},
		changeLayoutModeAction(state, action) {
			state.layoutModeType = action.payload;
		},
		changeSidebarThemeAction(state, action) {
			state.leftSidebarType = action.payload;
		},
		changeLayoutWidthAction(state, action) {
			state.layoutWidthType = action.payload;
		},
		changeLayoutPositionAction(state, action) {
			state.layoutPositionType = action.payload;
		},
		changeTopbarThemeAction(state, action) {
			state.topbarThemeType = action.payload;
		},
		changeLeftsidebarSizeTypeAction(state, action) {
			state.leftsidbarSizeType = action.payload;
		},
		changeLeftsidebarViewTypeAction(state, action) {
			state.leftSidebarViewType = action.payload;
		},
		changeSidebarImageTypeAction(state, action) {
			state.leftSidebarImageType = action.payload;
		},
		changePreLoaderAction(state, action) {
			state.preloader = action.payload;
		},
		changeSidebarVisibilityAction(state, action) {
			state.sidebarVisibilitytype = action.payload;
		},
	},
});

export const {
	changeLayoutAction,
	changeLayoutModeAction,
	changeSidebarThemeAction,
	changeLayoutWidthAction,
	changeLayoutPositionAction,
	changeTopbarThemeAction,
	changeLeftsidebarSizeTypeAction,
	changeLeftsidebarViewTypeAction,
	changeSidebarImageTypeAction,
	changePreLoaderAction,
	changeSidebarVisibilityAction,
} = LayoutSlice.actions;

export default LayoutSlice.reducer;
