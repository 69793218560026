import { useEffect, useState } from 'react';

import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

import { getLoggedInUser } from '../../helpers/api_helper';

const useProfile = () => {
	const user_session = getLoggedInUser();

	const [userProfile, setUserProfile] = useState(user_session || null);
	const [token, setToken] = useState(user_session?.token || null);
	const [loading, setLoading] = useState(!user_session);

	// * Refresh user data on every login
	const selectUser = createSelector(
		state => state.Login,
		state => ({
			user: state.user,
			isUserLogout: state.isUserLogout,
		})
	);

	const { user, isUserLogout } = useSelector(selectUser);

	useEffect(() => {
		refreshData();
	}, [user]);

	useEffect(() => {
		if (isUserLogout) {
			setUserProfile(null);
			setToken(null);
			setLoading(true);
		}
	}, [isUserLogout]);

	const refreshData = () => {
		const new_user_session = getLoggedInUser();
		const new_token = new_user_session?.token;

		setUserProfile(new_user_session || null);
		setToken(new_token || null);
		setLoading(!new_user_session);
	};

	return { userProfile, loading, token };
};

export { useProfile };
