import { APIClient } from './api_helper';

import * as url from './url_helper';

const api = new APIClient();

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
	const user = localStorage.getItem('user');
	if (user) return JSON.parse(user);
	return null;
};

export const isUserAuthenticated = () => {
	return getLoggedInUser() !== null;
};

export const postLogin = data => api.create(url.POST_LOGIN, data);
export const postLogout = () => api.create(url.POST_LOGOUT);

export const getCombinedLogs = () => api.get(url.GET_COMBINED_LOGS);
export const getErrorLogs = () => api.get(url.GET_ERROR_LOGS);

export const getAllCompanies = () => api.get(url.GET_All_COMPANIES);
export const getCompany = () => api.get(url.GET_COMPANY);
export const postNewCompany = data =>
	api.create(url.ADD_COMPANY, data, { headers: { 'Content-Type': 'multipart/form-data' } });
export const updateCompany = data =>
	api.update(url.UPDATE_COMPANY, data, { headers: { 'Content-Type': 'multipart/form-data' } });
export const deleteCompany = data => api.delete(url.DELETE_COMPANY, data);

export const getCompanyPermissions = () => api.get(url.GET_COMPANY_PERMISSIONS);
export const updateCompanyPermissions = data => api.put(url.UPDATE_COMPANY_PERMISSIONS, data);

export const getMergedPermissions = () => api.get(url.GET_MERGED_PERMISSIONS);
export const updateMergedPermissions = data => api.update(url.UPDATE_MERGED_PERMISSIONS, data);

export const getCompanyConfigurations = () => api.get(url.GET_COMPANY_CONFIGURATIONS);
export const updateCompanyConfigurations = data => api.put(url.UPDATE_COMPANY_CONFIGURATIONS, data);

export const getMergedConfigurations = () => api.get(url.GET_MERGED_CONFIGURATIONS);
export const updateMergedConfigurations = data => api.update(url.UPDATE_MERGED_CONFIGURATIONS, data);
export const replaceMergedConfigurations = data => api.put(url.REPLACE_MERGED_CONFIGURATIONS, data);

export const getAllUsers = () => api.get(url.GET_ALL_USERS);
export const postNewUser = data => api.create(url.ADD_USER, data);
export const updateUser = data => api.update(url.UPDATE_USER, data);
export const deleteUser = data => api.delete(url.DELETE_USER, data);

export const getAllOrders = () => api.get(url.GET_ALL_ORDERS);
export const getAllOrderItems = order_id => api.get(`${url.GET_ALL_ORDER_ITEMS}/${order_id}`);
export const addNewOrder = data => api.create(url.ADD_ORDER, data);

export const getAllClientOrders = () => api.get(url.GET_ALL_CLIENT_ORDERS);
export const getClientOrderFilters = () => api.get(url.GET_CLIENT_ORDER_FILTERS);

export const getApiToken = () => api.get(url.GET_API_TOKEN);
export const refreshApiToken = () => api.create(url.REFRESH_API_TOKEN);

export const getMaximumAgentUsers = () => api.get(url.GET_MAXIMUM_AGENT_USERS);

export const getPaginatedClients = data => api.get(url.GET_PAGINATED_CLIENTS, data);
export const getPaginatedSalesAgents = data => api.get(url.GET_PAGINATED_SALES_AGENT, data);
export const getPaginatedCollectionsAgents = data => api.get(url.GET_PAGINATED_COLLECTIONS_AGENT, data);
export const getPaginatedDeliveryAgents = data => api.get(url.GET_PAGINATED_DELIVERY_AGENT, data);
export const getPaginatedProducts = data => api.get(url.GET_PAGINATED_PRODUCTS, data);

export const getAssignments = () => api.get(url.GET_ASSIGNMENTS);
export const replaceAssignments = data => api.create(url.REPLACE_ASSIGNMENTS, data);

export const getAllPaymentCollections = () => api.get(url.GET_ALL_PAYMENT_COLLECTIONS);
export const getAllPaymentCollectionDebts = payment_collection_id =>
	api.get(`${url.GET_ALL_PAYMENT_COLLECTION_DEBTS}/${payment_collection_id}`);
export const getAllPaymentCollectionMethods = payment_collection_id =>
	api.get(`${url.GET_ALL_PAYMENT_COLLECTION_METHODS}/${payment_collection_id}`);

export const getAllNewClients = () => api.get(url.GET_ALL_NEW_CLIENTS);

export const getUserTracking = (user_id, data) => api.get(`${url.GET_USER_TRACKING}/${user_id}`, data);
export const getUsersLastPosition = () => api.get(url.GET_USERS_LAST_POSITION);
