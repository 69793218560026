import React from 'react';

import { Login } from '../pages/Authentication/Login';

import { Companies } from '../pages/Companies';
import { Company } from '../pages/Company';

import { Orders } from '../pages/Orders';
import { OrderDetails } from '../pages/Orders/OrderDetails';

import { ClientOrders } from '../pages/ClientOrders';
import { ClientOrderDetails } from '../pages/ClientOrders/ClientOrderDetails';

import { PaymentCollections } from '../pages/PaymentCollections';
import { PaymentCollectionDetails } from '../pages/PaymentCollections/PaymentCollectionDetails';

import { NewClients } from '../pages/NewClients';
import { NewClientDetails } from '../pages/NewClients/NewClientDetails';

import { Users } from '../pages/Users';
import { UserPermissions } from '../pages/UserPermissions';
import { UserConfigurations } from '../pages/UserConfigurations';

import { AgentsTracking } from '../pages/AgentsTracking';

import { ProductsSelection } from '../pages/NewOrder/ProductsSelection';
import { Cart } from '../pages/NewOrder/Cart';
import { Checkout } from '../pages/NewOrder/Checkout';
import { OrderSuccessfullySent } from '../pages/NewOrder/OrderSuccessfullySent';

import { Error404 } from '../pages/AuthenticationInner/Errors/Error404';
import { Error500 } from '../pages/AuthenticationInner/Errors/Error500';

const publicRoutes = [
	{ path: '/login', component: <Login /> },

	{ path: '/*', component: <Error404 /> },
	{ path: '/500', component: <Error500 /> },
];

const authenticatedRoutes = [
	{ path: '/companies', roles: ['admin'], component: <Companies /> },
	{ path: '/company/:company_id', roles: ['admin'], component: <Company /> },

	{ path: '/orders', roles: ['admin', 'company_owner'], component: <Orders /> },
	{ path: '/order/:order_id/items', roles: ['admin', 'company_owner'], component: <OrderDetails /> },

	{ path: '/payment-collections', roles: ['admin', 'company_owner'], component: <PaymentCollections /> },
	{
		path: '/payment-collection/:payment_collection_id/details',
		roles: ['admin', 'company_owner'],
		component: <PaymentCollectionDetails />,
	},

	{ path: '/new-clients', roles: ['admin', 'company_owner'], component: <NewClients /> },
	{ path: '/new-client/:new_client_id', roles: ['admin', 'company_owner'], component: <NewClientDetails /> },

	{ path: '/client-orders', roles: ['admin', 'company_client'], component: <ClientOrders /> },
	{ path: '/client-order/:order_id/items', roles: ['admin', 'company_client'], component: <ClientOrderDetails /> },

	{ path: '/users', roles: ['admin', 'company_owner'], component: <Users /> },

	{ path: '/user/:user_id/permissions', roles: ['admin', 'company_owner'], component: <UserPermissions /> },
	{ path: '/user/:user_id/configurations', roles: ['admin', 'company_owner'], component: <UserConfigurations /> },

	{ path: '/tracking', roles: ['company_owner'], component: <AgentsTracking /> },

	{ path: '/new-order', roles: ['admin', 'company_client'], component: <ProductsSelection /> },
	{ path: '/cart', roles: ['admin', 'company_client'], component: <Cart /> },
	{ path: '/checkout', roles: ['admin', 'company_client'], component: <Checkout /> },
	{ path: '/order-successfully-sent', roles: ['admin', 'company_client'], component: <OrderSuccessfullySent /> },
];

const defaultRoutes = {
	admin: '/companies',
	company_owner: '/orders',
	company_client: '/client-orders',
	default: '/login',
};

export { authenticatedRoutes, publicRoutes, defaultRoutes };
