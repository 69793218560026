import { createSlice } from '@reduxjs/toolkit';

import { loginUser } from './thunk';
import { resetAuthState } from '../../../actions/resetState';

export const initialState = {
	user: {},
	error: '',
	loading: false,
	isUserLogout: false,
};

const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Login
		builder.addCase(loginUser.pending, state => {
			state.error = null;
			state.loading = true;
		});

		builder.addCase(loginUser.fulfilled, (state, action) => {
			state.user = action.payload;
			state.loading = false;
			state.isUserLogout = false;
		});

		builder.addCase(loginUser.rejected, (state, action) => {
			state.error = action?.error || null;
			state.loading = false;
		});

		// * Reset state
		builder.addCase(resetAuthState, () => ({ ...initialState, isUserLogout: true }));
	},
});

export default loginSlice.reducer;
