import { createSlice } from '@reduxjs/toolkit';

import { getAllCompanies, addNewCompany, deleteCompany } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	companies: [],
	error: null,

	isCompanyAdded: false,
	isCompanyAddedFail: false,

	isCompanyDelete: false,
	isCompanyDeleteFail: false,
};

const companiesSlice = createSlice({
	name: 'companies',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getAllCompanies.fulfilled, (state, action) => {
			state.companies = action.payload?.data?.companies || [];
		});

		builder.addCase(getAllCompanies.rejected, (state, action) => {
			state.error = action?.error || null;
		});

		// * Add
		builder.addCase(addNewCompany.pending, state => {
			state.isCompanyAdded = false;
			state.isCompanyAddedFail = false;
		});

		builder.addCase(addNewCompany.fulfilled, (state, action) => {
			state.companies.push(action.payload);

			state.isCompanyAdded = true;
			state.isCompanyAddedFail = false;
		});

		builder.addCase(addNewCompany.rejected, (state, action) => {
			state.error = action?.error || null;

			state.isCompanyAdded = false;
			state.isCompanyAddedFail = true;
		});

		// * Delete
		builder.addCase(deleteCompany.pending, state => {
			state.isCompanyDelete = false;
			state.isCompanyDeleteFail = false;
		});

		builder.addCase(deleteCompany.fulfilled, (state, action) => {
			state.companies = (state.companies || []).filter(
				item => item.company_id.toString() !== action.payload.company_id.toString()
			);

			state.isCompanyDelete = true;
			state.isCompanyDeleteFail = false;
		});

		builder.addCase(deleteCompany.rejected, (state, action) => {
			state.error = action?.error || null;

			state.isCompanyDelete = false;
			state.isCompanyDeleteFail = true;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default companiesSlice.reducer;
