import { createSlice } from '@reduxjs/toolkit';

import { getAllOrders } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	orders: [],
	error: null,
};

const ordersSlice = createSlice({
	name: 'orders',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getAllOrders.fulfilled, (state, action) => {
			state.orders = action.payload?.data?.orders || [];
		});

		builder.addCase(getAllOrders.rejected, (state, action) => {
			state.error = action?.error || null;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default ordersSlice.reducer;
