import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { getApiToken as getApiTokenAPI, refreshApiToken as refreshApiTokenAPI } from '../../helpers/backend_helper';

export const getApiToken = createAsyncThunk('apiToken/get', async () => {
	return getApiTokenAPI();
});

export const refreshApiToken = createAsyncThunk('apiToken/refresh', async data => {
	try {
		const response = await refreshApiTokenAPI(data);

		if (!response.success) {
			throw new Error(response);
		}

		toast.success('Actualización de API Token realizada con éxito', { autoClose: 3000 });

		return response;
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error';

		toast.error(message, { autoClose: 3000 });
		throw error;
	}
});
