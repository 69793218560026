import React, { useLayoutEffect } from 'react';

import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { Routes } from './routes';
import { addAxiosRequestInterceptors, addAxiosResponseInterceptors } from './helpers/api_helper';
import { logoutClear } from './slices/thunks';

import './assets/scss/themes.scss';

function App() {
	const dispatch = useDispatch();

	// * Workaround. It's necessary to use useLayoutEffect() instead useEffect() in order to execute this code before children useEffects
	useLayoutEffect(() => {
		addAxiosRequestInterceptors(
			request => {
				// * Add "X-Request-Uuid" header to all POST requests, in order to avoid duplications
				if (request.method === 'post') {
					request.headers['X-Request-Uuid'] = uuidv4();
				}

				return request;
			},
			error => error
		);
	}, []);

	useLayoutEffect(() => {
		addAxiosResponseInterceptors(
			response => response?.data || response,
			error => {
				// * Verify if user token has expired
				if (
					error?.response &&
					error.response.status === 401 &&
					error.response.data?.error === 'expired-token'
				) {
					setTimeout(() => {
						toast.error('La sesión ha expirado.', { toastId: 'session-expired', autoClose: 3000 });
					}, 100);

					dispatch(logoutClear());
				}

				const { error: name, message, status: code, stack } = error.response?.data;

				// eslint-disable-next-line prefer-promise-reject-errors
				return Promise.reject({
					name,
					message,
					code: String(code),
					stack,
				});
			}
		);
	}, []);

	return <Routes />;
}

export { App };
