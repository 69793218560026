import { createSlice } from '@reduxjs/toolkit';

import { getAllOrderItems } from './thunk';
import { resetState } from '../../actions/resetState';

export const initialState = {
	order_items: [],
	loading_items: false,
	error: null,
};

const orderItemsSlice = createSlice({
	name: 'order_items',
	initialState,
	reducers: {},

	extraReducers: builder => {
		// * Get
		builder.addCase(getAllOrderItems.pending, state => {
			state.loading_items = true;
		});

		builder.addCase(getAllOrderItems.fulfilled, (state, action) => {
			state.order_items = action.payload?.data?.order_items || [];
			state.loading_items = false;
		});

		builder.addCase(getAllOrderItems.rejected, (state, action) => {
			state.error = action?.error || null;
			state.loading_items = false;
		});

		// * Reset state
		builder.addCase(resetState, () => initialState);
	},
});

export default orderItemsSlice.reducer;
