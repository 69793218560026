import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { getCompany as getCompanyAPI, updateCompany as updateCompanyAPI } from '../../helpers/backend_helper';

export const getCompany = createAsyncThunk('companies/own', async () => {
	return getCompanyAPI();
});

export const updateCompany = createAsyncThunk('companies/updateCompany', async data => {
	try {
		const response = await updateCompanyAPI(data);

		if (!response.success) {
			throw new Error(response);
		}

		toast.success('Edición de empresa realizada con éxito', { autoClose: 3000 });

		const form_data = data;
		const { logo_file } = response.data;

		return {
			logo_file,
			form_data,
		};
	} catch (error) {
		const message = error?.message || 'Ha ocurrido un error';

		toast.error(message, { autoClose: 3000 });
		throw error;
	}
});
